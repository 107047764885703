import type { SelectChangeEvent } from '@mui/material';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import React from 'react';
import ShowSpinner from '../../components/ShowSpinner';
import type { DefaultFeature, Entity, Feature } from './api';
import { useGetDefaultFeatures, useGetFeaturesList } from './api';
import { EntitySelect } from './EntitySelect';
import styles from './ManageFeatures.module.css';
import { PercentageSlider } from './PercentageSlider';
import type { WithAddDefaultFeatureProps } from './useAddDefaultFeature';

const mapFeature = (feature: Feature, i: number) => (
  <MenuItem value={feature.name} key={`feature_${i}`} title={feature.label}>
    {feature.name}
  </MenuItem>
);

const createFeatureNotDefault = (feature: Feature) => {
  return (defaultFeature: DefaultFeature) =>
    feature.name !== defaultFeature.feature;
};

const createDefaultsFilter = (
  defaultFeatures: DefaultFeature[],
  entity: DefaultFeature['entity']
) => {
  const entityDefaultFeatures = defaultFeatures.filter(
    (feature) => feature.entity === entity
  );

  return (feature: Feature): boolean => {
    const featureNotDefault = createFeatureNotDefault(feature);
    return entityDefaultFeatures.every(featureNotDefault);
  };
};

const sortFeatures = (a: Feature, b: Feature) => a.name.localeCompare(b.name);

export const AddDefaultFeatureForm = ({
  data,
  dispatch,
}: WithAddDefaultFeatureProps): JSX.Element => {
  const { data: allFeatures } = useGetFeaturesList();
  const { data: defaultFeatures } = useGetDefaultFeatures();

  if (allFeatures == null || defaultFeatures == null) {
    return <ShowSpinner />;
  }

  let filteredFeatures: Feature[];
  if (defaultFeatures.length > 0) {
    const defaultsFilter = createDefaultsFilter(defaultFeatures, data.entity);
    filteredFeatures = allFeatures.filter(defaultsFilter);
  } else {
    filteredFeatures = allFeatures;
  }

  const featuresList = filteredFeatures.sort(sortFeatures).map(mapFeature);

  const handleEntityChange = (value: Entity) => {
    dispatch({ type: 'setEntity', payload: value });
  };

  const handleFeatureChange = (event: SelectChangeEvent) => {
    dispatch({ type: 'setFeature', payload: event.target.value });
  };

  const handlePercentageChange = (value: number) => {
    dispatch({ type: 'setPercentage', payload: value });
  };

  return (
    <form className={styles.form}>
      {data.submitting && (
        <div className={styles.overlay}>
          <ShowSpinner />
        </div>
      )}
      <div className={styles.selects}>
        <EntitySelect value={data.entity} onValueChange={handleEntityChange} />

        <FormControl fullWidth className={styles.featureSelect}>
          <InputLabel id="label_add-default-feature_feature">
            Feature
          </InputLabel>
          <Select
            labelId="label_add-default-feature_feature"
            id="input_add-default-feature_feature"
            label="Select feature"
            value={data.feature}
            onChange={handleFeatureChange}
            inputProps={{
              'aria-invalid': data.featureError != null,
              'aria-errormessage': 'error_add-default-feature_feature',
            }}
          >
            {featuresList}
          </Select>
          <FormHelperText
            id="error_add-default-feature_feature"
            role="alert"
            aria-label={data.featureError}
            error={data.featureError != null}
          >
            {data.featureError ?? ' '}
          </FormHelperText>
        </FormControl>
      </div>

      <PercentageSlider
        value={data.percentage}
        onValueChange={handlePercentageChange}
      />

      <FormHelperText
        role="alert"
        aria-label={data.submitError}
        error={data.submitError != null}
      >
        {data.submitError ?? ' '}
      </FormHelperText>
    </form>
  );
};
