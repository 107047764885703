import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import type { AccountServicePeriods } from '../models/service-alp.model';
import { queryKeys, fetchAccountServicePeriodsURL } from './urls';

export type { AccountServicePeriods };

interface Response {
  service_periods: AccountServicePeriods;
}

export function useAccountServicePeriods({
  accountId,
  enabled,
}: {
  accountId: number | undefined;
  enabled: boolean;
}) {
  const { data, isLoading } = useQuery({
    queryKey: queryKeys.accountServicePeriods(accountId),
    queryFn: async () => {
      if (!accountId) {
        return undefined;
      }

      try {
        const response = await fetch(fetchAccountServicePeriodsURL(accountId));
        const returnData: Response = await response.json();
        return returnData.service_periods;
      } catch (error: unknown) {
        if (error instanceof Error) {
          throw new Error(
            `Error getting Account Service Periods: ${error.message}.`
          );
        }
      }
    },
    enabled: !isNil(accountId) && enabled,
  });

  return {
    accountServicePeriodsData: data,
    isLoading,
  };
}
