import _ from 'lodash';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from '../../query-client-provider';
import { apiGet, apiPost, apiPut } from '../../utils/api';

export type Entity = 'account' | 'organization' | 'site';

export type Feature = {
  client_script_exposed?: boolean;
  name: string;
  description: string;
  label: string;
};

export type ResponseWithFeatures = {
  feature_flags: Feature[];
  success: boolean;
};

export type PostFeatureToggleProps = {
  changeBy: 'emails' | 'account_ids';
  identifiers: string;
  feature: Feature['name'];
  action: 'enable_feature' | 'disable_feature';
};

/**
 * @deprecated
 */
export function postFeatureToggle({
  changeBy,
  identifiers,
  feature,
  action,
}: PostFeatureToggleProps) {
  const params = {
    feature,
    identifiers: _.uniq(identifiers.split(',')),
    identifier_type: changeBy,
  };

  return apiPost('/features', action, params, 2).then((data) => {
    if (data.success) {
      return Promise.resolve(data.message);
    } else {
      return Promise.reject(data);
    }
  });
}

const getFeaturesList = (): Promise<ResponseWithFeatures> =>
  apiGet('/features/list');

export const useGetFeaturesList = (): UseQueryResult<Feature[], Error> =>
  useQuery({
    queryKey: ['useGetFeaturesList'],
    queryFn: async () => {
      const { feature_flags } = await getFeaturesList();

      return feature_flags;
    },
  });

type AssignFeatureProps = {
  entity: Entity;
  entityIds: number[];
  feature: string;
};

export const postFeaturesAssignAdd = ({
  entity,
  entityIds,
  feature,
}: AssignFeatureProps): Promise<void> =>
  apiPost(
    '/features/assign',
    'add',
    {
      entity,
      entity_ids: entityIds,
      feature,
    },
    2
  );

export const postFeaturesAssignRemove = ({
  entity,
  entityIds,
  feature,
}: AssignFeatureProps): Promise<void> =>
  apiPost(
    '/features/assign',
    'remove',
    {
      entity,
      entity_ids: entityIds,
      feature,
    },
    2
  );

type FeaturesStartUpdateProps = {
  entity: Entity;
  feature: string;
  percentage: number;
  jiraId: string;
};

export const postFeaturesStartUpdateAdd = ({
  entity,
  feature,
  percentage,
  jiraId,
}: FeaturesStartUpdateProps): Promise<void> =>
  apiPost(
    '/features/start-update',
    'add',
    { entity, feature, percentage, jira_id: jiraId },
    2
  );

export const postFeaturesStartUpdateRemove = ({
  entity,
  feature,
  percentage,
  jiraId,
}: FeaturesStartUpdateProps) =>
  apiPost(
    '/features/start-update',
    'remove',
    { entity, feature, percentage, jira_id: jiraId },
    2
  );

export type DefaultFeature = {
  entity: Entity;
  feature: string;
  hardcoded: boolean;
  percentage: number;
};

export type ResponseWithDefaultFeatures = {
  feature_flags: DefaultFeature[];
  success: boolean;
};

export const getDefaultFeatures = (): Promise<ResponseWithDefaultFeatures> =>
  apiGet('/features/defaults');

const defaultFeaturesQueryKey = ['useGetDefaultFeatures'];
export const useGetDefaultFeatures = (): UseQueryResult<
  DefaultFeature[],
  Error
> =>
  useQuery({
    queryKey: defaultFeaturesQueryKey,
    queryFn: async () => {
      const { feature_flags } = await getDefaultFeatures();

      return feature_flags;
    },
  });

const invalidateDefaultFeatures = () =>
  queryClient.invalidateQueries({ queryKey: defaultFeaturesQueryKey });

export type SetDefaultFeatureProps = Pick<
  DefaultFeature,
  'entity' | 'feature' | 'percentage'
>;

export type ApiError = {
  message: string;
  response: Response;
};

export const setDefaultFeature = async (
  params: SetDefaultFeatureProps
): Promise<void> => {
  const response = await apiPut('/features/defaults', params, 2);

  await invalidateDefaultFeatures();

  return response;
};

type DeleteDefaultFeatureProps = {
  entity: DefaultFeature['entity'];
  feature: string;
};

export const deleteDefaultFeature = async (
  params: DeleteDefaultFeatureProps
): Promise<void> => {
  const response = await apiPost(
    '/features/defaults/delete',
    undefined,
    params,
    2
  );

  await invalidateDefaultFeatures();

  return response;
};
