import { bindDialog, usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import RestoreIcon from '@mui/icons-material/Restore';
import { ActionButton } from './ActionButton';
import { postFeaturesStartUpdateRemove } from './api';
import { ManageFeatureDialog } from './ManageFeatureDialog';
import styles from './ManageFeatures.module.css';
import { StartUpdateFeatureForm } from './StartUpdateFeatureForm';
import type { FeatureAction } from './useFeatureActionForm';
import type { StartUpdateFeatureFields } from './useStartUpdateFeatureForm';
import { useStartUpdateFeatureForm } from './useStartUpdateFeatureForm';

const action: FeatureAction<StartUpdateFeatureFields> =
  postFeaturesStartUpdateRemove;

type StartUpdateRemoveFeatureProps = {
  feature: string;
};

export const StartUpdateRemoveFeature = ({
  feature,
}: StartUpdateRemoveFeatureProps): JSX.Element => {
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'start-update-remove-feature',
  });
  const [form, handleSubmit] = useStartUpdateFeatureForm({
    action,
    feature,
    close: popupState.close,
  });

  return (
    <>
      <ActionButton
        action={popupState.open}
        Icon={RestoreIcon}
        title="Remove from percentage"
        busyMessage={`Starting update for feature ${feature}`}
      />

      <ManageFeatureDialog
        title={
          <>
            Remove feature <span className={styles.featureName}>{feature}</span>{' '}
            by percentage
          </>
        }
        onSubmit={handleSubmit}
        {...bindDialog(popupState)}
      >
        <StartUpdateFeatureForm mode="remove" feature={feature} form={form} />
      </ManageFeatureDialog>
    </>
  );
};
