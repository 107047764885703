import { apiPost } from '../../../utils/api';

export const inviteUser = (email, access, org_id, adm_action_annotation) => {
  return apiPost(
    `/organization/${org_id}`,
    'invite',
    { email, access, adm_action_annotation },
    2
  );
};
