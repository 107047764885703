import { Checkbox, FormControlLabel } from '@mui/material';
import { DOMAIN_USERS_PERMISSIONS_MAP } from './constants';

const ACCOUNT_OWNER_PERMISSION_FILTER = 'account_owner';
const ONLY_ACCOUNT_OWNERS_FILTER_LABEL = 'Only Account owners';

const ONLY_PAYING_CUSTOMERS_FILTER = 'only_paying_customers';
const ONLY_PAYING_CUSTOMERS_FILTER_LABEL = 'Only Paying customers';

const filterDisplayRenderer =
  (filterLabel, filterName, isDisabled) =>
  (filterList, onChange, index, column) =>
    (
      <FormControlLabel
        label={filterLabel}
        sx={{
          minWidth: '30ch',
        }}
        control={
          <Checkbox
            disabled={isDisabled}
            checked={filterList[index].includes(filterName)}
            onChange={(event) => {
              onChange(event.target.checked ? [filterName] : [], index, column);
            }}
          />
        }
      />
    );

// ********************
// Account owner filter
// ********************

export const accountOwnerCustomFilterListOptions = {
  render: (columnFilterList) =>
    columnFilterList.map((filter) =>
      filter === ACCOUNT_OWNER_PERMISSION_FILTER
        ? ONLY_ACCOUNT_OWNERS_FILTER_LABEL
        : filter
    ),
};

export const accountOwnerFilterOptions = {
  logic: (permission, filters) => {
    if (filters.length) {
      return !(DOMAIN_USERS_PERMISSIONS_MAP[filters[0]] === permission);
    }
    return false;
  },
  display: filterDisplayRenderer(
    ONLY_ACCOUNT_OWNERS_FILTER_LABEL,
    ACCOUNT_OWNER_PERMISSION_FILTER
  ),
};

// ***************************
// Only paying customer filter
// ***************************

export const isPayingCustomerCustomFilterListOptions = {
  render: (columnFilterList) =>
    columnFilterList.map((filter) =>
      filter === ONLY_PAYING_CUSTOMERS_FILTER
        ? ONLY_PAYING_CUSTOMERS_FILTER_LABEL
        : filter
    ),
};

export const isPayingCustomerFilterOptions = (hasCustomerData) => ({
  logic: (isPayingCustomer, filters) => {
    if (filters.length) {
      return !(filters[0] === ONLY_PAYING_CUSTOMERS_FILTER && isPayingCustomer);
    }
    return false;
  },
  display: filterDisplayRenderer(
    ONLY_PAYING_CUSTOMERS_FILTER_LABEL,
    ONLY_PAYING_CUSTOMERS_FILTER,
    !hasCustomerData
  ),
});
