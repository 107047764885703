import { Checkbox, FormControlLabel } from '@mui/material';

interface Props {
  skipTaxation: boolean;
  handleUpdateField: () => void;
}
export function SkipTaxationField({ handleUpdateField, skipTaxation }: Props) {
  return (
    <FormControlLabel
      label="Skip Taxation"
      control={
        <Checkbox
          id="skipTaxation"
          onChange={handleUpdateField}
          checked={skipTaxation}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
        />
      }
    />
  );
}
