import { FC } from 'react';
import BlockIcon from '@mui/icons-material/Block';
import IconBadge, { IconBadgeProps } from '../../../../components/IconBadge';
import { itemCountToString } from '../../../../utils/itemCountToString';

interface IgnoredIpBadgeProps extends Pick<IconBadgeProps, 'handleOnClick'> {
  ignoredIpsCount?: number;
}

const IgnoredIpBadge: FC<IgnoredIpBadgeProps> = ({
  ignoredIpsCount,
  handleOnClick,
}) => {
  const iPCount = itemCountToString(ignoredIpsCount);

  return (
    <IconBadge
      badgeText={iPCount}
      icon={<BlockIcon />}
      tooltip="Ignored IPs"
      handleOnClick={handleOnClick}
    />
  );
};

export default IgnoredIpBadge;
