import { getBillingV3Info } from './billingV3.functions';

const hasOneOrgWithSize1to10 = (organizations, organizationsLookup) => {
  if (!organizations || organizations?.length > 1 || !organizationsLookup) {
    return false;
  }

  if (!organizationsLookup) {
    return false;
  }

  return organizationsLookup[organizations[0]]?.size === '1-10';
};

/*
  * A User is eligible when:
    1. It is not part of the UBP experiment(only variant)
    2. Has only ONE Organization with size 1-10
    3. The Account Type is not an "agency"
    4. The current date is earlier than CONVERSION_OFFER_CAMPAIGN_END_DATE
    5. The account has no subscription
    6. The account is not excluded(enabling the up_conversion_offers_startup_exclusion from ADM)
  * */
export const isConversionOfferEligible = (props) => {
  const { features, type, magpie, organizations, organizationsLookup } = props;
  const { billingV3Unconverted } = getBillingV3Info(magpie, features);

  // It is not part of the UBP experiment(only variant)
  const isUBP = features?.includes('pp_usage_based_pricing_phase_0_variant1');

  // When an account has the up_conversion_offers_startup_exclusion ff is excluded from the experiment
  const isAccountExcluded = features?.includes(
    'up_conversion_offers_startup_exclusion'
  );

  // The Account Type is not an "agency"
  const isAgency = (type && type === 'agency') || false;

  return (
    hasOneOrgWithSize1to10(organizations, organizationsLookup) &&
    !isAccountExcluded &&
    !isUBP &&
    !isAgency &&
    billingV3Unconverted
  );
};
