import React from 'react';

import { UserConsumer } from '../contexts/UserContext';

import _ from 'lodash';

const checkPermission = (userRoles, rolesAccepted) => {
  if (rolesAccepted && rolesAccepted.length > 0)
    return _.intersection(userRoles, rolesAccepted).length > 0;
  else return true;
};

const HasPermission = (props) => (
  <UserConsumer>
    {(user) =>
      checkPermission(user.roleNames, props.rolesAccepted)
        ? props.children
        : props.message || null
    }
  </UserConsumer>
);

export default HasPermission;

HasPermission.defaultProps = {
  rolesAccepted: [],
};
