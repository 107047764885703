import { apiPost, apiPut } from '../../../utils/api';

export const modifyPlanSettings = (
  entityType,
  id,
  sample_rate,
  always_on_rate,
  lock_plan,
  plan_id,
  annotation,
  percentage_discount,
  site_ids
) => {
  if (entityType === 'organization') {
    return apiPut('/' + entityType + '/' + id, {
      sample_rate,
      always_on_rate,
      lock_plan,
      adm_action_annotation: annotation,
    });
  } else {
    return apiPost('/sites', 'bulk_update_site_plan', {
      site_ids,
      sample_rate,
      plan_id,
      always_on_rate,
      lock_plan,
      percentage_discount,
      adm_action_annotation: annotation,
    });
  }
};
