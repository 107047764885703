import React from 'react';
import styles from './DisplayOrganization.module.css';
import MUIDataTable from 'mui-datatables';
import { useUserContext } from '../../contexts/UserContext';
import DeleteInvitation from '../../components/DeleteInvitation';

const OrganizationInvitationsTable = ({
  invitations,
  org_id,
  org_name,
  onSuccess,
}) => {
  const getColumnSettings = (roleNames) => {
    const columnSettings = ['ID', 'Email', 'Access Level'];

    const hasDeletePermission =
      roleNames.includes('admin') ||
      roleNames.includes('insights_user_manager');
    hasDeletePermission &&
      columnSettings.push({
        name: 'Delete Invitation',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (i) => {
            return (
              <div className={styles.removeButtonCell}>
                <DeleteInvitation
                  invitation={i}
                  org_id={org_id}
                  org_name={org_name}
                  onSuccess={onSuccess}
                />
              </div>
            );
          },
        },
      });

    return columnSettings;
  };

  const getTableSettings = () => ({
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    pagination: false,
    print: false,
    download: false,
    viewColumns: false,
  });

  const invites = invitations.map((i) => [i.id, i.email, i.access, i]);
  const user = useUserContext();

  return (
    <div className={styles.usersTableContainer}>
      <React.Fragment>
        <MUIDataTable
          data={invites}
          columns={getColumnSettings(user.roleNames)}
          options={getTableSettings()}
        />
      </React.Fragment>
    </div>
  );
};

export default OrganizationInvitationsTable;
