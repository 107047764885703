import React, { Component } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import t from 'tcomb-validation';
import { Email, RequiredString } from '../../../utils/validators';
import styles from './InviteUser.module.css';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        access: null,
      },
      access: 'read',
      email: '',
      adm_action_annotation: '',
    };
  }

  validators = {
    email: {
      validator: Email,
      cast: (s) => {
        return s.trim();
      },
    },
    adm_action_annotation: {
      validator: RequiredString,
      cast: (s) => {
        return s.trim();
      },
    },
    access: {
      validator: RequiredString,
      cast: (s) => {
        return s;
      },
    },
  };

  validate = () => {
    let errors = {},
      valid = true;

    for (let field in this.validators) {
      let value = this.validators[field].cast(this.state[field]);
      let status = t.validate(value, this.validators[field].validator);

      if (status.isValid()) {
        this.setState({ [field]: value });
        errors[field] = null;
      } else {
        errors[field] = status.firstError().message;
        valid = false;
      }
    }
    this.setState({ errors: errors });
    return valid;
  };

  handleAccessFieldChange = (event) => {
    this.setState({
      errors: {
        ...this.state.errors,
        access: null,
      },
      access: event.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.onFormSubmit(this.state);
    }
  };

  handleTextFieldChange = (event) => {
    let fieldName = event.target.name,
      errors = this.state.errors;
    errors[fieldName] = null;
    this.setState({
      errors: errors,
      [fieldName]: event.target.value,
    });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className={styles.form}>
        <FormControl
          className={styles.formControl}
          error={this.state.errors.access !== null ? true : false}
        >
          <InputLabel htmlFor="access-select">Access Level</InputLabel>
          <Select
            native
            className={styles.select}
            value={this.state.access}
            onChange={this.handleAccessFieldChange}
            inputProps={{
              name: 'access-select',
              id: 'access-select',
            }}
            data-testid="access-select"
            variant="standard"
          >
            <option value="read">Read Only</option>
            <option value="write">Write</option>
            <option value="admin">Admin</option>
          </Select>
          <FormHelperText>{this.state.errors.access}</FormHelperText>
        </FormControl>
        <br />
        <TextField
          required
          className={styles.textField}
          id="email"
          name="email"
          label="Email"
          value={this.state.email}
          onChange={this.handleTextFieldChange}
          error={!!this.state.errors.email}
          placeholder="Email of user to invite"
          helperText={this.state.errors.email || ''}
          variant="standard"
        />
        <br />
        <TextField
          multiline
          required
          value={this.state.adm_action_annotation}
          onChange={this.handleTextFieldChange}
          error={!!this.state.errors.adm_action_annotation}
          label="Annotation"
          name="adm_action_annotation"
          id="adm_action_annotation"
          helperText={
            this.state.errors.adm_action_annotation ||
            'Please provide reason for change and/or Zendesk ticket link'
          }
          variant="standard"
        />
      </form>
    );
  }
}
