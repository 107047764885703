import type { ProductCatalogItem } from '../../../typings';
import {
  createAccountEntity,
  isBasicAllowance,
} from '../../../containers/Lookup/billingV3.functions';

export const hasSelectedPlanChanged = (
  enrichedMagpieSubscriptions: ProductCatalogItem[],
  newProductInfo: ProductCatalogItem
) => {
  const magpieSubscriptionProductInfo = enrichedMagpieSubscriptions.find(
    (subscriptionProductInfo) =>
      subscriptionProductInfo.productName === newProductInfo.productName
  );
  return magpieSubscriptionProductInfo?.sku !== newProductInfo.sku;
};

export interface EntityProductDescription {
  sku: `SKU-${number}`;
  entity: {
    entityIdentifier: number;
    entityLabel: string;
    entityType: string;
  };
}
export const preparePayload = (
  selectedPlans: ProductCatalogItem[],
  currentPlans: ProductCatalogItem[],
  allPlans: ProductCatalogItem[],
  accountId: string
): {
  productsToAdd: EntityProductDescription[];
  productsToRemove: EntityProductDescription[];
} => {
  const changedPlans = selectedPlans.filter((plan) =>
    hasSelectedPlanChanged(currentPlans, plan)
  );
  const activePlans =
    currentPlans.filter((productPlan) =>
      changedPlans.some((plan) => plan.productName === productPlan.productName)
    ) ?? [];

  const productsToAdd = changedPlans
    .filter((selectedPlan) => !isBasicAllowance(selectedPlan.sku, allPlans))
    .map(({ sku }) => ({ sku, entity: createAccountEntity(accountId) }));

  const productsToRemove = activePlans
    .filter((activePlan) =>
      changedPlans.some(
        (selectedPlan) =>
          selectedPlan.productName === activePlan.productName &&
          selectedPlan.sku !== activePlan.sku
      )
    )
    .map(({ sku }) => ({ sku, entity: createAccountEntity(accountId) }));

  return { productsToAdd, productsToRemove };
};
