import { FC } from 'react';
import { Typography, Breadcrumbs } from '@mui/material';
import { NavLink } from 'react-router-dom';

import styles from './SiteBreadCrumbs.module.css';

interface SiteBreadCrumbsProps {
  siteId: number;
  accountId: number;
  organizationId: number;
}

const SiteBreadCrumbs: FC<SiteBreadCrumbsProps> = ({
  siteId,
  accountId,
  organizationId,
}) => (
  <Breadcrumbs
    className={styles.breadCrumbs}
    aria-label="Breacrumb"
    separator="›"
  >
    <NavLink to={`/lookup/account_id/${accountId}`}>
      Account {accountId}
    </NavLink>
    <Typography>Organization {organizationId}</Typography>
    <Typography>Site {siteId}</Typography>
  </Breadcrumbs>
);

export default SiteBreadCrumbs;
