import { apiGet, apiPost } from '../../utils/api';

export const getAccountData = (search_by, search_term, page = 1) => {
  if (search_by === 'account_id') {
    return apiGet(`/account/${search_term}?page=${page}`, {});
  } else {
    let params = {};
    params[search_by] = search_term;
    params['page'] = page;
    return apiGet('/accounts', params).then((data) => {
      return data[0];
    });
  }
};

export const getUserData = (search_by, search_term) => {
  if (search_by === 'user_id') {
    return apiGet('/user/' + search_term, {});
  } else {
    let params = {};
    params[search_by] = search_term;
    return apiPost('/users', 'search', params).then((data) => {
      return data[0];
    });
  }
};

export const removePaymentDetails = (
  account_id,
  verification,
  adm_action_annotation
) => {
  return apiPost('/account/' + account_id, 'remove_payment_details', {
    adm_action_annotation,
    verification,
  });
};

export const undeleteSite = ({ id }) => apiPost('/site/' + id, 'undelete');

export const undeleteUser = ({ id }) => apiPost('/account/' + id, 'undelete');

export const undeleteOrganization = ({ id }) =>
  apiPost('/organization/' + id, 'undelete');

export const getFeatures = () =>
  apiGet('/features', {}, 2, {}, true, null, true);

export const clearInterims = (site_id, adm_action_annotation) => {
  return apiPost('/site/' + site_id, 'clear_interims', {
    adm_action_annotation,
  });
};

export const setNonProfit = (account_id, adm_action_annotation) => {
  return apiPost('/account/' + account_id, 'set_nonprofit', {
    adm_action_annotation,
  });
};
