import React, { Component } from 'react';
import SearchBox from './SearchBox';
import { withModalContext } from '../../contexts/ModalContext';

class Lookup extends Component {
  componentDidMount() {
    this.props.modalContext.closeAllModals();
  }

  render() {
    return <SearchBox history={this.props.history} />;
  }
}

export default withModalContext(Lookup);
