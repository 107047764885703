import React from 'react';
import Paper from '@mui/material/Paper';
import styles from './Login.module.css';
import GoogleSignInButton from '../../components/GoogleSignInButton';
import Form from './Form';

const Login = ({ history, location }) => {
  const env = process.env.REACT_APP_ENVIRONMENT;
  const isManualLoginVisible = env === 'review' || env === 'development';

  return (
    <>
      <Paper className={styles.formWrapper}>
        <GoogleSignInButton href="/api/v2/adm/login/google-start" />
      </Paper>
      {isManualLoginVisible && (
        <Paper className={styles.formWrapper}>
          <Form history={history} location={location} />
        </Paper>
      )}
    </>
  );
};

export default Login;
