import React, { Component } from 'react';
import { Alert, Button, Dialog } from '@mui/material';
import { withLoadingDialog } from '../../withLoading';
import { modifyPlanSettings } from './api';

import Form from './form';

const LoadingAwareDialog = withLoadingDialog(Dialog);

export class ModifyPlanSettingsModal extends Component {
  state = {
    validation_errors: {
      percentage_discount: '',
    },
    error_message: null,
    sample_rate: null,
    always_on_rate: null,
    lock_plan: null,
    plan_id: null,
    annotation: '',
    percentage_discount: 0,
    site_ids: [],
    hasActiveTrialV2: false,
  };

  componentDidUpdate(_, prevState) {
    if (prevState.percentage_discount !== this.state.percentage_discount)
      this.validateDiscount();
  }

  componentDidMount() {
    this.initializeData();
  }

  initializeData = () => {
    // Sets initial state when Modal opens
    if (this.state.initial_site_ids === undefined) {
      const {
        sample_rate,
        always_on_rate,
        plan_id,
        lock_plan,
        percentage_discount,
        id,
        site_ids,
        context,
        hasActiveTrialV2,
      } = this.props.props;

      this.setState({
        sample_rate,
        always_on_rate,
        plan_id,
        lock_plan,
        percentage_discount,
        id,
        site_ids: context === 'single_entity' ? site_ids : [],
        initial_site_ids: site_ids,
        hasActiveTrialV2,
      });
    }
  };

  setDiscountError = (message) => {
    this.setState({ validation_errors: { percentage_discount: message } });
  };

  validateDiscount = () => {
    if (isNaN(this.state.percentage_discount)) {
      this.setDiscountError('Discount must be a number');
      return;
    }
    if (this.state.percentage_discount > 50) {
      this.setDiscountError('Discount must be below 50%');
      return;
    }
    this.setDiscountError('');
    return;
  };

  handleCheckboxChange = (rows) => {
    let site_ids = [];
    for (let i = 0; i < rows.length; i++) {
      site_ids.push(this.state.initial_site_ids[rows[i].dataIndex]);
    }
    this.setState({ site_ids });
  };

  handleFormUpdate = (data) => {
    if (data.annotation) {
      this.setState({ ...data });
    } else {
      this.setState({ ...data, changed: true });
    }
  };

  closeModal = () => {
    this.setState({ annotation: '', error_message: null, changed: false });
    this.props.onCloseModal();
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    const {
      plan_id,
      plan_id_combinedlegacy,
      plan_id_analytics,
      plan_id_feedback,
    } = this.state;
    let newPlanID;
    if (plan_id) newPlanID = plan_id;
    if (plan_id_combinedlegacy) newPlanID = plan_id_combinedlegacy;
    if (plan_id_analytics && plan_id_feedback)
      newPlanID = `${plan_id_analytics};${plan_id_feedback}`;
    if (!newPlanID) {
      this.setState({
        isLoading: false,
        error_message: 'Modification failed - no plans included',
      });
      return;
    }
    modifyPlanSettings(
      this.props.props.entityType,
      this.props.props.id,
      this.state.sample_rate,
      this.state.always_on_rate,
      this.state.lock_plan,
      newPlanID,
      this.state.annotation,
      this.state.percentage_discount,
      this.state.site_ids
    )
      .then(() => {
        this.setState({ isLoading: false, annotation: '' });
        this.props.onCloseModal();
      })
      .catch((e) =>
        this.setState({
          isLoading: false,
          error_message: e.error_message || e.message || e.error,
        })
      );
  };

  render() {
    const modalProps = this.props.props;

    if (this.state.initial_site_ids === undefined) return null;

    if (modalProps) {
      const modalActions = [
        <Button color="primary" key="cancel" onClick={this.closeModal}>
          Cancel
        </Button>,
        <Button
          color="primary"
          key="save"
          onClick={this.handleSubmit}
          disabled={
            !this.state.changed ||
            this.state.annotation === '' ||
            this.state.validation_errors.percentage_discount.length > 0 ||
            (modalProps.entityType !== 'organization' &&
              this.state.site_ids.length === 0)
          }
        >
          Save Changes
        </Button>,
      ];

      return (
        <LoadingAwareDialog
          open
          actions={modalActions}
          title="Modify Plan & Rates"
          isLoading={this.state.isLoading}
        >
          <div className="error">
            {this.state.error_message
              ? 'ERROR: ' + this.state.error_message
              : ''}
          </div>

          {modalProps.entityType === 'organization' && (
            <span data-testid="org_name">Organization: {modalProps.name}</span>
          )}

          {this.state.hasActiveTrialV2 ? (
            <Alert severity="warning" sx={{ marginBottom: '24px' }}>
              This site is currently on trial. Any plan amendments (upgrade or
              downgrade) will terminate the trial.
            </Alert>
          ) : null}

          {this.state.site_ids &&
            modalProps.context === 'single_entity' &&
            modalProps.entityType !== 'organization' && (
              <span data-testid="site_name">Site: {modalProps.name}</span>
            )}
          <Form
            isUbpAccount={modalProps.isUbpAccount}
            onFormUpdate={this.handleFormUpdate}
            always_on_rate={this.state.always_on_rate}
            sample_rate={this.state.sample_rate}
            lock_plan={this.state.lock_plan}
            plan_id={this.state.plan_id}
            annotation={this.state.annotation}
            hasAlwaysOnFeature={modalProps.has_always_on}
            entityType={modalProps.entityType}
            percentageDiscount={this.state.percentage_discount}
            validationErrors={this.state.validation_errors}
            sites={modalProps.sites}
            initial_site_ids={this.state.initial_site_ids}
            handleCheckboxChange={this.handleCheckboxChange}
            context={modalProps.context}
            pricing_table={modalProps.pricing_table}
          />
        </LoadingAwareDialog>
      );
    } else {
      return null;
    }
  }
}

export default ModifyPlanSettingsModal;
