import React, { Component } from 'react';
import moment from 'moment';
import { Button, Paper, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import styles from './Referrals.module.css';
import { toDateString } from '../../utils/dates';
import { referralsApi } from './api';
import { downloadReferralsCsv } from '../../utils/CSVHelpers';

export class ReferralsReport extends Component {
  state = {
    from: moment().subtract(2, 'weeks'),
    to: moment(),
    error_message: null,
  };

  updateDateFrom = (date) => {
    this.setState({ from: date.utc() });
  };

  updateDateTo = (date) => {
    this.setState({ to: date.utc() });
  };

  getReport = () => {
    const from = toDateString(this.state.from);
    const to = toDateString(this.state.to);
    const { content_type } = this.props.api_args;
    referralsApi(from, to, content_type)
      .then((data) => {
        downloadReferralsCsv(this.props.filename_prefix, data);
      })
      .catch((e) => {
        this.setState({ error_message: e });
      });
  };

  render() {
    return (
      <Paper className={styles.paper}>
        <h3>{this.props.report_title}</h3>
        <p>Extract a referrals report between two dates</p>
        <div className={styles.fields}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="from"
              name="from"
              inputFormat="YYYY-MM-DD"
              label="From"
              showTodayButton
              className={styles.date}
              error={!!this.state.error_message}
              helperText={this.state.error_message}
              onChange={this.updateDateFrom}
              value={this.state.from}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
            <DatePicker
              id="to"
              name="to"
              inputFormat="YYYY-MM-DD"
              variant="dialog"
              showTodayButton
              className={styles.date}
              error={!!this.state.error_message}
              helperText={this.state.error_message}
              label="To"
              onChange={this.updateDateTo}
              value={this.state.to}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            className={styles.button}
            color="primary"
            onClick={this.getReport}
          >
            Get Report
          </Button>
        </div>
      </Paper>
    );
  }
}

export default ReferralsReport;
