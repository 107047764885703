import { MenuItem } from '@mui/material';
import { useModalContext } from '../../../../contexts/ModalContext';
import { MODAL_ENABLE_SSO_FOR_ORG } from '../../../../components/modals/constants';

interface Props {
  accountId: number;
  orgId: number;
}

export const EnableSSOForOrg = ({ accountId, orgId }: Props) => {
  const modalContext = useModalContext();

  return (
    <>
      <MenuItem
        onClick={() =>
          modalContext.openModal(MODAL_ENABLE_SSO_FOR_ORG, { accountId, orgId })
        }
      >
        Enable SSO for Organization
      </MenuItem>
    </>
  );
};
