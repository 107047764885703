import { apiGet, apiPost, apiPut, apiPatch } from '../../../utils/api';

export const requestPasswordResetToken = (userID) => {
  return apiPost('/user/' + userID, 'generate_password_reset_link');
};

export const requestMfaDisable = (userID, annotation) => {
  return apiPost(`/user/${userID}/mfa-settings/disable`, '', {
    adm_action_annotation: annotation,
  });
};

export const updateIntercomUser = (userID) => {
  return apiPost('/user/' + userID, 'update_intercom');
};

export const upgradeUser = (userID, country, account_type) => {
  return apiPost('/user/' + userID, 'upgrade_to_account', {
    country,
    account_type,
  });
};

export const changeEmailBlocklist = (userID, isInputChecked) => {
  return apiPut(
    '/user/' + userID,
    { email_blocklisted: isInputChecked },
    2,
    {},
    true,
    'change email blocklist'
  ).then(() => {
    return isInputChecked;
  });
};

export const getUserOrganizations = (userID) => {
  return apiGet('/user/' + userID + '/organizations');
};

export const deleteUser = (userID, annotation) => {
  return apiPost(`/user/${userID}`, 'delete_user_and_condemn_accounts', {
    adm_action_annotation: annotation,
  });
};

export const modifyAccessLevel = (organizationID, userId, accessLevel) => {
  return apiPatch(
    `/organization/${organizationID}/user/${userId}`,
    { access_level: accessLevel },
    2,
    {},
    true,
    'modify access level'
  );
};
