import React, { Component } from 'react';
import _ from 'lodash';

import {
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
  Switch,
} from '@mui/material';

import { always_on_prices, sample_rate_prices } from '../../../utils/constants';
import { getPlans } from '../../../utils/plans';
import HasPermission from '../../HasPermission';
import SiteSelectTable from './SiteSelectTable';
import styles from './ModifyPlanSettings.module.css';

class Form extends Component {
  state = {
    skus: [],
  };

  componentDidMount() {
    return getPlans().then((plans) => {
      let filteredPlans;
      filteredPlans = plans.filter((plan) => {
        return (
          Object.keys(this.props.pricing_table.prices).indexOf(
            plan.identifier
          ) > -1
        );
      });

      if (['PT_2022_Q1_BET_5_2'].includes(this.props.pricing_table.label)) {
        filteredPlans = this.handleCombinedSKUPlans(filteredPlans);
      } else {
        filteredPlans = this.handleDualSKUPlans(filteredPlans);
      }

      return this.setState({ skus: filteredPlans });
    });
  }

  handleDualSKUPlans = (plans) => {
    const analyticsPlans = [];
    const feedbackPlans = [];
    plans.forEach((plan) => {
      const [analyticsID, feedbackID] = plan.identifier.split(';');
      if (
        !analyticsPlans.filter(
          (analyticsPlan) => analyticsPlan.label === analyticsID
        ).length
      ) {
        analyticsPlans.push({
          label: analyticsID,
          niceLabel: `${_.capitalize(analyticsID.split('_')[1])} - ${
            this.props.isUbpAccount
              ? Math.round((plan.dailySessionAllowance * 30) / 100) * 100
              : plan.dailySessionAllowance
          } ${this.props.isUbpAccount ? 'Monthly' : 'Daily'} Sessions`,
        });
      }
      if (
        !feedbackPlans.filter(
          (feedbackPlan) => feedbackPlan.label === feedbackID
        ).length
      ) {
        feedbackPlans.push({
          label: feedbackID,
          niceLabel: `${_.capitalize(feedbackID.split('_')[1])} - ${
            feedbackID.split('_')[2] || 'Unlimited'
          } Responses`,
        });
      }
    });
    const planArray = [
      {
        label: 'analytics',
        niceLabel: 'Observe',
        plans: analyticsPlans,
      },
      {
        label: 'feedback',
        niceLabel: 'Ask',
        plans: feedbackPlans,
      },
    ];
    return planArray;
  };

  handleCombinedSKUPlans = (plans) => {
    return {
      combined: {
        label: 'combinedlegacy',
        niceLabel: 'Combined Legacy',
        plans: plans.map((plan) => {
          let planLabel = _.capitalize(plan.featureGroup) + ' - ';
          planLabel += plan.dailySessionAllowance
            ? plan.dailySessionAllowance + ' sessions'
            : plan.sampleRate + ' pageviews';
          return {
            label: plan.identifier,
            niceLabel: planLabel,
          };
        }),
      },
    };
  };

  handleSampleRateChange = (event) => {
    if (event.target.value < 20000 && this.props.hasAlwaysOnFeature) {
      this.props.onFormUpdate({
        always_on_rate: 0,
      });
    }
    this.props.onFormUpdate({
      sample_rate: event.target.value,
    });
  };

  handleAlwaysOnRateChange = (event) => {
    this.props.onFormUpdate({
      always_on_rate: event.target.value,
    });
  };

  handlePercentageDiscountChange = (event) => {
    this.props.onFormUpdate({
      percentage_discount: event.target.value,
    });
  };

  handlePlanIdChange = (event, SkuID) => {
    if (
      this.props.hasAlwaysOnFeature &&
      (event.target.value === 'basic_2000' ||
        event.target.value === 'plus_10000')
    ) {
      this.props.onFormUpdate({
        always_on_rate: 0,
      });
    }
    const updateObj = {};
    updateObj[`plan_id_${SkuID}`] = event.target.value;
    this.props.onFormUpdate(updateObj);
  };

  handleAnnotationFieldChange = (event) => {
    this.props.onFormUpdate({
      annotation: event.target.value,
    });
  };

  handleToggle = (event, isInputChecked) => {
    this.props.onFormUpdate({
      [event.target.name]: isInputChecked,
    });
  };

  getTableData = (site_ids, sites) => {
    let tableData = [];
    site_ids.map((site_id) => tableData.push([site_id, sites[site_id].name]));
    return tableData;
  };

  render() {
    let alwaysOnDisabled;
    if (this.props.entityType === 'organization') {
      alwaysOnDisabled =
        this.props.lock_plan ||
        !this.props.hasAlwaysOnFeature ||
        this.props.sample_rate < 20000;
    } else {
      alwaysOnDisabled =
        this.props.lock_plan ||
        !this.props.hasAlwaysOnFeature ||
        this.props.plan_id === 'basic_2000' ||
        this.props.plan_id === 'plus_10000';
    }
    let sampleRateDisabled = this.props.lock_plan;
    return (
      <form onSubmit={this.handleSubmit} className={styles.form}>
        {this.props.context === 'bulk_site' && (
          <div
            data-testid="site_select_table"
            className={styles.siteSelectTable}
          >
            <SiteSelectTable
              handleCheckboxChange={this.props.handleCheckboxChange}
              tableData={this.getTableData(
                this.props.initial_site_ids,
                this.props.sites
              )}
            />
          </div>
        )}
        {this.props.entityType === 'organization' ? (
          <FormControl className={styles.select}>
            <InputLabel htmlFor="sample_rate">
              Plan + data collection rate
            </InputLabel>
            <Select
              native
              value={this.props.sample_rate || ''}
              onChange={this.handleSampleRateChange}
              disabled={sampleRateDisabled}
              name="sample_rate"
              id="sample_rate"
              data-testid="sample_rate"
              variant="standard"
            >
              {Object.keys(sample_rate_prices).map(function (plan) {
                let rate = sample_rate_prices[plan].rate;
                let label = sample_rate_prices[plan].label;

                return (
                  <option value={rate} key={rate}>
                    {label + ' - ' + rate + ' sample rate'}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        ) : (
          Object.keys(this.state.skus).map((sku) => {
            let thisSku = this.state.skus[sku];
            return (
              <FormControl
                className={styles.sitePlan}
                key={thisSku.label}
                fullWidth={true}
              >
                <InputLabel htmlFor={`plan_id_${thisSku.label}`}>
                  {thisSku.niceLabel} Plan ID
                </InputLabel>
                <Select
                  native
                  onChange={(e) => this.handlePlanIdChange(e, thisSku.label)}
                  disabled={sampleRateDisabled}
                  name={`plan_id_${thisSku.label}`}
                  id={`plan_id_${thisSku.label}`}
                  data-testid="plan_id"
                  variant="standard"
                >
                  <option value=""></option>
                  {thisSku.plans.map((plan) => (
                    <option value={plan.label} key={plan.label}>
                      {plan.niceLabel}
                    </option>
                  ))}
                </Select>
              </FormControl>
            );
          })
        )}
        <FormControl className={styles.alwaysOn}>
          <InputLabel htmlFor="always_on_rate">Always on rate</InputLabel>
          <Select
            native
            value={this.props.always_on_rate || ''}
            onChange={this.handleAlwaysOnRateChange}
            disabled={alwaysOnDisabled}
            name="always_on_rate"
            id="always_on_rate"
            data-testid="always_on_rate"
            variant="standard"
          >
            <option value=""></option>
            {Object.keys(always_on_prices).map(function (price) {
              let rate = always_on_prices[price].rate;
              return (
                <option value={rate} key={rate}>
                  {rate.toString() /* to avoid blank select option */}
                </option>
              );
            })}
          </Select>
        </FormControl>
        {this.props.entityType === 'site' && (
          <HasPermission rolesAccepted={['admin', 'billing_management_2']}>
            <TextField
              className={styles.percentageDiscount}
              label="Discount"
              value={this.props.percentageDiscount || ''}
              name="percentageDiscount"
              id="percentageDiscount"
              onChange={this.handlePercentageDiscountChange}
              error={this.props.validationErrors.percentage_discount.length > 0}
              helperText={this.props.validationErrors.percentage_discount}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              variant="standard"
            />
          </HasPermission>
        )}
        {this.props.context !== 'bulk_site' && (
          <div>
            <Switch
              name="lock_plan"
              checked={this.props.lock_plan || false}
              onChange={this.handleToggle}
              id="lock_plan"
              data-testid="lock_plan"
            />
            <label htmlFor="lock_plan">Lock Plans</label>
          </div>
        )}
        <div>
          <TextField
            multiline
            required
            value={this.props.annotation}
            onChange={this.handleAnnotationFieldChange}
            className={styles.annotation}
            label="Annotation"
            id="annotation"
            name="annotation"
            helperText="Please provide reason for change and/or Zendesk ticket link"
            variant="standard"
          />
        </div>
      </form>
    );
  }
}

export default Form;
