import {
  Box,
  Dialog,
  TextField,
  Stack,
  DialogActions,
  FormControl,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useProducts } from '../../../products/data-access/use-products';
import { Currency } from '../../../../types/types';
import { SelectPlan } from './plan-select';
import { useForm, FormProvider } from 'react-hook-form';
import { ServiceDateSelect } from './service-date-select';
import { SourceSelect } from './source-select';
import { useServiceProvisionMutation } from '../../api/use-service-provision-mutation';
import { Magpie, ProductCatalogItem } from '../../../../typings';
import { useToasts } from 'react-toast-notifications';
import moment, { Moment } from 'moment';
import HasPermission from '../../../../components/HasPermission';

type ProvisionServiceModalProps = {
  handleClose: () => void;
  isOpen: boolean;
  billingPeriod: Magpie['subscription']['billingPeriod'] | undefined;
  currency: Currency;
  version: string;
  accountId: number;
};

function getErrorMessage(error: unknown, defaultMessage: string) {
  if (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return error.message;
  }
  return defaultMessage;
}

export type ServiceProvisionFormData = {
  planDXA: ProductCatalogItem | null;
  planVoC: ProductCatalogItem | null;
  serviceStartDate: Moment | null;
  serviceEndDate: Moment | null;
  annotation: string;
  source: string;
};

const MODAL_WIDTH = 800;

export function ProvisionServiceModal({
  handleClose,
  isOpen,
  billingPeriod = 'MONTHLY',
  currency = Currency.Eur,
  version = 'v5',
  accountId,
}: ProvisionServiceModalProps) {
  const { plans } = useProducts({
    billingPeriod,
    currency,
    version,
  });
  const form = useForm<ServiceProvisionFormData>({
    defaultValues: {
      planDXA: null,
      planVoC: null,
      serviceStartDate: null,
      serviceEndDate: null,
      annotation: '',
      source: '',
    },
  });
  const { addToast } = useToasts();
  const { mutate, isLoading } = useServiceProvisionMutation(accountId, {
    onSuccess: () => {
      addToast('Service provisioned successfully', { appearance: 'success' });
      handleClose();
    },
    onError: (error: unknown) => {
      const message = getErrorMessage(error, 'Failed to provision service');
      addToast(message, { appearance: 'error' });
    },
  });

  function onSubmit(data: ServiceProvisionFormData) {
    if (!data.planDXA && !data.planVoC) {
      addToast('At least one plan is required', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    if (!data.serviceStartDate || !data.serviceEndDate) {
      addToast('Service start and end dates are required', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    if (moment(data.serviceStartDate).isAfter(data.serviceEndDate)) {
      addToast('Service start date should be before the Service end date', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    mutate({
      ...data,
      planDXA: data.planDXA?.sku ?? null,
      planVoC: data.planVoC?.sku ?? null,
    });
  }

  const {
    formState: { errors },
  } = form;

  const rolesAccepted = ['admin', 'dxa_voc_manual_provisioning'];

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      maxWidth="md"
    >
      <HasPermission
        rolesAccepted={rolesAccepted}
        message={`You are not authorized to provision services. Required roles: ${rolesAccepted.join(
          ', '
        )}`}
      >
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Box sx={{ padding: 6 }} width={MODAL_WIDTH}>
              <h2 id="parent-modal-title">Provision Service</h2>
              <p id="parent-modal-description">
                Provision service for this account.
              </p>
              <hr />
              <Stack direction="row" spacing={2} sx={{ paddingRight: 1 }}>
                <SelectPlan
                  product="DXA"
                  plans={plans}
                  onChange={(plan) => form.setValue('planDXA', plan)}
                  plan={form.watch('planDXA')}
                />
                <SelectPlan
                  product="VoC"
                  plans={plans}
                  onChange={(plan) => form.setValue('planVoC', plan)}
                  plan={form.watch('planVoC')}
                />
              </Stack>
              <Stack direction="row" spacing={18} sx={{ paddingRight: 1 }}>
                <ServiceDateSelect
                  field="serviceStartDate"
                  onChange={(date) => form.setValue('serviceStartDate', date)}
                />
                <ServiceDateSelect
                  field="serviceEndDate"
                  onChange={(date) => form.setValue('serviceEndDate', date)}
                />
              </Stack>
              <Box>
                <FormControl fullWidth>
                  <TextField
                    label="Annotation"
                    {...form.register('annotation', {
                      required: 'Annotation is required',
                    })}
                    error={!!errors.annotation}
                  />
                </FormControl>
              </Box>
              <Box>
                <SourceSelect />
              </Box>
            </Box>
            <DialogActions>
              <LoadingButton
                type="submit"
                size="large"
                variant="contained"
                disabled={isLoading}
                loading={isLoading}
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </form>
        </FormProvider>
      </HasPermission>
    </Dialog>
  );
}
