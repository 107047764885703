import { Button, DialogActions } from '@mui/material';

interface Props {
  closeModal: () => void;
  children?: React.ReactNode;
}

export const Actions = ({ closeModal, children }: Props) => {
  return (
    <DialogActions>
      <Button onClick={closeModal}>Cancel</Button>
      {children}
    </DialogActions>
  );
};
