import { FC } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import PeopleIcon from '@mui/icons-material/People';
import ActionsBadge from '../../../../components/ActionsBadge';
import IconBadge from '../../../../components/IconBadge';
import IgnoredIpBadge from '../IgnoredIpBadge';
import { itemCountToString } from '../../../../utils/itemCountToString';

interface OrganizationBadgesProps {
  siteCount?: number;
  usersCount?: number;
  actionsCount?: number;
  ignoredIpsCount?: number;
  pendingInvitationsCount?: number;
  handleOnActionsClick: () => void;
  handleOnIgnoredIpClick: () => void;
}

const OrganizationBadges: FC<OrganizationBadgesProps> = ({
  siteCount,
  usersCount,
  actionsCount,
  ignoredIpsCount,
  pendingInvitationsCount,
  handleOnActionsClick,
  handleOnIgnoredIpClick,
}) => {
  return (
    <>
      <IgnoredIpBadge
        ignoredIpsCount={ignoredIpsCount}
        handleOnClick={handleOnIgnoredIpClick}
      />
      <ActionsBadge
        actionsCount={actionsCount}
        handleOnClick={handleOnActionsClick}
      />
      <IconBadge
        tooltip="Site Count"
        icon={<LanguageIcon />}
        badgeText={itemCountToString(siteCount)}
      />
      <IconBadge
        tooltip="Org Users"
        icon={<PeopleIcon />}
        badgeText={itemCountToString(usersCount)}
      />
      <IconBadge
        icon={<EmailIcon />}
        tooltip="Pending Invitations"
        badgeText={itemCountToString(pendingInvitationsCount)}
      />
    </>
  );
};

export default OrganizationBadges;
