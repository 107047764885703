import { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';

const ToastManager = ({ toast }) => {
  /*
    This manager exists because the UserDetail component is still class-based,
    so it can't call the useToasts hook. When it changes to functional, we need
    to move the hook call to the component and delete this file.
    */
  const { addToast } = useToasts();

  useEffect(() => {
    const { message, appearance } = toast;
    if (message && appearance)
      addToast(message, { appearance, autoDismiss: true });
  }, [toast, addToast]);

  return null;
};

export default ToastManager;
