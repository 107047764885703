import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
  Box,
} from '@mui/material';
import type { ProductCatalogItem, ProductName } from '../../../../typings';

import { getOptionLabel } from './plan-select-label';

function renderInput(params: AutocompleteRenderInputParams) {
  return <TextField {...params} label="Choose a plan" />;
}

type SelectPlanProps = {
  product: ProductName;
  plans: ProductCatalogItem[];
  onChange: (plan: ProductCatalogItem | null) => void;
  plan?: ProductCatalogItem | null;
};
export function SelectPlan({
  product,
  plans,
  onChange,
  plan,
}: SelectPlanProps) {
  const planOptions = plans.filter((plan) => plan.productName === product);

  return (
    <Box sx={{ width: '100%', gap: 2 }}>
      <h3>{product}</h3>
      <Autocomplete
        options={planOptions}
        renderInput={renderInput}
        getOptionLabel={getOptionLabel}
        onChange={(_, value) => onChange(value)}
        isOptionEqualToValue={(option, value) => option.sku === value.sku}
        value={plan}
      />
    </Box>
  );
}
