import React from 'react';
import ReferralsReport from './ReferralsReport';
import styles from './Referrals.module.css';

const Referrals = () => {
  const ReferralsReportElement = (
    <ReferralsReport
      report_title="Referrals Report"
      filename_prefix="referrals-report-"
      api_args={{
        content_type: 'text/csv',
        report_type: 'drr',
      }}
    />
  );

  return (
    <div className={styles.container}>
      <h2>Referrals page</h2>
      {ReferralsReportElement}
    </div>
  );
};

export default Referrals;
