import { List, ListItem } from '@mui/material';

interface Props {
  lastData: string;
  created: string;
}

export const SiteInfoAlp = ({ lastData, created }: Props): JSX.Element => {
  return (
    <List>
      <ListItem>Last Data: {lastData}</ListItem>
      <ListItem>Created: {created}</ListItem>
    </List>
  );
};
