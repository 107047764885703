import React, { Component } from 'react';

import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import MUIDataTable from 'mui-datatables';

import { toUTCDateTimeString } from '../../utils/dates';

export default class NotificationTable extends Component {
  getColumnSettings = () => [
    { name: 'ID', options: { filter: false } },
    {
      name: 'Created',
      options: {
        customBodyRender: (date) => toUTCDateTimeString(date),
        filter: false,
      },
    },
    { name: 'Recipients', options: { filter: false } },
    { name: 'Reason' },
    { name: 'Type' },
  ];

  getTableSettings = () => ({
    page: this.props.page,
    count: this.props.count,
    rowsPerPage: this.props.rowsPerPage,
    responsive: 'standard',
    selectableRows: 'none',
    rowsPerPageOptions: [10, 25, 50, 100],
    pagination: true,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    filter: false,
    search: false,
    sort: false,
    onChangeRowsPerPage: this.props.onChangeRowsPerPage,
    onChangePage: this.props.onChangePage,
    customToolbar: () => {
      return (
        <div>
          <Tooltip title={'Refresh'}>
            <IconButton onClick={this.props.onRefresh}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
  });

  render() {
    return (
      <div>
        <MUIDataTable
          title={'Notifications'}
          data={this.props.notifications}
          columns={this.getColumnSettings()}
          options={this.getTableSettings()}
        />
      </div>
    );
  }
}
