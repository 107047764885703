import { useQuery } from '@tanstack/react-query';
import { fetchMagpieCustomerURL } from './urls';
import { MagpieCustomer } from '../models/magpie.model';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { HTTP_ERROR_STATUS_STRING } from '../../../utils/constants';

const showMagpieCustomer = async (
  accountId: number
): Promise<MagpieCustomer> => {
  const response = await fetch(fetchMagpieCustomerURL(accountId));

  return handleFetchApiResponse<MagpieCustomer>(response);
};

export const useShowMagpieCustomer = (accountId?: number) => {
  const { addToast } = useToasts();
  const { data, isInitialLoading, isError, error } = useQuery<
    MagpieCustomer,
    Error
  >({
    queryKey: ['showMagpieCustomer', accountId],
    queryFn: () => showMagpieCustomer(accountId!),
    enabled: !!accountId,
    retry: false,
  });

  useEffect(() => {
    // Error toast not displayed on 404
    if (isError && error.message !== HTTP_ERROR_STATUS_STRING) {
      addToast(
        `There was an error fetching data for account with id:"${accountId}" subscription`,
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );
    }
  }, [accountId, addToast, isError, error]);

  return {
    magpieCustomerData: data,
    magpieCustomerError: error,
    isMagpieCustomerError: isError,
    isMagpieCustomerLoading: isInitialLoading,
  };
};
