import React, { useContext, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { toUTCDateString } from '../../utils/dates';
import styles from './DisplayOrganization.module.css';
import RemoveOrgUser from '../../components/RemoveOrgUser';
import { UserContext } from '../../contexts/UserContext';
import { ProvisioningVOCWrapper } from '../ProvisioningVoCInsights';
import { Button } from '@mui/material';
import { ModalContext } from '../../contexts/ModalContext';
import { MODAL_MODIFY_ACCESS_LEVEL } from '../../components/modals/constants';

const TABLE_SETTINGS = {
  filterType: 'dropdown',
  responsive: 'standard',
  selectableRows: 'none',
  pagination: false,
  print: false,
  download: false,
  viewColumns: false,
  customSort: (data, colIndex, order) => {
    return data.sort((a, b) => {
      let aData = a.data[colIndex];
      let bData = b.data[colIndex];
      if (aData && aData.created) {
        aData = aData.created;
      }
      if (bData && bData.created) {
        bData = bData.created;
      }
      return (aData < bData ? -1 : 1) * (order === 'desc' ? 1 : -1);
    });
  },
};

const getColumnSettings = (
  roleNames,
  openModifyUserAccessLevelModal,
  onSuccess
) => {
  const columnSettings = [
    {
      name: 'ID',
      options: {
        filter: false,
        customBodyRender: (id) => (
          <Link to={`/lookup/user_id/${id}`}>{id}</Link>
        ),
      },
    },
    { name: 'Name', options: { filter: false } },
    { name: 'Email', options: { filter: false } },
    {
      name: 'Access Level',
      options: {
        customBodyRender: ({ access, organizationId, userId }) => {
          return (
            <div className={styles.modifyAccessContainer}>
              {access}
              <ProvisioningVOCWrapper>
                <Button
                  size="small"
                  variant="text"
                  onClick={() =>
                    openModifyUserAccessLevelModal(
                      organizationId,
                      userId,
                      access
                    )
                  }
                >
                  Modify
                </Button>
              </ProvisioningVOCWrapper>
            </div>
          );
        },
      },
    },
    {
      name: 'Created On',
      options: {
        customBodyRender: (date) => toUTCDateString(date),
        filter: false,
      },
    },
    {
      name: 'Last Login',
      options: {
        customBodyRender: (date) =>
          date ? toUTCDateString(new Date(date * 1000)) : '',
        filter: false,
      },
    },
    {
      name: 'Latest Feed Event',
      options: {
        customBodyRender: (data) => {
          if (data) {
            const toolTipText = `Module: ${data.module}, Action: ${data.action}`;
            return (
              <span>
                {toUTCDateString(new Date(data.created * 1000))}
                <Tooltip title={toolTipText}>
                  <InfoIcon className={styles.infoIcon} />
                </Tooltip>
              </span>
            );
          } else {
            return '';
          }
        },
        filter: false,
      },
    },
  ];

  // Only certain user roles should be able to see the 'delete' button
  const hasRemovePermission =
    roleNames.includes('admin') || roleNames.includes('insights_user_manager');
  hasRemovePermission &&
    columnSettings.push({
      name: 'Remove from Organization',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (ou) => {
          return (
            <div className={styles.removeButtonCell}>
              <RemoveOrgUser
                user={ou.user}
                organization={ou.organization}
                onSuccess={onSuccess}
              />
            </div>
          );
        },
      },
    });

  return columnSettings;
};

export const OrganizationUsersTable = ({ users, onSuccess }) => {
  const modalContext = useContext(ModalContext);
  const user = useContext(UserContext);

  const openModifyUserAccessLevelModal = useCallback(
    (organizationId, userId, accessLevel) => {
      modalContext.openModal(MODAL_MODIFY_ACCESS_LEVEL, {
        organizationId,
        userId,
        accessLevel,
      });
    },
    [modalContext]
  );

  const data = useMemo(
    () =>
      users.map((ou) => {
        return [
          ou.user.id,
          ou.user.name,
          ou.user.email,
          {
            access: ou.access,
            userId: ou.user.id,
            organizationId: ou.organization.id,
          },
          ou.user.created,
          ou.user.last_successful_login,
          ou.user.latest_feed_event,
          ou,
        ];
      }),
    [users]
  );

  const columnSettings = useMemo(
    () =>
      getColumnSettings(
        user.roleNames,
        openModifyUserAccessLevelModal,
        onSuccess
      ),
    [user.roleNames, openModifyUserAccessLevelModal, onSuccess]
  );

  return (
    <div className={styles.usersTableContainer}>
      <MUIDataTable
        data={data}
        columns={columnSettings}
        options={TABLE_SETTINGS}
      />
    </div>
  );
};
