import React from 'react';
import styles from '../RemoveOrgUser/RemoveOrgUser.module.css';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { withModalContext } from '../../contexts/ModalContext';
import { deleteInvitation } from './api';
import { MODAL_CONFIRMATION_DIALOG } from '../modals/constants';

const DeleteInvitation = ({
  org_id,
  org_name,
  invitation,
  onSuccess,
  modalContext,
}) => {
  const onModalSubmit = (annotation) => {
    return deleteInvitation(org_id, invitation.email, annotation);
  };

  const openModal = () => {
    const modalWarning = (
      <span>
        Are you sure you want to delete the invitation for user{' '}
        <b>{invitation.email}</b> from organization{' '}
        <b>
          {org_name} (ID: {org_id})
        </b>
        ?
      </span>
    );

    modalContext.openModal(MODAL_CONFIRMATION_DIALOG, {
      title: 'Delete Invitation',
      onSubmit: onModalSubmit,
      modalWarning,
      onSuccess,
    });
  };

  return (
    <React.Fragment>
      <button
        data-testid="deleteInvitationButton"
        className={styles.button}
        onClick={openModal}
      >
        <CancelOutlinedIcon />
      </button>
    </React.Fragment>
  );
};

export default withModalContext(DeleteInvitation);
