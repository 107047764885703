import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';

import InvoicesTable from './InvoicesTable';
import { getInvoices, viewInvoice, regenerateInvoice } from './api';
import { MODAL_VIEW_INVOICES } from '../constants';
import { withLoadingDialog } from '../../withLoading';
import { getCurrencySymbol } from '../../../utils/costs';
import { toUTCDateString } from '../../../utils/dates';
import { normalizeInvoicesResponse } from '../../../utils/normalizer';
import { withModalContext } from '../../../contexts/ModalContext';

const LoadingAwareDialog = withLoadingDialog(Dialog);

class ViewInvoicesModal extends Component {
  state = {
    invoices: [],
    error_message: '',
    success_message: '',
    isLoading: false,
  };

  componentDidUpdate(prevProps) {
    const modalContext = this.props.modalContext;
    const modalProps = modalContext.openModalProps[MODAL_VIEW_INVOICES];
    const isOpen = modalContext.isModalOpen(MODAL_VIEW_INVOICES);
    const wasOpen =
      prevProps.modalContext.currentlyOpenModals.indexOf(MODAL_VIEW_INVOICES) >
      -1;
    if (!wasOpen && isOpen) {
      this.setState({ isLoading: true });
      getInvoices(modalProps.account.id)
        .then((data) => {
          this.setState({
            invoices: normalizeInvoicesResponse(data),
            isLoading: false,
          });
        })
        .catch((e) =>
          this.setState({
            error_message: e.error_message || e.error,
            isLoading: false,
          })
        );
    }
  }

  resetState = () => {
    this.setState({
      invoices: [],
      error_message: '',
      success_message: '',
      isLoading: false,
    });
  };

  closeModal = () => {
    const modalContext = this.props.modalContext;
    this.resetState();
    modalContext.closeModal(MODAL_VIEW_INVOICES);
  };

  onGetInvoice = (invoice_id, displayFn) => {
    this.setState({ isLoading: true });
    viewInvoice(invoice_id)
      .then((data) => {
        displayFn(data, 'invoice-' + invoice_id + '.pdf');
        this.setState({
          isLoading: false,
        });
      })
      .catch((e) =>
        this.setState({
          error_message: e.error_message || e.error,
          isLoading: false,
        })
      );
  };

  onRegenerateInvoice = (invoice_id) => {
    this.setState({ isLoading: true });
    regenerateInvoice(invoice_id)
      .then(() => {
        this.setState({
          success_message: 'Invoice Regenerated Successfully',
          isLoading: false,
        });
      })
      .catch((e) =>
        this.setState({
          error_message: e.error_message || e.error,
          isLoading: false,
        })
      );
  };

  render() {
    const modalContext = this.props.modalContext;
    const modalProps = modalContext.openModalProps[MODAL_VIEW_INVOICES];
    const isOpen = modalContext.isModalOpen(MODAL_VIEW_INVOICES);
    if (isOpen && modalProps) {
      return (
        <LoadingAwareDialog
          maxWidth="md"
          open={isOpen}
          onClose={this.closeModal}
          title={
            <div>
              Invoices for Account ID{' '}
              <span data-hj-suppress>{modalProps.account.id}</span>
            </div>
          }
          isLoading={this.state.isLoading}
        >
          <div className="error">
            {this.state.error_message
              ? 'ERROR: ' + this.state.error_message
              : ''}
          </div>
          <br />
          {this.state.success_message
            ? 'SUCCESS: ' + this.state.success_message
            : ''}
          <br />
          Customer ID:{' '}
          <span data-hj-suppress>{modalProps.account.customer_id}</span>
          <br />
          Payment Method:{' '}
          <span data-hj-suppress>{modalProps.account.payment.label}</span>
          <br />
          Next Billing Date:{' '}
          {toUTCDateString(modalProps.account.next_billing_date)}
          <br />
          VAT Number:{' '}
          <span data-hj-suppress>{modalProps.account.vat_number}</span>
          <br />
          Next Bill Cost: {getCurrencySymbol(modalProps.account.currency)}
          {modalProps.account.total_cost}
          <br />
          <InvoicesTable
            invoices={this.state.invoices}
            getInvoice={this.onGetInvoice}
            regenerateInvoice={this.onRegenerateInvoice}
          />
        </LoadingAwareDialog>
      );
    } else {
      return null;
    }
  }
}

export default withModalContext(ViewInvoicesModal);
