import type { Dispatch } from 'react';
import { useReducer } from 'react';
import type { ApiError, SetDefaultFeatureProps } from './api';
import { setDefaultFeature } from './api';
import { isValidPercentage } from './formUtils';

type State = SetDefaultFeatureProps & {
  open: boolean;
  submitting: boolean;
  featureError?: string;
  submitError?: string;
};

type SetActionType = `set${Capitalize<keyof SetDefaultFeatureProps>}`;

const actionMap = {
  setEntity: 'entity',
  setFeature: 'feature',
  setPercentage: 'percentage',
} as const;
// can append this after `as const` above, once recent TS is available in babel.
// satisfies Record<SetActionType, keyof SetDefaultFeatureProps>;

type ActionMap = typeof actionMap;
type ActionProperty<TType extends SetActionType> = ActionMap[TType];
type SetActionPayload<TType extends SetActionType> =
  State[ActionProperty<TType>];

type SetAction<TType extends SetActionType> = {
  type: TType;
  payload: SetActionPayload<TType>;
};

type PublicAction =
  | SetAction<'setEntity'>
  | SetAction<'setFeature'>
  | SetAction<'setPercentage'>
  | {
      type: 'show';
      payload?: SetDefaultFeatureProps['entity'];
    }
  | { type: 'cancel' }
  | { type: 'submit' };
type InternalAction =
  | {
      type: 'error';
      payload: string;
    }
  | { type: 'complete' };
type Action = PublicAction | InternalAction;

const defaults: State = {
  entity: 'account',
  feature: '',
  percentage: 100,
  open: false,
  submitting: false,
};

const validEntities = ['account', 'organization', 'site'] as const;
const isValidEntity = (entity: State['entity']): boolean =>
  validEntities.includes(entity);

const isValidFeature = <T extends string>(feature: T): boolean =>
  feature !== '';

const normalizePercentage = (percentage: number): number => {
  if (percentage < 0) {
    return 0;
  }

  if (percentage > 100) {
    return 100;
  }

  return percentage;
};

const isValid = ({
  entity,
  feature,
  percentage,
}: SetDefaultFeatureProps): boolean =>
  isValidEntity(entity) &&
  isValidFeature(feature) &&
  isValidPercentage(percentage);

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setEntity':
      if (!isValidEntity(action.payload)) {
        // no need for error message, as this cannot happen with normal UI use.
        return state;
      }

      return {
        ...state,
        entity: action.payload,
      };
    case 'setFeature':
      if (!isValidFeature(action.payload)) {
        return {
          ...state,
          featureError: 'Feature must be selected.',
        };
      }

      return {
        ...state,
        feature: action.payload,
        featureError: undefined,
      };
    case 'setPercentage':
      const percentage = normalizePercentage(action.payload);

      return {
        ...state,
        percentage,
      };
    case 'show':
      return {
        ...state,
        open: true,
        entity: action.payload == null ? state.entity : action.payload,
      };
    case 'cancel':
      return { ...defaults };
    case 'submit':
      if (!isValidFeature(state.feature)) {
        return {
          ...state,
          featureError: 'Feature must be selected.',
        };
      }

      if (!isValid(state)) {
        return state;
      }

      return {
        ...state,
        submitting: true,
      };
    case 'error':
      return {
        ...state,
        submitting: false,
        submitError: action.payload,
      };
    case 'complete':
      return { ...defaults };
  }
};

type Dispatcher = Dispatch<PublicAction>;

export type WithAddDefaultFeatureProps = {
  data: State;
  dispatch: Dispatcher;
};

const responseErrors: Readonly<Record<number | 'default', string>> = {
  422: 'Incorrect data',
  423: 'Feature flag is already hard coded to be a default and so cannot be modified',
  424: 'No such feature flag',
  default: 'Unknown error',
};

export const useAddDefaultFeature = (): [State, Dispatcher] => {
  const [state, dispatch] = useReducer(reducer, defaults);

  const wrappedDispatch = (action: PublicAction): void => {
    dispatch(action);

    if (action.type === 'submit' && isValid(state)) {
      const { entity, feature, percentage } = state;
      setDefaultFeature({ entity, feature, percentage }).then(
        () => {
          dispatch({ type: 'complete' });
        },
        ({ message, response }: ApiError) => {
          const payload =
            response?.status in responseErrors
              ? responseErrors[response.status]
              : message ?? responseErrors.default;
          dispatch({ type: 'error', payload });
        }
      );
    }
  };

  return [state, wrappedDispatch];
};
