import { FC } from 'react';
import { clsx } from 'clsx';

import styles from './DomainItemDivider.module.css';

interface DomainItemDividerProps {
  className?: string;
}

const DomainItemDivider: FC<DomainItemDividerProps> = ({ className }) => (
  <div className={clsx(styles.divider, className)} />
);

export default DomainItemDivider;
