import { Link } from 'react-router-dom';
import { useUserContext } from '../../../contexts/UserContext';
import RemoveOrgUser from '../../RemoveOrgUser';
import styles from '../../../containers/Lookup/DisplayOrganization.module.css';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from '@mui/material';

import {
  invalidateUserOrganizationsQuery,
  useUserOrganizationsQuery,
} from './query';
import { useContext } from 'react';
import { LookupContext } from '../../../contexts/LookupContext';

export const UserOrgsTable = () => {
  const user = useUserContext();
  const { user: userLookup } = useContext(LookupContext) || {};
  const { data: userOrganizations } = useUserOrganizationsQuery(userLookup.id);

  if (userOrganizations.length === 0) {
    return <p>This user does not belong to any organization</p>;
  }

  const hasRemovePermission =
    user.roleNames.includes('admin') ||
    user.roleNames.includes('insights_user_manager');

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Account ID</TableCell>
            <TableCell>Organization ID</TableCell>
            <TableCell>Organization Name</TableCell>
            <TableCell>Access Level</TableCell>
            {hasRemovePermission && (
              <TableCell>Remove from Organization</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {userOrganizations.map((uo) => {
            return (
              <TableRow key={uo.organization.id}>
                <TableCell>
                  <Link to={`/lookup/account_id/${uo.organization.account_id}`}>
                    <span data-hj-suppress>{uo.organization.account_id}</span>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/lookup/organization_id/${uo.organization.id}`}>
                    <span data-hj-suppress>{uo.organization.id}</span>
                  </Link>
                </TableCell>
                <TableCell>
                  <span data-hj-suppress>{uo.organization.name}</span>
                </TableCell>
                <TableCell>{uo.access}</TableCell>
                {hasRemovePermission && (
                  <TableCell className={styles.removeButtonCell}>
                    <RemoveOrgUser
                      user={uo.user}
                      organization={uo.organization}
                      onSuccess={invalidateUserOrganizationsQuery}
                    />
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};
