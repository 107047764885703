import UpdateIcon from '@mui/icons-material/Update';
import { bindDialog, usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { ActionButton } from './ActionButton';
import { postFeaturesStartUpdateAdd } from './api';
import { ManageFeatureDialog } from './ManageFeatureDialog';
import styles from './ManageFeatures.module.css';
import { StartUpdateFeatureForm } from './StartUpdateFeatureForm';
import type { FeatureAction } from './useFeatureActionForm';
import type { StartUpdateFeatureFields } from './useStartUpdateFeatureForm';
import { useStartUpdateFeatureForm } from './useStartUpdateFeatureForm';

const action: FeatureAction<StartUpdateFeatureFields> =
  postFeaturesStartUpdateAdd;

type StartUpdateFeatureProps = {
  feature: string;
};

export const StartUpdateAddFeature = ({
  feature,
}: StartUpdateFeatureProps): JSX.Element => {
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'start-update-add-feature',
  });
  const [form, handleSubmit] = useStartUpdateFeatureForm({
    action,
    feature,
    close: popupState.close,
  });

  return (
    <>
      <ActionButton
        action={popupState.open}
        Icon={UpdateIcon}
        title="Add to percentage"
        busyMessage={`Starting update for feature ${feature}`}
      />

      <ManageFeatureDialog
        title={
          <>
            Add feature <span className={styles.featureName}>{feature}</span> by
            percentage
          </>
        }
        onSubmit={handleSubmit}
        {...bindDialog(popupState)}
      >
        <StartUpdateFeatureForm mode="add" feature={feature} form={form} />
      </ManageFeatureDialog>
    </>
  );
};
