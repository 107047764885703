import React, { Component } from 'react';

import Paper from '@mui/material/Paper';

import SNSMessageLogTable from './SNSMessageLogTable';
import ShowSpinner from '../../components/ShowSpinner';
import styles from './UserNotifications.module.css';
import { getSNSMessageLogs } from './api';

export default class SNSMessageLogs extends Component {
  state = {
    SNSMessageLogs: [],
    errorMessage: null,
    hasSearched: false,
    loading: false,
    searchQuery: '',
    count: 0,
    page: 0,
    rowsPerPage: 10,
  };

  transformSNSMessageLogData = (items) => {
    return items.map((item) => {
      const message = JSON.parse(item.content['Message']);
      return [
        item.id,
        new Date(item.created * 1000),
        message.bounce.bounceType,
        message.bounce.bounceSubType,
        message.bounce.bouncedRecipients,
      ];
    });
  };

  getSNSMessageLogData = () => {
    this.setState({
      loading: true,
    });
    return getSNSMessageLogs({
      email: this.state.searchQuery,
    })
      .then((response) => {
        this.setState({
          SNSMessageLogs: this.transformSNSMessageLogData(response),
          loading: false,
          errorMessage: null,
        });
      })
      .catch((e) => {
        this.setState({
          SNSMessageLogs: [],
          errorMessage: e.error_message || e.message || e.error,
          loading: false,
        });
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.searchQuery !== prevProps.searchQuery) {
      this.setState(
        {
          hasSearched: true,
          searchQuery: this.props.searchQuery,
        },
        this.getSNSMessageLogData
      );
    }
  }

  render() {
    return (
      this.state.hasSearched && (
        <Paper className={styles.searchResults} elevation={1}>
          {!this.state.loading ? (
            <div>
              {this.state.errorMessage && (
                <p className={styles.errorMessage}>{this.state.errorMessage}</p>
              )}
              <SNSMessageLogTable
                onRefresh={this.getSNSMessageLogData}
                SNSMessageLogs={this.state.SNSMessageLogs}
                searchQuery={this.state.searchQuery}
              />
            </div>
          ) : (
            <ShowSpinner />
          )}
        </Paper>
      )
    );
  }
}
