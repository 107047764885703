import React from 'react';
import MUIDataTable from 'mui-datatables';
import Linkify from 'react-linkify';
import { linkifyOpenInNew } from '../../utils/linkifyDecorators';
import styles from './Reports.module.css';

const QueryResults = ({ report, results }) => {
  const tableOptions = {
    responsive: 'standard',
    pagination: true,
    selectableRows: 'none',
    print: false,
    download: true,
    search: false,
    filter: false,
  };

  const tableColumns = () => {
    return Object.keys(results[0]).map((key) => {
      const label = key.split('_').join(' ');
      return {
        name: key,
        label,
        options: {
          customBodyRender: (content) => (
            <Linkify componentDecorator={linkifyOpenInNew}>{content}</Linkify>
          ),
        },
      };
    });
  };

  const policyLink =
    'https://hotjar.atlassian.net/wiki/spaces/REC/' +
    'pages/1058308207/Acceptable+Use+Policy+-+Live+data+access+in+ADM';
  return (
    <div data-testid="table">
      <MUIDataTable
        title={report.name}
        data={results}
        columns={tableColumns()}
        options={tableOptions}
        className={styles.dataTable}
      />
      <div className={styles.warning}>
        Warning: this is live customer data and falls under the&nbsp;
        <a href={policyLink}>Acceptable Use Policy</a>
      </div>
    </div>
  );
};

export default QueryResults;
