import { Component } from 'react';
import DisplayLookup from './DisplayLookup';
import SearchBox from './SearchBox';
import UserDetailModal from '../../components/modals/UserDetail';
import UpgradeUserModal from '../../components/modals/UpgradeUser';
import EmailChangeDialog from '../../components/modals/EmailChangeDialog';
import ConfirmationDialog from '../../components/modals/ConfirmationDialog';
import { withModalContext } from '../../contexts/ModalContext';
import { withLookupContext } from '../../contexts/LookupContext';
import { PositiveInteger } from '../../utils/validators';
import t from 'tcomb-validation';
import { UserAuditLogModal } from './actions/UserAuditLog/UserAuditLogModal';
import { LookupResultsModalRoot } from './LookupResultsModalRoot';

class LookupResults extends Component {
  types = {
    account_id: { validator: PositiveInteger, cast: Number },
    organization_id: { validator: PositiveInteger, cast: Number },
    site_id: { validator: PositiveInteger, cast: Number },
    invoice_id: { validator: PositiveInteger, cast: Number },
    user_id: { validator: PositiveInteger, cast: Number },
    bt_customer_id: { validator: PositiveInteger, cast: Number },
  };

  sanitize = (search_by, search_term) => {
    if (this.types[search_by].cast) {
      // We don't want NaN passed to the validator,
      // so if this is returned we instead pass the original value
      return Number.isNaN(this.types[search_by].cast(search_term))
        ? search_term
        : this.types[search_by].cast(search_term);
    } else {
      return search_term;
    }
  };

  validate = (search_by, search_term) => {
    if (!this.types[search_by]) {
      this.props.lookupContext.setSearch({ search_by, search_term });
      this.props.history.push('/lookup/');
      return false;
    }
    let value = this.sanitize(search_by, search_term),
      status = t.validate(value, this.types[search_by].validator);

    if (status.isValid()) {
      return true;
    } else {
      this.setError(status.firstError().message);
      this.props.history.push('/lookup/');
      return false;
    }
  };

  setError = (error) => {
    this.props.lookupContext.setError(error);
  };

  checkParams = () => {
    let params = this.props.match.params;
    if (params.search_by && params.search_term) {
      this.props.lookupContext.setSearch({
        search_by: params.search_by,
        search_term: params.search_term,
      });
    }
  };

  performLookup = () => {
    // Not putting this in a callback as want to run validation after
    // setting state so the UI shows what was entered
    let params = this.props.match.params;
    let valid = this.validate(params.search_by, params.search_term);
    if (params.search_by && params.search_term && valid) {
      this.props.lookupContext.lookupRequest(
        params.search_by,
        params.search_term,
        params.page
      );
    }
  };

  refreshLookup = (newProps) => {
    this.props.modalContext.closeAllModals();
    let valid = this.validate(newProps.search_by, newProps.search_term);
    if (valid) {
      // State needs to be set, otherwise links won't refresh the lookup
      this.props.lookupContext.setSearch({
        search_by: newProps.search_by,
        search_term: newProps.search_term,
        page: newProps.page,
      });
      this.props.lookupContext.lookupRequest(
        newProps.search_by,
        newProps.search_term,
        newProps.page
      );
    }
  };

  UNSAFE_componentWillMount() {
    this.checkParams();
  }

  componentDidMount() {
    let openModal = null;
    if ('URLSearchParams' in window) {
      // Handle automatic modal opening.
      const searchParams = new URLSearchParams(window.location.search);
      // We must have a target site_id or we can't open anything.
      openModal = searchParams.get('open');
    }

    if (!openModal) {
      this.props.modalContext.closeAllModals();
    }
    this.performLookup();
  }

  componentDidUpdate(prevProps) {
    let oldProps = prevProps.match.params;
    let newProps = this.props.match.params;
    if (newProps !== oldProps) {
      if (this.props.history.location.search.includes('page')) {
        newProps['page'] = this.props.history.location.search.split('=')[1];
      }
      this.refreshLookup(newProps);
    }
  }

  setLookupProps = () => {
    const contextToProps = {
      account: this.props.lookupContext.account,
      user: this.props.lookupContext.user,
      organizationsLookup: this.props.lookupContext.organizations,
      organization_users: this.props.lookupContext.organization_users,
      magpie: this.props.lookupContext.magpie,
    };

    return contextToProps;
  };

  render() {
    return (
      <div>
        {this.props.isTopWindow && <SearchBox history={this.props.history} />}
        <DisplayLookup {...this.setLookupProps()} />
        {Boolean(this.props.lookupContext.account) && (
          <div>
            <LookupResultsModalRoot />
          </div>
        )}
        {Boolean(this.props.lookupContext.user) && (
          <div>
            <UserDetailModal />
            <UserAuditLogModal />
            <UpgradeUserModal
              lookupRequest={this.props.lookupContext.lookupRequest}
            />
            <EmailChangeDialog />
          </div>
        )}
        <ConfirmationDialog />
      </div>
    );
  }
}

export default withModalContext(withLookupContext(LookupResults));
