import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line
import * as browserHistory from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import analytics from 'react-segment';

import './index.css';
import App from './App';

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  analytics.default.load('HAcxJ9HCjgjAICRIHzKWZ4rCIS6WIjdG');
  datadogRum.init({
    applicationId: '81b0cfae-2a53-4693-87e6-214065758e89',
    clientToken: 'pub528ea3c16a1329eec0750179f87304a7',
    site: 'datadoghq.eu',
    service: 'adm',
    sampleRate: 100,
    trackInteractions: true,
  });
}

if (
  process.env.REACT_APP_ENVIRONMENT === 'development' ||
  process.env.REACT_APP_ENVIRONMENT === 'review' ||
  process.env.REACT_APP_ENVIRONMENT === 'staging'
) {
  analytics.default.load('d4U9TEqwwtpJ9PJB228s9MoLCoXasEYc');
}

ReactDOM.render(
  <App history={browserHistory} />,
  document.getElementById('root')
);
