import { FC } from 'react';
import SiteProductSubscription, {
  SubscriptionData,
} from '../SiteProductSubscription';
import ServicesTable, { Service } from '../ServicesTable';
import { ProductName } from '../../../../../products/models/product.model';
import ObserveIcon from '../../../../../../components/ObserveIcon';
import AskIcon from '../../../../../../components/AskIcon';

import styles from './SiteProduct.module.css';

const iconsMapping = {
  ask: AskIcon,
  observe: ObserveIcon,
};

interface SiteProductProps {
  product: ProductName;
  services?: Service[];
  isSubscriptionLoading: boolean;
  subscriptionData?: SubscriptionData;
}

const SiteProduct: FC<SiteProductProps> = ({
  product,
  services,
  subscriptionData,
  isSubscriptionLoading,
}) => {
  const ProductIcon = iconsMapping[product];

  return (
    <div className={styles.siteProduct}>
      <div className={styles.serviceTable}>
        <div className={styles.productHeader}>
          <ProductIcon />
          <h2 className={styles.productName}>{product}</h2>
        </div>
        {services && <ServicesTable services={services} />}
      </div>
      <div className={styles.subscriptionData}>
        <SiteProductSubscription
          subscriptionData={subscriptionData}
          isSubscriptionLoading={isSubscriptionLoading}
        />
      </div>
    </div>
  );
};

export default SiteProduct;
