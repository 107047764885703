import { useEffect, useState } from 'react';
import {
  Allowance,
  Magpie,
  PlanName,
  ProductCatalogItem,
  ProductName,
} from '../../../typings';
import { camelKeys } from 'js-convert-case';
import { getProductCatalogFromMagpie } from '../../../utils/api';
import type { Currency } from '../../../types/types';

interface Props {
  billingPeriod: Magpie['subscription']['billingPeriod'] | undefined;
  currency: Currency | undefined;
  version: string | undefined;
}

interface ProductCatalogDto {
  product_name: ProductName;
  plans: {
    plan_name: PlanName;
    allowances: Allowance[];
  }[];
}

export interface ProductCatalog {
  productName: ProductName;
  plans: {
    planName: PlanName;
    allowances: Allowance[];
  }[];
}

export const useProducts = ({ billingPeriod, currency, version }: Props) => {
  const [productCatalog, setProductCatalog] = useState<ProductCatalog[]>([]);
  const [plans, setPlans] = useState<ProductCatalogItem[]>([]);

  useEffect(() => {
    if (!billingPeriod || !currency || !version) return;

    const getPlans = async () => {
      try {
        const plansRes = await getProductCatalogFromMagpie(
          billingPeriod.toLowerCase(),
          currency.toLowerCase(),
          version
        );
        const productCatalogDto: ProductCatalogDto[] = await plansRes.json();
        const productCatalog: ProductCatalog[] = productCatalogDto.map(
          (product) =>
            camelKeys(product, {
              recursive: true,
              recursiveInArray: true,
            }) as ProductCatalog
        );
        setProductCatalog(productCatalog);

        const plans = productCatalog
          .map((product) =>
            product.plans.map((plan) =>
              plan.allowances.map((allowance) => ({
                sku: allowance.sku,
                allowance: (allowance as Allowance) ?? [],
                planName: plan.planName,
                productName: product.productName,
              }))
            )
          )
          .flat(2);
        setPlans(plans);
      } catch (e) {
        console.log(e);
      }
    };
    getPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingPeriod, currency, version]);

  return { plans, productCatalog };
};
