import { FC, useState } from 'react';

import { Button, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { ActionsMenu } from '../../../../components/ActionsMenu';
import HasPermission from '../../../../components/HasPermission';

interface SiteActionsProps {
  handleOnOpenVisualizerModalClick: () => void;
  handleClearInterimsModalOpen: () => void;
  handlePlanSettingsModalOpen: () => void;
  handleActionsModalOpen: () => void;
  // TODO: this needs to be calculated in the FE
  isInFairDowngradeState?: boolean;
  hasMagpieSubscription: boolean;
}

const SiteActionsButton = (
  <Button variant="contained" color="primary">
    Actions
    <ArrowDropDownIcon />
  </Button>
);

const SiteActions: FC<SiteActionsProps> = ({
  handleOnOpenVisualizerModalClick,
  handleClearInterimsModalOpen,
  handlePlanSettingsModalOpen,
  handleActionsModalOpen,
  isInFairDowngradeState,
  hasMagpieSubscription,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOnClickAndCloseMenu = (clickHandler: () => void) => () => {
    setIsOpen(false);
    clickHandler();
  };

  return (
    <ActionsMenu
      button={SiteActionsButton}
      closePopover={handleClose}
      openPopover={handleOpen}
      isOpen={isOpen}
    >
      <MenuItem
        onClick={handleOnClickAndCloseMenu(handleOnOpenVisualizerModalClick)}
      >
        View sampling rates
      </MenuItem>
      {hasMagpieSubscription && (
        <MenuItem
          onClick={handleOnClickAndCloseMenu(handlePlanSettingsModalOpen)}
        >
          Modify plan and rates
        </MenuItem>
      )}
      {isInFairDowngradeState && (
        <HasPermission rolesAccepted={['admin', 'billing_management_2']}>
          <MenuItem
            onClick={handleOnClickAndCloseMenu(handleClearInterimsModalOpen)}
          >
            Clear Fair Downgrade
          </MenuItem>
        </HasPermission>
      )}
      <MenuItem onClick={handleOnClickAndCloseMenu(handleActionsModalOpen)}>
        Site Activity
      </MenuItem>
    </ActionsMenu>
  );
};

export default SiteActions;
