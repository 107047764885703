import { PropsWithChildren } from 'react';
import { useIsAlp } from '../../utils/useIsAlp';

export const HideInAlp = ({ children }: PropsWithChildren) => {
  const isAlp = useIsAlp();

  if (isAlp) {
    return null;
  }

  return children;
};
