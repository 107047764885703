import * as Cookies from 'js-cookie';
import { apiPost } from '../../utils/api';

export function postLogout() {
  return apiPost('/logout', null, {}, 2, {}, true, 'logout success').then(
    (res) => {
      if (res.success) {
        Cookies.remove('LOGGED-IN');
        return Promise.resolve();
      } else {
        return Promise.reject({
          message: 'Got an unexpected HTTP response code: ' + res.status,
        });
      }
    }
  );
}

export function postLogin(email, password) {
  const url = 'https://' + window.location.host + '/api/v2/adm/login';
  const init = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  };
  return fetch(url, init).then((res) => {
    if (res.ok) {
      Cookies.set('LOGGED-IN', 1, { sameSite: 'None', secure: true });
      return Promise.resolve();
    } else {
      return res.json().then((json) => Promise.reject({ message: json.error }));
    }
  });
}
