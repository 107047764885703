import { FC, useMemo } from 'react';

import EntityDetails from '../../../../components/EntityDetails';
import SidebarAccordion from '../../../../components/SidebarAccordion';

import styles from './OrganizationWidget.module.css';
import { useShowOrganization } from '../../api/useShowOrganization';
import { getAccordionTitle, getOrganizationData } from './utils';
import { Typography } from '@mui/material';

interface OrganizationWidgetProps {
  organizationId?: number;
}

const OrganizationWidget: FC<OrganizationWidgetProps> = ({
  organizationId,
}) => {
  const { organizationData, isOrganizationLoading } =
    useShowOrganization(organizationId);

  const id = organizationData?.organization_id;
  const name = organizationData?.organization_name;
  const hasSsoEnabled = organizationData?.organization_sso;

  const data = useMemo(() => {
    if (!id || !name) {
      return;
    }

    return getOrganizationData({ id, name });
  }, [id, name]);

  const accordionTitle = useMemo(
    () => getAccordionTitle(isOrganizationLoading, hasSsoEnabled),
    [hasSsoEnabled, isOrganizationLoading]
  );

  return (
    <SidebarAccordion
      title={accordionTitle}
      accordionName="organization"
      isLoading={isOrganizationLoading}
    >
      {data && (
        <EntityDetails data={data} wrapperClassName={styles.detailsWrapper} />
      )}

      {!isOrganizationLoading && !data && (
        <Typography>No data available.</Typography>
      )}
    </SidebarAccordion>
  );
};

export default OrganizationWidget;
