import { apiPost } from '../../../../utils/api';

export type AddUserFormData = {
  email: string;
  name: string;
};

export const addUser = async (
  organizationId: number,
  userData: AddUserFormData
): Promise<{ success: boolean }> => {
  return apiPost(
    `/organization/${organizationId}`,
    'add_and_provision_dxa_voc_user',
    userData
  );
};
