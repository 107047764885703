import React from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router';

import InternalUserManagement from '../containers/InternalUserManagement';
import Campaigns from '../containers/Campaigns';
import { FeatureManager } from '../containers/FeatureManager';
import InvoiceReports from '../containers/InvoiceReports';
import Lookup from '../containers/Lookup/';
import LookupResults from '../containers/Lookup/LookupResults';
import ReportQuery from '../containers/Reports/ReportQuery';
import Reports from '../containers/Reports';
import SideMenu from './SideMenu';
import UserNotifications from '../containers/UserNotifications';
import { LearnLinker } from '../components/LearnLinker/index';
import styles from './AppContainer.module.css';
import { ModalProvider } from '../contexts/ModalContext';
import { UserProvider } from '../contexts/UserContext';
import { LookupProvider } from '../contexts/LookupContext';

import ShowSite from '../domains/sites/views/ShowSite';
import Referrals from '../containers/Referrals';
import { ProvisionVoCAccount } from '../containers/ProvisioningVoCInsights';

export class AppContainer extends React.Component {
  render() {
    let isTopWindow = window.self === window.top; // Checks to see if being rendered NOT in an iframe
    return (
      <UserProvider>
        <ModalProvider>
          <div className={isTopWindow ? styles.container : ''}>
            <Route
              path={
                '/(' +
                'internal-user-management|' +
                'campaigns|' +
                'referrals|' +
                'feature-manager|' +
                'invoice-reports|' +
                'lookup|' +
                'reports|' +
                'user-notifications|' +
                'provisioning-voc' +
                ')/'
              }
              render={(props) => (
                <SideMenu {...props} isTopWindow={isTopWindow} />
              )}
            />
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/lookup" />} />
              <Route
                exact
                path="/lookup"
                render={({ history }) => (
                  <LookupProvider>
                    <Lookup history={history} isTopWindow={isTopWindow} />
                  </LookupProvider>
                )}
              />
              <Route
                path="/lookup/sites/:siteId"
                render={({ match }) => <ShowSite id={match.params.siteId} />}
              />
              <Route
                path="/lookup/:search_by/:search_term"
                render={({ history, match }) => (
                  <LookupProvider>
                    <LookupResults
                      history={history}
                      match={match}
                      isTopWindow={isTopWindow}
                    />
                  </LookupProvider>
                )}
              />
              <Route
                exact
                path="/reports"
                render={(routerProps) => <Reports {...routerProps} />}
              />
              <Route
                path="/reports/:report_key"
                component={ReportQuery}
                render={(routerProps) => <ReportQuery {...routerProps} />}
              />
              <Route path="/feature-manager" component={FeatureManager} />
              <Route path="/invoice-reports" component={InvoiceReports} />
              <Route path="/user-notifications" component={UserNotifications} />
              <Route path="/campaigns" component={Campaigns} />
              <Route path="/referrals" component={Referrals} />
              <Route
                path="/internal-user-management"
                component={InternalUserManagement}
              />
              <Route path="/learn-linker" component={LearnLinker} />
              <Route path="/provisioning-voc" component={ProvisionVoCAccount} />
              <Route render={() => <Redirect to="/lookup" />} />
            </Switch>
          </div>
        </ModalProvider>
      </UserProvider>
    );
  }
}

export default withRouter(AppContainer);
