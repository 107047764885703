import { useContext, useEffect, useState } from 'react';
import { groupBy, isEmpty } from 'lodash';
import { DialogContent, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  previewPrice,
  getAmendmentPayload,
  getReactivationPayload,
} from '../../../utils/api';
import { LookupContext } from '../../../contexts/LookupContext';
import { useToasts } from 'react-toast-notifications';
import { Subscriptions as SlpSubscriptions } from '../SubscriptionCreationModal/SlpSubscriptions';
import { camelKeys } from 'js-convert-case';
import { ASK, OBSERVE } from '../constants';
import { Magpie, ProductCatalogItem, ProductName, SKU } from '../../../typings';
import {
  createEntity,
  getSalesDeductionPercentage,
  isBasicAllowance,
} from '../../../containers/Lookup/billingV3.functions';
import { SubscriptionInfo } from './SubscriptionInfo';
import { getBasicPlan } from '../../../utils/getFreePlans';
import { getProductCatalogVersion } from '../../../domains/accounts/utils/getProductCatalogVersion';
import moment, { Moment } from 'moment';
import type { AdmModalProps } from '../AdmModalRoot';
import type { FormData, FormSubscription, FormAllowance } from './types';
import { useProducts } from '../../../domains/products/data-access/use-products';
import { CostPreview } from './components/CostPreview';
import { DateSelect } from './components/DateSelect';
import { Actions } from './components/Actions';

export const PreviewCostsModalSlp = ({ onCloseModal }: AdmModalProps<{}>) => {
  const context = useContext(LookupContext);
  const magpie = context?.magpie
    ? (camelKeys(context.magpie, {
        recursive: true,
        recursiveInArray: true,
      }) as Magpie)
    : undefined;

  const { currency, id: accountId } = context?.account;

  const { addToast } = useToasts();
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  const [selectedPreviewDate, setSelectedPreviewDate] = useState<Moment | null>(
    moment()
  );
  useEffect(() => {
    if (magpie?.subscription.currentPeriodEndDate) {
      setSelectedPreviewDate(moment(magpie.subscription.currentPeriodEndDate));
    }
  }, [magpie?.subscription.currentPeriodEndDate]);

  const { plans } = useProducts({
    billingPeriod: magpie?.subscription.billingPeriod,
    currency,
    version: getProductCatalogVersion(context?.account),
  });

  const [formData, setFormData] = useState<FormData>({
    subscriptions: [],
    costs: {
      payNow: {
        subTotal: 0.0,
        taxPercentage: 0.0,
        total: 0.0,
      },
      renewWith: { subTotal: 0.0, taxPercentage: 0.0, total: 0.0 },
    },
  });

  const enrichedMagpieSubscriptions =
    magpie?.subscription.products
      .filter((subscription) => subscription.sku !== 'compound_discount')
      .map(({ sku, entity: { entityIdentifier: siteId } }) => {
        const productCatalogItem = plans.find(
          (plan) => plan.sku === sku
        ) as ProductCatalogItem;
        return {
          ...productCatalogItem,
          site: siteId,
        };
      }) ?? [];

  const getDefaultCosts = () => ({
    payNow: {
      subTotal: magpie?.currentPeriodCostSummary.amountWithoutTax ?? 0.0,
      taxPercentage: magpie?.currentPeriodCostSummary.taxRate ?? 0.0,
      total: magpie?.currentPeriodCostSummary.amountWithTax ?? 0.0,
    },
    renewWith: {
      subTotal: magpie?.currentPeriodCostSummary.amountWithoutTax ?? 0.0,
      taxPercentage: magpie?.currentPeriodCostSummary.taxRate ?? 0.0,
      total: magpie?.currentPeriodCostSummary.amountWithTax ?? 0.0,
    },
  });

  const fillMissingBasicPlan = (
    plans: (ProductCatalogItem & { site: string })[],
    site: string,
    productsCatalog: ProductCatalogItem[]
  ) => {
    const askBasicPlan = getBasicPlan(ASK, productsCatalog);
    const observeBasicPlan = getBasicPlan(OBSERVE, productsCatalog);

    if (plans.length === 2) {
      return plans;
    }

    if (observeBasicPlan && plans.some((plan) => plan.productName === ASK)) {
      return [...plans, { ...observeBasicPlan, site }];
    }

    if (askBasicPlan && plans.some((plan) => plan.productName === OBSERVE)) {
      return [...plans, { ...askBasicPlan, site }];
    }

    return plans;
  };

  useEffect(() => {
    if (!magpie || !context.organizations || !plans.length) {
      return;
    }

    const getOrganizationIdFromSite = (siteId: string) =>
      Object.values(
        context.organizations as Record<string, { id: number; sites: number[] }>
      )
        .find(({ sites }) => sites.includes(Number(siteId)))
        ?.id.toString() ?? '';

    const convertMagpieSubscriptionToFormSubscription =
      (): FormSubscription[] => {
        const groupedSubscriptionsBySite = groupBy(
          enrichedMagpieSubscriptions,
          'site'
        );

        return Object.entries(groupedSubscriptionsBySite).map(
          ([siteId, allowances]) => {
            const orgId = getOrganizationIdFromSite(siteId);
            if (allowances.length !== 2) {
              allowances = fillMissingBasicPlan(allowances, siteId, plans);
            }
            return {
              id: `subscription-${orgId}-${siteId}`,
              organization: orgId,
              site: siteId,
              allowances,
            };
          }
        );
      };

    const data = {
      costs: getDefaultCosts(),
      subscriptions: convertMagpieSubscriptionToFormSubscription(),
    };
    setFormData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.magpie, context.organizations, plans]);

  const hasSelectedPlanChanged = (
    sku: SKU,
    productName: ProductName,
    siteId: string
  ) => {
    const siteProducts = enrichedMagpieSubscriptions.filter(
      (product) => product.site === siteId
    );
    const siteProductsWithBasic = fillMissingBasicPlan(
      siteProducts,
      siteId,
      siteProducts
    );
    return siteProductsWithBasic
      .filter((product) => product.productName === productName)
      .every((product) => product.sku !== sku);
  };

  const preparePayload = () => {
    const changedPlans = formData.subscriptions.flatMap(
      ({ site, allowances }) =>
        allowances.filter(({ sku, productName }) =>
          hasSelectedPlanChanged(sku, productName, site)
        )
    );

    const activePlans =
      enrichedMagpieSubscriptions.filter((sitePlan) =>
        changedPlans.some((plan) => plan.site === sitePlan.site)
      ) ?? [];

    const productsToAdd = changedPlans
      .filter(
        (selectedPlan) =>
          !isBasicAllowance(selectedPlan.sku, plans) &&
          activePlans
            .filter((activePlan) => selectedPlan.site === activePlan.site)
            .every((activePlan) => selectedPlan.sku !== activePlan.sku)
      )
      .map(({ sku, site }) => ({
        sku,
        entity: createEntity(site, context.sites[site].name),
      }));

    const productsToRemove = activePlans
      .filter((activePlan) =>
        changedPlans.some(
          (selectedPlan) =>
            selectedPlan.site === activePlan.site &&
            selectedPlan.productName === activePlan.productName &&
            selectedPlan.sku !== activePlan.sku
        )
      )
      .map(({ sku, site }) => ({
        entity: createEntity(site, context.sites[site].name),
        sku,
      }));

    return { productsToAdd, productsToRemove };
  };

  const handleError = (error: Error) => {
    const errorMessage = error?.message ?? error?.name;
    addToast(errorMessage, {
      appearance: 'error',
      autoDismiss: true,
    });
    console.error('Order', JSON.stringify(error));
  };

  const hasDuplicatedSite = () => {
    const addedSites = formData.subscriptions.map(({ site }) => site);
    return new Set(addedSites).size !== addedSites.length;
  };
  const previewCosts = async () => {
    const isCanceledSubscription = magpie?.subscription.status === 'CANCELLED';

    if (hasDuplicatedSite()) {
      handleError(
        new Error('Please remove the duplicated site/sites to continue')
      );
      return;
    }

    const { productsToAdd, productsToRemove } = preparePayload();

    if (!productsToAdd.length && !productsToRemove.length) {
      setFormData({ ...formData, costs: getDefaultCosts() });
      return;
    }

    const salesDeductionPercentage =
      getSalesDeductionPercentage(magpie?.subscription) ?? 0;

    const amendmentData = {
      productsToAdd,
      productsToRemove,
      salesDeductionPercentage,
    };

    const reactivationData = {
      billingPeriod: magpie?.subscription.billingPeriod,
      products: productsToAdd,
      salesDeductionPercentage,
    };

    let payload = isCanceledSubscription
      ? getReactivationPayload(accountId, reactivationData)
      : getAmendmentPayload(accountId, amendmentData);

    setIsPreviewLoading(true);
    try {
      payload = {
        ...payload,
        order_date: selectedPreviewDate?.format('YYYY-MM-DD'),
      };
      const rawResponse = await previewPrice(payload);
      const response = await rawResponse.json();
      if (!rawResponse.ok) {
        const errorMessage = response?.message ?? rawResponse.status;
        throw new Error(errorMessage);
      }
      const payNow = {
        subTotal: response.pay_now.amount_without_tax,
        taxPercentage: response.pay_now.tax_rate,
        total: response.pay_now.amount_with_tax,
      };

      const renewWith = {
        subTotal: response.renew_with.amount_without_tax,
        taxPercentage: response.renew_with.tax_rate,
        total: response.renew_with.amount_with_tax,
      };

      setFormData({
        ...formData,
        costs: {
          payNow,
          renewWith,
        },
      });
      setIsPreviewLoading(false);
    } catch (error) {
      setIsPreviewLoading(false);
      handleError(error as Error);
    }
  };

  const handleSubscriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    oldAllowance: FormAllowance
  ) => {
    const updatedSubscriptions = [...formData.subscriptions];
    const { id: eventId, value: eventValue } = event.target as {
      id: 'organization' | 'site' | 'plan'; // TODO add account?
      value: string;
    };
    const isPlanChange = eventId === 'plan';
    const isSiteChange = eventId === 'site';
    const currentSubscription = formData.subscriptions[index];
    let allowances = currentSubscription.allowances;

    if (isPlanChange) {
      allowances = [
        ...currentSubscription.allowances,
        {
          ...(plans.find(
            (product) => product.sku === eventValue
          ) as ProductCatalogItem),
          site: oldAllowance.site,
        },
      ];

      // Remove the old one
      if (allowances.length === 3) {
        const indexOfAllowanceToRemove = allowances.findIndex(
          (allowance) => allowance.sku === oldAllowance.sku
        );
        allowances.splice(indexOfAllowanceToRemove, 1);
      }
    } else {
      updatedSubscriptions[index][eventId] = eventValue;
    }

    if (isSiteChange) {
      allowances = allowances.map((allowance) => ({
        ...allowance,
        site: eventValue,
      }));
    }

    updatedSubscriptions[index] = {
      ...currentSubscription,
      allowances,
    };

    setFormData({
      ...formData,
      subscriptions: updatedSubscriptions,
    });
  };

  const addNewSubscription = () => {
    const askBasicPlan = getBasicPlan(ASK, plans);
    const observeBasicPlan = getBasicPlan(OBSERVE, plans);

    if (askBasicPlan && observeBasicPlan) {
      setFormData({
        ...formData,
        subscriptions: [
          ...formData.subscriptions,
          {
            id: `subscription-${new Date().getTime()}`,
            organization: '',
            site: '',
            allowances: [
              { ...askBasicPlan, site: '' },
              { ...observeBasicPlan, site: '' },
            ],
          },
        ],
      });
    }
  };

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          <CostPreview
            costs={formData.costs}
            isPreviewLoading={isPreviewLoading}
            currency={currency}
          />
        </Grid>

        {magpie && <SubscriptionInfo subscription={magpie.subscription} />}

        <DateSelect
          selectedPreviewDate={selectedPreviewDate}
          setSelectedPreviewDate={setSelectedPreviewDate}
        />

        {!isEmpty(formData?.subscriptions) && (
          <SlpSubscriptions
            formData={formData}
            addNewSubscription={addNewSubscription}
            handleSubscriptionChange={handleSubscriptionChange}
            organizations={context.organizations}
            sites={context.sites}
            productsCatalog={plans}
            showDeleteIcon={false}
          />
        )}
      </DialogContent>
      <Actions closeModal={onCloseModal}>
        <LoadingButton
          onClick={() => previewCosts()}
          variant="contained"
          color="primary"
          loading={isPreviewLoading}
        >
          Preview costs
        </LoadingButton>
      </Actions>
    </>
  );
};

export default PreviewCostsModalSlp;
