import React from 'react';
import styles from './Reports.module.css';

const AccessError = () => {
  return (
    <div className={styles.error}>
      Sorry, you don't have the right role to access reports. Follow&nbsp;
      <a href="https://app.getguru.com/card/TApqkXKc/Request-Report-Access">
        this guide
      </a>
      &nbsp; to request access.
    </div>
  );
};

export default AccessError;
