import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import { ModalContext } from '../../../contexts/ModalContext';
import {
  amendSubscriptionInMagpie,
  getProductCatalogFromMagpie,
  cancelSubscriptionOrderInMagpie,
  getProducts,
  reactivateSubscriptionInMagpie,
  parseResponseAndCheckForError,
} from '../../../utils/api';
import { ASK, OBSERVE, MODAL_MODIFY_PLAN_MAGPIE } from '../constants';
import { useToasts } from 'react-toast-notifications';
import { LoadingDialog } from '../LoadingDialog/index';
import { SitesSelection } from './SitesSelection';

import styles from './ModifyPlanSettingsMagpie.module.css';
import {
  getSalesDeductionPercentage,
  createEntity,
} from '../../../containers/Lookup/billingV3.functions';
import { getFreePlans, isFreePlan } from '../../../utils/getFreePlans';
import { getProductCatalogVersion } from '../../../domains/accounts/utils/getProductCatalogVersion';

const getPlanBySku = (sku, products) =>
  products.find((product) => product.sku === sku);

export const ModifyPlanSettingsModalMagpie = () => {
  const { addToast } = useToasts();
  const [renewedProducts, setRenewedProducts] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBulkSites, setSelectedBulkSites] = useState([]);
  const context = useContext(ModalContext);
  const modalProps = context.openModalProps[MODAL_MODIFY_PLAN_MAGPIE];
  const isOpen = context.isModalOpen(MODAL_MODIFY_PLAN_MAGPIE);
  const isBulkEdit = modalProps?.isBulkEdit;
  const siteIds = modalProps?.siteIds;
  const sitesLookup = modalProps?.sitesLookup;
  const [magpieData, setMagpieData] = useState({
    productsCatalog: [],
    salesDeductionPercentage: 0,
  });
  const [selectedPlans, setSelectedPlans] = useState({
    [ASK]: '',
    [OBSERVE]: '',
  });
  const [formData, setFormData] = useState({
    productsToAdd: {
      [ASK]: [],
      [OBSERVE]: [],
    },
    productsToRemove: {
      [ASK]: [],
      [OBSERVE]: [],
    },
    annotation: '',
  });

  const handleFieldUpdate = (event) =>
    setFormData((prevData) => ({
      ...prevData,
      [event.target.id]: event.target.value,
    }));

  const areSelectedPlansOnBasic = (selectedPlans) =>
    getFreePlans(magpieData.productsCatalog).includes(selectedPlans.Ask) &&
    getFreePlans(magpieData.productsCatalog).includes(selectedPlans.Observe);

  const areAllOtherPlansOnBasic = () =>
    renewedProducts.every(
      (product) =>
        // Check if the site is part of the selected site IDs, then skip it, as we want to check non-selected sites if they are on Basic
        // This will help to know if the user will cancel the subscription when it is called with the function `areSelectedPlansOnBasic` above
        siteIds.some((siteId) => siteId === product.entity.entity_identifier) ||
        getFreePlans(magpieData.productsCatalog).includes(product.sku)
    );

  const handlePlanAmendFieldUpdate = (event) => {
    const selectedProduct = event.target.id;
    const selectedPlan = event.target.value;

    setSelectedPlans((prevData) => ({
      ...prevData,
      [selectedProduct]: selectedPlan,
    }));
  };

  const handleSubmit = async () => {
    const accountId = modalProps.magpie.hotjar_account_id;
    const selectedSites = isBulkEdit ? selectedBulkSites : siteIds;
    const activePlans = renewedProducts.filter((sitePlan) =>
      selectedSites.includes(sitePlan.entity.entity_identifier)
    );

    const productsToAmend = selectedSites.reduce(
      (products, selectedSite) => {
        const activePlansForTheSelectedSite = activePlans.filter(
          (activePlan) => activePlan.entity.entity_identifier === selectedSite
        );

        // For the `productsToRemove`, we want to get the active plans (What the sites already have) and check them
        // We need the ones that are not on Basic + active plans that are different than the target plans selected
        // For example, if Plus plans were chosen, and there is already an active site on Plus plan, we need to exclude it from the list.
        products.productsToRemove = [
          ...products.productsToRemove,
          ...activePlansForTheSelectedSite
            .filter(
              (plan) =>
                !Object.values(selectedPlans).includes(plan.sku) &&
                !getFreePlans(magpieData.productsCatalog).includes(plan.sku)
            )
            .map((plan) => ({
              sku: plan.sku,
              entity: createEntity(
                plan.entity.entity_identifier,
                plan.entity.entity_label
              ),
            })),
        ];

        // For the `productsToAdd`, we want to get the new selected plans and check them
        // We need the new plans that are not on Basic + active plans that are different than the target plans selected
        // For example, if Plus plans were chosen, and there is already an active site on Plus plan, we need to exclude it from the list.
        products.productsToAdd = [
          ...products.productsToAdd,
          ...Object.values(selectedPlans)
            .filter(
              (sku) =>
                !activePlansForTheSelectedSite.find(
                  (plan) => plan.sku === sku
                ) && !getFreePlans(magpieData.productsCatalog).includes(sku)
            )
            .map((sku) => ({
              sku: sku,
              entity: createEntity(
                activePlansForTheSelectedSite[0].entity.entity_identifier,
                activePlansForTheSelectedSite[0].entity.entity_label
              ),
            })),
        ];

        return products;
      },
      {
        productsToAdd: [],
        productsToRemove: [],
      }
    );

    const { productsToAdd, productsToRemove } = productsToAmend;

    const hasCanceledSubscription =
      modalProps.magpie.subscription.status === 'CANCELLED';
    const isCancelingSubscription =
      !productsToAdd.length &&
      areSelectedPlansOnBasic(selectedPlans) &&
      areAllOtherPlansOnBasic();

    const amendmentData = {
      productsToAdd,
      productsToRemove,
      salesDeductionPercentage: Number(magpieData.salesDeductionPercentage),
    };

    const reactivationData = {
      billingPeriod: modalProps.magpie.subscription.billing_period,
      products: productsToAdd,
      salesDeductionPercentage: Number(magpieData.salesDeductionPercentage),
    };

    let rawResponse;

    try {
      setIsLoading(true);

      if (isCancelingSubscription) {
        rawResponse = await cancelSubscriptionOrderInMagpie(accountId);
      } else if (hasCanceledSubscription) {
        rawResponse = await reactivateSubscriptionInMagpie(
          accountId,
          reactivationData
        );
      } else {
        rawResponse = await amendSubscriptionInMagpie(accountId, amendmentData);
      }
      parseResponseAndCheckForError(rawResponse);

      setIsLoading(false);
      context.closeModal(MODAL_MODIFY_PLAN_MAGPIE);
      window.location.reload();
    } catch (error) {
      addToast('Order error', { appearance: 'error', autoDismiss: true });
      console.error('Order', error);
    }
  };

  // Fetch the product catalog and active plans
  useEffect(() => {
    if (!isOpen || !modalProps?.magpie) {
      return;
    }

    const accountId = modalProps.magpie.hotjar_account_id;
    const billingPeriod =
      modalProps.magpie.subscription.billing_period.toLowerCase();
    const currency = modalProps.magpie.currency.toLowerCase();

    const salesDeductionPercentage =
      getSalesDeductionPercentage(modalProps.magpie.subscription) ?? 0;

    const getActivePlans = () => getProducts(accountId);

    const getProductsCatalog = () =>
      getProductCatalogFromMagpie(
        billingPeriod,
        currency,
        getProductCatalogVersion(modalProps?.account)
      );

    Promise.all([getProductsCatalog(), getActivePlans()])
      .then(async ([rawProductsCatalog, rawActivePlans]) => {
        const productsCatalog = await parseResponseAndCheckForError(
          rawProductsCatalog
        );
        const activePlans = await parseResponseAndCheckForError(rawActivePlans);

        const enrichedProductsCatalog = productsCatalog
          .map((product) =>
            product.plans.map((plan) =>
              plan.allowances.map((allowance) => {
                return {
                  sku: allowance.sku,
                  allowance,
                  planName: plan.plan_name,
                  productName: product.product_name,
                };
              })
            )
          )
          .flat(2);

        const initialSelectedPlans = isBulkEdit
          ? {
              [ASK]: enrichedProductsCatalog.find(
                (item) => item.productName === ASK && isFreePlan(item)
              ).sku,
              [OBSERVE]: enrichedProductsCatalog.find(
                (item) => item.productName === OBSERVE && isFreePlan(item)
              ).sku,
            }
          : activePlans.renew_with
              .filter((item) => item.entity.entity_identifier === siteIds[0])
              .reduce(
                (selectedPlans, plan) => {
                  const enrichedPlan = getPlanBySku(
                    plan.sku,
                    enrichedProductsCatalog
                  );
                  selectedPlans[enrichedPlan.productName] = plan.sku;
                  return selectedPlans;
                },
                {
                  [ASK]: '',
                  [OBSERVE]: '',
                }
              );

        setSelectedPlans(() => initialSelectedPlans);
        setRenewedProducts(activePlans.renew_with);
        setMagpieData((prevData) => ({
          ...prevData,
          salesDeductionPercentage,
          productsCatalog: enrichedProductsCatalog,
        }));
      })
      .catch((error) => {
        addToast('Error with fetching the products/plans', {
          appearance: 'error',
          autoDismiss: true,
        });
        console.error(error);
      });
  }, [addToast, isBulkEdit, isOpen, modalProps, siteIds]);

  if (!modalProps && !isOpen) return null;

  if (isLoading) {
    return <LoadingDialog title="Updating plans" />;
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} disableEnforceFocus>
      <DialogTitle>Amend Plans and Rates</DialogTitle>
      <DialogContent>
        <div className={styles.container}>
          {isBulkEdit && (
            <SitesSelection
              siteIds={siteIds}
              sitesLookup={sitesLookup}
              selectedSites={selectedBulkSites}
              handleCheckboxChange={(event) => {
                setSelectedBulkSites(event);
              }}
            />
          )}
          <div>
            <InputLabel variant="standard" htmlFor={OBSERVE} shrink>
              Select Observe Plan
            </InputLabel>
            <Select
              id={OBSERVE}
              label="Select Observe Plan"
              variant="standard"
              value={selectedPlans.Observe}
              onChange={(event) => handlePlanAmendFieldUpdate(event)}
              native
              fullWidth
              displayEmpty
            >
              {!!magpieData.productsCatalog?.length &&
                magpieData.productsCatalog
                  .filter((item) => item.productName.includes(OBSERVE))
                  .map((item) => (
                    <option key={item.sku} value={item.sku}>
                      {item.planName} –{' '}
                      {item.allowance.monthly_cc_sessions ??
                        item.allowance.daily_cc_sessions}{' '}
                      {item.allowance.monthly_cc_sessions ? 'Monthly' : 'Daily'}{' '}
                      Sessions
                    </option>
                  ))}
            </Select>
          </div>
          <div>
            <InputLabel variant="standard" htmlFor={ASK} shrink>
              Select Ask Plan
            </InputLabel>
            <Select
              id={ASK}
              label="Select Ask Plan"
              variant="standard"
              value={selectedPlans.Ask}
              onChange={(event) => handlePlanAmendFieldUpdate(event)}
              native
              fullWidth
              displayEmpty
            >
              {!!magpieData.productsCatalog?.length &&
                magpieData.productsCatalog
                  .filter((item) => item.productName.includes(ASK))
                  .map((item) => (
                    <option key={item.sku} value={item.sku}>
                      {item.planName} – {item.allowance.monthly_responses}{' '}
                      Responses
                    </option>
                  ))}
            </Select>
          </div>
          <TextField
            label="Annotation"
            id="annotation"
            variant="standard"
            value={formData.annotation}
            onChange={(event) => handleFieldUpdate(event)}
            type="text"
            helperText={!formData.annotation ? 'Please add an annotation' : ''}
            fullWidth
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            context.closeModal(MODAL_MODIFY_PLAN_MAGPIE);
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
