import React, { Component } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { withModalContext } from '../../../contexts/ModalContext';
import { MODAL_CONFIRMATION_DIALOG } from '../constants';

class ConfirmationDialog extends Component {
  state = {
    errors: {
      server_message: null,
    },
    loading: false,
    annotation: '',
    confirmation: '',
  };

  closeModal = () => {
    this.handleClearErrors();
    this.props.modalContext.closeModal(MODAL_CONFIRMATION_DIALOG);
  };

  handleClearErrors = () => {
    this.setState({ errors: { server_message: null } });
  };

  handleTextFieldChange = (event) => {
    let fieldName = event.target.name;
    this.setState({
      [fieldName]: event.target.value,
    });
  };

  handleSubmit = (e) => {
    let modalProps =
      this.props.modalContext.openModalProps[MODAL_CONFIRMATION_DIALOG];
    e.preventDefault();
    if (this.state.loading) return;
    this.setState({
      ...this.state,
      loading: true,
    });
    modalProps
      .onSubmit(this.state.annotation)
      .then((event) => {
        // If it is an HTTP request and the status is false, throw it
        if (event?.ok === false) {
          throw new Error(
            JSON.stringify(
              event.message || `Something went wrong, status: ${event.status}`
            )
          );
        }

        this.setState(
          {
            errors: {
              server_message: null,
            },
            loading: false,
            annotation: '',
          },
          this.closeModal
        );
        modalProps.onSuccess();
      })
      .catch((e) => {
        if (modalProps.onError) {
          modalProps.onError(e);
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            ...this.state,
            errors: {
              server_message: e.error || e.message,
            },
            loading: false,
          });
        }
      });
  };

  isConfirmationConditionSatisfied = (modalProps) => {
    if (modalProps.hasConfirmationInputField) {
      return modalProps.confirmationInputFieldHandler?.(
        this.state.confirmation
      );
    }
    return true;
  };

  render() {
    const modalProps =
      this.props.modalContext.openModalProps[MODAL_CONFIRMATION_DIALOG];
    const modalOpen = this.props.modalContext.isModalOpen(
      MODAL_CONFIRMATION_DIALOG
    );

    if (modalProps) {
      return (
        <div>
          <Dialog
            open={modalOpen}
            disableEnforceFocus={true}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">{modalProps.title}</DialogTitle>
            <form onSubmit={this.handleSubmit}>
              <DialogContent>
                <DialogContentText sx={{ whiteSpace: 'pre-line' }}>
                  {modalProps.modalWarning}
                </DialogContentText>
                {modalProps.hasConfirmationInputField && (
                  <TextField
                    fullWidth
                    required
                    margin="dense"
                    id="confirmation"
                    label={modalProps.confirmationInputLabel}
                    name="confirmation"
                    value={this.state.confirmation}
                    onChange={this.handleTextFieldChange}
                    helperText={`Please enter the ${modalProps.confirmationInputLabel} to confirm.`}
                    variant="standard"
                  />
                )}
                {!modalProps.noAnnotationRequired && (
                  <TextField
                    multiline
                    fullWidth
                    required
                    margin="dense"
                    id="annotation"
                    label="Annotation"
                    name="annotation"
                    value={this.state.annotation}
                    onChange={this.handleTextFieldChange}
                    helperText="Please provide reason for change and/or Zendesk ticket link."
                    variant="standard"
                  />
                )}
                {this.state.errors.server_message && (
                  <span className="error">
                    Error: {this.state.errors.server_message}
                  </span>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.closeModal}
                  disabled={this.state.loading}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={
                    modalProps.disableConfirmation ||
                    this.state.loading ||
                    (!modalProps.noAnnotationRequired &&
                      this.state.annotation.length < 1) ||
                    !this.isConfirmationConditionSatisfied(modalProps)
                  }
                  color="primary"
                >
                  Confirm
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withModalContext(ConfirmationDialog);
