import HasPermission from '../../../components/HasPermission';
import { useIsAskCrossSell } from './useIsAskCrossSell';

export const ProvisioningVOCWrapper = ({
  children,
  showForCrossSell = true,
}: {
  children: React.ReactNode;
  showForCrossSell?: boolean;
}): React.ReactNode => {
  const hasCrossSellFlag = useIsAskCrossSell();

  if (hasCrossSellFlag === true && showForCrossSell) {
    return (
      <HasPermission rolesAccepted={['admin', 'dxa_voc_manual_provisioning']}>
        {children}
      </HasPermission>
    );
  }
  if (hasCrossSellFlag === false && !showForCrossSell) {
    return children;
  }
  return null;
};
