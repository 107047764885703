import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from 'mui-datatables';
import { FC } from 'react';
import { Currency } from '../../../../types/types';
import { getCurrencySymbol } from '../../../../utils/costs';
import { toUTCDateString } from '../../../../utils/dates';
import { Deduction } from './components/Deductions';
import { discountsCustomRenderer, recValueCustomRenderer } from './renderers';

import styles from './SiteInfoTable.module.css';
import { SiteInfoAlp } from './components/SiteInfoAlp';
import { useIsAlp } from '../../../accounts/utils/useIsAlp';

const siteTableOptions: MUIDataTableOptions = {
  search: false,
  sort: false,
  filter: false,
  selectableRows: 'none',
  pagination: false,
  print: false,
  download: false,
  viewColumns: false,
  responsive: 'standard',
  elevation: 0,
};

interface SiteInfoTableProps {
  totalCost: number;
  currency: Currency;
  created: number;
  lastData: number;
  recValue: number;
  hasSkuDiscount: boolean;
  deductions?: Deduction[];
  isBillingV3Converted: boolean;
  handleOnOpenVisualizerModalClick: () => void;
}

const SiteInfoTable: FC<SiteInfoTableProps> = ({
  totalCost,
  currency,
  created,
  lastData,
  recValue,
  deductions,
  hasSkuDiscount,
  isBillingV3Converted,
  handleOnOpenVisualizerModalClick,
}) => {
  const currencySymbol = getCurrencySymbol(currency);
  const isAlp = useIsAlp();
  const lastDataFormatted = lastData ? toUTCDateString(lastData) : 'unkown';
  const createdFormatted = toUTCDateString(new Date(created * 1000));

  if (isAlp) {
    return (
      <SiteInfoAlp lastData={lastDataFormatted} created={createdFormatted} />
    );
  }

  const tableColumns: MUIDataTableColumnDef[] = [
    { name: 'Full Site Cost' },
    {
      name: 'Discounts',
      options: {
        customBodyRender: discountsCustomRenderer({
          deductions,
          hasSkuDiscount,
          isBillingV3Converted,
          currency: currencySymbol,
        }),
      },
    },
    { name: 'Last Data' },
    {
      name: 'Rec Value',
      options: {
        customBodyRender: recValueCustomRenderer(
          handleOnOpenVisualizerModalClick
        ),
      },
    },
    { name: 'Created' },
  ];

  const tableData = [
    [
      `${currencySymbol} ${totalCost}`,
      '0',
      lastDataFormatted,
      recValue,
      createdFormatted,
    ],
  ];

  return (
    <div className={styles.table}>
      <MUIDataTable
        title=""
        data={tableData}
        columns={tableColumns}
        options={siteTableOptions}
      />
    </div>
  );
};

export default SiteInfoTable;
