import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useToasts } from 'react-toast-notifications';
import { fetchSiteActionsURL } from './urls';
import { HTTP_ERROR_STATUS_STRING } from '../../../utils/constants';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { SiteAction } from '../models/adm.model';

interface ShowSiteActionsResponse {
  items: SiteAction[];
}

const showSiteActions = async (
  id: string
): Promise<ShowSiteActionsResponse> => {
  const response = await fetch(fetchSiteActionsURL(id));

  return handleFetchApiResponse<ShowSiteActionsResponse>(response);
};

export const useShowSiteActions = (siteId?: string) => {
  const { addToast } = useToasts();
  const { data, isInitialLoading, isError, error, refetch } = useQuery<
    ShowSiteActionsResponse,
    Error
  >({
    queryKey: ['showSiteActions', siteId],
    queryFn: () => showSiteActions(siteId!),
    enabled: !!siteId,
  });

  useEffect(() => {
    // Error toast not displayed on 404
    if (isError && error.message !== HTTP_ERROR_STATUS_STRING) {
      addToast(
        `There was an error fetching data for site with id:"${siteId}"`,
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );
    }
  }, [siteId, addToast, isError, error]);

  return {
    refetchSiteActions: refetch,
    siteActionsData: data?.items || [],
    siteActionsError: error,
    isSiteActionsError: isError,
    isSiteActionsLoading: isInitialLoading,
  };
};
