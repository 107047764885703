import { MenuItem } from '@mui/material';
import { useModalContext } from '../../../../contexts/ModalContext';
import { MODAL_ADD_IDP } from '../../../../components/modals/constants';

interface Props {
  accountId: number;
}

export const AddIDPMenuItem = ({ accountId }: Props) => {
  const modalContext = useModalContext();
  return (
    <MenuItem
      onClick={() => {
        modalContext.openModal(MODAL_ADD_IDP, { accountId });
      }}
    >
      Add Identity Provider (SAML)
    </MenuItem>
  );
};
