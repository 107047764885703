import { Fragment } from 'react';
import { Grid, InputLabel, Select, Card, CardContent } from '@mui/material';
import { FormDataAlp } from './types';
import type { ProductCatalog } from '../../../domains/products/data-access/use-products';

interface Props {
  formData: FormDataAlp;
  handleSubscriptionChange: (sku: string) => void;
  productCatalog: ProductCatalog[];
}

export const AlpSubscriptions = ({
  formData,
  handleSubscriptionChange,
  productCatalog,
}: Props) => {
  const renderPlanOptions = (plan: ProductCatalog['plans'][number]) => {
    return plan.allowances
      .filter(
        (allowance) =>
          (allowance.quotas?.analyticsSessionQuota ??
            allowance.quotas?.monthlyResponses) &&
          allowance.selfServeEnabled
      )
      .map((allowance) => (
        <option key={allowance.sku} value={allowance.sku}>
          {plan.planName} –{' '}
          {allowance.quotas?.monthlyResponses
            ? `${allowance.quotas?.monthlyResponses} Responses`
            : `${allowance.quotas?.analyticsSessionQuota} Sessions`}
        </option>
      ));
  };

  return (
    <>
      {!!formData.subscriptions.length && (
        <Card sx={{ marginBottom: '24px;' }}>
          <CardContent>
            <Grid container columnSpacing={8} rowSpacing={4}>
              {productCatalog.map((product) => (
                <Fragment key={product.productName}>
                  <Grid item xs={1}>
                    <h3>{product.productName}</h3>
                  </Grid>
                  <Grid item xs={5}>
                    <InputLabel variant="standard" htmlFor="plan" shrink>
                      {`Select ${product.productName} Plan`}
                    </InputLabel>
                    <Select
                      id="plan"
                      label={`Select ${product.productName} Plan`}
                      inputProps={{
                        'aria-label': `Select ${product.productName} Plan`,
                      }}
                      value={
                        formData.subscriptions.find(
                          (subscription) =>
                            subscription.productName === product.productName
                        )?.sku
                      }
                      variant="standard"
                      onChange={(event) =>
                        handleSubscriptionChange(event.target.value)
                      }
                      native
                      fullWidth
                    >
                      {product.plans.map((plan) => renderPlanOptions(plan))}
                    </Select>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
};
