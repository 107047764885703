import { apiGet, apiPost } from '../../utils/api';

export function postAdmAction(action) {
  return apiPost('/actions', null, action, 2).then((data) => {
    if (data.id) {
      return Promise.resolve(data);
    } else {
      return Promise.reject(data);
    }
  });
}

export function getAdmUserActions(adm_user_id) {
  return apiGet(`/actions/${adm_user_id}`);
}
