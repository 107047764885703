import { apiGet } from './api.js';

class Plan {
  constructor(identifier, featureGroup, sampleRate, dailySessionAllowance) {
    this.identifier = identifier;
    this.featureGroup = featureGroup;
    this.sampleRate = sampleRate;
    this.dailySessionAllowance = dailySessionAllowance;
  }

  static fromDTO(planDTO) {
    return new Plan(
      planDTO.identifier,
      planDTO.feature_group,
      planDTO.sample_rate,
      planDTO.cc_daily_allowance
    );
  }

  get label() {
    if (this.identifier.includes(';')) {
      return this.identifier
        .split(';')
        .map((plan) => plan.replaceAll('_', ' '))
        .join(' & ');
    } else if (this.dailySessionAllowance) {
      return `${this.featureGroup} - ${this.dailySessionAllowance} sessions`;
    } else {
      return `${this.featureGroup} - ${this.sampleRate} pageviews`;
    }
  }
}

export function getPlans() {
  return apiGet('/settings', {}, 1, {}, false).then((response) =>
    response.available_plans.map(Plan.fromDTO)
  );
}

export function getPlansAndPricingTables() {
  return apiGet('/settings', {}, 1, {}, false).then((response) => ({
    plans: response.available_plans.map(Plan.fromDTO),
    pricingTables: response.pricing_tables,
  }));
}
