import { isServiceManagementMigrated } from '../../services/utils/isServiceManagementMigrated';
import { Account } from '../../accounts/models/account.model';
import { TrialState } from '../models/TrialState';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchTrialStates } from './fetchTrialStates';

export const createAccountTrialStatesQueryConfig = (
  accountId: string | number | undefined
) => {
  return {
    queryKey: ['trials', accountId?.toString(), 'states'],
    queryFn: () => fetchTrialStates('account', accountId?.toString()),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  };
};

/**
 * Returns the v2 trial states of a given CS_Lite account as an array of `TrialState` objects.
 */
export const useCsLiteAccountTrialV2States = (
  account: Account | undefined
): UseQueryResult<Array<TrialState>> => {
  const siteIsOnServiceManagement = isServiceManagementMigrated(account);

  return useQuery({
    ...createAccountTrialStatesQueryConfig(account?.id),
    enabled: !!account && !!siteIsOnServiceManagement,
  });
};
