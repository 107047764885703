import { apiPost } from '../../../../utils/api';

export const swapSitePlan = (
  site_id,
  target_site_id,
  adm_action_annotation
) => {
  return apiPost('/site/' + site_id, 'swap_site_plans', {
    target_site_id,
    adm_action_annotation,
  });
};
