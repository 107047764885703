import { FC, useMemo } from 'react';
import { Typography } from '@mui/material';

import EntityDetails from '../../../../components/EntityDetails';
import DomainItemDivider from '../../../../components/DomainItemDivider';
import SidebarAccordion from '../../../../components/SidebarAccordion';
import ExternalLink from '../../../../components/ExternalLink';

import { useShowMagpieCustomer } from '../../api/useShowMagpieCustomer';

import { getCostsData, getSubscriptionData } from './utils';

import styles from './SubscriptionWidget.module.css';

interface SubscriptionWidgetProps {
  accountId?: number;
}

const SubscriptionWidget: FC<SubscriptionWidgetProps> = ({ accountId }) => {
  const { magpieCustomerData, isMagpieCustomerLoading } =
    useShowMagpieCustomer(accountId);

  const linkToZuora = magpieCustomerData?.external_customer.external_url;

  const data = useMemo(() => {
    if (!magpieCustomerData) {
      return null;
    }

    return getSubscriptionData(magpieCustomerData);
  }, [magpieCustomerData]);

  const costsData = useMemo(() => {
    if (!magpieCustomerData) {
      return null;
    }

    return getCostsData(magpieCustomerData);
  }, [magpieCustomerData]);

  return (
    <>
      <SidebarAccordion
        title="Subscription details"
        accordionName="subscriptionDetails"
        isLoading={isMagpieCustomerLoading}
      >
        {data && costsData && (
          <>
            <EntityDetails data={data} wrapperClassName={styles.wrapper} />
            <DomainItemDivider />
            <EntityDetails data={costsData} wrapperClassName={styles.wrapper} />
            <DomainItemDivider />
            {linkToZuora && (
              <ExternalLink
                text="See details in Zuora"
                url={linkToZuora}
                className={styles.link}
              />
            )}
          </>
        )}
        {!isMagpieCustomerLoading && !data && (
          <Typography>No data available.</Typography>
        )}
      </SidebarAccordion>
    </>
  );
};

export default SubscriptionWidget;
