import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useToasts } from 'react-toast-notifications';
import { fetchSiteServicePeriodsURL } from './urls';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { ServicePeriods } from '../models/service.model';
import { queryClient } from '../../../query-client-provider';

interface ShowSiteServicePeriods {
  service_periods: ServicePeriods;
}

export const createShowSiteServicePeriodsQueryOptions = (
  siteId: string | undefined
) => ({
  queryKey: ['showSiteServicePeriods', siteId],
  queryFn: () => showSiteServicePeriods(siteId!),
  enabled: !!siteId,
});

export const showSiteServicePeriods = async (
  siteId: string | undefined
): Promise<ServicePeriods | null> => {
  if (!siteId) {
    return null;
  }

  const response = await handleFetchApiResponse<ShowSiteServicePeriods>(
    await fetch(fetchSiteServicePeriodsURL(siteId))
  );

  const { service_periods: servicePeriods } = response;

  /**
   * The service management API may respond with `{}` if the entity has no service period or has not
   * been migrated to service management. Handling the `ShowSiteServicePeriods | {}` can have
   * some complications in TypeScript, so for simplicity, and since we don't need to distinguish
   * between the above two cases anyway, let's treat the `{}` response as `undefined`.
   */
  return JSON.stringify(servicePeriods || {}) === '{}' ? null : servicePeriods;
};

export const invalidateServicePeriodsQuery = (siteId: string) => {
  return queryClient.invalidateQueries(['showSiteServicePeriods', siteId]);
};

export const useShowSiteServicePeriods = (siteId: string | undefined) => {
  const { addToast } = useToasts();
  const { data, isInitialLoading, isError, error } = useQuery<
    ServicePeriods | null,
    Error
  >(createShowSiteServicePeriodsQueryOptions(siteId));

  useEffect(() => {
    if (isError) {
      addToast(
        `There was an error fetching data for site with id:"${siteId}"`,
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );
    }
  }, [siteId, addToast, isError, error]);

  return {
    siteServicePeriodsData: data,
    siteServicePeriodsError: error,
    isSiteServicePeriodsError: isError,
    isSiteServicePeriodsLoading: isInitialLoading,
  };
};
