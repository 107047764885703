import ColoredLabel from '../../../components/ColoredLabel';
import { SEPARATE_SKU_PRICING_TABLE } from '../../../utils/constants';
import { Tooltip } from '@mui/material';
import type { Account } from '../../../domains/accounts/models/account.model';
import type { Magpie } from '../../../typings';
import { useBilledOutsideZuora } from './use-billed-outside-zuora';
interface AccountLabelsProps {
  pricing_table: Account['pricing_table'];
  billingVersion: number;
  billingV3Converted: boolean;
  billingV3Unconverted: boolean;
  isConversionOfferEligibleForAccount: boolean;
  magpie: Magpie;
  features: string[];
  trial_state: string;
  total_cost: number;
  type: string;
  hasActiveTrialV2: boolean;
  isAlp: boolean;
  accountId: number;
}

export function AccountLabels(props: AccountLabelsProps) {
  const {
    pricing_table,
    billingVersion,
    billingV3Converted,
    billingV3Unconverted,
    features,
    magpie,
    isConversionOfferEligibleForAccount,
    trial_state,
    isAlp,
    type,
    hasActiveTrialV2,
    total_cost,
    accountId,
  } = props;
  const billedOutsideZuora = useBilledOutsideZuora({
    accountId,
    features,
  });

  return (
    <>
      {isAlp ? (
        <Tooltip title="CS Lite account with account level plans">
          <span>
            <ColoredLabel color={'var(--red)'}>
              CS Lite ALP account
            </ColoredLabel>
          </span>
        </Tooltip>
      ) : (
        <Tooltip title="Regular account with site level plans">
          <span>
            <ColoredLabel color={'var(--blue)'}>
              Hotjar SLP account
            </ColoredLabel>
          </span>
        </Tooltip>
      )}
      {billedOutsideZuora && (
        <Tooltip title="This account has plans that are billed outside Zuora">
          <span>
            <ColoredLabel color={'var(--red)'}>
              Billed outside Zuora
            </ColoredLabel>
          </span>
        </Tooltip>
      )}
      <hr />
      <ColoredLabel color={'var(--business)'}>
        {pricing_table.label === SEPARATE_SKU_PRICING_TABLE
          ? 'DUAL SKU PLAN'
          : 'COMBINED SKU PLAN'}
      </ColoredLabel>
      <ColoredLabel color={'var(--blue)'}>
        BILLING {billingVersion}.0
      </ColoredLabel>
      {billingV3Converted ? (
        <ColoredLabel color={'var(--business)'}>
          SUB {magpie.subscription.status}
        </ColoredLabel>
      ) : (
        ''
      )}
      {features.indexOf('prescriptive_pricing') > -1 && (
        <ColoredLabel color={'var(--green)'}>Prescriptive Pricing</ColoredLabel>
      )}
      {isConversionOfferEligibleForAccount && (
        <ColoredLabel color={'var(--green)'}>
          40% Yearly Startup Offer Eligible
        </ColoredLabel>
      )}
      {trial_state === 'trial_active' && (
        <ColoredLabel color={'var(--business)'}>On Trial</ColoredLabel>
      )}
      {trial_state === 'trial_eligible' && (
        <ColoredLabel color={'var(--business)'} bordered={true}>
          Trial Eligible
        </ColoredLabel>
      )}
      {total_cost >= 289 && trial_state !== 'trial_active' && (
        <ColoredLabel color={'var(--blue)'}>Big Business</ColoredLabel>
      )}
      {type === 'agency' && (
        <ColoredLabel color={'var(--blue)'}>Agency</ColoredLabel>
      )}
      {hasActiveTrialV2 && (
        <Tooltip
          title={
            isAlp
              ? 'This account has an active trial v2'
              : 'At least one site has an active trial v2'
          }
        >
          <span>
            <ColoredLabel color={'var(--business)'}>
              Trial 2.0 active
            </ColoredLabel>
          </span>
        </Tooltip>
      )}
      {features.includes('pp_usage_based_pricing_phase_0_variant1') && (
        <ColoredLabel color={'var(--blue)'}>UBP</ColoredLabel>
      )}
      {billingV3Converted ? (
        <p>
          <strong>NB:</strong> This account is on Billing V3, so many
          account-based functions are disabled. Please use Zuora for these
          actions.
        </p>
      ) : null}
      {billingV3Unconverted ? (
        <p>
          <strong>NB:</strong> This account is on Billing V3, but doesn't have
          account in Zuora yet or it was removed
        </p>
      ) : null}
    </>
  );
}
