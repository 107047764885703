import type { ComponentProps, ReactNode, SyntheticEvent } from 'react';
import React, { useState } from 'react';
import { AppBar, Paper, Tab, Tabs } from '@mui/material';

import styles from './FeatureManager.module.css';
import { FeatureList } from './FeatureList';
import { ToggleFeature } from './ToggleFeature';
import { DefaultFeatures } from './DefaultFeatures';

type TabValues =
  | 'feature_list'
  | 'enable_feature'
  | 'disable_feature'
  | 'default_features';

type TabNavProps = {
  tabs: { label: ReactNode; value: TabValues }[];
  activeTab: TabValues;
  onChange: ComponentProps<typeof Tabs>['onChange'];
};

const TabNav = ({ tabs, activeTab, onChange }: TabNavProps): JSX.Element => {
  const tabItems = tabs.map(({ label, value }, index) => (
    <Tab
      key={`tab-${index}`}
      id={`tab-${value}`}
      aria-controls={`tabpanel-${value}`}
      tabIndex={index}
      className={activeTab === value ? styles.activeTab : ''}
      label={label}
      value={value}
    />
  ));

  return (
    <Tabs
      value={activeTab}
      onChange={onChange}
      variant="fullWidth"
      indicatorColor="secondary"
      textColor="inherit"
    >
      {tabItems}
    </Tabs>
  );
};

type TabPanelProps = {
  tab: TabValues;
  activeTab: TabValues;
  children: ReactNode;
};

const TabPanel = ({
  tab,
  activeTab,
  children,
}: TabPanelProps): JSX.Element | null => {
  if (activeTab !== tab) {
    return null;
  }

  return (
    <Paper
      role="tabpanel"
      tabIndex={-1}
      id={`tabpanel-${tab}`}
      aria-labelledby={`tab-${tab}`}
      className={styles.feature}
    >
      {children}
    </Paper>
  );
};

export const FeatureManager = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState<TabValues>('feature_list');

  const handleTabChange = (_e: SyntheticEvent, value: TabValues) => {
    setActiveTab(value);
  };

  return (
    <div className={styles.container}>
      <h2>Feature Manager</h2>
      <p>To enable/disable a feature for associated accounts:</p>
      <ul>
        <li>Select ENABLE FEATURE or DISABLE FEATURE.</li>
        <li>Select a feature.</li>
        <li>
          Enter a list of Account Owner emails or Account IDs separated by
          either a new line or comma.
        </li>
      </ul>

      <div>
        <AppBar position="relative">
          <TabNav
            activeTab={activeTab}
            tabs={[
              { value: 'feature_list', label: 'Feature List' },
              { value: 'default_features', label: 'Default Features' },
              {
                value: 'enable_feature',
                label: (
                  <>
                    <span className={styles.deprecated}>Enable Feature</span>{' '}
                    (Deprecated)
                  </>
                ),
              },
              {
                value: 'disable_feature',
                label: (
                  <>
                    <span className={styles.deprecated}>Disable Feature</span>{' '}
                    (Deprecated)
                  </>
                ),
              },
            ]}
            onChange={handleTabChange}
          />
        </AppBar>
        <TabPanel tab="feature_list" activeTab={activeTab}>
          <FeatureList />
        </TabPanel>
        <TabPanel tab="enable_feature" activeTab={activeTab}>
          <ToggleFeature action="enable_feature" />
        </TabPanel>
        <TabPanel tab="disable_feature" activeTab={activeTab}>
          <ToggleFeature action="disable_feature" />
        </TabPanel>
        <TabPanel tab="default_features" activeTab={activeTab}>
          <DefaultFeatures />
        </TabPanel>
      </div>
    </div>
  );
};
