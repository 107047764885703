import { FC, PropsWithChildren } from 'react';
import styles from './ColoredLabel.module.css';

interface ColoredLabelProps {
  textColor?: string;
  uppercase?: boolean;
  bordered?: boolean;
  color?: string;
}

type ColorStyle = Record<string, string | null>;

const ColoredLabel: FC<PropsWithChildren<ColoredLabelProps>> = ({
  children,
  bordered = false,
  color = 'var(--teal-grey)',
  textColor,
  uppercase = true,
}) => {
  let colorStyle: ColorStyle = {};
  colorStyle.border = '2px solid ' + color;

  if (bordered) {
    colorStyle.backgroundColor = null;
    colorStyle.color = textColor ? textColor : color;
  } else {
    colorStyle.backgroundColor = color;
    colorStyle.color = textColor ? textColor : '#FFF';
  }

  let className = styles.label;
  if (uppercase) {
    className = [styles.label, styles.uppercase].join(' ');
  }

  return (
    <span className={className} style={colorStyle}>
      {children}
    </span>
  );
};

export default ColoredLabel;
