import { Grid, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

interface Props {
  selectedPreviewDate: moment.Moment | null;
  setSelectedPreviewDate: (date: moment.Moment | null) => void;
}

export const DateSelect = ({
  selectedPreviewDate,
  setSelectedPreviewDate,
}: Props) => {
  return (
    <Grid>
      <Grid item xs={6} style={{ marginTop: '82px', marginBottom: '10px' }}>
        <h2>Configure Preview</h2>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Preview date"
            value={selectedPreviewDate}
            inputFormat="YYYY-MM-DD"
            minDate={moment()}
            onChange={(newSelectedPreviewDate) =>
              setSelectedPreviewDate(newSelectedPreviewDate)
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};
