import type { DefaultFeature, Entity } from './api';

export const entities = {
  account: 'Account',
  organization: 'Organization',
  site: 'Site',
} as const;

export const isEntity = (value: string): value is DefaultFeature['entity'] =>
  value in entities;

export const getEntityName = <TEntity extends Entity>(
  entity: TEntity
): typeof entities[TEntity] => entities[entity];

const isValidIdentifier = (value: number) =>
  Number.isSafeInteger(value) && value > 0;

export const isValidIdentifiers = (identifiers: number[]): boolean => {
  if (identifiers.length < 1) {
    return false;
  }

  return identifiers.every(isValidIdentifier);
};

const filterNonNumberCharacters = (value: string): string => {
  if (/\D/.test(value)) {
    return '';
  }

  return value;
};

export const sanitizeIdentifiers = (value: string): number[] => {
  const identifiers: number[] = [];

  for (const entry of value.split(/[,|\n]/)) {
    const trimmed = entry.trim();
    // ignore empty entries or padding.
    if (trimmed === '') {
      continue;
    }

    // reject everything if any entry isn't a number.
    if (filterNonNumberCharacters(trimmed) === '') {
      return [];
    }

    const identifier = parseInt(trimmed, 10);
    // ignore entries already added.
    if (identifiers.includes(identifier)) {
      continue;
    }

    identifiers.push(identifier);
  }

  return identifiers;
};

export const isValidPercentage = (percentage: number): boolean =>
  Number.isSafeInteger(percentage) && 0 <= percentage && percentage <= 100;

export const isValidJiraId = (id: string): boolean => /^[A-Z]+-\d+$/.test(id);
