import React, { Component } from 'react';

import { Button, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import t from 'tcomb-validation';

import styles from './SearchForm.module.css';
import { Email } from '../../utils/validators';

class SearchForm extends Component {
  state = {
    searchQuery: '',
    errorMessage: null,
  };

  validators = {
    email: {
      validator: Email,
      cast: (s) => {
        return s.trim();
      },
    },
  };

  setErrorMessage = (errorMessage) => {
    this.setState({ errorMessage: errorMessage });
  };

  validateEmail = (value) => {
    let errors = '',
      valid = true;

    let val = this.validators['email'].cast(value),
      status = t.validate(val, this.validators['email'].validator);
    if (status.isValid()) {
      errors = null;
    } else {
      valid = false;
      errors = status.firstError().message;
    }

    this.setErrorMessage(errors);
    return valid;
  };

  handleTextFieldChange = (event) => {
    this.setState({
      searchQuery: event.target.value.trim(),
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateEmail(this.state.searchQuery)) {
      this.props.onFormSubmit(this.state.searchQuery);
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <SearchIcon className={styles.searchIcon} />
        <TextField
          label={this.state.errorMessage || 'Email'}
          id="email"
          name="email"
          value={this.state.searchQuery}
          onChange={this.handleTextFieldChange}
          error={this.state.errorMessage != null}
          className={styles.textField}
          data-hj-suppress
          variant="standard"
        />
        <Button
          variant="contained"
          color="primary"
          disabled={
            !this.state.searchQuery.length ||
            this.props.searchQuery === this.state.searchQuery
          }
          id="search"
          className={styles.searchButton}
          type="submit"
        >
          Search
        </Button>
      </form>
    );
  }
}

export default SearchForm;
