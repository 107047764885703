import { Fragment } from 'react';
import { ASK, OBSERVE } from '../constants';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  Grid,
  InputLabel,
  Select,
  Divider,
  Card,
  CardContent,
  Button,
} from '@mui/material';

export const Subscriptions = ({
  formData,
  addNewSubscription,
  handleSubscriptionChange,
  organizations,
  sites,
  productsCatalog,
  showDeleteIcon = true,
  removeSubscriptionFromList = (_index) => {},
}) => {
  if (!organizations || !sites) {
    return null;
  }

  const renderPlanOptions = (allowance, index) => {
    const label = `Select ${allowance.productName} Plan`;
    return (
      <>
        <InputLabel variant="standard" htmlFor="plan" shrink>
          {label}
        </InputLabel>
        <Select
          id="plan"
          label={label}
          inputProps={{ 'aria-label': label }}
          value={allowance.sku}
          variant="standard"
          onChange={(event) =>
            handleSubscriptionChange(event, index, allowance)
          }
          native
          fullWidth
        >
          {productsCatalog?.length ? (
            productsCatalog
              .filter((item) => item.productName === allowance.productName)
              .map((item) => (
                <option key={item.sku} value={item.allowance.sku}>
                  {item.planName} –{' '}
                  {item.allowance.monthly_cc_sessions ??
                    item.allowance.dailyCcSessions ??
                    item.allowance.daily_cc_sessions ??
                    item.allowance.monthlyResponses ??
                    item.allowance.monthly_responses}{' '}
                  {item.allowance.monthly_cc_sessions && 'Monthly'}
                  {!item.allowance.monthly_cc_sessions &&
                    (item.allowance.dailyCcSessions ||
                      item.allowance.daily_cc_sessions) &&
                    'Daily'}{' '}
                  {item.productName === OBSERVE && 'Sessions'}
                  {item.productName === ASK && 'Responses'}
                </option>
              ))
          ) : (
            <option value="-"> — </option>
          )}
        </Select>
      </>
    );
  };

  return (
    <>
      {!!formData.subscriptions.length && (
        <Card sx={{ marginBottom: '24px;' }}>
          <CardContent>
            {formData.subscriptions?.map((subscription, index) => (
              <Fragment key={subscription.id}>
                <Grid container justifyContent={'space-between'}>
                  <h3>{`Site #${index + 1}`}</h3>
                  {formData?.subscriptions.length > 1 && showDeleteIcon && (
                    <IconButton
                      onClick={() => removeSubscriptionFromList(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>
                <Grid container columnSpacing={8} rowSpacing={2}>
                  <Grid item xs={6}>
                    <InputLabel
                      variant="standard"
                      htmlFor="organization"
                      shrink
                      size="small"
                    >
                      Organization
                    </InputLabel>
                    <Select
                      id="organization"
                      label="Organization"
                      inputProps={{ 'aria-label': 'Organization' }}
                      value={subscription.organization || ''}
                      variant="standard"
                      onChange={(event) =>
                        handleSubscriptionChange(event, index)
                      }
                      native
                      fullWidth
                    >
                      <option value="">-</option>
                      {Object.keys(organizations).map((orgNumber) => {
                        const org = organizations[orgNumber];
                        return (
                          <option key={org.id} value={org.id}>
                            ID {org.id} | {org.name}
                          </option>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel variant="standard" htmlFor="site" shrink>
                      Site
                    </InputLabel>
                    <Select
                      id="site"
                      label="Site"
                      inputProps={{ 'aria-label': 'Site' }}
                      value={subscription.site || ''}
                      variant="standard"
                      onChange={(event) =>
                        handleSubscriptionChange(event, index)
                      }
                      autoWidth={false}
                      native
                      fullWidth
                    >
                      <option value="">-</option>
                      {subscription.organization &&
                        organizations[subscription.organization].sites.map(
                          (siteNumber) => {
                            const site = sites[siteNumber];
                            return (
                              <option key={site.id} value={site.id}>
                                ID {site.id} | {site.name} | {site.url}
                              </option>
                            );
                          }
                        )}
                    </Select>
                  </Grid>
                  {subscription.allowances
                    .sort((a, b) => {
                      return b.productName.localeCompare(a.productName);
                    })
                    .map((allowance) => (
                      <Grid
                        item
                        xs={6}
                        key={`${subscription.id}${allowance.sku}`}
                      >
                        {renderPlanOptions(allowance, index)}
                      </Grid>
                    ))}
                </Grid>
                <Divider sx={{ margin: '40px 0', borderColor: '#000' }} />
              </Fragment>
            ))}
          </CardContent>
        </Card>
      )}
      <Card sx={{ border: '1px dashed #bbb', boxShadow: 'none' }}>
        <CardContent sx={{ textAlign: 'center' }}>
          <Button
            disabled={
              Object.keys(sites).length === formData.subscriptions.length ||
              productsCatalog.length === 0
            }
            onClick={addNewSubscription}
          >
            <AddIcon />
            Add Site
          </Button>
        </CardContent>
      </Card>
    </>
  );
};
