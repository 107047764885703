import { fetchEnterpriseIDPURL } from './urls';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { EnterpriseIDP } from '../models/enterpriseSSO.model';

export const getEnterpriseIDP = async (
  accountId: number
): Promise<EnterpriseIDP[]> => {
  const url = new URL(fetchEnterpriseIDPURL());
  url.searchParams.set('account_id', `${accountId}`);

  const response = await fetch(url);

  return handleFetchApiResponse<{ items: EnterpriseIDP[] }>(response).then(
    (response) => response.items
  );
};
