export enum ProductName {
  Observe = 'observe',
  Ask = 'ask',
}

export enum FeatureGroup {
  Basic = 'BASIC',
  Business = 'BUSINESS',
}

interface Product {
  name: ProductName;
  effective_feature_group: FeatureGroup;
  feature_group: FeatureGroup;
  price: string;
}

export interface ObserveProduct extends Product {
  name: ProductName.Observe;
  session_average_seven_day: number;
  effective_cc_sessions: number;
  effective_sessions: number;
  cc_sessions: number;
  sessions: number;
}

export interface AskProduct extends Product {
  name: ProductName.Ask;
  response_count: number;
  monthly_responses: number;
  effective_monthly_responses: number;
}

export type Products = [ObserveProduct, AskProduct];
