import { useEffect, useState } from 'react';
import { isNil } from 'lodash';
import { useAccountServicePeriods } from '../../../domains/services/api/use-account-service-periods';
import type { AccountServicePeriod } from '../../../domains/services/models/service-alp.model';

function isNonSelfServedDxaPlan(servicePeriod: AccountServicePeriod) {
  return (
    !servicePeriod.disabled &&
    servicePeriod.product.name === 'dxa' &&
    servicePeriod.product.plan === 'growth' &&
    servicePeriod.product.allowances.analytics_session_quota > 200_000
  );
}

function hasNonSelfServedVocPlan(features: string[]) {
  if (features.includes('ask.cross_sell')) {
    // VoC plans sold CS customers starting July 2024
    return true;
  }

  if (features.includes('voc.pro')) {
    // VoC plans sold to CS customers as part of CS Lite November 2024 release
    return true;
  }

  return false;
}

/**
 * More details available here: https://contentsquare.atlassian.net/wiki/spaces/PAY/pages/4480172250/Customers+and+billing+systems
 */
export function useBilledOutsideZuora({
  accountId,
  features,
}: {
  accountId: number | undefined;
  features: string[];
}) {
  const [billedOutsideZuora, setBilledOutsideZuora] = useState(false);
  const { accountServicePeriodsData } = useAccountServicePeriods({
    accountId,
    enabled: !isNil(accountId),
  });

  useEffect(() => {
    if (accountServicePeriodsData === undefined) {
      return;
    }

    if (!('dxa' in accountServicePeriodsData)) {
      return;
    }

    const hasNonSelfServedGrowthPlan = accountServicePeriodsData.dxa.some(
      isNonSelfServedDxaPlan
    );

    if (hasNonSelfServedGrowthPlan) {
      setBilledOutsideZuora(true);
    }
  }, [accountServicePeriodsData]);

  useEffect(() => {
    if (hasNonSelfServedVocPlan(features)) {
      setBilledOutsideZuora(true);
    }
  }, [features]);

  return billedOutsideZuora;
}
