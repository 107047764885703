import { isNil } from 'lodash';
import containerStyles from '../Lookup.module.css';
import moment from 'moment';
import { toUTCDateString } from '../../../utils/dates';
import { NextBillingDateMismatch } from './next-bill-date-mismatch';

function getMagpieDateString(date: string | undefined): string | undefined {
  if (isNil(date)) {
    return undefined;
  }

  return moment.utc(date).add(1, 'days').format('YYYY-MM-DD');
}

function getInsightsDateString(date: Date | null): string | undefined {
  if (isNil(date)) {
    return undefined;
  }

  return toUTCDateString(date);
}

type AccountNextBillingDateProps = {
  magpieCurrentPeriodEndDate: string | undefined;
  insightsNextBillingDate: Date | null;
};

export function AccountNextBillingDate(
  props: AccountNextBillingDateProps
): JSX.Element {
  const { insightsNextBillingDate, magpieCurrentPeriodEndDate } = props;
  const magpieUtcDate = getMagpieDateString(magpieCurrentPeriodEndDate);
  const insightsUtcDate = getInsightsDateString(insightsNextBillingDate);
  const utcDate = magpieUtcDate ?? insightsUtcDate;

  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <dt className={containerStyles.label}>Next Bill:</dt>
      <dd className={containerStyles.value}>
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          {utcDate}
          <NextBillingDateMismatch
            insightsDate={insightsUtcDate}
            magpieDate={magpieUtcDate}
          />
        </div>
      </dd>
    </div>
  );
}
