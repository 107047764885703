import React, { useEffect, useState } from 'react';

import { Avatar, Chip, Tooltip } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MUIDataTable from 'mui-datatables';
import { useToasts } from 'react-toast-notifications';

import AddInternalUser from '../../components/modals/AddInternalUser';
import InternalUserActionsMenu from './InternalUserActionsMenu';
import ShowSpinner from '../../components/ShowSpinner';
import UserRoleSwitch from './UserRoleSwitch';
import UserRoleTableToolbar from './UserRoleTableToolbar';
import styles from './UserManagement.module.css';
import { getAllRoles, getAllUsers } from './api';
import { snakeToTitle } from '../../utils/strings';

export const EXCLUDED_ROLES = ['admin', 'basic_access'];

const UserRoleTable = () => {
  const [loading, setLoading] = useState(true);
  const [allRoles, setAllRoles] = useState([]);
  const [tableData, setTableData] = useState([]);

  const { addToast } = useToasts();

  const handleApiError = (e) => {
    addToast(e.error_message || e.message || e.error, {
      appearance: 'error',
      autoDismiss: true,
    });
    setLoading(false);
  };

  const updateRoleData = () => {
    return getAllRoles()
      .then((result) => {
        const roles = result.filter((role) => {
          return !EXCLUDED_ROLES.includes(role.name);
        });
        setAllRoles(roles);
        return roles;
      })
      .catch(handleApiError);
  };

  const updateUserData = (roles) => {
    return getAllUsers()
      .then((users) => {
        const tableData = users.map((user) => {
          const userRoleNames = user.roles.map((role) => role.name);
          const roleData = (roles || allRoles).map((role) => {
            return {
              value: userRoleNames.includes(role.name),
              user: user,
            };
          });
          roleData.push(user);
          return [user, user.email].concat(roleData);
        });
        setTableData(tableData);
      })
      .catch(handleApiError);
  };

  const updateTableData = async () => {
    const roles = await updateRoleData();
    await updateUserData(roles);
    setLoading(false);
  };

  useEffect(() => {
    updateTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColumnSettings = () => {
    let columns = [
      {
        name: 'Name',
        options: {
          customBodyRender: (user) => {
            return (
              <span>
                {user.name}
                {!user.active && (
                  <Tooltip title={'Locked out of ADM'}>
                    <Chip
                      className={`${styles.chip} ${styles.locked}`}
                      avatar={
                        <Avatar className={styles.avatar}>
                          <LockOutlinedIcon fontSize="small" />
                        </Avatar>
                      }
                      label="Adm"
                    />
                  </Tooltip>
                )}
                {user.insights_user && !!user.insights_user.locked_out && (
                  <Tooltip title={'Locked out of Insights'}>
                    <Chip
                      className={`${styles.chip} ${styles.locked}`}
                      avatar={
                        <Avatar className={styles.avatar}>
                          <LockOutlinedIcon fontSize="small" />
                        </Avatar>
                      }
                      label="Insights"
                    />
                  </Tooltip>
                )}
                {user.insights_user && !!user.insights_user.is_superuser && (
                  <Tooltip title={'Insights Superuser'}>
                    <Chip className={styles.chip} label="Superuser" />
                  </Tooltip>
                )}
              </span>
            );
          },
        },
      },
      {
        name: 'Email',
        sortOrder: 'asc',
      },
    ];
    let roleColumns = allRoles.map((role) => {
      return {
        name: snakeToTitle(role.name),
        options: {
          customBodyRender: (data) => {
            return (
              <UserRoleSwitch
                role={role}
                data={data}
                updateUserData={updateUserData}
              />
            );
          },
        },
      };
    });
    columns = columns.concat(roleColumns);
    columns.push({
      name: 'Actions',
      options: {
        customBodyRender: (user) => {
          return (
            <InternalUserActionsMenu
              user={user}
              refreshTable={updateUserData}
            />
          );
        },
      },
    });
    return columns;
  };

  const tableSettings = {
    responsive: 'standard',
    selectableRows: 'none',
    download: false,
    filter: false,
    pagination: true,
    print: false,
    search: true,
    sort: false,
    viewColumns: false,
    serverSide: false,
    rowsPerPage: 25,
    rowsPerPageOptions: [5, 25, 50, 100],
    customToolbar: () => <UserRoleTableToolbar />,
  };

  return loading ? (
    <div data-testid="loadingSpinner">
      <ShowSpinner />
    </div>
  ) : (
    <div>
      <AddInternalUser allRoles={allRoles} updateUserData={updateUserData} />
      <MUIDataTable
        title={'Internal Users'}
        data={tableData}
        columns={getColumnSettings()}
        options={tableSettings}
      />
    </div>
  );
};

export default UserRoleTable;
