import React, { Component } from 'react';
import {
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import { toUTCDateString } from '../../../utils/dates';
import { downloadFile, openFileInNewWindow } from '../../../utils/';
import ButtonLink from '../../ButtonLink';

export default class InvoicesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popover: {
        open: false,
        invoice_id: null,
      },
    };
  }

  openPopover = (event, invoice_id) => {
    // This prevents ghost click.
    event.preventDefault();
    this.closePopover();
    this.setState({
      ...this.state,
      popover: {
        open: true,
        anchorEl: event.currentTarget,
        invoice_id,
      },
    });
  };

  closePopover = () => {
    this.setState({
      ...this.state,
      popover: {
        open: false,
      },
    });
  };

  handleRegenerateRequest = () => {
    this.closePopover();
    this.props.regenerateInvoice(this.state.popover.invoice_id);
  };

  handleGetRequest = (fn) => {
    this.closePopover();
    this.props.getInvoice(this.state.popover.invoice_id, fn);
  };

  render() {
    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>Payment Method</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.invoices.map((invoice) => {
              return (
                <TableRow key={invoice.id}>
                  <TableCell>
                    <span data-hj-suppress>{invoice.id}</span>
                  </TableCell>
                  <TableCell>
                    <span data-hj-suppress>{invoice.transaction_id}</span>
                  </TableCell>
                  <TableCell>{toUTCDateString(invoice.created)}</TableCell>
                  <TableCell>{invoice.cost}</TableCell>
                  <TableCell>{invoice.payment_method_label}</TableCell>
                  <TableCell>
                    <ButtonLink
                      onClick={(e) => {
                        this.openPopover(e, invoice.id);
                      }}
                    >
                      ...
                    </ButtonLink>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Menu
          open={this.state.popover.open}
          anchorEl={this.state.popover.anchorEl}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          onClose={this.closePopover}
        >
          <MenuItem
            onClick={() => {
              this.handleGetRequest(openFileInNewWindow);
            }}
          >
            View
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.handleGetRequest(downloadFile);
            }}
          >
            Download
          </MenuItem>
          <MenuItem onClick={this.handleRegenerateRequest}>Regenerate</MenuItem>
        </Menu>
      </div>
    );
  }
}
