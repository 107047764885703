import type { ReactNode, SyntheticEvent } from 'react';
import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

type ManageFeatureDialogProps = {
  title: ReactNode | string;
  children: ReactNode;
  open: boolean;
  onSubmit: () => void | Promise<void>;
  onClose: (event: SyntheticEvent<unknown, Event>) => void;
};

export const ManageFeatureDialog = ({
  title,
  children,
  open,
  onSubmit,
  onClose,
}: ManageFeatureDialogProps): JSX.Element => {
  const [busy, setBusy] = useState<boolean>(false);

  const handleSubmit = async () => {
    setBusy(true);

    await onSubmit();

    setBusy(false);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={busy}
          startIcon={busy ? <CircularProgress /> : null}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
