import React, { useEffect, useState } from 'react';
import { Button, Paper, TextField } from '@mui/material';
import { passwordResetCall } from './api';
import styles from '../Login/Login.module.css';
import ShowSpinner from '../../components/ShowSpinner';

const RequestADMPasswordReset = (props) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const email = params.get('email');

    email && setEmail(email);
  }, [props.location.search]);

  const handleFormUpdate = (event) => {
    setEmail(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setSuccess(false);
    passwordResetCall(email)
      .then((data) => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((e) => {
        setLoading(false);
        setError(e.error_message || e.message || e.error);
      });
  };

  return (
    <Paper className={styles.formWrapper}>
      {loading ? (
        <div data-testid="loadingSpinner">
          <ShowSpinner />
        </div>
      ) : (
        <React.Fragment>
          {success ? (
            <div>
              We've received your request! If the email provided is correct,
              we'll send you instructions on how to reset your password.
            </div>
          ) : (
            <form onSubmit={handleFormSubmit}>
              <TextField
                autoFocus
                label="Email"
                value={email}
                className={styles.textField}
                name="email"
                id="email"
                onChange={handleFormUpdate}
                inputProps={{
                  pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
                  title: 'Please enter a valid email address.',
                }}
                variant="standard"
              />
              {error && <div className={styles.error}>{error}</div>}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth={true}
                className={styles.button}
                disabled={!email.length > 0}
              >
                Request New Password
              </Button>
            </form>
          )}
        </React.Fragment>
      )}
    </Paper>
  );
};

export default RequestADMPasswordReset;
