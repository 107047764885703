import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchSiteURL } from './urls';
import { Site } from '../models/site.model';
import { useToasts } from 'react-toast-notifications';
import { HTTP_ERROR_STATUS_STRING } from '../../../utils/constants';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';

const showSite = async (id: string): Promise<Site> => {
  const response = await fetch(fetchSiteURL(id));

  return handleFetchApiResponse<Site>(response);
};

export const useShowSite = (id: string) => {
  const { addToast } = useToasts();
  const { data, isInitialLoading, isError, error, refetch } = useQuery<
    Site,
    Error
  >({
    queryKey: ['showSite', id],
    queryFn: () => showSite(id),
  });

  useEffect(() => {
    // Error toast not displayed on 404
    if (isError && error.message !== HTTP_ERROR_STATUS_STRING) {
      addToast(`There was an error fetching data for site with id:"${id}"`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [id, addToast, isError, error]);

  return {
    siteData: data,
    siteError: error,
    isSiteError: isError,
    isSiteLoading: isInitialLoading,
    refetchSite: refetch,
  };
};
