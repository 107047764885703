import { TextField } from '@mui/material';

export const CustomerEmailRecipientsField = ({
  value,
  handleUpdateField,
  textFieldRows,
}) => (
  <TextField
    sx={{ marginBottom: 0 }}
    label="Email recipients"
    inputProps={{
      'aria-label': 'Email recipients',
    }}
    helperText="Separate email addresses by commas"
    variant="standard"
    id="cutomerEmailRecipients"
    rows={textFieldRows ?? 6}
    value={value}
    onChange={handleUpdateField}
    multiline
    fullWidth
  />
);
