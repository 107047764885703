type ServiceManagementEntity = {
  features?: Array<string> | undefined;
};

const SERVICE_MANAGEMENT_MIGRATED_FEATURE_FLAG_KEY =
  'service_management_migrated';

/**
 * Given an object that has a `features` array of strings, returns whether or not that entity has
 * access to service management. Service management is currently an opt-in concept, meaning not
 * all entities can use it, only the ones we migrate.
 *
 * @param entity - Any entity that has a `features` array
 * @returns A boolean value
 */
export const isServiceManagementMigrated = (
  entity: ServiceManagementEntity | undefined
): boolean => {
  return (
    entity?.features?.includes(SERVICE_MANAGEMENT_MIGRATED_FEATURE_FLAG_KEY) ||
    false
  );
};
