import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from 'mui-datatables';
import { Typography, Stack } from '@mui/material';
import ColoredLabel from '../../../../components/ColoredLabel';
import { FC } from 'react';

import { useShowSiteServicePeriods } from '../../../services/api/useShowSiteServicePeriods';
import ShowSpinner from '../../../../components/ShowSpinner';
import { formatServicesData } from '../SiteProducts/utils';

const siteTableOptions: MUIDataTableOptions = {
  search: false,
  sort: true,
  filter: false,
  selectableRows: 'none',
  pagination: false,
  print: false,
  download: false,
  viewColumns: true,
  responsive: 'standard',
  elevation: 0,
};

interface SiteServicePeriodsTableProps {
  siteId: string | undefined;
}

const SiteServicePeriodsTable: FC<SiteServicePeriodsTableProps> = ({
  siteId,
}) => {
  const { siteServicePeriodsData, isSiteServicePeriodsLoading } =
    useShowSiteServicePeriods(siteId);

  if (isSiteServicePeriodsLoading) {
    return <ShowSpinner />;
  }

  const dateRenderer = (date: string): string => new Date(date).toDateString();
  const toCapitalizeRenderer = (s: string) => (
    <Typography textTransform={'capitalize'}>{s}</Typography>
  );

  const tableColumns: MUIDataTableColumnDef[] = [
    {
      name: 'Product name',
      options: { customBodyRender: toCapitalizeRenderer },
    },
    {
      name: 'Plan',
      options: {
        customBodyRender: (plan) => (
          <ColoredLabel color={`var(--${plan.toLowerCase()})`}>
            {plan}
          </ColoredLabel>
        ),
      },
    },
    { name: 'Allowance' },
    {
      name: 'Source',
      options: { customBodyRender: toCapitalizeRenderer },
    },
    {
      name: 'Start date',
      options: { customBodyRender: dateRenderer },
    },
    {
      name: 'End date',
      options: { customBodyRender: dateRenderer },
    },
    {
      name: 'Created date',
      options: { customBodyRender: dateRenderer },
    },
    {
      name: 'Disabled',
    },
  ];

  const tableData: (number | string)[][] = siteServicePeriodsData
    ? formatServicesData(siteServicePeriodsData).reduce(
        (data: (number | string)[][], entry) => {
          entry.services.forEach((service) => {
            data.push([
              entry.product,
              service.plan as string,
              service.allowances,
              service.source,
              service.startDate,
              service.endDate,
              service.createdDate,
              service.disabled ?? 'No',
            ]);
          });

          return data;
        },
        []
      )
    : [];

  return (
    <Stack mt={3}>
      {siteServicePeriodsData ? (
        <MUIDataTable
          title="Service Periods"
          data={tableData}
          columns={tableColumns}
          options={siteTableOptions}
        />
      ) : (
        <Stack padding={2}>
          <Typography variant="body2">
            There're no service periods for this site yet.
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default SiteServicePeriodsTable;
