import { useQuery } from '@tanstack/react-query';
import { fetchMagpieProductCatalogURL } from './urls';
import { MagpieBillingPeriod, MagpieProduct } from '../models/magpie.model';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Currency } from '../../../types/types';
import { useShowAccountPricingTable } from '../../accounts/api/useShowAccountPricingTable';

const showMagpieProductCatalog = async (
  billingPeriod: Lowercase<MagpieBillingPeriod>,
  currency: Lowercase<Currency>,
  productCatalogVersion: string | undefined
): Promise<MagpieProduct[]> => {
  const response = await fetch(
    fetchMagpieProductCatalogURL(billingPeriod, currency, productCatalogVersion)
  );

  return handleFetchApiResponse<MagpieProduct[]>(response);
};

export const useShowMagpieProductCatalog = (
  accountId: string,
  billingPeriod?: Lowercase<MagpieBillingPeriod>,
  currency?: Lowercase<Currency>
) => {
  const { accountPricingTableData } = useShowAccountPricingTable(accountId);
  const { addToast } = useToasts();
  const { data, isError, error, isInitialLoading } = useQuery<
    MagpieProduct[],
    Error
  >({
    queryKey: [
      'showMagpieProductCatalog',
      billingPeriod,
      currency,
      accountPricingTableData?.product_catalog_version,
    ],
    queryFn: () =>
      showMagpieProductCatalog(
        billingPeriod!,
        currency!,
        accountPricingTableData?.product_catalog_version
      ),
    enabled: !!billingPeriod && !!currency,
    retry: false,
  });

  useEffect(() => {
    if (isError) {
      addToast(
        `There was an error fetching the product catalog for the billing period:"${billingPeriod}" and currency:"${currency}".`,
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );
    }
  }, [billingPeriod, currency, addToast, isError, error]);

  return {
    magpieProductCatalogData: data,
    magpieProductCatalogError: error,
    isMagpieProductCatalogError: isError,
    isMagpieProductCatalogLoading: isInitialLoading,
  };
};
