import { FC, PropsWithChildren } from 'react';
import ShowSpinner from '../../../../../../components/ShowSpinner';
import { Currency } from '../../../../../../types/types';
import { MagpieBillingPeriod } from '../../../../../magpie/models/magpie.model';
import { billingPeriodCostFormatter } from './utils';

import styles from './SiteProductSubscription.module.css';

export interface SubscriptionData {
  product: string;
  plan: string;
  allowance: number;
  currency: Currency;
  finalCost: number;
  costBeforeDiscount: number;
  billingPeriod: MagpieBillingPeriod;
  deductions: string[] | [];
}

interface SiteProductSubscriptionProps {
  subscriptionData?: SubscriptionData;
  isSubscriptionLoading: boolean;
}

const Wrapper: FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.subscription}>
    <h2 className={styles.subscriptionTitle}>Active subscription</h2>
    {children}
  </div>
);

const SiteProductSubscription: FC<SiteProductSubscriptionProps> = ({
  subscriptionData,
  isSubscriptionLoading,
}) => {
  if (isSubscriptionLoading) {
    return <ShowSpinner />;
  }

  if (!subscriptionData) {
    return (
      <Wrapper>
        <p>No active subscription data.</p>
      </Wrapper>
    );
  }

  const {
    product,
    plan,
    allowance,
    currency,
    finalCost,
    costBeforeDiscount,
    billingPeriod,
    deductions,
  } = subscriptionData;

  const hasDeductions = deductions.length > 0;

  return (
    <Wrapper>
      <p className={styles.service}>
        {product}, {plan}, {allowance}
      </p>
      <div className={styles.costs}>
        <p>{billingPeriodCostFormatter(currency, finalCost, billingPeriod)}</p>
        {hasDeductions && (
          <p className={styles.costBeforeDiscount}>
            {billingPeriodCostFormatter(
              currency,
              costBeforeDiscount,
              billingPeriod
            )}
          </p>
        )}
      </div>
      {!hasDeductions && <p className={styles.noDiscount}>No discounts</p>}
      {hasDeductions && (
        <ul className={styles.discount}>
          {deductions.map((deduction) => (
            <li key={deduction}>{deduction}</li>
          ))}
        </ul>
      )}
    </Wrapper>
  );
};

export default SiteProductSubscription;
