import React, { Component } from 'react';

import Paper from '@mui/material/Paper';

import Notifications from './Notifications';
import SNSMessageLogs from './SNSMessages';
import SearchForm from './SearchForm';
import styles from './UserNotifications.module.css';

export default class UserNotifications extends Component {
  state = {
    searchQuery: '',
  };

  handleFormSubmit = (data) => {
    this.setState({
      searchQuery: data,
    });
  };

  render() {
    return (
      <div>
        <Paper className={styles.searchBox} elevation={1}>
          <SearchForm
            searchQuery={this.state.searchQuery}
            onFormSubmit={this.handleFormSubmit}
          />
        </Paper>
        <div>
          <Notifications searchQuery={this.state.searchQuery} />
          <SNSMessageLogs searchQuery={this.state.searchQuery} />
        </div>
      </div>
    );
  }
}
