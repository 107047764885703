export const sample_rate_prices = {
  2000: {
    label: 'Basic',
    rate: 2000,
  },
  10000: {
    label: 'Plus',
    rate: 10000,
  },
  20000: {
    label: 'Business',
    rate: 20000,
  },
  50000: {
    label: 'Business',
    rate: 50000,
  },
  120000: {
    label: 'Business',
    rate: 120000,
  },
  400000: {
    label: 'Business',
    rate: 400000,
  },
  800000: {
    label: 'Business',
    rate: 800000,
  },
  2000000: {
    label: 'Business',
    rate: 2000000,
  },
  5000000: {
    label: 'Business',
    rate: 5000000,
  },
  10000000: {
    label: 'Business',
    rate: 10000000,
  },
  15000000: {
    label: 'Business',
    rate: 15000000,
  },
};

export const always_on_prices = {
  0: {
    label: '0.00',
    rate: 0,
  },
  2000: {
    label: '100.00',
    rate: 2000,
  },
  5000: {
    label: '200.00',
    rate: 5000,
  },
  15000: {
    label: '500.0',
    rate: 15000,
  },
  30000: {
    label: '900.0',
    rate: 30000,
  },
  50000: {
    label: '1400.0',
    rate: 50000,
  },
  100000: {
    label: '2700.0',
    rate: 100000,
  },
};

export const SEPARATE_SKU_PRICING_TABLE = 'PT_2022_Q1_BET_1_TWO_SKUS';

export const NO_DATA_PLACEHOLDER = '-';

export const HTTP_ERROR_STATUS = 404;
export const HTTP_ERROR_STATUS_STRING = '404';
