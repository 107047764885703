import { FC } from 'react';
import {
  InputAdornment,
  TextField,
  Paper,
  Grid,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import ViewHeader, { ViewEntity } from '../../../../components/ViewHeader';
import EntityDetails from '../../../../components/EntityDetails';
import DomainItemDivider from '../../../../components/DomainItemDivider';
import SiteBadges from '../../components/SiteBadges';
import SiteSidebar from '../../components/SiteSideBar';
import SiteBreadCrumbs from '../../components/SiteBreadCrumbs';
import SiteActions from '../../components/SiteActions';
import ShowSpinner from '../../../../components/ShowSpinner';

import { entityDetailsSite } from './utils';

import { useShowSite } from '../../api/useShowSite';
import { useShowMagpieCustomer } from '../../../magpie/api/useShowMagpieCustomer';

import styles from './ShowSite.module.css';
import { useOpenSiteModal } from './useOpenSiteModal';
import {
  MODAL_SAMPLING_VISUALIZER,
  MODAL_ADM_ACTIONS,
  MODAL_MODIFY_PLAN_MAGPIE,
} from '../../../../components/modals/constants';
import SiteModals from '../../components/SiteModals';
import SiteReopenModals from '../../components/SiteReopenModals';
import SiteProducts from '../../components/SiteProducts';
import { useShowSiteActions } from '../../../adm/api/useShowSiteActions';
import SiteUndelete from '../../components/SiteUndelete';

interface ShowSiteProps {
  id: string;
}

const ShowSite: FC<ShowSiteProps> = ({ id }) => {
  const { siteData, isSiteLoading, refetchSite } = useShowSite(id);
  const { magpieCustomerData } = useShowMagpieCustomer(siteData?.account_id);
  const { siteActionsData, refetchSiteActions } = useShowSiteActions(id);
  const { openSiteModal } = useOpenSiteModal();

  const handleRefetchAfterNewActionCreated = () => {
    refetchSite();
    refetchSiteActions();
  };

  // TODO: Wire up in stand alone tasks
  // Reactive in fair downgrade
  const handleClearInterimsModalOpen = () => {};
  const handlePlanSettingsModalOpen = () => {
    openSiteModal({
      siteId: id,
      modal: MODAL_MODIFY_PLAN_MAGPIE,
      payload: {
        magpie: magpieCustomerData,
        siteIds: [parseInt(id, 10)],
      },
    });
  };
  const handleActionsModalOpen = () => {
    openSiteModal({
      siteId: id,
      modal: MODAL_ADM_ACTIONS,
      payload: {
        data: siteActionsData,
        related_id: id,
        related_type: 'Site',
        newActionCallback: handleRefetchAfterNewActionCreated,
      },
    });
  };
  const handleOnOpenVisualizerModalClick = () => {
    openSiteModal({
      siteId: id,
      modal: MODAL_SAMPLING_VISUALIZER,
      payload: {
        id: siteData?.site_id,
        name: siteData?.site_name,
      },
    });
  };

  const modalNameToOpenMethod: Record<string, () => void> = {
    [MODAL_SAMPLING_VISUALIZER]: handleOnOpenVisualizerModalClick,
    [MODAL_MODIFY_PLAN_MAGPIE]: handlePlanSettingsModalOpen,
    [MODAL_ADM_ACTIONS]: handleActionsModalOpen,
  };

  const actionsRenderer = () => (
    <SiteActions
      // TODO: we agreed to calculate in the frontend from Magpie response
      isInFairDowngradeState={false}
      hasMagpieSubscription={Boolean(magpieCustomerData)}
      handleActionsModalOpen={handleActionsModalOpen}
      handlePlanSettingsModalOpen={handlePlanSettingsModalOpen}
      handleClearInterimsModalOpen={handleClearInterimsModalOpen}
      handleOnOpenVisualizerModalClick={handleOnOpenVisualizerModalClick}
    />
  );

  const undeleteActionRenderer = () => <SiteUndelete siteId={id} />;

  const badgesRenderer = () => (
    <SiteBadges
      actionsCount={siteData?.number_of_adm_actions}
      products={undefined}
      usesSingleSkuProducts={false}
      handleOnActionsClick={handleActionsModalOpen}
    />
  );

  if (isSiteLoading) {
    return <ShowSpinner />;
  }

  if (!isSiteLoading && !siteData) {
    return (
      <Typography className={styles.noData}>
        No data for the site with id:"{id}"
      </Typography>
    );
  }

  const isSiteDeleted = Boolean(siteData?.deleted);

  return (
    <div className={styles.newScreen}>
      <SiteModals />
      <SiteReopenModals
        siteId={id}
        modalNameToOpenMethod={modalNameToOpenMethod}
      />
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Searchbar"
        className={styles.searchBar}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <SiteBreadCrumbs
        siteId={siteData!.site_id}
        accountId={siteData!.account_id}
        organizationId={siteData!.organization_id}
      />

      <Grid className={styles.grid} container spacing="24px">
        <Grid item xs={8}>
          <Paper elevation={1} className={styles.mainPanel}>
            <ViewHeader
              entityId={id}
              entityName={ViewEntity.Site}
              badgesRenderer={badgesRenderer}
              isEntityDeleted={isSiteDeleted}
              actionsRenderer={
                isSiteDeleted ? undeleteActionRenderer : actionsRenderer
              }
            />

            <DomainItemDivider className={styles.headerDivider} />
            <EntityDetails
              data={entityDetailsSite({
                isSiteDeleted,
                siteData: siteData!,
                magpieCustomerData,
                handleOnOpenVisualizerModalClick,
              })}
            />
            {!isSiteDeleted && (
              <>
                <DomainItemDivider className={styles.tableDivider} />
                <SiteProducts
                  siteId={siteData?.site_id.toString()}
                  accountId={siteData!.account_id.toString()}
                />
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <SiteSidebar
            accountId={siteData?.account_id}
            organizationId={siteData?.organization_id}
            accountOwnerUserId={siteData?.account_owner_user_id}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ShowSite;
