import { apiPost } from '../../../utils/api';

export const updateUserEmail = (
  user_id,
  old_email,
  new_email,
  adm_action_annotation
) => {
  return apiPost(`/user/${user_id}`, 'update_email', {
    old_email,
    new_email,
    adm_action_annotation,
  });
};
