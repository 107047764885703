const setCookieNextWeek = () => {
  const expiryDate = new Date();
  const oneWeekFromNow = expiryDate.getDate() + 7;
  expiryDate.setDate(oneWeekFromNow);
  document.cookie = `adm_satisfaction_poll_triggered=true; expires=${expiryDate}`;
};

const isCookieExpired = () => {
  if (document.cookie.includes('adm_satisfaction_poll_triggered')) {
    return false;
  }
  return true;
};

const fireTriggers = () => {
  if (isCookieExpired()) {
    // Expanded from:
    // https://help.hotjar.com/hc/en-us/articles/115011624487-How-to-Use-JavaScript-Triggers-for-Feedback-Tools
    window.hj =
      window.hj ||
      function () {
        (window.hj.q = window.hj.q || []).push(arguments);
      };
    window.hj('trigger', 'adm-satisfaction-poll');
    setCookieNextWeek();
  }
};

export default fireTriggers;
