import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

function BasicAlertDialog({ onCloseModal, props: modalProps }) {
  if (!modalProps) {
    return null;
  }

  return (
    <div>
      <Dialog
        open
        disableEnforceFocus={true}
        aria-labelledby="basic-alert-dialog-title"
      >
        <DialogTitle id="basic-alert-dialog-title">
          {modalProps.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{modalProps.modalWarning}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseModal} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default BasicAlertDialog;
