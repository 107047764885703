import React from 'react';
import styles from './RemoveOrgUser.module.css';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { removeOrgUser } from './api';
import { MODAL_CONFIRMATION_DIALOG } from '../modals/constants';
import { withModalContext } from '../../contexts/ModalContext';

const RemoveOrgUser = ({ user, organization, onSuccess, modalContext }) => {
  const onSuccessHandler = () => {
    onSuccess();
  };

  const onModalSubmit = (annotation) => {
    return removeOrgUser(organization.id, user.id, annotation);
  };

  const openModal = () => {
    const modalWarning = `Are you sure you want to remove user ${user.email} 
        (ID: ${user.id}) from
        organization ${organization.name} (ID: ${organization.id})?`;

    modalContext.openModal(MODAL_CONFIRMATION_DIALOG, {
      title: 'Remove from Organization',
      modalWarning: modalWarning,
      onSubmit: onModalSubmit,
      onSuccess: onSuccessHandler,
    });
  };

  return (
    <React.Fragment>
      <button
        data-testid="removeButton"
        className={styles.button}
        onClick={openModal}
      >
        <CancelOutlinedIcon />
      </button>
    </React.Fragment>
  );
};

export default withModalContext(RemoveOrgUser);
