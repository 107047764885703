import React, { Component } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { removePaymentDetails } from '../../../containers/Lookup/api';

export default class RemovePaymentDetailsModal extends Component {
  state = {
    verification: '',
    annotation: '',
    loading: false,
    errors: {
      verification: null,
      server_message: null,
    },
  };

  verificationTest = () => {
    if (parseFloat(this.state.verification) !== this.props.account.id) {
      this.setState({
        ...this.state,
        errors: {
          verification: 'Must match account id.',
        },
      });
      return false;
    } else {
      return true;
    }
  };

  handleRemovePaymentDetails = (e) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    } else {
      this.setState({ loading: true });
    }

    if (this.verificationTest()) {
      removePaymentDetails(
        this.props.account.id,
        this.state.verification,
        this.state.annotation
      )
        .then(() => {
          this.props.onPaymentDetailsRemoved();
          this.props.onClose();
        })
        .catch((e) => {
          this.setState({
            ...this.state,
            loading: false,
            errors: {
              server_message: e.error || e.message,
            },
          });
        });
    }
  };

  handleTextFieldChange = (event) => {
    let fieldName = event.target.name,
      errors = this.state.errors;
    errors[fieldName] = null;
    this.setState({
      errors: errors,
      [fieldName]: event.target.value,
    });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          aria-labelledby="form-dialog-title"
          disableEnforceFocus={true}
        >
          <DialogTitle id="form-dialog-title">
            Remove Payment Details
          </DialogTitle>
          <form onSubmit={this.handleRemovePaymentDetails}>
            <DialogContent>
              <DialogContentText>
                Are you sure? This action is not reversible. Please enter the
                account number to confirm.
              </DialogContentText>
              <TextField
                onChange={this.handleTextFieldChange}
                autoFocus
                required
                fullWidth
                type="text"
                margin="dense"
                id="verification"
                name="verification"
                label="Verification"
                value={this.state.verification}
                error={!!this.state.errors.verification}
                helperText={this.state.errors.verification || ''}
                variant="standard"
              />
              <TextField
                multiline
                fullWidth
                required
                margin="dense"
                id="annotation"
                label="Annotation"
                name="annotation"
                value={this.state.annotation}
                onChange={this.handleTextFieldChange}
                helperText="Please provide reason for change and/or Zendesk ticket link."
                variant="standard"
              />
              {this.state.errors.server_message && (
                <span className="error">
                  Error: {this.state.errors.server_message}
                </span>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.props.onClose}
                disabled={this.state.loading}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={this.state.loading}
                color="primary"
              >
                Confirm
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}
