import * as Cookies from 'js-cookie';

export function postResetADMPassword(token, password, password_confirm) {
  const url = 'https://' + window.location.host + '/api/v2/adm/reset_password';
  const init = {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
    },
    body: JSON.stringify({
      token,
      password,
      password_confirm,
    }),
  };

  return fetch(url, init).then((res) => {
    if (res.ok) {
      return res.json().then((data) => {
        return Promise.resolve({ success: true });
      });
    } else {
      return res.json().then((data) => {
        return Promise.reject(data);
      });
    }
  });
}
