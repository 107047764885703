import { FC } from 'react';
import ColoredLabel from '../../../../components/ColoredLabel';
import ActionsBadge from '../../../../components/ActionsBadge';
import ProductBadges from './components/ProductBadges';
import { Products } from '../../../products/models/product.model';
import styles from './SiteBadges.module.css';

interface SiteBadgesProps {
  products?: Products;
  actionsCount?: number;
  usesSingleSkuProducts: boolean;
  migratedBillingV1Site?: boolean;
  ubpState?: {
    limit: number;
    usage: number;
    isBlocked: boolean;
    renewDate: string;
  };
  handleOnActionsClick: () => void;
}

const SiteBadges: FC<SiteBadgesProps> = ({
  products,
  actionsCount,
  usesSingleSkuProducts,
  migratedBillingV1Site,
  ubpState,
  handleOnActionsClick,
}) => {
  return (
    <div className={styles.icons}>
      {!!products && !usesSingleSkuProducts && (
        <ProductBadges products={products} ubpState={ubpState} />
      )}
      <ActionsBadge
        actionsCount={actionsCount}
        handleOnClick={handleOnActionsClick}
      />
      {migratedBillingV1Site && (
        <ColoredLabel color="var(--blue)">
          Migrated Billing V1 Site
        </ColoredLabel>
      )}
    </div>
  );
};

export default SiteBadges;
