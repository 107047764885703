import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useEnterpriseIDP } from '../../../../domains/enterpriseSSO/api/useEnterpriseIDP';
import { setOrgEnterpriseIDP } from '../../../../domains/enterpriseSSO/api/setOrgEnterpriseIDP';
import { useToasts } from 'react-toast-notifications';
import { useOrgEnterpriseIDP } from '../../../../domains/enterpriseSSO/api/useOrgEnterpriseIDP';
import { AdmModalProps } from '../../../../components/modals/AdmModalRoot';

interface Props {
  accountId: number;
  orgId: number;
}

const StyledForm = styled.div`
  margin-top: 8px;
`;

const NONE = 'NONE';

type EnableSSOForOrgModalProps = AdmModalProps<Props>;

export const EnableSSOForOrgModal = ({
  props: { accountId, orgId },
  onCloseModal,
}: EnableSSOForOrgModalProps) => {
  const { addToast } = useToasts();
  const { data = [] } = useEnterpriseIDP(accountId);
  const [selectedIdp, setSelectedIdp] = useState<string>(NONE);
  const { data: idpId, invalidate } = useOrgEnterpriseIDP(orgId);

  useEffect(() => {
    setSelectedIdp(idpId ? idpId : NONE);
  }, [idpId]);

  const onSave = () => {
    setOrgEnterpriseIDP(orgId, selectedIdp === NONE ? null : selectedIdp).then(
      () => {
        invalidate();
        addToast('IDP has been attached to the selected organization.', {
          appearance: 'success',
        });
        onCloseModal();
      }
    );
  };

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onCloseModal}>
      <DialogTitle>Add Identity Provider (Org ID: {orgId})</DialogTitle>
      <DialogContent>
        <StyledForm>
          <FormControl fullWidth={true}>
            <InputLabel htmlFor={`select_idp`}>Select IDP</InputLabel>
            <Select
              native
              value={selectedIdp}
              name={`select_idp`}
              id={`select_idp`}
              data-testid="select_idp"
              variant="standard"
              onChange={(e) => setSelectedIdp(e.target.value)}
            >
              <option key="none" value={NONE}>
                None
              </option>
              {data.map((item) => (
                <option key={item.idp_id} value={item.idp_id}>
                  {item.login_id}
                </option>
              ))}
            </Select>
          </FormControl>
        </StyledForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>Close</Button>
        <Button color="primary" onClick={onSave}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
