import { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { LookupContext } from '../../../contexts/LookupContext';
import { LoadingDialog } from '../LoadingDialog/index';
import { useToasts } from 'react-toast-notifications';
import { amendSubscriptionInMagpie } from '../../../utils/api';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
} from '@mui/material';
import { getSalesDeductionPercentage } from '../../../containers/Lookup/billingV3.functions';
import type { AdmModalProps } from '../AdmModalRoot';

const S = {
  Alert: styled(Alert)`
    margin-bottom: 16px;
  `,
};

export const UpdateSalesDiscountModal = ({
  onCloseModal,
}: AdmModalProps<{}>) => {
  const { addToast } = useToasts();

  const { magpie, account, lookupRequest } = useContext(LookupContext) || {};

  const { id: accountId } = account;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [salesDiscount, setSalesDiscount] = useState('0');

  useEffect(() => {
    if (magpie === undefined) {
      return;
    }
    const discount = getSalesDeductionPercentage(magpie?.subscription);
    setSalesDiscount(discount);
  }, [magpie]);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const amendmentData = {
      productsToAdd: [],
      productsToRemove: [],
      salesDeductionPercentage: Number(salesDiscount),
    };

    try {
      const response = await amendSubscriptionInMagpie(
        accountId,
        amendmentData
      );

      if (!response.ok) {
        const parsedResponse = await response.json();
        throw new Error(parsedResponse?.message ?? response.status);
      }
      onCloseModal();
      lookupRequest();
    } catch (error) {
      addToast('Order error', { appearance: 'error', autoDismiss: true });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!magpie) {
    return null;
  }

  if (isSubmitting) {
    return <LoadingDialog title="Updating Sales Discount" />;
  }

  const hasError = Number(salesDiscount) > 100 || Number(salesDiscount) < 0;

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onCloseModal}>
      <DialogTitle>Update Sales Discount of Account {accountId}</DialogTitle>
      <DialogContent>
        <S.Alert severity="info">
          <ul>
            <li>
              Sales discount will take effect{' '}
              <em>from the next billing period onwards.</em>
            </li>
            <li>
              The request might take a long time to finish. Even if after you
              see an error message, it might succeed in the background.
            </li>
          </ul>
        </S.Alert>

        <TextField
          label="Sales discount"
          variant="standard"
          id="salesDiscount"
          type="number"
          value={salesDiscount}
          helperText={hasError && 'Value should be between 0 and 100'}
          error={hasError}
          onChange={(event) => setSalesDiscount(event.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={hasError}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateSalesDiscountModal;
