import React, { FC, PropsWithChildren, useState } from 'react';
import Badge from '@mui/material/Badge';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import styles from './ClickToCopy.module.css';

export const SUCCESS_ICON = '✔';
export const ERROR_ICON = '⨯';

interface CopyButtonProps extends PropsWithChildren {
  overwriteText?: string;
  copyIcon?: boolean;
}

const CopyButton: FC<CopyButtonProps> = ({
  overwriteText,
  copyIcon,
  children,
}) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyError, setCopyError] = useState(false);

  if (!overwriteText && !children) {
    throw new Error('No children or overwrite text were provided.');
  }

  const resetTooltip = () => {
    setCopySuccess(false);
    setCopyError(false);
  };

  const fallbackCopyTextToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        setCopySuccess(true);
        setCopyError(false);
      } else {
        setCopySuccess(false);
        setCopyError(true);
      }
      setTimeout(resetTooltip, 2000);
    } catch (err) {
      console.error('Fallback: failed to copy', err);
      setCopySuccess(false);
      setCopyError(true);
    }

    document.body.removeChild(textArea);
  };

  const copyText = () => {
    const textToCopy = (overwriteText || children) as string;

    navigator.clipboard
      .writeText(textToCopy.toString())
      .then(() => {
        setCopySuccess(true);
        setCopyError(false);
        setTimeout(resetTooltip, 2000);
      })
      .catch((e) => {
        console.error(e);
        console.log('Attempting fallback');
        fallbackCopyTextToClipboard(textToCopy);
      });
  };

  let badgeIcon,
    badgeClass = '';
  if (copySuccess) {
    badgeIcon = SUCCESS_ICON;
    badgeClass = styles.badgeSuccess;
  } else if (copyError) {
    badgeIcon = ERROR_ICON;
    badgeClass = styles.badgeError;
  }

  return (
    <React.Fragment>
      <Badge
        badgeContent={badgeIcon}
        overlap="circular"
        className={`${styles.badge} ${badgeClass}`}
      >
        <button className={styles.copyText} onClick={copyText}>
          {children}
          {copyIcon && <ContentCopyIcon className={styles.copyIcon} />}
        </button>
      </Badge>
    </React.Fragment>
  );
};
export default CopyButton;
