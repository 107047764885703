import React, { useEffect, useState } from 'react';

import { CircularProgress, Switch } from '@mui/material';
import { toggleRole } from './api';
import { useToasts } from 'react-toast-notifications';

import styles from './UserManagement.module.css';

const UserRoleSwitch = ({ data, role, updateUserData }) => {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(false);

  const { addToast } = useToasts();
  const userLocked =
    !data.user.active &&
    data.user.insights_user &&
    !!data.user.insights_user.locked_out;

  useEffect(() => {
    setValue(data.value);
    setLoading(false);
  }, [data.value]);

  const showSuccessToast = (newState) => {
    const action = newState ? 'Added' : 'Removed';
    const preposition = newState ? 'to' : 'from';
    const message = `${action} ${role.name} role ${preposition} ${data.user.email}`;
    addToast(message, { appearance: 'success', autoDismiss: true });
  };

  const handleChange = async (e) => {
    setLoading(true);
    try {
      const newState = await toggleRole(
        data.user.id,
        role.name,
        e.target.checked
      );
      setValue(newState);
      updateUserData();
      showSuccessToast(newState);
    } catch (e) {
      addToast(e.error_message || e.message || e.error, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    setLoading(false);
  };

  return loading ? (
    <CircularProgress className={styles.loadingSpinner} size={25} />
  ) : (
    <Switch
      data-testid={`role_${role.id}_user_${data.user.id}`}
      color="primary"
      checked={value}
      onChange={handleChange}
      disabled={userLocked}
    />
  );
};

export default UserRoleSwitch;
