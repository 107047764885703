import { Component, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
  Switch,
  TextField,
} from '@mui/material';

import { UserOrgsTable } from './UserOrgsTable';
import ResetEmailLinkButton from './ResetEmailLinkButton';
import ToastManager from './ToastManager';

import {
  MODAL_CONFIRMATION_DIALOG,
  MODAL_EMAIL_CHANGE,
  MODAL_UPGRADE_USER,
  MODAL_USER_DETAIL,
  MODAL_VIEW_USER_AUDIT_LOG,
} from '../constants';
import {
  changeEmailBlocklist,
  deleteUser,
  requestMfaDisable,
  requestPasswordResetToken,
  updateIntercomUser,
} from './api';
import styles from './UserDetail.module.css';
import HasPermission from '../../HasPermission';
import { withModalContext } from '../../../contexts/ModalContext';
import { withLookupContext } from '../../../contexts/LookupContext';

class UserDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popover: {
        open: false,
      },
      changeEmailData: {
        updated: false,
      },
      user_orgs: [],
      user_email: '',
      email_blocklisted: false,
      passwordReset: {
        email: '',
        body: '',
        subject: '',
      },
      mfaDisableConfirmation: false,
      mfaDisableSuccess: false,
      mfaDisableAnnotation: '',
      toast: {
        message: null,
        appearance: null,
      },
    };
  }

  componentDidUpdate(prevProps) {
    const isModalOpen = this.props.modalContext.isModalOpen(MODAL_USER_DETAIL);
    const modalProps =
      this.props.modalContext.openModalProps[MODAL_USER_DETAIL];
    if (
      prevProps.modalContext.currentlyOpenModals.indexOf(MODAL_USER_DETAIL) ===
        -1 &&
      isModalOpen
    ) {
      this.setState({
        user_email: modalProps.user.email,
        email_blocklisted: modalProps.email_blocklisted,
      });
    }
  }

  handleShowMfaConfirmation = () => {
    this.setState({ mfaDisableConfirmation: true }, () => {
      this.closePopover();
    });
  };

  handleMfaAnnotationChange = (e) => {
    this.setState({ mfaDisableAnnotation: e.target.value });
  };

  handleMfaDisable = () => {
    return requestMfaDisable(
      this.props.modalContext.openModalProps[MODAL_USER_DETAIL].user_id,
      this.state.mfaDisableAnnotation
    )
      .then(() => {
        this.setState({
          toast: {
            message: 'Multi-factor Authentication was successfully disabled',
            appearance: 'success',
          },
        });
        this.setState({
          mfaDisableConfirmation: false,
          mfaDisableSuccess: true,
        });
      })
      .catch((e) =>
        this.setState({
          toast: {
            message: `Error – Multi-factor Authentication could not be disabled: ${
              e.error_message || e.error || e.message
            }.`,
            appearance: 'error',
          },
        })
      );
  };

  getPasswordReset = () => {
    return requestPasswordResetToken(
      this.props.modalContext.openModalProps[MODAL_USER_DETAIL].user_id
    )
      .then((data) => {
        this.setState({ passwordReset: data });
        this.closePopover();
      })
      .catch((e) =>
        this.setState({
          toast: {
            message: `Error getting Password Reset: ${
              e.error_message || e.error || e.message
            }.`,
            appearance: 'error',
          },
        })
      );
  };

  showSignInActivity = () => {
    this.closePopover();
    this.props.modalContext.openModal(MODAL_VIEW_USER_AUDIT_LOG, {
      userId: this.props.lookupContext.user.id,
      name: this.props.lookupContext.user.name,
    });
  };

  updateIntercomData = () => {
    return updateIntercomUser(
      this.props.modalContext.openModalProps[MODAL_USER_DETAIL].user_id
    )
      .then(() => {
        this.setState({
          toast: {
            message: 'Intercom data updated successfully.',
            appearance: 'success',
          },
        });
        this.closePopover();
      })
      .catch((e) =>
        this.setState({
          toast: {
            message: `Error updating Intercom Data: ${
              e.error_message || e.error || e.message
            }.`,
            appearance: 'error',
          },
        })
      );
  };

  handleToggleChange = (event, isInputChecked) => {
    return changeEmailBlocklist(
      this.props.modalContext.openModalProps[MODAL_USER_DETAIL].user_id,
      isInputChecked
    )
      .then(() => {
        this.setState({
          toast: {
            message: 'Email blocklist changed.',
            appearance: 'success',
          },
          email_blocklisted: !this.state.email_blocklisted,
        });
        this.closePopover();
      })
      .catch((e) =>
        this.setState({
          toast: {
            message: `Error changing email blocklist status: ${
              e.error_message || e.error || e.message
            }.`,
            appearance: 'error',
          },
        })
      );
  };

  handleClick = (event) => {
    // This prevents ghost click.
    event.preventDefault();
    this.setState({
      ...this.state,
      popover: {
        open: true,
        anchorEl: event.currentTarget,
      },
    });
  };

  closePopover = () => {
    this.setState({
      ...this.state,
      popover: {
        open: false,
      },
    });
  };

  openUpgradeModal = () => {
    const modalProps =
      this.props.modalContext.openModalProps[MODAL_USER_DETAIL];
    this.closePopover();
    this.props.modalContext.openModal(MODAL_UPGRADE_USER, {
      user_id: modalProps.user_id,
    });
  };

  openEmailChangeModal = () => {
    const modalProps =
      this.props.modalContext.openModalProps[MODAL_USER_DETAIL];
    this.closePopover();
    this.props.modalContext.openModal(MODAL_EMAIL_CHANGE, {
      user_id: modalProps.user.id,
      user_email: this.state.user_email,
      onSuccess: this.onEmailChangeSuccess,
    });
  };

  onEmailChangeSuccess = (email) => {
    const modalProps =
      this.props.modalContext.openModalProps[MODAL_USER_DETAIL];
    this.setState({
      user_email: email,
      changeEmailData: {
        updated: true,
      },
    });
    modalProps.updateUserEmailAction(email);
  };

  openDeleteUserModal = () => {
    const { user } = this.props.modalContext.openModalProps[MODAL_USER_DETAIL];
    this.closePopover();
    this.props.modalContext.openModal(MODAL_CONFIRMATION_DIALOG, {
      title: 'Delete User',
      onSubmit: (annotation) => deleteUser(user.id, annotation),
      modalWarning:
        'Are you sure you want to delete this user and comdemn their account for deletion?',
      onSuccess: () => {
        this.setState(
          {
            toast: {
              message: `User ${user.email} deleted.`,
              appearance: 'success',
            },
          },
          () => {
            this.closePopover();
            this.props.lookupContext.lookupRequest('user_id', user.id);
            this.props.modalContext.closeModal(MODAL_USER_DETAIL);
          }
        );
      },
      onError: (e) => {
        this.setState({
          toast: {
            message: `Error deleting user: ${
              e.error_message || e.error || e.message
            }.`,
            appearance: 'error',
          },
        });
      },
    });
  };

  onCloseModal = () => {
    this.setState({
      ...this.state,
      changeEmailData: {
        updated: false,
      },
    });
    this.props.modalContext.closeModal(MODAL_USER_DETAIL);
  };

  render() {
    const modalActions = [
      <Button key="cancel" color="primary" onClick={this.onCloseModal}>
        Close
      </Button>,
    ];

    const modalProps =
      this.props.modalContext.openModalProps[MODAL_USER_DETAIL];
    const isModalOpen = this.props.modalContext.isModalOpen(MODAL_USER_DETAIL);
    if (isModalOpen) {
      return (
        <Dialog
          open={isModalOpen}
          scroll="paper"
          onClose={this.onCloseModal}
          maxWidth="md"
          disableEnforceFocus={true}
        >
          <DialogTitle>{modalProps.user.name}</DialogTitle>
          <DialogContent>
            <div className={styles.detailBlock}>
              <div className={styles.detailList}>
                <div>
                  Id: <span data-hj-suppress>{modalProps.user.id}</span>
                </div>
                <div>
                  Email: <span data-hj-suppress>{this.state.user_email}</span>{' '}
                  {this.state.changeEmailData.updated && (
                    <span className={styles.updatedAlert}>UPDATED</span>
                  )}
                </div>
                <div>
                  MFA Status:{' '}
                  {modalProps.user.has_mfa_enabled ? 'Enabled' : 'Disabled'}
                  {this.state.mfaDisableSuccess
                    ? ' (refresh the page to see updated data)'
                    : null}
                </div>
                {this.state.mfaDisableConfirmation ? (
                  <form className={styles.mfaConfirmation}>
                    <p>
                      Are you sure you want to disable MFA for this user? This
                      operation can't be undone.
                    </p>
                    <TextField
                      multiline
                      fullWidth
                      required
                      inputProps={{ 'data-testid': 'annotation' }}
                      value={this.state.mfaDisableAnnotation}
                      onChange={this.handleMfaAnnotationChange}
                      label="Annotation"
                      name="annotation"
                      helperText="Please provide reason for disabling MFA"
                      variant="standard"
                    />
                    <Button
                      disabled={!this.state.mfaDisableAnnotation.length}
                      className={styles.mfaConfirmationConfirmButton}
                      variant="contained"
                      onClick={this.handleMfaDisable}
                    >
                      Disable MFA
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() =>
                        this.setState({ mfaDisableConfirmation: false })
                      }
                    >
                      Cancel
                    </Button>
                  </form>
                ) : null}
              </div>
              <Button
                className={styles.actionsButton}
                variant="contained"
                onClick={this.handleClick}
              >
                Actions &#9660;
              </Button>
              <Menu
                open={this.state.popover.open}
                anchorEl={this.state.popover.anchorEl}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                onClose={this.closePopover}
              >
                {modalProps.user.has_mfa_enabled ? (
                  <MenuItem onClick={this.handleShowMfaConfirmation}>
                    Disable Multi-factor Authentication
                  </MenuItem>
                ) : null}
                <MenuItem onClick={this.showSignInActivity}>
                  View sign-in activity
                </MenuItem>
                <MenuItem onClick={this.getPasswordReset}>
                  Generate Password Reset Link
                </MenuItem>
                <MenuItem onClick={this.updateIntercomData}>
                  Update Intercom Data
                </MenuItem>
                {!modalProps.is_account_owner ? (
                  <MenuItem onClick={this.openUpgradeModal}>
                    Upgrade User to Account Owner
                  </MenuItem>
                ) : (
                  ''
                )}
                <HasPermission
                  rolesAccepted={['admin', 'insights_user_manager']}
                >
                  <MenuItem onClick={this.openEmailChangeModal}>
                    Change Email
                  </MenuItem>
                </HasPermission>
                <HasPermission
                  rolesAccepted={['admin', 'insights_user_manager']}
                >
                  <MenuItem onClick={this.openDeleteUserModal}>
                    Delete User
                  </MenuItem>
                </HasPermission>
              </Menu>
            </div>
            {this.state.passwordReset.email ? (
              <ResetEmailLinkButton
                email={this.state.passwordReset.email}
                subject={this.state.passwordReset.subject}
                body={this.state.passwordReset.body}
              />
            ) : (
              ''
            )}
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.email_blocklisted}
                    onChange={this.handleToggleChange}
                  />
                }
                label="User Email Blocklisted"
              />
            </FormGroup>
            <ErrorBoundary
              fallback={<p>Error retrieving user organizations</p>}
            >
              <Suspense fallback={<p>Loading...</p>}>
                <UserOrgsTable />
              </Suspense>
            </ErrorBoundary>
            <ToastManager toast={this.state.toast} />
            <DialogActions>{modalActions}</DialogActions>
          </DialogContent>
        </Dialog>
      );
    } else {
      return null;
    }
  }
}

export default withLookupContext(withModalContext(UserDetailModal));
