import {
  FC,
  PropsWithChildren,
  SyntheticEvent,
  cloneElement,
  useState,
} from 'react';

import Menu from '@mui/material/Menu';

import styles from './ActionsMenu.module.css';

interface ActionsMenuProps extends PropsWithChildren {
  isOpen: boolean;
  button?: JSX.Element;
  openPopover: () => void;
  closePopover: () => void;
  handleEventTracking?: () => void;
}

export const ActionsMenu: FC<ActionsMenuProps> = ({
  isOpen,
  button,
  children,
  openPopover,
  closePopover,
  handleEventTracking,
}) => {
  const [anchorElement, setAnchorElement] = useState<Element>();

  const handleClick = (event: SyntheticEvent) => {
    // This prevents ghost click.
    event.preventDefault();
    setAnchorElement(event.currentTarget);

    openPopover();

    if (handleEventTracking) {
      handleEventTracking();
    }
  };

  const handleRequestClose = () => {
    closePopover();
  };

  const renderActionsButton = () => {
    const buttonProps = {
      onClick: handleClick,
    };

    if (button) {
      return cloneElement(button, buttonProps);
    }

    return (
      <button className={styles.actionsButton} type="button" {...buttonProps}>
        Actions &#9660;
      </button>
    );
  };

  return (
    <div>
      {renderActionsButton()}
      <Menu
        open={isOpen}
        anchorEl={anchorElement}
        onClose={handleRequestClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {children}
      </Menu>
    </div>
  );
};
