import { FC, PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import styles from './ButtonLink.module.css';

interface ButtonLinkProps {
  onClick: () => void;
  className?: string;
}

const ButtonLink: FC<PropsWithChildren<ButtonLinkProps>> = ({
  onClick,
  children,
  className,
}) => (
  <button onClick={onClick} className={clsx(styles.buttonLink, className)}>
    {children}
  </button>
);

export default ButtonLink;
