import React, { useContext, useEffect, useReducer } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
} from '@mui/material';
import { bulkLockPlans } from './api';
import SiteSelectTable from '../ModifyPlanSettings/SiteSelectTable';
import { MODAL_BULK_LOCK_PLAN } from '../constants';
import { ModalContext } from '../../../contexts/ModalContext';

const initialState = {
  lockedPlans: 0,
  unlockedPlans: 0,
  targetSites: [],
  annotation: '',
  annotationError: false,
};

const reducer = (state, action) => {
  return {
    ...state,
    [action.type]: action.payload,
  };
};

const BulkLockPlansModal = () => {
  const [
    { lockedPlans, unlockedPlans, targetSites, annotation, annotationError },
    dispatch,
  ] = useReducer(reducer, initialState);

  const { addToast } = useToasts();

  const context = useContext(ModalContext);
  const isOpen = context.isModalOpen(MODAL_BULK_LOCK_PLAN);
  const modalProps = context.openModalProps[MODAL_BULK_LOCK_PLAN];

  useEffect(() => {
    if (modalProps?.sites) {
      let lockedPlans = 0,
        unlockedPlans = 0;
      Object.keys(modalProps.sites).forEach((key) => {
        if (modalProps.sites[key].lock_plan === true) lockedPlans++;
        else unlockedPlans++;
      });
      dispatch({ type: 'lockedPlans', payload: lockedPlans });
      dispatch({ type: 'unlockedPlans', payload: unlockedPlans });
    }
  }, [isOpen, modalProps]);

  const handleSubmit = async (lock = true) => {
    if (!annotation) {
      dispatch({ type: 'annotationError', payload: 'Annotation Required' });
      return;
    }
    dispatch({ type: 'annotationError', payload: '' });
    try {
      await bulkLockPlans(targetSites, lock, annotation);
      context.closeModal(MODAL_BULK_LOCK_PLAN);
      addToast('Plans Changed Successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      modalProps.onSuccess();
    } catch (e) {
      dispatch({ type: 'annotationError', payload: e.message });
    }
  };

  const handleCheckboxChange = (rows) => {
    dispatch({
      type: 'targetSites',
      payload: rows.map((row) => modalProps.site_ids[row.index]),
    });
  };

  if (modalProps && isOpen) {
    return (
      <Dialog open={isOpen} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>Bulk Lock Plan</DialogTitle>
        <DialogContent>
          <div>
            There are {lockedPlans} Locked Plans and {unlockedPlans} Unlocked
            Plans.
          </div>
          <SiteSelectTable
            handleCheckboxChange={handleCheckboxChange}
            tableData={modalProps.site_ids.map((site_id) => [
              site_id,
              modalProps.sites[site_id].name,
            ])}
          />
          <TextField
            multiline
            required
            onChange={(e) =>
              dispatch({ type: 'annotation', payload: e.target.value })
            }
            error={!!annotationError && !annotation}
            label="Annotation"
            name="adm_action_annotation"
            id="adm_action_annotation"
            helperText={
              !!annotationError
                ? annotationError
                : 'Please provide reason for change and/or Zendesk ticket link'
            }
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            key="cancel"
            onClick={() => context.closeModal(MODAL_BULK_LOCK_PLAN)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit(false)}
            color="primary"
            key="unlockAll"
            type={'submit'}
          >
            Unlock All
          </Button>
          <Button
            onClick={() => handleSubmit()}
            color="primary"
            key="lockAll"
            type={'submit'}
          >
            Lock All
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
};

export default BulkLockPlansModal;
