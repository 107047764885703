import { useState } from 'react';
import moment, { Moment } from 'moment';
import styles from '../../../components/modals/TrialActivationModal/TrialActivationModal.module.css';
import { DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

export enum TRIAL_PERIOD_TYPE {
  DEFAULT = 'default',
  EXTENSION = 'extension',
  CUSTOM_DATE = 'custom_date',
}

export const MAX_TRIAL_PERIOD = 60;
const DEFAULT_TRIAL_PERIOD = 15;
const EXTENSION_PERIODS = [DEFAULT_TRIAL_PERIOD, 30, MAX_TRIAL_PERIOD];

interface TrialCustomDurationProps {
  mode: 'create' | 'extend';
}

export const useTrialV2CustomDuration = ({
  mode,
}: TrialCustomDurationProps) => {
  const getUniqueID = (type: TRIAL_PERIOD_TYPE, period?: number | null) =>
    type + (period ? period : '');

  const [trialDateSelected, setTrialDateSelected] = useState<Moment>(moment());
  const [trialPeriod, setTrialPeriod] = useState(DEFAULT_TRIAL_PERIOD);
  const [trialPeriodType, setTrialPeriodType] = useState<string>(
    getUniqueID(
      TRIAL_PERIOD_TYPE[`${mode === 'create' ? 'DEFAULT' : 'EXTENSION'}`],
      DEFAULT_TRIAL_PERIOD
    )
  );

  const onTrialPeriodChange = (
    durationTypeId: string,
    period: number | null
  ) => {
    setTrialPeriodType(durationTypeId);
    setTrialDateSelected(moment());
    if (durationTypeId !== TRIAL_PERIOD_TYPE.CUSTOM_DATE && period) {
      setTrialPeriod(period);
    }
  };

  /**
   * Calculates days for trial period using the date picker mode
   * @param customDateValue
   */
  const onTrialDatePickedPeriod = (customDateValue: Moment | null = null) => {
    if (customDateValue) {
      setTrialDateSelected(customDateValue);
      const daysPeriod = customDateValue
        .startOf('day')
        .diff(moment().startOf('day'), 'days');

      if (daysPeriod > 0 && daysPeriod <= MAX_TRIAL_PERIOD) {
        setTrialPeriod(daysPeriod);
      } else {
        setTrialPeriod(DEFAULT_TRIAL_PERIOD);
      }
    }
  };

  const periodRadio = (period: number | null, type: TRIAL_PERIOD_TYPE) => {
    const uniqueID = getUniqueID(type, period);
    const periodLabel =
      type === TRIAL_PERIOD_TYPE.CUSTOM_DATE ? 'Custom' : `${period} days`;
    return (
      <div>
        <input
          onChange={() => onTrialPeriodChange(uniqueID, period)}
          type="radio"
          id={uniqueID}
          name={uniqueID}
          value={period ? period : ''}
          checked={trialPeriodType === uniqueID}
        />
        <label htmlFor={uniqueID}>{periodLabel}</label>
      </div>
    );
  };

  const trialPeriodElement = (
    <>
      <p className={styles.bold}>
        {mode === 'create' ? 'Chose trial duration:' : 'Extend trial by:'}
      </p>
      <div className={styles.trialOptions}>
        {mode === 'create'
          ? periodRadio(DEFAULT_TRIAL_PERIOD, TRIAL_PERIOD_TYPE.DEFAULT)
          : EXTENSION_PERIODS.map((p) =>
              periodRadio(p, TRIAL_PERIOD_TYPE.EXTENSION)
            )}
        {periodRadio(null, TRIAL_PERIOD_TYPE.CUSTOM_DATE)}
        <div>
          {trialPeriodType === TRIAL_PERIOD_TYPE.CUSTOM_DATE && (
            <div style={{ marginTop: '8px' }}>
              <label>Choose date when trial should end (max 60 days)</label>
              <DatePicker
                disablePast
                maxDate={moment().startOf('day').add(MAX_TRIAL_PERIOD, 'days')}
                inputFormat="DD-MM-YYYY"
                className={styles.date}
                onChange={onTrialDatePickedPeriod}
                value={trialDateSelected}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} />
                )}
              />
            </div>
          )}
        </div>
      </div>
      <br />
      <p className={styles.textGray}>
        Trial period: {trialPeriod} day{trialPeriod > 1 && 's'}
      </p>
    </>
  );

  return { trialPeriodElement, trialPeriod };
};
