import React from 'react';

function ResourceListItem({
  resource,
  updateResourceList,
  checkbox = true,
  selected = false,
}) {
  const handleChange = (e) => {
    updateResourceList(
      [
        {
          url: resource.url,
          title: resource.title,
          subtitle: resource.subtitle,
          type: resource.type,
        },
      ],
      !e.target.checked
    );
  };

  return (
    <div
      className={`listItem ${resource.title.toLowerCase().replace(' ', '-')}`}
      key={resource.url}
    >
      {checkbox ? (
        <input
          type="checkbox"
          url={resource.url}
          id={resource.url}
          onChange={handleChange}
          checked={selected}
        />
      ) : (
        ''
      )}
      <label htmlFor={resource.url}>
        <h5>{resource.title}</h5>
        <a href={resource.url} target="_blank" rel="noreferrer">
          [link]
        </a>
      </label>
    </div>
  );
}

export default ResourceListItem;
