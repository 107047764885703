import React from 'react';
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import styles from './UserDetail.module.css';

const ResetEmailLinkButton = (props) => {
  const emailLink = `mailto:${props.email}?subject=${props.subject}&body=${props.body}`;

  return (
    <Button
      variant="contained"
      href={emailLink}
      target="_blank"
      rel="noopener noreferrer"
      color="primary"
      className={styles.resetButton}
    >
      Create Password Reset Email
      <OpenInNewIcon />
    </Button>
  );
};

export default ResetEmailLinkButton;
