import { useQuery } from '@tanstack/react-query';
import { fetchAccountPricingTableURL } from './urls';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { PricingTable } from '../models/account.pricingTable.model';

const showAccountPricingTable = async (
  accountId: string
): Promise<PricingTable> => {
  const response = await fetch(fetchAccountPricingTableURL(accountId));

  return handleFetchApiResponse<PricingTable>(response);
};

export const useShowAccountPricingTable = (accountId: string) => {
  const { addToast } = useToasts();
  const { data, isInitialLoading, isError, error } = useQuery<
    PricingTable,
    Error
  >({
    queryKey: ['showAccountPricingTable', accountId],
    queryFn: () => showAccountPricingTable(accountId),
    retry: false,
  });

  useEffect(() => {
    if (isError) {
      addToast(
        `There was an error fetching default plans for account with id:"${accountId}".`,
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );
    }
  }, [accountId, addToast, isError, error]);

  return {
    accountPricingTableData: data,
    accountPricingTableError: error,
    isAccountPricingTableError: isError,
    isAccountPricingTableLoading: isInitialLoading,
  };
};
