import { TextField } from '@mui/material';
import type { ChangeEventHandler, FocusEventHandler } from 'react';
import React from 'react';
import styles from './ManageFeatures.module.css';

type JiraStoryFieldProps = {
  name?: string;
  value: string;
  onValueChange?: (value: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  error?: string;
};

export const JiraStoryField = ({
  name = 'JiraId',
  value,
  onValueChange,
  onBlur,
  error,
}: JiraStoryFieldProps): JSX.Element => {
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onValueChange?.(event.target.value);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    onBlur?.(event);

    const trimmed = event.target.value.trim();
    if (trimmed !== event.target.value) {
      onValueChange?.(trimmed);
    }
  };

  return (
    <TextField
      name={name}
      label="Jira ID"
      value={value}
      onChange={handleInputChange}
      onBlur={handleBlur}
      helperText={error ?? 'Enter a Jira story/task ID, in the format: XXX-###'}
      variant="standard"
      error={error != null}
      className={styles.field}
      inputProps={{
        'data-1p-ignore': true,
      }}
    />
  );
};
