import { Site } from '../../sites/models/site.model';
import { useQuery } from '@tanstack/react-query';
import { fetchTrialEligibility } from './fetchTrialEligibility';
import { SKU } from '../../../typings';
import { useIsAlp } from '../../accounts/utils/useIsAlp';

export const createTrialEligibilityQueryConfig = (
  accountId: string | number | undefined,
  entityId: string | number | undefined,
  entityType: 'account' | 'site'
) => {
  return {
    queryKey: [
      'trials',
      accountId?.toString(),
      entityId?.toString(),
      'eligibility',
    ],
    queryFn: () =>
      fetchTrialEligibility(accountId, entityType, entityId?.toString()),
    enabled: !!accountId && !!entityId,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  };
};

interface TrialV2Eligibility {
  isLoading: boolean;
  isEligible: boolean;
  eligibleTrials: `${SKU}_${SKU}`[];
}

/**
 * Hook that returns whether or not a given site is eligible for trial v2.
 * *
 * @param site Site object
 * @returns Whether the site is eligible for trial v2
 */
export const useTrialV2Eligibility = (
  site: Pick<Site, 'site_id' | 'account_id'> | undefined
): TrialV2Eligibility => {
  const isAlp = useIsAlp();
  const accountId = site?.account_id;
  const entityId = isAlp ? accountId : site?.site_id;
  const entityType = isAlp ? 'account' : 'site';

  const { data, isLoading } = useQuery({
    ...createTrialEligibilityQueryConfig(accountId, entityId, entityType),
    enabled: (isAlp && !!accountId) || (!isAlp && !!entityId),
  });

  const eligibleBundles = data?.eligible || [];

  return {
    isLoading,
    isEligible: eligibleBundles.length > 0,
    eligibleTrials: data?.eligible ?? [],
  };
};
