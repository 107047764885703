import React from 'react';
import MUIDataTable from 'mui-datatables';
import styles from './DomainSearch.module.css';
import { DOMAIN_USERS_SEARCH_LABEL } from './constants';
import {
  accountIdRenderer,
  highestPermissionRenderer,
  lastLoginRenderer,
  userIdRenderer,
} from './renderers';
import {
  accountOwnerCustomFilterListOptions,
  accountOwnerFilterOptions,
  isPayingCustomerCustomFilterListOptions,
  isPayingCustomerFilterOptions,
} from './filters';
import { entitiesWithCustomerData } from './utils';

const DomainUsersTable = ({ users, searchTerm, customerData }) => {
  const columnSettings = [
    {
      name: 'User ID',
      options: {
        filter: false,
        customBodyRender: userIdRenderer,
      },
    },
    {
      name: 'Account ID',
      options: {
        filter: false,
        customBodyRender: accountIdRenderer,
      },
    },
    {
      name: 'Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'Email',
      options: {
        filter: false,
      },
    },
    {
      name: 'Highest level of permissions',
      options: {
        filterType: 'custom',
        customBodyRender: highestPermissionRenderer,
        filterOptions: accountOwnerFilterOptions,
        customFilterListOptions: accountOwnerCustomFilterListOptions,
      },
    },
    {
      name: 'lastLogin',
      label: 'Last login',
      options: {
        filter: false,
        customBodyRender: lastLoginRenderer,
      },
    },
    {
      name: 'isPayingCustomer',
      options: {
        display: 'excluded',
        filterType: 'custom',
        filterOptions: isPayingCustomerFilterOptions(customerData),
        customFilterListOptions: isPayingCustomerCustomFilterListOptions,
      },
    },
    {
      name: 'Company name',
      options: {
        filter: false,
        // Displayed in the account id tooltip
        display: 'excluded',
      },
    },
  ];

  const tableSettings = {
    pagination: false,
    print: false,
    download: false,
    selectableRows: 'none',
    responsive: 'standard',
    searchAlwaysOpen: true,
    searchPlaceholder: DOMAIN_USERS_SEARCH_LABEL,
    searchProps: {
      'aria-label': DOMAIN_USERS_SEARCH_LABEL,
    },
    sortOrder: {
      name: 'lastLogin',
      direction: 'desc',
    },
  };

  const userData = entitiesWithCustomerData(users, customerData).map((user) => [
    user.id,
    user.account_id,
    user.name,
    user.email,
    user.highest_permission,
    user.last_successful_login,
    user.hasActiveSubscription,
    // company name should be the last item in the column array
    user.company_name,
  ]);

  return (
    <div className={styles.container}>
      {users.length <= 0 ? (
        `No users found for "${searchTerm}"`
      ) : (
        <MUIDataTable
          columns={columnSettings}
          options={tableSettings}
          data={userData}
        />
      )}
    </div>
  );
};

export default DomainUsersTable;
