import { FC, useMemo } from 'react';
import { Typography } from '@mui/material';

import EntityDetails from '../../../../components/EntityDetails';
import SidebarAccordion from '../../../../components/SidebarAccordion';

import { useShowsUser } from '../../../users/api/useShowUser';
import { getAccountData } from './utils';

import styles from './AccountWidget.module.css';
import { EntityDetailsColumnsConfig } from '../../../../components/EntityDetails/interfaces';

interface AccountWidgetProps {
  accountId?: number;
  accountOwnerUserId?: number;
}

const AccountWidget: FC<AccountWidgetProps> = ({
  accountId,
  accountOwnerUserId,
}) => {
  const { userData, isUserLoading } = useShowsUser(accountOwnerUserId);

  const userId = userData?.user_id;
  const userName = userData?.user_name;
  const userEmail = userData?.user_email;

  const data = useMemo(() => {
    if (!accountId || !userName || !userId) {
      return;
    }

    return getAccountData({
      accountId,
      userName,
      userId,
    });
  }, [accountId, userName, userId]);

  const emailData = useMemo(() => {
    if (!userEmail) {
      return;
    }

    return [
      [{ name: 'Email', value: userEmail }],
    ] as EntityDetailsColumnsConfig;
  }, [userEmail]);

  return (
    <SidebarAccordion
      title="Account"
      accordionName="account"
      isLoading={isUserLoading}
    >
      {data && <EntityDetails data={data} wrapperClassName={styles.wrapper} />}
      {emailData && <EntityDetails data={emailData} />}
      {!isUserLoading && !data && <Typography>No data available.</Typography>}
    </SidebarAccordion>
  );
};

export default AccountWidget;
