import React from 'react';

import ModifySubscriptionModal from '../../components/modals/ModifySubscription';
import ModifyPlanSettingsModal from '../../components/modals/ModifyPlanSettings';
import { ModifyPlanSettingsModalMagpie } from '../../components/modals/ModifyPlanSettingsMagpie';
import ViewInvoicesModal from '../../components/modals/ViewInvoices';
import TransfersModal from '../../components/modals/Transfers';
import AdmActionsModal from '../../components/modals/AdmActions';
import IgnoredIPsModal from '../../components/modals/IgnoredIPs';
import InviteUserModal from '../../components/modals/InviteUser';
import SamplingVisualizer from '../../components/modals/SamplingVisualizer';
import FeatureManagerDialog from '../../components/modals/FeatureManagerDialog';
import FormDialog from '../../components/modals/FormDialog';

import BulkLockPlansModal from '../../components/modals/BulkLockPlans';
import SubscriptionCreationModal from '../../components/modals/SubscriptionCreationModal';
import { MigrateManualCustomerModal } from '../../components/modals/MigrateManualCustomerModal';
import UpdateCustomerDetailsModal from '../../components/modals/UpdateCustomerDetailsModal';
import UpdateCustomerCountryModal from '../../components/modals/UpdateCustomerCountryModal';
import UpdateSalesDiscountModal from '../../components/modals/UpdateSalesDiscountModal';
import BasicAlertDialog from '../../components/modals/BasicAlertDialog';
import UpdateCustomerEmailRecipientsModal from '../../components/modals/UpdateCustomerEmailRecipientsModal';
import PreviewCostsModal from '../../components/modals/PreviewCostsModal';
import TrialManageModal from '../../components/modals/TrialManageModal';
import TrialActivationModal from '../../components/modals/TrialActivationModal';
import TrialExtendModal from '../../components/modals/TrialExtendModal';
import TrialCancelModal from '../../components/modals/TrialCancelModal';
import { ModifyAccessLevelModal } from '../../components/modals/ModifyAccessLevelModal/ModifyAccessLevelModal';
import { AddUserModal } from './actions/AddUser/AddUserModal';
import { EnableSSOForOrgModal } from './actions/EnableSSOForOrg/EnableSSOForOrgModal';
import { ProvisionCsAccountModal } from './actions/ProvisionCsAccount/ProvisionCsAccountModal';
import AdmAddActionDialog from '../../components/AdmActionTable/AdmAddActionDialog';
import { AddIDPModal } from './actions/AddIDP/AddIDPModal';
import {
  MODAL_ACTIVATE_TRIAL,
  MODAL_ADD_ACTION,
  MODAL_ADD_IDP,
  MODAL_ADD_SERVICE_PERIOD,
  MODAL_ADD_USER,
  MODAL_ADM_ACTIONS,
  MODAL_BASIC_ALERT_DIALOG,
  MODAL_BULK_LOCK_PLAN,
  MODAL_CANCEL_TRIAL,
  MODAL_CREATE_SUBSCRIPTION,
  MODAL_ENABLE_SSO_FOR_ORG,
  MODAL_EXTEND_TRIAL,
  MODAL_FEATURE_MANAGER,
  MODAL_FORM_DIALOG,
  MODAL_IGNORED_IPS,
  MODAL_INVITE_USER,
  MODAL_MANAGE_TRIAL,
  MODAL_MIGRATE_MANUAL,
  MODAL_MODIFY_ACCESS_LEVEL,
  MODAL_MODIFY_PLAN,
  MODAL_MODIFY_PLAN_MAGPIE,
  MODAL_MODIFY_SUBSCRIPTION,
  MODAL_PREVIEW_COSTS,
  MODAL_PROVISION_CS_ACCOUNT,
  MODAL_SAMPLING_VISUALIZER,
  MODAL_TRANSFER,
  MODAL_UPDATE_CUSTOMER_COUNTRY,
  MODAL_UPDATE_CUSTOMER_DETAILS,
  MODAL_UPDATE_CUSTOMER_EMAIL_RECIPIENTS,
  MODAL_UPDATE_SALES_DISCOUNT,
  MODAL_VIEW_INVOICES,
} from '../../components/modals/constants';
import { AdmModalRoot } from '../../components/modals/AdmModalRoot';
import { AddServicePeriodModal } from './actions/AddServicePeriod/AddServicePeriodModal';

const MODAL_MAP = {
  [MODAL_MODIFY_ACCESS_LEVEL]: ModifyAccessLevelModal,
  [MODAL_MODIFY_SUBSCRIPTION]: ModifySubscriptionModal,
  [MODAL_MODIFY_PLAN]: ModifyPlanSettingsModal,
  [MODAL_MODIFY_PLAN_MAGPIE]: ModifyPlanSettingsModalMagpie,
  [MODAL_BULK_LOCK_PLAN]: BulkLockPlansModal,
  [MODAL_VIEW_INVOICES]: ViewInvoicesModal,
  [MODAL_TRANSFER]: TransfersModal,
  [MODAL_ADM_ACTIONS]: AdmActionsModal,

  [MODAL_SAMPLING_VISUALIZER]: SamplingVisualizer,
  [MODAL_INVITE_USER]: InviteUserModal,
  [MODAL_IGNORED_IPS]: IgnoredIPsModal,
  [MODAL_FEATURE_MANAGER]: FeatureManagerDialog,

  [MODAL_FORM_DIALOG]: FormDialog,
  [MODAL_BASIC_ALERT_DIALOG]: BasicAlertDialog,
  [MODAL_CREATE_SUBSCRIPTION]: SubscriptionCreationModal,
  [MODAL_MIGRATE_MANUAL]: MigrateManualCustomerModal,
  [MODAL_PREVIEW_COSTS]: PreviewCostsModal,
  [MODAL_UPDATE_CUSTOMER_DETAILS]: UpdateCustomerDetailsModal,
  [MODAL_UPDATE_SALES_DISCOUNT]: UpdateSalesDiscountModal,
  [MODAL_UPDATE_CUSTOMER_EMAIL_RECIPIENTS]: UpdateCustomerEmailRecipientsModal,
  [MODAL_UPDATE_CUSTOMER_COUNTRY]: UpdateCustomerCountryModal,
  [MODAL_MANAGE_TRIAL]: TrialManageModal,
  [MODAL_ACTIVATE_TRIAL]: TrialActivationModal,
  [MODAL_EXTEND_TRIAL]: TrialExtendModal,
  [MODAL_CANCEL_TRIAL]: TrialCancelModal,
  [MODAL_ADD_USER]: AddUserModal,
  [MODAL_ENABLE_SSO_FOR_ORG]: EnableSSOForOrgModal,
  [MODAL_PROVISION_CS_ACCOUNT]: ProvisionCsAccountModal,
  [MODAL_ADD_ACTION]: AdmAddActionDialog,
  [MODAL_ADD_IDP]: AddIDPModal,
  [MODAL_ADD_SERVICE_PERIOD]: AddServicePeriodModal,
} as const;

export const LookupResultsModalRoot = () => {
  return <AdmModalRoot modalMap={MODAL_MAP} />;
};
