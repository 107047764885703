import {
  Grid,
  InputLabel,
  Select,
  TextField,
  InputAdornment,
} from '@mui/material';
import { insightsMagpieBillingPeriodsMapping } from '../../../domains/magpie/models';

export const PaymentTerms = [
  { label: '21 days', value: 'DAYS_21' },
  { label: '30 days', value: 'DAYS_30' },
  { label: '45 days', value: 'DAYS_45' },
  { label: '60 days', value: 'DAYS_60' },
  { label: '90 days', value: 'DAYS_90' },
];

export const SubscriptionsCommonFields = ({
  formData,
  handleUpdateField,
  isCreationMode,
  allowBillingPeriodSelection = false,
}) => (
  <div style={{ marginBottom: '24px' }}>
    <Grid container spacing={10}>
      {isCreationMode && (
        <Grid item xs>
          <InputLabel variant="standard" htmlFor="billingPeriod" shrink>
            Billing Period
          </InputLabel>
          <Select
            id="billingPeriod"
            label="Billing Period"
            inputProps={{ 'aria-label': 'Billing Period' }}
            value={formData.billingPeriod}
            variant="standard"
            onChange={handleUpdateField}
            native
            fullWidth
            disabled={!allowBillingPeriodSelection}
          >
            {Object.values(insightsMagpieBillingPeriodsMapping).map(
              (billingPeriod) => (
                <option key={billingPeriod} value={billingPeriod}>
                  {billingPeriod}
                </option>
              )
            )}
          </Select>
        </Grid>
      )}

      <Grid item xs>
        <InputLabel variant="standard" htmlFor="paymentTerm" shrink>
          Payment Term
        </InputLabel>
        <Select
          id="paymentTerm"
          label="Payment Term"
          inputProps={{ 'aria-label': 'Payment Term' }}
          value={formData.paymentTerm}
          onChange={handleUpdateField}
          variant="standard"
          native
          fullWidth
        >
          {PaymentTerms.map(({ label, value }) => {
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          })}
        </Select>
      </Grid>

      <Grid item xs>
        <TextField
          label="PO number"
          variant="standard"
          id="purchaseOrderNumber"
          value={formData.purchaseOrderNumber}
          onChange={handleUpdateField}
          fullWidth
        />
      </Grid>

      {isCreationMode && (
        <Grid item xs>
          <TextField
            label="Discount"
            id="discountPercentage"
            variant="standard"
            value={formData.discountPercentage}
            onChange={handleUpdateField}
            error={formData.discountPercentage > 100}
            helperText={
              formData.discountPercentage > 100
                ? 'Discount cannot be over 100.'
                : ''
            }
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { min: 0, max: 100 },
            }}
            fullWidth
          />
        </Grid>
      )}
    </Grid>
  </div>
);
