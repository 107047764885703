import React from 'react';
import MUIDataTable from 'mui-datatables';
import Checkbox from '@mui/material/Checkbox';
import { toggleEnabled, deleteCampaign } from './api';

const getColumnSettings = (getData) => [
  { name: 'ID', options: { sortOrder: 'desc' } },
  { name: 'Name' },
  {
    name: 'URL',
    options: {
      customBodyRender: (value) => (
        <a href={value} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      ),
    },
  },
  { name: 'Plan ID' },
  { name: 'Days' },
  { name: 'Type' },
  {
    name: 'Enabled',
    options: {
      customBodyRender: (value, tableMeta) => (
        <Checkbox
          checked={value}
          data-testid="enableCheckbox"
          onChange={() => {
            toggleEnabled(tableMeta.rowData[0], !value).then(() => {
              getData();
            });
          }}
        />
      ),
    },
  },
];

const getTableSettings = (campaigns) => ({
  selectableRows: 'multiple',
  responsive: 'standard',
  rowsPerPageOptions: [25, 50, 100],
  pagination: true,
  print: false,
  download: false,
  viewColumns: false,
  serverSide: false,
  filter: true,
  search: true,
  onRowsDelete: (rowsDeleted) => {
    rowsDeleted.data.forEach((row) => {
      const index = row.dataIndex;
      deleteCampaign(campaigns[index][0]);
    });
  },
});

const CampaignTable = ({ campaigns, getData }) => {
  return (
    <div>
      <MUIDataTable
        data={campaigns}
        columns={getColumnSettings(getData)}
        options={getTableSettings(campaigns)}
      />
    </div>
  );
};

export default CampaignTable;
