import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { DOMAIN_USERS_PERMISSIONS_MAP } from './constants';
import { NO_DATA_PLACEHOLDER } from '../../../utils/constants';
import { epochToDateString, timestampToTimeAgo } from '../../../utils/dates';
import { getCurrencySymbol } from '../../../utils/costs';
import ErroredColumnHead from './ErroredColumnHead';
import LoadingColumnHead from './LoadingColumnHead';

// SHARED RENDERERS

export const accountIdRenderer = (accountId, metaData) => {
  if (!accountId) {
    return NO_DATA_PLACEHOLDER;
  }

  // make sure company name is the last item in the column array
  const companyName = metaData.rowData?.[metaData.rowData.length - 1];

  if (!companyName) {
    return <Link to={`/lookup/account_id/${accountId}`}>{accountId}</Link>;
  }

  return (
    <Tooltip
      title={companyName}
      placement="top"
      arrow={true}
      disableHoverListener={!companyName}
      disableFocusListener={!companyName}
    >
      <Link to={`/lookup/account_id/${accountId}`}>{accountId}</Link>
    </Tooltip>
  );
};

// DOMAIN USER RENDERERS

export const userIdRenderer = (id) => (
  <Link to={`/lookup/user_id/${id}`}>{id}</Link>
);

export const highestPermissionRenderer = (permission) =>
  DOMAIN_USERS_PERMISSIONS_MAP[permission] || NO_DATA_PLACEHOLDER;

export const lastLoginRenderer = (timestamp) => timestampToTimeAgo(timestamp);

// DOMAIN SITE RENDERERS

export const lastDataRenderer = (timeStampInSeconds) =>
  timeStampInSeconds
    ? epochToDateString(timeStampInSeconds)
    : NO_DATA_PLACEHOLDER;

export const totalSpendRenderer = (isLoading) => (total, metaData) => {
  if (isLoading) {
    return;
  }

  // make sure currency is the before last item in the column array
  const currency = metaData.rowData?.[metaData.rowData.length - 2];

  return total ? `${getCurrencySymbol(currency)}${total}` : NO_DATA_PLACEHOLDER;
};

export const totalSpendErrorCustomHeadLabelRender = ({ name }) => (
  <ErroredColumnHead columnName={name} />
);

export const totalSpendLoadingCustomHeadLabelRender = ({ name }) => (
  <LoadingColumnHead columnName={name} />
);
