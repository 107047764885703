import React, { Component } from 'react';
import { Button, Dialog, Alert, Typography } from '@mui/material';
import { modifySubscription } from './api';
import Form from './Form';
import { MODAL_MODIFY_SUBSCRIPTION } from '../constants';
import { withLoadingDialog } from '../../withLoading';
import { addDays, tzAdjustLocalToUTC } from '../../../utils/dates';
import { withModalContext } from '../../../contexts/ModalContext';

const LoadingAwareDialog = withLoadingDialog(Dialog);

class ModifySubscriptionModal extends Component {
  state = {
    billing_date: '',
    max_billing_date: '',
    billing_cycle: '',
    switch_to_yearly: false,
    invoice_external_id: '',
    currency_paid: 'eur',
    monthly_amount: '',
    months_paid: 12,
    error_message: null,
    changed: false,
    isLoading: false,
    annotation: '',
  };

  componentDidUpdate(prevProps) {
    const modalContext = this.props.modalContext;
    const modalProps = modalContext.openModalProps[MODAL_MODIFY_SUBSCRIPTION];
    const isOpen = modalContext.isModalOpen(MODAL_MODIFY_SUBSCRIPTION);
    const wasOpen =
      prevProps.modalContext.currentlyOpenModals.indexOf(
        MODAL_MODIFY_SUBSCRIPTION
      ) > -1;
    if (!wasOpen && isOpen) {
      const billing_date = tzAdjustLocalToUTC(modalProps.billing_date);
      const new_billing_date = modalProps.isBillingV3
        ? addDays(billing_date, -1)
        : billing_date;

      this.setState({
        billing_cycle: modalProps.billing_cycle,
        billing_date: new_billing_date,
        max_billing_date: modalProps.isBillingV3 ? new_billing_date : undefined,
      });
    }
  }

  resetState = () => {
    this.setState({
      billing_cycle: '',
      billing_date: '',
      switch_to_yearly: false,
      invoice_external_id: '',
      currency_paid: 'eur',
      monthly_amount: '',
      months_paid: 12,
      error_message: null,
      changed: false,
      isLoading: false,
      annotation: '',
    });
  };

  closeModal = () => {
    const modalContext = this.props.modalContext;
    this.resetState();
    modalContext.closeModal(MODAL_MODIFY_SUBSCRIPTION);
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    const modalProps =
      this.props.modalContext.openModalProps[MODAL_MODIFY_SUBSCRIPTION];

    let data = {
      billing_cycle: this.state.billing_cycle,
      next_billing_date: this.state.billing_date,
      adm_action_annotation: this.state.annotation,
    };

    if (this.state.switch_to_yearly) {
      data['invoice_external_id'] = this.state.invoice_external_id;
      data['currency_paid'] = this.state.currency_paid;
      data['monthly_amount'] = this.state.monthly_amount;
      data['months_paid'] = this.state.months_paid;
    }

    modifySubscription(modalProps.account_id, data)
      .then(() => {
        modalProps.onSuccess();
        this.closeModal();
      })
      .catch((e) =>
        this.setState({
          error_message: e.error_message || e.error,
          isLoading: false,
        })
      );
  };

  render() {
    const modalContext = this.props.modalContext;
    const modalProps = modalContext.openModalProps[MODAL_MODIFY_SUBSCRIPTION];
    const isOpen = modalContext.isModalOpen(MODAL_MODIFY_SUBSCRIPTION);

    const modalActions = [
      <Button color="primary" key="cancel" onClick={this.closeModal}>
        Cancel
      </Button>,
      <Button
        color="primary"
        key="save"
        onClick={this.triggerSubmit}
        disabled={!this.state.changed || this.state.annotation === ''}
      >
        Save Changes
      </Button>,
    ];

    if (isOpen && modalProps) {
      const isBillingV3 = modalProps.isBillingV3;
      const title = isBillingV3 ? 'Extend Service' : 'Modify Subscription';

      return (
        <LoadingAwareDialog
          open={isOpen}
          actions={modalActions}
          title={title}
          isLoading={this.state.isLoading}
          maxWidth="sm"
        >
          <div className="error">
            {this.state.error_message
              ? 'ERROR: ' + this.state.error_message
              : ''}
          </div>
          <Typography>
            Account ID: <span data-hj-suppress>{modalProps.account_id}</span>
          </Typography>

          {isBillingV3 && (
            <Alert severity="info">
              For B3.0 customers you can also extend the Invoice Due Date in
              Zuora. <br />
              Extend service for customers in case the regular 21 days of grace
              period is not enough.
            </Alert>
          )}
          <Form
            ref="form"
            billing_cycle={this.state.billing_cycle}
            switch_to_yearly={this.state.switch_to_yearly}
            billing_date={this.state.billing_date}
            max_billing_date={this.state.max_billing_date}
            invoice_external_id={this.state.invoice_external_id}
            currency_paid={this.state.currency_paid}
            monthly_amount={this.state.monthly_amount}
            months_paid={this.state.months_paid}
            onFormUpdate={this.handleFormUpdate}
            onFormSubmit={this.handleSubmit}
            setErrors={this.setErrors}
            annotation={this.state.annotation}
            isBillingV3={isBillingV3}
          />
        </LoadingAwareDialog>
      );
    } else {
      return null;
    }
  }

  setErrors = (error) => {
    this.setState({ error_message: error });
  };

  triggerSubmit = () => {
    this.refs.form.handleSubmit(new Event('submit'));
  };

  handleFormUpdate = (data) => {
    this.setState({ ...data, changed: true });
  };
}

export default withModalContext(ModifySubscriptionModal);
