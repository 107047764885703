import { ChangeEvent, useMemo, useState } from 'react';
import {
  Button,
  InputLabel,
  FormControl,
  Select,
  TextField,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useToasts } from 'react-toast-notifications';
import styles from './ProvisionVoCAccount.module.css';
import { createProvisioningAccount } from '../api';
import { countryChoices } from '../../../utils/countries';
import moment from 'moment';
import type { Moment } from 'moment';

export type AccountProps = {
  account_admin_email: string;
  account_admin_name: string;
  organization_name: string;
  site_url: string;
  company_name: string;
  size: string;
  location: string;
  limit: string;
  start_date: string; // YYYY-MM-DD
  end_date: string; // YYYY-MM-DD
};

type AccountPropId = keyof AccountProps;

const LIMIT_CHOICES = [
  '500',
  '1000',
  '2500',
  '5000',
  '7500',
  '10000',
  '25000',
  '50000',
  '100000',
  '250000',
  '500000',
  '750000',
  '1000000',
];

const DEFAULT_DATA = {
  account_admin_email: '',
  account_admin_name: '',
  organization_name: '',
  site_url: '',
  company_name: '',
  size: '1-10',
  location: countryChoices[0].value,
  limit: LIMIT_CHOICES[0],
  start_date: '',
  end_date: '',
};

const DateField = ({
  value,
  label,
  error,
  onChange,
}: {
  value: string;
  label: string;
  error?: string;
  onChange: (value: Moment | null) => void;
}) => {
  const dateValue = useMemo(
    () => (value === null ? undefined : moment(value)),
    [value]
  );
  return (
    <DesktopDatePicker
      label={label}
      value={dateValue}
      onChange={onChange}
      inputFormat="YYYY-MM-DD"
      renderInput={(params) => (
        <TextField
          {...params}
          className={styles.inputField}
          data-hj-suppress
          required
          variant="standard"
          error={error !== undefined}
          helperText={error}
          inputProps={{ ...params.inputProps, 'aria-label': label }}
        />
      )}
    />
  );
};

export const ProvisionVoCAccount = () => {
  const { addToast } = useToasts();
  const [formData, setFormData] = useState<AccountProps>(DEFAULT_DATA);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdateField = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target?.id]: event.target.value,
    });
  };

  const handleUpdateDateField = (value: Moment | null, id: string) => {
    const formattedDate =
      value === null ? undefined : value.format('YYYY-MM-DD');
    setFormData({
      ...formData,
      [id]: formattedDate,
    });
  };

  const handleUpdateSelect = (
    event: SelectChangeEvent<string | number>,
    id: string
  ) => {
    setFormData({
      ...formData,
      [id]: event.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await createProvisioningAccount(formData);

      if (
        response?.error_message === undefined &&
        response?.account_id !== undefined &&
        response?.user_id !== undefined
      ) {
        setFormData(DEFAULT_DATA);
        addToast('Provisioning account has been created', {
          appearance: 'success',
        });
      } else {
        addToast('There was an error while creating provisioning account', {
          appearance: 'error',
        });
      }
    } catch (error) {
      addToast(
        'There was an error while attempting the provisioning requests',
        {
          appearance: 'error',
        }
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const createField = ({ label, id }: { label: string; id: AccountPropId }) => {
    return (
      <TextField
        label={label}
        id={id}
        name={id}
        value={formData[id]}
        onChange={handleUpdateField}
        className={styles.inputField}
        required
        data-hj-suppress
        variant="standard"
      />
    );
  };

  const isEndDateAfterStartDate = useMemo(
    () => moment(formData.end_date).isAfter(moment(formData.start_date)),
    [formData.start_date, formData.end_date]
  );

  const isDataReadyForSubmission = useMemo(
    () =>
      Object.values(formData).every(
        (e) => typeof e !== 'string' || e.length > 0
      ) &&
      moment(formData.start_date).isValid() &&
      moment(formData.end_date).isValid() &&
      isEndDateAfterStartDate,
    [formData, isEndDateAfterStartDate]
  );

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <Stack direction="row" flexWrap="wrap" gap={1}>
        {createField({
          label: 'Account admin email',
          id: 'account_admin_email',
        })}
        {createField({
          label: 'Account admin name',
          id: 'account_admin_name',
        })}
        {createField({ label: 'Organization name', id: 'organization_name' })}
        {createField({ label: 'Site url', id: 'site_url' })}
        {createField({ label: 'Company name', id: 'company_name' })}

        <FormControl required className={styles.inputField}>
          <InputLabel id="size-label" htmlFor="size">
            Type
          </InputLabel>
          <Select
            inputProps={{ 'aria-labelledby': 'size-label' }}
            label="Type"
            name="size"
            id="size"
            value={formData.size}
            onChange={(e) => handleUpdateSelect(e, 'size')}
            variant="standard"
            native
          >
            <option value="1-10">MICRO (1-10)</option>
            <option value="11-50">SMALL (11-50)</option>
            <option value="51-100">MEDIUM (51-100)</option>
            <option value="101-250">MEDIUM-LARGE (101-250)</option>
            <option value="251-1000">LARGE (251-1000)</option>
            <option value="1000-5000">ENTERPRISE (1000-5000)</option>
            <option value="5000+">MULTINATIONAL (5000-5000+)</option>
          </Select>
        </FormControl>

        <FormControl required className={styles.inputField}>
          <InputLabel id="location-label" htmlFor="location">
            Location
          </InputLabel>
          <Select
            inputProps={{ 'aria-labelledby': 'location-label' }}
            labelId="location-label"
            label="Location"
            name="location"
            id="location"
            value={formData.location}
            onChange={(e) => handleUpdateSelect(e, 'location')}
            variant="standard"
            native
          >
            {countryChoices.map(({ label, value }) => {
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              );
            })}
          </Select>
        </FormControl>

        <FormControl required className={styles.inputField}>
          <InputLabel id="limit-label" htmlFor="limit">
            Monthly Limit
          </InputLabel>
          <Select
            inputProps={{ 'aria-labelledby': 'limit-label' }}
            labelId="limit-label"
            label="Monthly Limit"
            name="limit"
            id="limit"
            value={formData.limit}
            onChange={(e) => handleUpdateSelect(e, 'limit')}
            variant="standard"
            native
          >
            {LIMIT_CHOICES.map((value) => {
              return (
                <option key={value} value={value}>
                  {value}
                </option>
              );
            })}
          </Select>
        </FormControl>

        <DateField
          value={formData.start_date}
          label="Start date"
          onChange={(value) => handleUpdateDateField(value, 'start_date')}
        />
        <DateField
          value={formData.end_date}
          label="End date"
          onChange={(value) => handleUpdateDateField(value, 'end_date')}
          error={
            isEndDateAfterStartDate ||
            formData.start_date === '' ||
            formData.end_date === ''
              ? undefined
              : 'End date must be after start date'
          }
        />
      </Stack>
      <Button
        variant="contained"
        color="primary"
        id="create"
        className={styles.createButton}
        type="submit"
        disabled={!isDataReadyForSubmission || isSubmitting}
      >
        Create
      </Button>
    </form>
  );
};
