import { useQuery } from '@tanstack/react-query';
import { getEnterpriseIDP } from './getEnterpriseIDP';
import { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { EnterpriseIDP } from '../models/enterpriseSSO.model';

export const useEnterpriseIDP = (accountId: number) => {
  const { addToast } = useToasts();
  const { data, isError, error, refetch } = useQuery<EnterpriseIDP[], Error>({
    queryKey: ['enterpriseIDP'],
    queryFn: () => getEnterpriseIDP(accountId),
    retry: false,
  });

  useEffect(() => {
    if (isError) {
      addToast('There was an error fetching enterprise SSO IDP.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, isError, error]);

  return {
    data,
    refetch,
  };
};
