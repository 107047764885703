export const convertReadableNumber = (amount: number): string => {
  if (amount < 1000) {
    return `${amount}`;
  }

  if (amount < 1000000) {
    return `${amount / 1000}k`;
  }

  return `${amount / 1000000}m`;
};
