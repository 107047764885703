import React from 'react';
import googleIcon from './google.svg';
import styles from './GoogleSignInButton.module.css';

const GoogleSignInButton = (props) => {
  return (
    <a className={styles.googleButton} {...props}>
      <img className={styles.googleButtonIcon} src={googleIcon} alt="Google" />
      <span className={styles.googleButtonText}>Sign In with Google</span>
    </a>
  );
};

export default GoogleSignInButton;
