import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Button,
  Checkbox,
  DialogActions,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import { useToasts } from 'react-toast-notifications';
import styles from './DynamicForm.module.css';

const DynamicForm = ({
  fields,
  successMessage,
  errorMessage,
  onSubmit,
  modalActions,
}) => {
  const [formFields, setFormFields] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [checkRequired, setCheckRequired] = useState(true);
  const { addToast } = useToasts();

  useEffect(() => {
    if (fields) {
      const fields_obj = {};
      fields.forEach((field) => {
        fields_obj[field.id] = field.default_value || '';
      });
      setFormFields(fields_obj);

      // Using a for loop here as there is no way to break a forEach loop
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        if (field.required && fields_obj[field.id].length < 1) {
          setCheckRequired(false);
          break;
        }
      }
    }
  }, [fields]);

  useEffect(() => {
    if (errorMessage) {
      addToast(errorMessage, { appearance: 'error', autoDismiss: true });
    }

    if (successMessage) {
      addToast(successMessage, { appearance: 'success', autoDismiss: true });
    }
  }, [successMessage, errorMessage, addToast]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formFields);
  };

  const validate = (validation_pattern, value) => {
    const validationTest = new RegExp(validation_pattern);
    const isValid = validationTest.test(value);
    return isValid;
  };

  const checkRequiredFields = (newState) => {
    let allFilled = true;
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field.required && newState[field.id].length < 1) {
        allFilled = false;
        break;
      }
    }
    setCheckRequired(allFilled);
  };

  const handleFormUpdate = (event, field) => {
    const newState = { ...formFields };
    const newErrors = { ...validationErrors };
    if (event.target.type === 'checkbox') {
      newState[event.target.name] = event.target.checked;
    } else {
      let isValid = true;
      if (
        field.validation_pattern &&
        field.validation_pattern.length > 0 &&
        event.target.value.length > 0
      ) {
        isValid = validate(field.validation_pattern, event.target.value);
      }
      if (isValid) {
        newErrors[event.target.name] = null;
      } else {
        newErrors[event.target.name] = field.validation_message;
      }
      newState[event.target.name] = event.target.value;
    }
    setValidationErrors(newErrors);
    setFormFields(newState);
    checkRequiredFields(newState);
  };

  const setupFields = (field) => {
    const fieldMap = {
      textarea: TextField,
      text: TextField,
      select: Select,
      checkbox: Checkbox,
    };
    let ComponentToRender = fieldMap[field.type];

    // Base field props
    let componentProps = {
      id: field.id,
      name: field.id,
      value: formFields[field.id] || '',
      onChange: (e) => handleFormUpdate(e, field),
      required: field.required,
      variant: 'standard',
    };

    // Specific field props
    if (field.type !== 'checkbox') componentProps.fullWidth = true;
    else componentProps.checked = formFields[field.id] || false;

    if (field.type === 'textarea') componentProps.multiline = true;

    if (field.type.indexOf('text') > -1) {
      if (validationErrors[field.id]) {
        componentProps.error = true;
        componentProps.helperText = field.validation_message;
      } else {
        componentProps.error = false;
        componentProps.helperText = field.label;
      }
    }

    if (field.type === 'select') {
      componentProps.role = 'select';
      componentProps.native = true;
    }

    return (
      <>
        <InputLabel htmlFor={componentProps.id}>{field.name}</InputLabel>
        <ComponentToRender {...componentProps} data-testid={field.id}>
          {field.type === 'select' &&
            field.options &&
            field.options.map((option, i) => (
              <option value={option.value} key={field.id + '-' + i}>
                {option.label}
              </option>
            ))}
        </ComponentToRender>
      </>
    );
  };

  if (formFields) {
    return (
      <form data-testid="form" onSubmit={handleSubmit} className={styles.form}>
        {fields.map((field) => {
          return (
            <div className={styles.inputGroup} key={field.id}>
              {setupFields(field)}
            </div>
          );
        })}
        <div>
          <DialogActions>
            {modalActions}
            <Button
              disabled={
                !checkRequired ||
                Object.keys(formFields).length === 0 ||
                Object.keys(_.pickBy(validationErrors, _.identity)).length > 0
              }
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </DialogActions>
        </div>
      </form>
    );
  } else {
    return null;
  }
};

export default DynamicForm;
