import React from 'react';
import { CircularProgress, DialogActions, DialogContent } from '@mui/material';
import { DialogTitleWithClose } from './DialogTitleWithClose';

const renderLoadingActions = (isLoading, actions) => {
  return isLoading ? <CircularProgress size={30} /> : actions;
};

export const withLoadingDialog =
  (Component) =>
  ({ isLoading, children, actions, title, ...otherProps }) => {
    return (
      <Component
        scroll="paper"
        fullWidth={true}
        disableEnforceFocus={true}
        {...otherProps}
      >
        <DialogTitleWithClose onClose={otherProps.onClose}>
          {title}
        </DialogTitleWithClose>
        <DialogContent>
          {children}
          {actions && (
            <DialogActions>
              {renderLoadingActions(isLoading, actions)}
            </DialogActions>
          )}
        </DialogContent>
      </Component>
    );
  };
