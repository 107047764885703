import { FC } from 'react';
import SmsIcon from '@mui/icons-material/Sms';
import IconBadge, { IconBadgeProps } from '../IconBadge';
import { itemCountToString } from '../../utils/itemCountToString';

interface ActionsBadgeProps
  extends Pick<Omit<IconBadgeProps, 'badgeText'>, 'handleOnClick'> {
  actionsCount?: number;
}

const ActionsBadge: FC<ActionsBadgeProps> = ({
  actionsCount,
  handleOnClick,
}) => {
  return (
    <IconBadge
      icon={<SmsIcon />}
      tooltip="See Activity"
      handleOnClick={handleOnClick}
      badgeText={itemCountToString(actionsCount)}
    />
  );
};

export default ActionsBadge;
