export const fakeZdData = [
  {
    id: 115001808227,
    locale: 'en-us',
    title: 'Installing Hotjar & Configuring Site Settings',
    url: 'https://hotjar.zendesk.com/api/v2/help_center/en-us/categories/115001808227.json',
    sections: [
      {
        id: 115002608787,
        locale: 'en-us',
        title: 'Installing the Hotjar Tracking Code',
        url: 'https://hotjar.zendesk.com/api/v2/help_center/en-us/sections/115002608787.json',
        category_id: 115001808227,
        articles: [
          {
            id: 115009336727,
            title: 'How to Install Your Hotjar Tracking Code',
            url: 'https://help.hotjar.com/hc/en-us/articles/115009336727-How-to-Install-Your-Hotjar-Tracking-Code',
            section_id: 115002608787,
          },
          {
            id: 360016303294,
            title: 'How to Check That Hotjar Is Working',
            url: 'https://hotjar.zendesk.com/api/v2/help_center/en-us/articles/360016303294.json',
            section_id: 115002608787,
          },
          {
            id: 360011761953,
            title: 'Troubleshooting FAQs for Tracking Code Installation',
            url: 'https://hotjar.zendesk.com/api/v2/help_center/en-us/articles/360011761953.json',
            section_id: 115002608787,
          },
        ],
      },
      {
        id: 12088743574167,
        locale: 'en-us',
        title: 'Platform-Specific Installations',
        url: 'https://hotjar.zendesk.com/api/v2/help_center/en-us/sections/12088743574167.json',
        category_id: 115001808227,
        articles: [
          {
            id: 115009502848,
            title: 'Shopify',
            url: 'https://hotjar.zendesk.com/api/v2/help_center/en-us/articles/115009502848.json',
            section_id: 12088743574167,
          },
          {
            id: 115009499708,
            title: 'Install the Tracking Code with Google Tag Manager',
            url: 'https://hotjar.zendesk.com/api/v2/help_center/en-us/articles/115009499708.json',
            section_id: 12088743574167,
          },
        ],
      },
    ],
  },
  {
    id: 360001640813,
    locale: 'en-us',
    title: 'Plans, Payments, and Invoices',
    url: 'https://hotjar.zendesk.com/api/v2/help_center/en-us/categories/360001640813.json',
    sections: [
      {
        id: 360003795554,
        locale: 'en-us',
        title: 'Plans, Sessions, and Responses',
        url: 'https://hotjar.zendesk.com/api/v2/help_center/en-us/sections/360003795554.json',
        category_id: 360001640813,
        articles: [
          {
            id: 360001389973,
            title: 'Hotjar Plans',
            url: 'https://hotjar.zendesk.com/api/v2/help_center/en-us/articles/360001389973.json',
            section_id: 360003795554,
          },
          {
            id: 115009346847,
            title: 'How to Upgrade or Downgrade Your Hotjar Plans',
            url: 'https://hotjar.zendesk.com/api/v2/help_center/en-us/articles/115009346847.json',
            section_id: 360003795554,
          },
        ],
      },
    ],
  },
];

export const fakeLearningData = {
  courses: [
    {
      title: 'How to track NPS® with Hotjar',
      url: 'https://learning.hotjar.com/how-to-track-nps-with-hotjar',
      subtitle: '5 minute course',
    },
    {
      title: 'What makes an effective CTA?',
      url: 'https://learning.hotjar.com/what-makes-an-effective-cta',
      subtitle: '5 minute course',
    },
    {
      title: 'How to achieve your goals with Hotjar',
      url: 'https://learning.hotjar.com/how-to-achieve-your-goals-with-hotjar',
      subtitle: '30 minute course',
    },
  ],
  videos: [
    {
      title: 'Installing the Hotjar tracking code',
      url: 'https://learning.hotjar.com/installing-the-hotjar-tracking-code',
      subtitle: 'How To',
    },
    {
      title: 'How to reply to Feedback responses',
      url: 'https://learning.hotjar.com/how-to-reply-to-feedback-responses',
      subtitle: 'How To',
    },
  ],
  learningPaths: [
    {
      title: 'New to Hotjar?',
      url: 'https://learning.hotjar.com/path/getting-started-path',
    },
    {
      title: 'How are users feeling?',
      url: 'https://learning.hotjar.com/path/how-are-users-feeling',
    },
  ],
  webinars: [
    {
      title: 'Building systems for UX improvements',
      url: 'https://learning.hotjar.com/building-systems-for-ux-improvements-webinar',
      subtitle: 'Webinar',
    },
    {
      title: 'Dig deep into user frustration signals',
      url: 'https://learning.hotjar.com/dig-deep-into-user-frustration-signals',
      subtitle: 'Webinar',
    },
  ],
  workbooks: [
    {
      title: 'Building systems for UX improvements',
      url: 'https://learning.hotjar.com/building-systems-for-ux-improvements',
      subtitle: 'UX improvement guide',
    },
  ],
};

export const fakeTemplateData = [
  {
    name: 'first test template',
    resources: [
      'https://help.hotjar.com/hc/en-us/articles/115009336727-How-to-Install-Your-Hotjar-Tracking-Code',
      'https://learning.hotjar.com/how-to-track-nps-with-hotjar',
      'https://learning.hotjar.com/what-makes-an-effective-cta',
    ],
  },
  {
    name: 'second test template',
    resources: [
      'https://help.hotjar.com/hc/en-us/articles/115009336727-How-to-Install-Your-Hotjar-Tracking-Code',
    ],
  },
];
