import React from 'react';
import InvoiceReport from './InvoiceReport';
import styles from './Invoices.module.css';
const Invoices = () => {
  const DeferredRevenueReport = (
    <InvoiceReport
      report_title="Deferred Revenue Report"
      filename_prefix="deferred-revenue-"
      api_args={{
        content_type: 'text/csv',
        report_type: 'drr',
      }}
    />
  );
  const RecapitulativeDeclarationReport = (
    <InvoiceReport
      report_title="Recapitulative Declaration Report"
      filename_prefix="recapitulative-declaration-"
      api_args={{
        content_type: 'text/csv',
        report_type: 'rdr',
      }}
    />
  );
  const AllInvoicesReport = (
    <InvoiceReport
      report_title="All Invoices Report"
      filename_prefix="all-invoices-"
      api_args={{
        content_type: 'text/csv',
        report_type: 'all',
      }}
      filters={['location', 'has_vat_number']}
    />
  );
  const XeroReport = (
    <InvoiceReport
      report_title="Xero Report"
      filename_prefix="xero-report-"
      api_args={{
        content_type: 'text/csv',
        report_type: 'xero',
      }}
      filters={['status', 'payment_method_type']}
    />
  );
  return (
    <div className={styles.container}>
      <h2>Invoice Reports</h2>
      {DeferredRevenueReport}
      {RecapitulativeDeclarationReport}
      {AllInvoicesReport}
      {XeroReport}
    </div>
  );
};
export default Invoices;
