export const convertReadableNumber = (amount: number): string => {
  const userLocale = navigator.language || 'en-US';
  const { format } = new Intl.NumberFormat(userLocale, {
    maximumFractionDigits: 1,
  });

  if (amount < 2000) {
    return `${format(amount)}`;
  }

  if (amount < 1000000) {
    return `${format(amount / 1000)}k`;
  }

  return `${format(amount / 1000000)}m`;
};
