import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { TrialsResponse } from '../models/TrialsResponse';

interface CancelTrialProps {
  bundleId?: string;
  trialId?: string;
  errorMessage: string;
  accountId: number;
}

const BASE_URL = '/api/v2/adm/trials/trials';

export const cancelBundleTrial = async ({
  bundleId,
  errorMessage,
  accountId,
}: CancelTrialProps) => {
  const response = await fetch(`${BASE_URL}/bundle/${bundleId}/end`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      hotjar_account_id: accountId,
    }),
  });

  return handleFetchApiResponse<TrialsResponse[]>(response, errorMessage);
};

export const cancelSingleTrial = async ({
  trialId,
  errorMessage,
  accountId,
}: CancelTrialProps) => {
  const response = await fetch(`${BASE_URL}/${trialId}/end`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      hotjar_account_id: accountId,
    }),
  });

  return handleFetchApiResponse<TrialsResponse[]>(response, errorMessage);
};
