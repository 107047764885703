import { Currency } from '../../../types/types';
import { PlanName } from '../../../typings';

export const COMPOUND_DISCOUNT = 'compound_discount';
export type SKU = `SKU-${number}` | typeof COMPOUND_DISCOUNT;

export type MagpieProductName = 'Ask' | 'Observe';

export enum MagpieApplicationScope {
  ProductScope = 'PRODUCT_SCOPE',
  SubscriptionScope = 'SUBSCRIPTION_SCOPE',
}

export enum MagpieApplicationType {
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING',
  TimeLimited = 'TIME_LIMITED',
}

export enum EntityType {
  Site = 'site',
  Account = 'account',
}

export interface MagpieComponentsService {
  identifier: string;
}

export interface MagpieEntity {
  entity_identifier: string;
  entity_type: EntityType;
  entity_label: string;
}

export interface MagpieComponentsDeduction {
  identifier: string;
  application_type: MagpieApplicationType;
  application_scope: MagpieApplicationScope;
  amount?: number;
  percentage?: number;
}

export interface MagpieComponentsTimedDeduction
  extends MagpieComponentsDeduction {
  start_date?: string;
  end_date?: string;
}

export interface MagpiePurchasedProductCost {
  sku: SKU;
  entity: MagpieEntity;
  amount_without_tax: number;
  amount_with_tax: number;
  tax_amount: number;
  tax_rate: number;
  component_costs: MagpieCurrentPeriodCostSummary[];
}

export interface MagpieCurrentPeriodCostSummary {
  amount_with_tax: number;
  amount_without_tax: number;
  tax_amount: number;
  tax_rate: number;
  purchased_product_costs?: MagpiePurchasedProductCost[];
  component_identifier?: string;
}

export interface MagpieCustomer {
  identifier: number;
  hotjar_account_id: number;
  created_at: string;
  company_name: string;
  billing_address: MagpieBillingAddress;
  additional_email_addresses: string[];
  tax_number: string;
  currency: Currency;
  external_customer: MagpieExternalCustomer;
  subscription: MagpieSubscription;
  current_period_cost_summary: MagpieCurrentPeriodCostSummary;
  invoice_notes: string | null;
  purchase_order_number: string | null;
  payment_term: string;
  payment_method: MagpiePaymentMethod | undefined;
}

export interface MagpieBillingAddress {
  address_line1: string;
  address_line2: string;
  city: string;
  zip_code: string;
  country: string;
  state: string | null;
  county: string | null;
  first_name: string;
  last_name: string;
  email_address: string;
  unstructured_address: null;
}

export interface MagpieExternalCustomer {
  external_id: string;
  payment_method_external_id: string;
  external_url: string;
  provider: string;
}

// FROM https://gitlab.com/hotjar/billing/magpie/-/blob/master/magpie/domain/models/common.py#L15
export type MagpiePaymentMethodType =
  | 'CREDIT_CARD'
  | 'PAYPAL'
  | 'HOTJAR_BRAINTREE_PAYMENT_METHOD'
  | 'EXTERNAL_PAYMENT_METHOD'
  // These last two ones come from https://gitlab.com/hotjar/billing/magpie/-/blob/master/magpie/domain/models/common.py#L32
  // not clear if they are used at all
  | 'ELECTRONIC'
  | 'EXTERNAL';

export interface MagpiePaymentMethod {
  payment_method_type: MagpiePaymentMethodType;
  details: Details;
}

export interface Details {}

export type MagpieBillingPeriod =
  | 'ANNUALLY'
  | 'MONTHLY'
  | 'THREE_MONTHS'
  | 'SIX_MONTHS'
  | 'TWO_YEARS'
  | 'THREE_YEARS';

export type MagpieSubscriptionStatus = 'ACTIVE' | 'CANCELLED' | 'OVERDUE';

export interface MagpieSubscription {
  created: string;
  start_date: string;
  current_period_start_date: string;
  current_period_end_date: string;
  billing_period: MagpieBillingPeriod;
  status: MagpieSubscriptionStatus;
  products: MagpieSubscriptionProduct[];
}

export interface MagpieSubscriptionProduct {
  sku: SKU;
  components_service: MagpieComponentsService[];
  components_fixed_volume: any[];
  components_selectable_volume: any[];
  components_percentage_deduction: MagpieComponentsDeduction[];
  components_monetary_deduction: MagpieComponentsDeduction[];
  components_timed_percentage_deduction?: MagpieComponentsTimedDeduction[];
  components_timed_monetary_deduction?: MagpieComponentsTimedDeduction[];
  entity: MagpieEntity;
}

export interface MagpieComponentsService {
  identifier: string;
}

interface MagpieAllowanceBase {
  sku: `SKU-${number}`;
  self_serve_enabled: boolean;
  chargeable: boolean;
  price: number;
}

interface MagpieAskAllowance extends MagpieAllowanceBase {
  monthly_responses: number;
}

interface MagpieObserveAllowance extends MagpieAllowanceBase {
  daily_cc_sessions: number;
}

export type MagpieAllowance = MagpieAskAllowance | MagpieObserveAllowance;

interface MagpiePlan {
  plan_id: `PLAN-${number}`;
  plan_name: PlanName;
  allowances: MagpieAllowance[];
}

export interface MagpieProduct {
  product_id: `PROD-${number}`;
  product_name: MagpieProductName;
  plans: MagpiePlan[];
}
