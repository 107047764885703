import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, KeyboardEvent, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useToasts } from 'react-toast-notifications';
import { setProvisionCsAccount } from './api';
import type { ProvisioningProps } from './api';
import { AdmModalProps } from '../../../../components/modals/AdmModalRoot';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';

interface Props {
  accountId: number;
}

type ProvisionError = {
  error?: string;
};

const StyledForm = styled.form`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const LIMIT_OPTIONS = [
  '500',
  '1000',
  '2500',
  '5000',
  '7500',
  '10000',
  '25000',
  '50000',
  '100000',
  '250000',
  '500000',
  '750000',
  '1000000',
];

const DEFAULT_DATA = {
  organization_name: '',
  site_name: '',
  site_url: '',
  limit: LIMIT_OPTIONS[0],
  start_date: '',
  end_date: '',
};

type ProvisionCsAccountModalProps = AdmModalProps<Props>;

const DateField = ({
  value,
  label,
  onChange,
}: {
  value: string | null;
  label: string;
  onChange: (value: Moment | null) => void;
}) => {
  const dateValue = useMemo(
    () => (value === null ? undefined : moment(value)),
    [value]
  );
  return (
    <DesktopDatePicker
      label={label}
      value={dateValue}
      onChange={onChange}
      inputFormat="YYYY-MM-DD"
      renderInput={(params) => (
        <TextField
          {...params}
          data-hj-suppress
          required
          variant="standard"
          inputProps={{ ...params.inputProps, 'aria-label': label }}
        />
      )}
    />
  );
};

export const ProvisionCsAccountModal = ({
  props: { accountId },
  onCloseModal,
}: ProvisionCsAccountModalProps) => {
  const { addToast } = useToasts();

  const [formData, setFormData] = useState<ProvisioningProps>(DEFAULT_DATA);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const stopPropagationForTab = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await setProvisionCsAccount(accountId, formData);

      if (response?.success === true) {
        setFormData(DEFAULT_DATA);
        addToast('Provisioning CS account done', {
          appearance: 'success',
        });
      } else {
        addToast('There was an error while provisioning account', {
          appearance: 'error',
        });
      }
    } catch (error) {
      const errorText = (error as ProvisionError)?.error;

      addToast(
        errorText ||
          'There was an error while attempting the provisioning requests',
        { appearance: 'error' }
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateField = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target?.id]: event.target.value,
    });
  };

  const isEndDateAfterStartDate = useMemo(
    () =>
      formData.end_date !== null &&
      formData.start_date !== null &&
      moment(formData.end_date).isAfter(moment(formData.start_date)),
    [formData.start_date, formData.end_date]
  );

  const isDataReadyForSubmission = useMemo(() => {
    if (formData.start_date !== null && formData.end_date !== null) {
      return (
        Object.values(formData).every((e) => e !== null && e.length > 0) &&
        moment(formData.start_date).isValid() &&
        moment(formData.end_date).isValid() &&
        isEndDateAfterStartDate
      );
    }
    return false;
  }, [formData, isEndDateAfterStartDate]);

  const setLimit = (event: SelectChangeEvent) => {
    setFormData({ ...formData, limit: event.target.value });
  };

  const onDateChange = (value: Moment | null, field: string) => {
    setFormData({ ...formData, [field]: value?.format('YYYY-MM-DD') });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open
      onClose={onCloseModal}
      onKeyDown={stopPropagationForTab}
    >
      <DialogTitle>Provision CS Account</DialogTitle>
      <DialogContent>
        <Typography>
          Account ID: <span data-hj-suppress>{accountId}</span>
        </Typography>

        <StyledForm onSubmit={handleSubmit}>
          <TextField
            autoFocus
            required
            fullWidth
            type="text"
            id="organization_name"
            label="Organization name"
            disabled={isSubmitting}
            value={formData.organization_name}
            onChange={handleUpdateField}
            variant="standard"
          />
          <TextField
            required
            fullWidth
            type="text"
            id="site_name"
            label="Site name"
            disabled={isSubmitting}
            value={formData.site_name}
            onChange={handleUpdateField}
            variant="standard"
          />
          <TextField
            required
            fullWidth
            type="text"
            id="site_url"
            label="Site URL"
            disabled={isSubmitting}
            value={formData.site_url}
            onChange={handleUpdateField}
            variant="standard"
          />
          <DateField
            value={formData.start_date}
            label="Start date"
            onChange={(value: Moment | null) =>
              onDateChange(value, 'start_date')
            }
          />
          <DateField
            value={formData.end_date}
            label="End date"
            onChange={(value: Moment | null) => onDateChange(value, 'end_date')}
          />

          <FormControl required sx={{ display: 'block' }}>
            <InputLabel id="limit-label" htmlFor="limit">
              Monthly Limit
            </InputLabel>
            <Select
              inputProps={{ 'aria-labelledby': 'limit-label' }}
              labelId="limit-label"
              label="Monthly Limit"
              name="limit"
              id="limit"
              value={formData.limit}
              onChange={setLimit}
              variant="standard"
              native
            >
              {LIMIT_OPTIONS.map((e) => (
                <option value={e} key={e}>
                  {e}
                </option>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            disabled={!isDataReadyForSubmission || isSubmitting}
            type="submit"
            sx={{ display: 'block' }}
          >
            Create
          </Button>
        </StyledForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
