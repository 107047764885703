import { EntityDetailsColumnsConfig } from '../../../../components/EntityDetails/interfaces';

interface GetAccountDataParams {
  accountId: number;
  userName: string;
  userId: number;
}

export const getAccountData = ({
  accountId,
  userName,
  userId,
}: GetAccountDataParams): EntityDetailsColumnsConfig => [
  [
    {
      name: 'Account ID',
      value: accountId.toString(),
      url: `/lookup/account_id/${accountId}`,
    },
    { name: 'Account owner', value: userName },
  ],
  [
    undefined,
    {
      name: 'User ID',
      value: userId.toString(),
      url: `/lookup/user_id/${userId}`,
    },
  ],
];
