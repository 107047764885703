import { FC, PropsWithChildren } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface SidebarAccordionProps {
  title: string | JSX.Element;
  accordionName: string;
  isLoading?: boolean;
}

const SidebarAccordion: FC<PropsWithChildren<SidebarAccordionProps>> = ({
  title,
  children,
  isLoading,
  accordionName,
}) => (
  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`${accordionName}-content`}
      id={`${accordionName}-header`}
      disabled={isLoading}
    >
      {typeof title === 'string' ? (
        <Typography variant="h2" sx={{ fontSize: '14px', fontWeight: '500' }}>
          {isLoading ? `${title} (Loading...)` : title}
        </Typography>
      ) : (
        title
      )}
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);

export default SidebarAccordion;
