import React, { Component } from 'react';

import Linkify from 'react-linkify';
import MUIDataTable from 'mui-datatables';
import Tooltip from '@mui/material/Tooltip';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import AdmActionToolbar from './AdmActionToolbar';
import ChangesTable from './ChangesTable';
import { linkifyOpenInNew } from '../../utils/linkifyDecorators';
import { postAdmAction } from './api';
import { snakeToTitle } from '../../utils/strings';
import { toUTCDateString, toUTCTimeString } from '../../utils/dates';

class AdmActionTable extends Component {
  getRelatedObjectData = (action) => {
    let type, id;
    if (action.site_id) {
      type = 'site';
      id = action.site_id;
    } else if (action.adm_user_id) {
      type = 'adm_user';
      id = action.adm_user_id;
    } else if (action.account_id) {
      type = 'account';
      id = action.account_id;
    } else if (action.organization_id) {
      type = 'organization';
      id = action.organization_id;
    } else if (action.invoice_id) {
      type = 'invoice';
      id = action.invoice_id;
    } else if (action.invoice_id) {
      type = 'invoice';
      id = action.account_id;
    } else if (action.user_id) {
      type = 'user';
      id = action.user_id;
    }
    return {
      type,
      id,
    };
  };

  serializeAction = (action) => {
    const relatedObject = this.getRelatedObjectData(action);

    // For compatibility reasons
    // there is a breaking change in API V3
    // - created field has been renamed created_time
    // - updated field has been renamed updated_time
    // Also the date is now an ISO string
    const createdDate = action.created
      ? action.created * 1000
      : action.created_time;

    return [
      <Link to={`/lookup/${relatedObject.type}_id/${relatedObject.id}`}>
        {snakeToTitle(relatedObject.type)}
      </Link>,
      _.startCase(action.action),
      action.annotation,
      <Link to={`/lookup/user_id/${action.created_by_id}`}>
        {action.created_by_name}
      </Link>,
      action.related_meta ? action.related_meta.changes : [],
      new Date(createdDate),
    ];
  };

  getRowData = () =>
    this.props.data.map((action) => this.serializeAction(action));

  state = {
    data: this.getRowData(),
    onlyToolbarWhenEmpty: this.props.onlyToolbarWhenEmpty,
    newActionId: null,
  };

  handleNewAction = (annotation) => {
    return postAdmAction({
      annotation: annotation,
      related_type: this.props.relatedType.toLowerCase(),
      related_id: this.props.relatedId,
    }).then((newAction) => {
      let newData = [...this.state.data, this.serializeAction(newAction)];
      this.setState({ data: newData, newActionId: newAction.id });

      // call newActionCallback with new action if it is defined
      this.props.newActionCallback && this.props.newActionCallback(newAction);
    });
  };

  getColumnSettings = () => [
    { name: 'Related Object' },
    { name: 'Action' },
    {
      name: 'Note',
      options: {
        customBodyRender: (note) => (
          <Linkify componentDecorator={linkifyOpenInNew}>{note}</Linkify>
        ),
      },
    },
    { name: 'User' },
    {
      name: 'Changes',
      options: {
        customBodyRender: (changes) => (
          <ChangesTable data={changes}></ChangesTable>
        ),
      },
    },
    {
      name: 'Date',
      options: {
        customBodyRender: (date) => (
          <Tooltip title={toUTCTimeString(date)} placement="left">
            <div>{toUTCDateString(date)}</div>
          </Tooltip>
        ),
      },
    },
  ];

  getTableSettings = () => ({
    filter: false,
    rowHover: false,
    responsive: 'standard',
    search: false,
    sort: false,
    selectableRows: 'none',
    pagination: false,
    print: false,
    download: false,
    viewColumns: false,
    elevation: 0,
    textLabels: {
      body: {
        noMatch: 'No activity for this ' + this.props.relatedType + '.',
      },
    },
    customToolbar: () => {
      if (this.props.showAddButton) {
        return (
          <AdmActionToolbar
            handleNewAction={this.handleNewAction}
            inTable={true}
          />
        );
      }
    },
    setRowProps: (row) => {
      let rowClasses = '';
      if (row[0] === this.state.newActionId) {
        rowClasses += 'newRow';
      }
      return {
        className: rowClasses,
      };
    },
  });

  render() {
    if (this.state.data.length === 0 && this.state.onlyToolbarWhenEmpty) {
      return (
        <AdmActionToolbar
          handleNewAction={this.handleNewAction}
          inTable={false}
        />
      );
    } else {
      return (
        <MUIDataTable
          title={this.props.title || 'Activity'}
          data={this.state.data}
          columns={this.getColumnSettings()}
          options={this.getTableSettings()}
        />
      );
    }
  }
}

AdmActionTable.defaultProps = {
  showAddButton: true,
};

export default AdmActionTable;
