import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

interface Props {
  startDate: `${number}-${number}-${number}`;
  handleUpdateField: () => void;
}
export function StartDate({ handleUpdateField, startDate }: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label="Subscription start date (must be in the past)"
        inputFormat="YYYY-MM-DD"
        onChange={handleUpdateField}
        value={startDate}
        renderInput={(params) => (
          <TextField
            variant="standard"
            {...params}
            sx={{ '&': { width: '300px' } }}
          />
        )}
        disableFuture
      />
    </LocalizationProvider>
  );
}
