import capitalize from 'lodash/capitalize';
import { ProductName } from '../../../products/models/product.model';
import {
  Allowance,
  Product,
  ServicePeriod,
  ServicePeriods,
} from '../../../services/models/service.model';
import { DefaultProductServices } from '../../../accounts/models/account.pricingTable.model';

const formatAllowance = ({ key, value }: Allowance) =>
  `${capitalize(key.replace(/_/g, ' '))} ${value}`;

const formatServicePeriod = ({
  product: { allowance, plan },
  source,
  start,
  end,
  created,
  disabled,
}: ServicePeriod) => {
  return {
    plan: plan,
    source: source[0],
    allowances: formatAllowance(allowance),
    startDate: start,
    endDate: end,
    createdDate: created,
    disabled,
  };
};

export const formatServicesData = (serviceManagementPeriods: ServicePeriods) =>
  Object.keys(serviceManagementPeriods).map((productName) => {
    const product =
      productName === 'feedback' ? ProductName.Ask : ProductName.Observe;
    const services = serviceManagementPeriods[productName as Product].map(
      (service) => formatServicePeriod(service)
    );

    return {
      product,
      services,
    };
  });

export const formatDefaultServices = (
  defaultProductServices: DefaultProductServices
) => {
  return Object.keys(defaultProductServices).map((product) => {
    const { plan, allowance_key, allowance_value } =
      defaultProductServices[product as ProductName];

    const allowances = formatAllowance({
      key: allowance_key,
      value: allowance_value,
    });

    return {
      product,
      services: [
        {
          plan,
          source: 'default',
          allowances,
        },
      ],
    };
  });
};
