import React, { Component } from 'react';
import Paper from '@mui/material/Paper';
import SearchForm from '../../components/SearchForm';
import DomainSearchModal from '../../components/modals/DomainSearch';
import { Email, PositiveInteger } from '../../utils/validators';
import t from 'tcomb-validation';
import { SEARCH_FIELDS } from './constants';
import styles from './Lookup.module.css';
import { withModalContext } from '../../contexts/ModalContext';
import { withLookupContext } from '../../contexts/LookupContext';
import { MODAL_DOMAIN_SEARCH } from '../../components/modals/constants';

export class SearchBox extends Component {
  state = {
    url: '',
  };

  types = {
    email: {
      validator: Email,
      cast: (s) => {
        return s.trim();
      },
    },
    account_id: { validator: PositiveInteger, cast: Number },
    organization_id: { validator: PositiveInteger, cast: Number },
    site_id: { validator: PositiveInteger, cast: Number },
    invoice_id: { validator: PositiveInteger, cast: Number },
    user_id: { validator: PositiveInteger, cast: Number },
    bt_customer_id: { validator: PositiveInteger, cast: Number },
  };

  sanitize = (search_by, search_term) => {
    if (this.types[search_by] && this.types[search_by].cast) {
      // We don't want NaN passed to the validator,
      // so if this is returned we instead pass the original value
      return Number.isNaN(this.types[search_by].cast(search_term))
        ? search_term
        : this.types[search_by].cast(search_term);
    } else {
      return search_term;
    }
  };

  validate = (search_by, search_term) => {
    if (this.types[search_by]) {
      let value = this.sanitize(search_by, search_term),
        status = t.validate(value, this.types[search_by].validator);

      if (status.isValid()) {
        return true;
      } else {
        this.setError(status.firstError().message);
        return false;
      }
    } else {
      return true;
    }
  };

  setError = (error) => {
    this.props.lookupContext.setError(error);
  };

  render() {
    return (
      <Paper className={styles.searchBox} elevation={1}>
        <SearchForm
          onFormUpdate={this.handleFormUpdate}
          onFormSubmit={this.handleSubmit}
          setError={this.setError}
          search_by={this.props.lookupContext.searchBox.search_by}
          search_term={this.props.lookupContext.searchBox.search_term}
          error_message={this.props.lookupContext.error_message}
          search_fields={SEARCH_FIELDS}
        />
        <DomainSearchModal />
      </Paper>
    );
  }

  handleFormUpdate = (data) => {
    this.props.lookupContext.setSearch(data);
  };

  handleSubmit = () => {
    const { search_by: searchBy, search_term: searchTerm } =
      this.props.lookupContext.searchBox;
    if (
      searchBy === 'email' ||
      searchBy === 'email_contains' ||
      searchBy === 'url_contains'
    ) {
      this.props.modalContext.openModal(MODAL_DOMAIN_SEARCH, {
        searchBy,
        searchTerm,
      });
      return;
    }
    let valid = this.validate(searchBy, searchTerm);
    if (valid) {
      this.setState({
        url: searchBy + '/' + searchTerm,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.url && prevState.url !== this.state.url) {
      this.props.history.push(`/lookup/${this.state.url}`);
    }
  }
}

export default withLookupContext(withModalContext(SearchBox));
