import { Component } from 'react';

import SamplingVisualizer from './SamplingVisualizer';

class SamplingVizWrapper extends Component {
  render() {
    const { props: modalProps, onCloseModal } = this.props;

    const id = (modalProps && modalProps.id) || null;
    const name = (modalProps && modalProps.name) || null;

    return (
      <SamplingVisualizer
        modalOpen
        onClose={onCloseModal}
        id={id}
        name={name}
      />
    );
  }
}

export default SamplingVizWrapper;
