import { MenuItem } from '@mui/material';
import { useModalContext } from '../../../../contexts/ModalContext';
import { MODAL_PROVISION_CS_ACCOUNT } from '../../../../components/modals/constants';

interface Props {
  accountId: number;
}

export const ProvisionCsAccount = ({ accountId }: Props) => {
  const modalContext = useModalContext();
  return (
    <>
      <MenuItem
        onClick={() => {
          modalContext.openModal(MODAL_PROVISION_CS_ACCOUNT, { accountId });
        }}
      >
        Provision new CS project
      </MenuItem>
    </>
  );
};
