import React, { useContext } from 'react';
import { apiGet } from '../utils/api';
import ShowSpinner from '../components/ShowSpinner';
import styles from './UserContext.module.css';

const UserContext = React.createContext();

class UserProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      email: null,
      id: null,
      name: null,
      roles: [],
      roleNames: [],
      error: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    // In tests we pass in a props.value, so in those cases we don't want to fetch data from the API.
    if (this.props.value === undefined) {
      this.fetchUserInfo();
    }
  }

  fetchUserInfo() {
    apiGet('/adm_users/me', {}, 2, {}, true, '', true)
      .then((data) =>
        this.setState({
          ...this.state,
          email: data.email,
          id: data.id,
          name: data.name,
          insights_user: data.insights_user,
          roles: data.roles,
          roleNames: data.roles.map((r) => r.name),
          isLoading: false,
        })
      )
      .catch((e) => {
        this.setState({
          error: 'Error fetching user info. Please logout and login again.',
          isLoading: false,
        });
      });
  }

  render() {
    // Only render when there's an error, we're loading user data, or we haven't passed in a value prop in a test.
    if (
      (this.state.error !== null || this.state.isLoading) &&
      this.props.value === undefined
    )
      return (
        <div className={styles.centered}>
          {this.state.isLoading ? <ShowSpinner /> : null}
          {this.state.error !== null ? (
            <div className={styles.error}>{this.state.error}</div>
          ) : null}
        </div>
      );

    // this.props is used to display children and overwrite value (if desired) in tests.
    return <UserContext.Provider value={{ ...this.state }} {...this.props} />;
  }
}

const UserConsumer = ({ children }) => {
  return (
    <UserContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error('UserContext must be used within a UserProvider.');
        }
        return children(context);
      }}
    </UserContext.Consumer>
  );
};

const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined)
    throw new Error('UserContext must be used within a UserProvider.');

  return context;
};

const withUserContext = (Component) => {
  return (props) => (
    <UserContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error('UserConsumer must be used within a UserProvider.');
        }
        return <Component {...props} userContext={context} />;
      }}
    </UserContext.Consumer>
  );
};

export {
  UserProvider,
  UserConsumer,
  UserContext,
  useUserContext,
  withUserContext,
};
