import React, { Component } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './Lookup.module.css';
import { fetchMagpieAccountExecutedCommandsURL } from '../../domains/magpie/api/urls';
import { snakeToTitleCase } from '../../utils/text';

class DisplayAccountExecutedCommands extends Component {
  state = {
    accountCommands: undefined,
    isLoadingAccountCommands: false,
  };

  handleExpand = (event, expanded) => {
    if (expanded && this.state.accountCommands === undefined) {
      this.setState((state) => ({ ...state, isLoadingAccountCommands: true }));
      fetch(
        fetchMagpieAccountExecutedCommandsURL(this.props.hotjarAccountId)
      ).then((response) =>
        response.json().then((response) => {
          this.setState({ accountCommands: response });
          this.setState((state) => ({
            ...state,
            isLoadingAccountCommands: false,
          }));
        })
      );
    }
  };

  render() {
    return (
      <>
        <Accordion
          onChange={this.handleExpand}
          className={styles.executedCommands}
        >
          <AccordionSummary
            className={styles.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="h5">Account Executed Commands</Typography>
            <Typography variant="body1">
              Summary of all activities on the account
            </Typography>
          </AccordionSummary>
          {this.state.isLoadingAccountCommands ? (
            <Paper className={styles.spinnerWrapper}>
              <CircularProgress />
            </Paper>
          ) : !this.state.accountCommands ||
            this.state.accountCommands.length < 1 ? (
            <Typography ml={'1rem'} variant="body1" gutterBottom>
              No executed commands were found for this account.
            </Typography>
          ) : (
            <AccordionDetails>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Command ID</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Command name</TableCell>
                    <TableCell align="right">Created at</TableCell>
                    <TableCell align="right">Last modified at</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.accountCommands.map((command) => (
                    <TableRow
                      key={command.identifier}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {command.identifier}
                      </TableCell>
                      <TableCell align="right">{command.status}</TableCell>
                      <TableCell align="right">
                        {snakeToTitleCase(command.command_name)}
                      </TableCell>
                      <TableCell align="right">
                        {new Date(command.created_at).toUTCString()}
                      </TableCell>
                      <TableCell align="right">
                        {new Date(command.last_modified_at).toUTCString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          )}
        </Accordion>
      </>
    );
  }
}

export default DisplayAccountExecutedCommands;
