import React, { useState } from 'react';

function ResourceListHeader({ children, isBordered = false, searchTerm }) {
  const [isClosed, setIsClosed] = useState(false);

  return (
    <div
      className={`listHead ${isClosed ? 'closed' : ''} ${
        isBordered ? 'bordered' : ''
      }`}
      onClick={() => {
        setIsClosed(!isClosed);
      }}
    >
      {children}
      <button>{isClosed && !searchTerm ? '➕' : '➖'}</button>
    </div>
  );
}

export default ResourceListHeader;
