import React from 'react';
import styles from './AdmActionTable.module.css';

const ChangesTable = ({ data }) => {
  if (data && data.length > 0) {
    return (
      <table className={styles.changesTable}>
        <thead>
          <tr>
            <th>Field</th>
            <th>Previous Value</th>
            <th>New Value</th>
          </tr>
        </thead>
        <tbody>
          {data.map(function (change, index) {
            return (
              <tr key={index}>
                <td>{change.field}</td>
                <td>{change.initial_state}</td>
                <td>{change.new_state}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  } else {
    return 'No change data available';
  }
};

export default ChangesTable;
