import { Component } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
} from '@mui/material';

class FeatureManagerDialog extends Component {
  state = {
    errors: {
      server_message: null,
    },
    loading: false,
    feature: '',
    action: 'enable_feature',
  };

  handleInputChange = (event) => {
    let fieldName = event.target.name;
    this.setState({
      [fieldName]: event.target.value,
    });
  };

  handleSubmit = (e) => {
    const { props: modalProps, onCloseModal } = this.props;
    e.preventDefault();
    if (this.state.loading) return;
    this.setState({
      ...this.state,
      loading: true,
    });
    modalProps
      .onSubmit(this.state.feature, this.state.action)
      .then(() => {
        this.setState(
          {
            errors: {
              server_message: null,
            },
            loading: false,
          },
          onCloseModal
        );
        modalProps.onSuccess();
      })
      .catch((e) => {
        this.setState({
          ...this.state,
          errors: {
            server_message: e.error_message || e.error,
          },
          loading: false,
        });
      });
  };

  render() {
    const { props: modalProps, onCloseModal } = this.props;
    if (!modalProps) {
      return null;
    }

    return (
      <div>
        <Dialog
          open
          disableEnforceFocus={true}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{modalProps.title}</DialogTitle>
          <form onSubmit={this.handleSubmit}>
            <DialogContent>
              <DialogContentText>{modalProps.modalWarning}</DialogContentText>
              <div style={{ marginBottom: '10px' }}>
                <Select
                  native
                  value={this.state.feature}
                  onChange={this.handleInputChange}
                  inputProps={{
                    name: 'feature',
                    id: 'feature',
                  }}
                >
                  <option value="" />
                  {modalProps.features &&
                    modalProps.features.map((feature, i) => (
                      <option value={feature.name} key={i}>
                        {feature.label}
                      </option>
                    ))}
                </Select>
              </div>
              <div>
                <Select
                  native
                  value={this.state.action}
                  onChange={this.handleInputChange}
                  inputProps={{
                    name: 'action',
                    id: 'action',
                  }}
                >
                  <option value="enable_feature">Enable Feature</option>
                  <option value="disable_feature">Disable Feature</option>
                </Select>
              </div>
              {this.state.errors.server_message && (
                <span className="error">
                  Error: {this.state.errors.server_message}
                </span>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onCloseModal}
                disabled={this.state.loading}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={this.state.loading || this.state.feature.length < 1}
                color="primary"
              >
                Confirm
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default FeatureManagerDialog;
