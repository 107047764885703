import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
} from '@mui/material';
import styles from './SitesToMigrate.module.css';

const NO_CHANGE_MESSAGE = 'Renews with the same plan';

export function SitesToMigrate({ sitesToMigrate, productsCatalog }) {
  if (!productsCatalog) {
    return null;
  }

  return (
    <div>
      {sitesToMigrate.map((site) => {
        return (
          <Card key={site.id} sx={{ marginBottom: '40px' }}>
            <dl style={{ marginLeft: '20px' }}>
              <li>ID: {site.id}</li>
              <li>URL: {site.url !== '' ? site.url : '[empty]'}</li>
              <li>Name: {site.name !== '' ? site.name : '[empty]'}</li>
            </dl>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Current Plan</TableCell>
                  <TableCell>Renew with Plan</TableCell>
                  <TableCell>Zuora Plan</TableCell>
                  <TableCell>Renew with Zuora Plan</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {site.products.map((product) => {
                  const futureZuoraPlan = productsCatalog.find(
                    (item) => product.sku === item.sku
                  );
                  const currentZuoraPlan = productsCatalog.find(
                    (item) => product.effectiveSku === item.sku
                  );
                  const isChangingPlans =
                    currentZuoraPlan.sku !== futureZuoraPlan.sku;

                  return (
                    <TableRow key={product.sku}>
                      <TableCell>
                        <dl className={styles.sitesProperties}>
                          <dt>name</dt>
                          <dd>{product.name}</dd>
                          <dt>group</dt>
                          <dd>{product.effective_feature_group}</dd>
                          <dt>allowance</dt>
                          <dd>
                            {product?.effective_cc_sessions ??
                              product?.effective_monthly_responses}
                          </dd>
                          <dt>plan id</dt>
                          <dd>{product.planId}</dd>
                        </dl>
                      </TableCell>
                      <TableCell>
                        {isChangingPlans ? (
                          <>
                            <dl className={styles.sitesProperties}>
                              <dt>name</dt>
                              <dd>{product.name}</dd>
                              <dt>group</dt>
                              <dd>{product.feature_group}</dd>
                              <dt>allowance</dt>
                              <dd>
                                {' '}
                                {product?.cc_sessions ??
                                  product?.monthly_responses}
                              </dd>
                              <dt>plan id</dt>
                              <dd>{product.planId}</dd>
                            </dl>
                          </>
                        ) : (
                          NO_CHANGE_MESSAGE
                        )}
                      </TableCell>
                      <TableCell>
                        <dl className={styles.sitesProperties}>
                          <dt>name</dt>
                          <dd>{currentZuoraPlan?.productName}</dd>
                          <dt>group</dt>
                          <dd>{currentZuoraPlan?.planName}</dd>
                          <dt>allowance</dt>
                          <dd>
                            {currentZuoraPlan?.allowance.daily_cc_sessions ??
                              currentZuoraPlan?.allowance.monthly_responses}
                          </dd>
                          <dt>SKU</dt>
                          <dd>{product.effectiveSku}</dd>
                        </dl>
                      </TableCell>
                      <TableCell>
                        {isChangingPlans ? (
                          <>
                            <dl className={styles.sitesProperties}>
                              <dt>name</dt>
                              <dd>{futureZuoraPlan.productName}</dd>
                              <dt>group</dt>
                              <dd>{futureZuoraPlan.planName}</dd>
                              <dt>allowance</dt>
                              <dd>
                                {futureZuoraPlan.allowance.daily_cc_sessions ??
                                  futureZuoraPlan.allowance.monthly_responses}
                              </dd>
                              <dt>SKU</dt>
                              <dd>{product.sku}</dd>
                            </dl>
                          </>
                        ) : (
                          NO_CHANGE_MESSAGE
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Card>
        );
      })}
    </div>
  );
}
