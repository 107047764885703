import React from 'react';
import { Component } from 'react';
import Plot from 'react-plotly.js';
import styles from './SamplingVisualizer.module.css';

export default class SamplingGraph extends Component {
  render() {
    const recValueChanges =
      (this.props.recValueData && this.props.recValueData.changes) || [];
    const recordingCounts =
      (this.props.recordingCountData && this.props.recordingCountData.counts) ||
      [];
    const maxRecordingCount =
      (this.props.recordingCountData && this.props.recordingCountData.max) || 1;

    return (
      <Plot
        className={styles.graph}
        useResizeHandler={true}
        data={[
          // RecordingCount data.
          {
            name: 'recording counts',
            x: recordingCounts.map((tuple) => tuple[0]),
            y: recordingCounts.map((tuple) => tuple[1]),
            type: 'bar',
            marker: {
              color: 'rgba(134, 191, 249, 0.7)',
            },
            yaxis: 'y2',
          },
          // RecValue data.
          {
            name: 'rec_value',
            x: recValueChanges.map((tuple) => tuple[0]),
            y: recValueChanges.map((tuple) => tuple[1]),
            type: 'scatter',
            mode: 'lines+markers',
            marker: {
              color: 'rgb(15, 32, 128)',
              size: 6,
            },
            line: {
              color: 'rgb(15, 32, 128)',
              width: 3,
              shape: 'hv',
            },
          },
        ]}
        config={{
          modeBarButtonsToRemove: [
            'lasso2d',
            'select2d',
            'pan2d',
            'zoom2d',
            'zoomIn2d',
            'zoomOut2d',
            'autoScale2d',
            'hoverCompareCartesian',
            'toggleSpikelines',
            'hoverClosestCartesian',
            'sendDataToCloud',
            'resetScale2d',
          ],
          displaylogo: false,
          displayModeBar: true,
        }}
        layout={{
          font: {
            family: 'Roboto, sans-serif',
          },
          autosize: true,
          showlegend: true,
          hovermode: 'closest',
          xaxis: {
            range: [this.props.fromDate, this.props.toDate],
            title: 'Date/Time',
            tickformat: '%b %d %H:%M',
            type: 'date',
          },
          yaxis: {
            fixedrange: true,
            range: [0, 1.1],
            title: 'Sampling Rate',
          },
          yaxis2: {
            fixedrange: true,
            overlaying: 'y',
            range: [0, maxRecordingCount],
            side: 'right',
            title: 'Recording Count',
          },
        }}
      />
    );
  }
}
