import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styles from './Pagination.module.css';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from '@mui/material/IconButton';

const Pagination = ({
  currentPage,
  itemsPerPage,
  itemCount,
  previousPageUrl,
  nextPageUrl,
  totalPages,
}) => {
  const history = useHistory();
  if (itemCount > 0) {
    return (
      <div>
        {itemCount > itemsPerPage ? (
          <div className={styles.pagination}>
            <IconButton
              disabled={currentPage === 1}
              onClick={() => {
                history.push(previousPageUrl);
              }}
            >
              <ArrowLeftIcon />
            </IconButton>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <IconButton
              disabled={currentPage === totalPages}
              onClick={() => {
                history.push(nextPageUrl);
              }}
            >
              <ArrowRightIcon />
            </IconButton>
          </div>
        ) : null}
      </div>
    );
  }
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number,
  itemCount: PropTypes.number,
  previousPageUrl: PropTypes.string,
  nextPageUrl: PropTypes.string,
  totalPages: PropTypes.number,
};

export default Pagination;
