import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { CustomerEmailRecipientsField } from './CustomerEmailRecipientsField';
import {
  getCustomerEmailRecipients,
  updateCustomerEmailRecipients,
} from '../../../utils/api';
import { LoadingDialog } from '../LoadingDialog';
import { useToasts } from 'react-toast-notifications';
import { mapEmailsFromResponse, mapEmailsToRequest } from './mappers';

export const UpdateCustomerEmailRecipientsModal = ({
  onCloseModal,
  props: modalProps,
}) => {
  const { addToast } = useToasts();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailRecipients, setEmailRecipients] = useState('');

  useEffect(() => {
    async function fetchEmails() {
      try {
        const emails = await getCustomerEmailRecipients({
          accountId: modalProps.accountId,
        });
        setEmailRecipients(mapEmailsFromResponse(emails));
      } catch (error) {
        addToast('An error occured while fetching customer email recipients', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }

    if (!modalProps?.accountId) {
      return;
    }

    fetchEmails();
  }, [modalProps?.accountId, addToast]);

  const handleUpdateField = (event) => setEmailRecipients(event.target.value);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await updateCustomerEmailRecipients({
        accountId: modalProps.accountId,
        emailRecipients: mapEmailsToRequest(emailRecipients),
      });
      onCloseModal();
    } catch (error) {
      addToast('An error occured submitting the email recipients', {
        appearance: 'error',
        autoDismiss: true,
      });
    }

    setIsSubmitting(false);
  };

  if (!modalProps) {
    return null;
  }

  if (isSubmitting) {
    return <LoadingDialog title="Updating email recipients" />;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open
      onClose={onCloseModal}
      disableEnforceFocus
    >
      <DialogTitle>
        Update email recipients for Account {modalProps.accountId}
      </DialogTitle>
      <DialogContent>
        <CustomerEmailRecipientsField
          value={emailRecipients}
          handleUpdateField={handleUpdateField}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCustomerEmailRecipientsModal;
