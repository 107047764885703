import React from 'react';
import MUIDataTable from 'mui-datatables';

class SiteSelectTable extends React.Component {
  state = {
    selected: [],
  };
  render() {
    const columns = [
      {
        name: 'ID',
        options: {
          sortOrder: 'asc',
        },
      },
      {
        name: 'Name',
      },
    ];

    return (
      <MUIDataTable
        title={'Sites'}
        data={this.props.tableData}
        columns={columns}
        options={{
          pagination: false,
          filter: false,
          print: false,
          download: false,
          search: true,
          responsive: 'vertical',
          rowsSelected: this.state.rowsSelected,
          viewColumns: false,
          elevation: 0,
          customToolbarSelect: () => {},
          onRowSelectionChange: (currentRows, allRows) => {
            this.props.handleCheckboxChange(allRows);
            const selected = allRows.map((item) => item.dataIndex);
            this.setState({
              rowsSelected: selected,
            });
          },
        }}
      />
    );
  }
}

export default SiteSelectTable;
