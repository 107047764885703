import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

import {
  cancelBundleTrial,
  cancelSingleTrial,
} from '../../../domains/trials/api/cancelTrial';
import styles from './TrialCancelModal.module.css';
import { AdmModalProps } from '../AdmModalRoot';

type Props = {
  modalProps: Record<string, string>;
  onSuccess: () => void;
};

const TrialCancelModal = ({
  onCloseModal,
  props: { modalProps, onSuccess },
}: AdmModalProps<Props>) => {
  const handleCancelTrial = async () => {
    if (!modalProps.bundleId && !modalProps.trialId) {
      return;
    }

    const target = modalProps.isAlp
      ? `account_id: ${modalProps?.accountId}`
      : `site_id: ${modalProps?.siteId}`;
    const errorMessage = `Unable to cancel trial for ${target}`;

    if (modalProps?.isSingleProductTrial) {
      await cancelSingleTrial({
        trialId: modalProps.trialId,
        errorMessage,
        accountId: Number(modalProps?.accountId),
      });
    } else {
      await cancelBundleTrial({
        bundleId: modalProps.bundleId,
        errorMessage,
        accountId: Number(modalProps?.accountId),
      });
    }

    onSuccess();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onCloseModal}>
      <DialogTitle>Cancel Trial</DialogTitle>
      <DialogContent>
        <p className={styles.info}>
          {`You're about to cancel a trial for ${
            modalProps.isAlp ? 'account' : 'site'
          } ${
            modalProps?.name
              ? modalProps.name
              : `ID: ${
                  modalProps.isAlp ? modalProps?.accountId : modalProps?.siteId
                }`
          }`}
        </p>
        <p>Are you sure you want to cancel trial?</p>
        <p>
          Once you cancel the trial, we will send an email to the account owner
          that trial ended.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>Cancel</Button>
        <Button variant="contained" onClick={handleCancelTrial}>
          Cancel trial
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrialCancelModal;
