export const toTitleCase = (inputString) => {
  return inputString.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const snakeToTitle = (inputString) => {
  if (!inputString) return inputString;
  return toTitleCase(inputString.replace(/_/g, ' '));
};
