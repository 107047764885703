import React from 'react';
import { IconButton, Button, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import styles from './AdmActionTable.module.css';
import { useModalContext } from '../../contexts/ModalContext';
import { MODAL_ADD_ACTION } from '../modals/constants';

const AdmActionToolbar = (props) => {
  const modalContext = useModalContext();

  const handleOpenModal = () =>
    modalContext.openModal(MODAL_ADD_ACTION, {
      handleNewAction: props.handleNewAction,
    });

  return (
    <React.Fragment>
      {props.inTable ? (
        <Tooltip title={'Add New Action'}>
          <IconButton
            onClick={handleOpenModal}
            data-testid="action-table-add-button"
          >
            <AddIcon titleAccess="Add action" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          className={styles.standaloneAddActionButton}
          onClick={handleOpenModal}
        >
          Add action
          <AddIcon />
        </Button>
      )}
    </React.Fragment>
  );
};

export default AdmActionToolbar;
