import { Typography } from '@mui/material';
import moment from 'moment';
import ColoredLabel from '../../../../components/ColoredLabel';
import { AlpPlan } from '../../models/service-alp.model';

export function dateRenderer(date: string) {
  const dateTime = moment.utc(date);
  return (
    <>
      <Typography noWrap>{dateTime.format('YYYY-MM-DD')}</Typography>
      {dateTime.format('HH:mm:ss')}
    </>
  );
}

export function toCapitalizeRenderer(value: string) {
  return <Typography textTransform="uppercase">{value}</Typography>;
}

export function toSmallCapitalized(value: string) {
  return (
    <Typography textTransform="uppercase" fontSize={12}>
      {value}
    </Typography>
  );
}

const planColors = {
  free: 'var(--green)',
  growth: 'var(--amber)',
  pro: 'var(--red)',
};

export function planRenderer(value: AlpPlan) {
  return <ColoredLabel color={planColors[value]}>{value}</ColoredLabel>;
}
