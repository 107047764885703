import { useQuery, useMutation } from '@tanstack/react-query';
import { getUserOrganizations, modifyAccessLevel } from './api';
import { normalizeOrganizationUsersResponse } from '../../../utils/normalizer';
import { queryClient } from '../../../query-client-provider';

export const invalidateUserOrganizationsQuery = (userId: string) => {
  return queryClient.invalidateQueries(['USER_ORGS', { userId }]);
};

export const useUserOrganizationsQuery = (userId: string) => {
  return useQuery(['USER_ORGS', { userId }], {
    suspense: true,
    queryFn: async () => {
      try {
        const data = await getUserOrganizations(userId);
        return Object.values(normalizeOrganizationUsersResponse(data));
      } catch (e: any) {
        throw new Error(
          `Error getting User Organizations: ${
            e.error_message || e.error || e.message
          }.`,
          { cause: e }
        );
      }
    },
  });
};

type AccessLevelMutationVariables = {
  organizationId: string;
  userId: string;
  accessLevel: 'read' | 'write' | 'admin';
};
export const useAccessLevelMutation = () => {
  return useMutation({
    mutationFn: ({
      organizationId,
      userId,
      accessLevel,
    }: AccessLevelMutationVariables) => {
      return modifyAccessLevel(organizationId, userId, accessLevel);
    },
    onSettled: (_data, _error, variables) => {
      const { userId } = variables;
      invalidateUserOrganizationsQuery(userId);
    },
  });
};
