import React, { useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';

import { swapSitePlan } from './api';
import HasPermission from '../../../../components/HasPermission';
import { MODAL_FORM_DIALOG } from '../../../../components/modals/constants';
import { LookupContext } from '../../../../contexts/LookupContext';

const SwapSitePlan = ({ siteId, handleContextModalOpen, disabled }) => {
  const lookupContext = useContext(LookupContext);

  const fields = [
    {
      name: 'Target Site ID',
      id: 'target_site_id',
      type: 'text',
      label: 'The ID of the site you want to move this plan to.',
      required: true,
    },
    {
      name: 'Annotation',
      id: 'annotation',
      type: 'text',
      label: 'Please provide reason for change and/or Zendesk ticket link',
      required: true,
    },
  ];

  const onSubmit = (args) => {
    return swapSitePlan(siteId, args.target_site_id, args.annotation);
  };

  return (
    <HasPermission rolesAccepted={['admin', 'billing_management_2']}>
      <MenuItem
        onClick={() =>
          handleContextModalOpen(MODAL_FORM_DIALOG, {
            title: 'Swap Site Plan',
            fields,
            onSubmit,
            successMessage: `Plan swapped for site ${siteId}.`,
            onSuccess: lookupContext.lookupRequest,
          })
        }
        disabled={disabled}
      >
        Swap Site Plan
      </MenuItem>
    </HasPermission>
  );
};

export default SwapSitePlan;
