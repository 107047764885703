import { apiPost } from '../../../../utils/api';

export type AddServicePeriodProps = {
  limit: string;
  start_date: string;
  end_date: string;
};

export const addServicePeriod = async (
  { limit, start_date, end_date }: AddServicePeriodProps,
  siteId: string,
  accountId: string
): Promise<{ success: boolean }> => {
  return apiPost(`/accounts/voc_service_period`, undefined, {
    limit,
    start_date,
    end_date,
    site_id: siteId,
    account_id: accountId,
  });
};
