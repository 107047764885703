import React, { Component } from 'react';

import Paper from '@mui/material/Paper';

import NotificationTable from './NotificationTable';
import ShowSpinner from '../../components/ShowSpinner';
import styles from './UserNotifications.module.css';
import { getNotifications } from './api';

export default class Notifications extends Component {
  state = {
    notifications: [],
    errorMessage: null,
    hasSearched: false,
    loading: false,
    searchQuery: '',
    count: 0,
    page: 0,
    rowsPerPage: 10,
  };

  handleChangePage = (currentPage) => {
    this.setState({ page: currentPage }, this.getNotificationData);
  };

  handleChangeRowsPerPage = (numberOfRows) => {
    this.setState({ rowsPerPage: numberOfRows }, this.getNotificationData);
  };

  transformNotificationData = (items) =>
    items.map((item) => [
      item.id,
      new Date(item.created * 1000),
      item.recipients,
      item.reason,
      item.type,
    ]);

  getNotificationData = () => {
    this.setState({
      loading: true,
    });
    return getNotifications({
      user_email: this.state.searchQuery,
      // API pages start on 1, MUI Table pages start on 0
      page: this.state.page + 1,
      items_per_page: this.state.rowsPerPage,
    })
      .then((response) => {
        this.setState({
          notifications: this.transformNotificationData(response.items),
          count: response.item_count,
          loading: false,
          errorMessage: null,
        });
      })
      .catch((e) => {
        this.setState({
          notifications: [],
          errorMessage: e.error_message || e.message || e.error,
          loading: false,
        });
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.searchQuery !== prevProps.searchQuery) {
      this.setState(
        {
          hasSearched: true,
          searchQuery: this.props.searchQuery,
        },
        this.getNotificationData
      );
    }
  }

  render() {
    return (
      this.state.hasSearched && (
        <Paper className={styles.searchResults} elevation={1}>
          {!this.state.loading ? (
            <div>
              {this.state.errorMessage && (
                <p className={styles.errorMessage}>{this.state.errorMessage}</p>
              )}
              <NotificationTable
                onRefresh={this.getNotificationData}
                notifications={this.state.notifications}
                page={this.state.page}
                count={this.state.count}
                rowsPerPage={this.state.rowsPerPage}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          ) : (
            <ShowSpinner />
          )}
        </Paper>
      )
    );
  }
}
