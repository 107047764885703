import { apiGet } from '../../utils/api';

/**
 *
 * @param {Date} dateFrom The start date for the query.
 * @param {Date} dateTo The end date for the query.
 * @param {String} contentType The accept type. Can be one of 'csv' or 'json'.
 * @returns {Promise<string>} The result of the API call in CSV format.
 */
export function referralsApi(dateFrom, dateTo, contentType) {
  const query = {
    from: dateFrom,
    to: dateTo,
  };

  return apiGet('/referrals/report', query, 2, { Accept: contentType })
    .then(handleApiErrors)
    .catch(function (error) {
      throw error.error_message || error.message;
    });
}

/**
 * Handle any API errors and return the response body.
 * @param {string | Object} response The response body to parse out.
 * @param {string} [response.error_message] If present, this will be thrown.
 * @returns {string} The response CSV text.
 * @throws The raw error message.
 */
function handleApiErrors(response) {
  if (response.error_message) {
    throw response.error_message;
  }
  return response;
}
