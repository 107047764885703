import { apiPost } from '../../../utils/api';

export const transferApiCall = (entityType, id_from, id_to, annotation) => {
  let to_key = entityType === 'organization' ? 'account_id' : 'user_id';
  return apiPost(
    '/' + entityType + '/' + id_from,
    'transfer',
    {
      [to_key]: id_to,
      adm_action_annotation: annotation,
    },
    2,
    {},
    true,
    `transfer ${entityType}`
  );
};
