import { FC, useState } from 'react';
import clsx from 'clsx';

import ButtonLink from '../../../../../../components/ButtonLink';
import { toDateString } from '../../../../../../utils/dates';

import styles from './ServicesTable.module.css';

interface DefaultService {
  plan: string;
  source: string;
  allowances: string;
  startDate?: string;
  endDate?: string;
}

interface ServiceWithDates extends DefaultService {
  startDate: string;
  endDate: string;
}

export type Service = ServiceWithDates | DefaultService;

interface ServicesTableProps {
  services: Service[];
}

const ServicesTable: FC<ServicesTableProps> = ({ services }) => {
  const [showAllServices, setShowAllServices] = useState<boolean>(false);

  return (
    <>
      <ul className={styles.serviceTable}>
        {services
          .filter((_service, index) => (showAllServices ? true : index === 0))
          .map(({ plan, source, allowances, startDate, endDate }, index) => {
            const isFirstService = index === 0;
            const servicePropertyClassName = clsx(styles.serviceProperty, {
              [styles.activeRow]: isFirstService,
            });
            const planAndSourceClassName = clsx(styles.planAndSource, {
              [styles.activeRow]: isFirstService,
            });

            return (
              <li
                key={`${plan}-${source}-${startDate}-${endDate}`}
                className={styles.service}
              >
                <dl className={styles.rowContent}>
                  <dt className={planAndSourceClassName}>
                    <span className={styles.plan}>{plan}</span>
                    <span className={styles.statusAndSource}>
                      {isFirstService && (
                        <>
                          <span className={styles.active}>Active</span>
                          <span> · </span>
                        </>
                      )}
                      <span className={styles.source}>{source}</span>
                    </span>
                  </dt>
                  <dd className={servicePropertyClassName}>{allowances}</dd>
                  <dd className={servicePropertyClassName}>
                    {toDateString(startDate)}
                  </dd>
                  <dd className={servicePropertyClassName}>
                    {toDateString(endDate)}
                  </dd>
                </dl>
              </li>
            );
          })}
      </ul>
      {services.length > 1 && (
        <ButtonLink
          className={styles.servicePeriodsToggle}
          onClick={() => setShowAllServices((state) => !state)}
        >
          Display
          {showAllServices
            ? ' only the active service period'
            : ' all service periods'}
        </ButtonLink>
      )}
    </>
  );
};

export default ServicesTable;
