import React from 'react';
import ResourceListHeader from './ResourceListHeader';
import ResourceListItem from './ResourceListItem';

function HelpCenterResources({
  categories,
  searchTerm,
  updateResourceList,
  selectedResources,
}) {
  return (
    <ul>
      {categories.map((category) => (
        <li key={category.title.replace(' ', '-')}>
          {!searchTerm ? (
            <ResourceListHeader isBordered searchTerm={searchTerm}>
              <h3>{category.title}</h3>
            </ResourceListHeader>
          ) : (
            ''
          )}
          <ul>
            {category.sections
              ? category.sections.map((section) => (
                  <li key={section.title.replace(' ', '-')}>
                    {!searchTerm ? (
                      <ResourceListHeader searchTerm={searchTerm}>
                        <h4>{section.title}</h4>
                      </ResourceListHeader>
                    ) : (
                      ''
                    )}
                    <ul>
                      {section.articles
                        ? section.articles
                            .filter((article) =>
                              article.title.toLowerCase().includes(searchTerm)
                            )
                            .map((article) => (
                              <li key={article.title.replace(' ', '-')}>
                                <ResourceListItem
                                  resource={{
                                    title: article.title,
                                    url: article.url,
                                    subtitle: category.title,
                                    type: 'article',
                                  }}
                                  updateResourceList={updateResourceList}
                                  selected={selectedResources.some(
                                    (resource) => resource.url === article.url
                                  )}
                                />
                              </li>
                            ))
                        : ''}
                    </ul>
                  </li>
                ))
              : ''}
          </ul>
        </li>
      ))}
    </ul>
  );
}

export default HelpCenterResources;
