import { Typography } from '@mui/material';
import ColoredLabel from '../../../../components/ColoredLabel';
import { EntityDetailsColumnsConfig } from '../../../../components/EntityDetails/interfaces';

import styles from './OrganizationWidget.module.css';

interface GetOrganizationDataParams {
  id: number;
  name: string;
}

export const getOrganizationData = ({
  id,
  name,
}: GetOrganizationDataParams): EntityDetailsColumnsConfig => [
  [{ name: 'Name', value: name }],
  [
    {
      name: 'Organization ID',
      value: id.toString(),
      url: `/lookup/organization_id/${id}`,
    },
  ],
];

export const getAccordionTitle = (
  isOrganizationLoading: boolean,
  hasSsoEnabled?: boolean
): JSX.Element => (
  <div className={styles.title}>
    <Typography variant="h2" sx={{ fontSize: '14px', fontWeight: '500' }}>
      {isOrganizationLoading ? 'Organization (Loading...)' : 'Organization'}
    </Typography>
    {hasSsoEnabled && <ColoredLabel color="#58AE5E">SSO</ColoredLabel>}
  </div>
);
