import moment from 'moment';
import React, { Component } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DialogTitleWithClose } from '../../DialogTitleWithClose';

import styles from './SamplingVisualizer.module.css';
import { getRecValues, getRecordingCountsHourly } from './api';
import SamplingGraph from './SamplingGraph';

export const getEndOfCurrentHour = () => moment().endOf('hour');

export const getMidnightStartPeriod = () =>
  moment().subtract(2, 'days').startOf('day');

const siteDataReset = {
  errors: {},
  hasAttemptedFetch: false,
  isLoading: false,
  recValueData: null,
  recordingCountData: null,
};

const initialState = {
  ...siteDataReset,
  fromDate: getMidnightStartPeriod().toDate(),
  toDate: getEndOfCurrentHour().toDate(),
};

const originalTitle = document.title;

class SamplingVisualizer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };
  }

  componentDidMount() {
    let fromDate = initialState.fromDate;
    let toDate = initialState.toDate;

    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('from_date')) {
        fromDate = moment(parseInt(searchParams.get('from_date'), 10)).toDate();
      }
      if (searchParams.has('to_date')) {
        toDate = moment(parseInt(searchParams.get('to_date'), 10)).toDate();
      }
    }

    this.setState({
      fromDate,
      toDate,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.id !== prevProps.id) {
      // Reset any site-specific data.
      this.setState({
        ...siteDataReset,
      });
    }

    if (
      this.props.modalOpen &&
      this.props.modalOpen !== prevProps.modalOpen &&
      window.location.search.indexOf('from_date') > -1 &&
      window.location.search.indexOf('to_date') > -1
    ) {
      // If someone has been linked directly to a modal state, fetch the data immediately.
      this.getSamplingData(
        this.props.id,
        this.state.fromDate,
        this.state.toDate
      );
    }
  }

  setDateSearchParams = (dates) => {
    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search);

      if (dates) {
        searchParams.set('from_date', dates.from);
        searchParams.set('to_date', dates.to);
      } else {
        searchParams.delete('from_date');
        searchParams.delete('to_date');
      }

      const newRelativePathQuery =
        window.location.pathname + '?' + searchParams.toString();
      // eslint-disable-next-line no-restricted-globals
      history.replaceState(null, '', newRelativePathQuery);
    }
  };

  onClose = () => {
    this.setDateSearchParams();
    document.title = originalTitle;
    this.props.onClose();
  };

  // prettier-ignore
  dataDogLogsLink = () =>
        // eslint-disable-next-line max-len
        `https://app.datadoghq.eu/logs?cols=core_host%2Ccore_service%2Clog_request_url&event&index=main&live=true&messageDisplay=inline&query=%22rec_value+updater%3A%22+%22Site+${this.props.id}%3A%22+environment%3Alive&stream_sort=desc&from_ts=${this.state.fromDate.valueOf()}&to_ts=${this.state.toDate.valueOf()}`;

  hasData = () =>
    (this.state.recValueData && this.state.recValueData.changes.length > 0) ||
    (this.state.recordingCountData && this.state.recordingCountData.counts);

  hasAtLeastOneCount = () =>
    (this.state.recValueData && this.state.recValueData.changes.length > 0) ||
    (this.state.recordingCountData &&
      this.state.recordingCountData.counts.length > 0);

  aggregate = (tuples) => {
    if (tuples.length < 1) {
      return { sum: 0, max: 0, min: 0, avg: 0 };
    }

    const aggregateResults = tuples.reduce(
      (aggregations, tuple) => {
        const value = tuple[1];

        aggregations.max = Math.max(value, aggregations.max);
        aggregations.min = Math.min(value, aggregations.min);
        aggregations.sum += value;

        return aggregations;
      },
      { sum: 0, max: Number.NEGATIVE_INFINITY, min: Number.POSITIVE_INFINITY }
    );

    // Safe (no risk of divide-by-zero) because of the conditional above.
    aggregateResults.avg = aggregateResults.sum / tuples.length;

    return aggregateResults;
  };

  getCountsPerDay = (tuples) => {
    const counts = tuples.reduce((counts, tuple) => {
      const dateKey = moment(tuple[0]).startOf('day').format('D MMM');
      const count = tuple[1];

      if (!counts[dateKey]) {
        counts[dateKey] = 0;
      }
      counts[dateKey] += count;

      return counts;
    }, {});

    let average = 0;
    const dateKeys = Object.keys(counts);
    if (dateKeys.length > 0) {
      for (const key of dateKeys) {
        average += counts[key];
      }
      average /= dateKeys.length;
    }
    counts['Average'] = Math.round(average);

    return counts;
  };

  getSamplingData = (id, fromDate, toDate) => {
    const promises = [
      getRecValues(id, fromDate, toDate).then((changes) => {
        const recValueAggregations = this.aggregate(changes);
        return this.setState({
          ...this.state,
          recValueData: {
            changes,
            avg: recValueAggregations.avg,
            max: recValueAggregations.max,
            min: recValueAggregations.min,
          },
        });
      }),

      getRecordingCountsHourly(id, fromDate, toDate).then((counts) => {
        const recordingCountAggregations = this.aggregate(counts);
        const countsPerDay = this.getCountsPerDay(counts);
        return this.setState({
          ...this.state,
          recordingCountData: {
            counts,
            avg: recordingCountAggregations.avg,
            max: recordingCountAggregations.max,
            min: recordingCountAggregations.min,
            sum: recordingCountAggregations.sum,
            countsPerDay,
          },
        });
      }),
    ].map((promise) => promise.catch((err) => err));

    Promise.all(promises).then(() => {
      this.setState({
        ...this.state,
        isLoading: false,
        hasAttemptedFetch: true,
      });
    });
  };

  handleFromDate = (date) => {
    date.startOf('day');
    this.setState({
      ...this.state,
      fromDate: date.toDate(),
    });
  };

  handleToDate = (date) => {
    date.endOf('day');
    // Cap the date to whichever is earlier - the end of the passed-in day
    // or the end of the current hour (like the initial value).
    // This is so that we show someone all of an earlier day, but only the
    // applicable section of the current day.
    const nowish = getEndOfCurrentHour();
    const cappedDate = nowish.isBefore(date) ? nowish : date;
    this.setState({
      ...this.state,
      toDate: cappedDate.toDate(),
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.isLoading) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    let toDate = this.state.toDate;
    if (moment(toDate).isSame(moment(), 'day')) {
      // We don't allow people to choose hours, so let's assume they want
      // to update the currently-selected time if it's today.
      // This is useful when you leave the page open for a while and just
      // want to get the updated data.
      const endOfCurrentHour = getEndOfCurrentHour().toDate();
      this.setState({
        toDate: endOfCurrentHour,
      });
      toDate = endOfCurrentHour;
    }

    this.setDateSearchParams({
      from: this.state.fromDate.valueOf(),
      to: toDate.valueOf(),
    });
    this.getSamplingData(this.props.id, this.state.fromDate, toDate);
  };

  render() {
    if (this.props.modalOpen) {
      document.title = `Site ${this.props.id} - Adm Sampling Viz`;
    }

    let loadingAwareButton;

    if (this.state.isLoading) {
      loadingAwareButton = (
        <Button
          key="fetch"
          aria-label="Fetch data"
          onClick={this.handleSubmit}
          color="primary"
          variant="contained"
          disabled
        >
          Fetching... <CircularProgress size={30} />
        </Button>
      );
    } else {
      loadingAwareButton = (
        <Button
          key="fetch"
          aria-label="Fetch data"
          onClick={this.handleSubmit}
          color="primary"
          variant="contained"
        >
          Fetch Data
        </Button>
      );
    }

    const recordingCountsPerDayKeys =
      (this.state.recordingCountData &&
        this.state.recordingCountData.countsPerDay &&
        Object.keys(this.state.recordingCountData.countsPerDay)) ||
      [];

    const recordingCountsPerDayHeader = recordingCountsPerDayKeys.map((key) => {
      return <TableCell key={key}>{key}</TableCell>;
    });

    const recordingCountsPerDayContent = recordingCountsPerDayKeys.map(
      (key) => {
        return (
          <TableCell key={key}>
            {this.state.recordingCountData.countsPerDay[key]}
          </TableCell>
        );
      }
    );

    return (
      <Dialog
        onClose={this.onClose}
        open={this.props.modalOpen}
        aria-labelledby="form-dialog-title"
        maxWidth={'lg'}
        fullScreen={this.hasAtLeastOneCount()}
      >
        <DialogTitleWithClose onClose={this.onClose}>
          Sampling Visualizer for {this.props.name} (ID {this.props.id} -&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={this.dataDogLogsLink()}
          >
            logs <OpenInNewIcon className="open-in-new" />
          </a>
          )
        </DialogTitleWithClose>
        <DialogContent>
          <form className={styles.datePickerWrapper}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                className={styles.datePicker}
                aria-label="From date"
                inputFormat="YYYY-MM-DD"
                variant="dialog"
                disableFuture
                showTodayButton
                autoOk={true}
                onChange={this.handleFromDate}
                value={this.state.fromDate}
                error={this.state.fromDate > this.state.toDate}
                label={
                  this.state.fromDate <= this.state.toDate
                    ? 'From Date'
                    : 'From Date is after To Date'
                }
                renderInput={(params) => (
                  <TextField variant="standard" {...params} />
                )}
              />
              <DatePicker
                className={styles.datePicker}
                aria-label="To date"
                inputFormat="YYYY-MM-DD"
                variant="dialog"
                disableFuture
                showTodayButton
                autoOk={true}
                value={this.state.toDate}
                onChange={this.handleToDate}
                error={this.state.fromDate > this.state.toDate}
                label={
                  this.state.fromDate <= this.state.toDate
                    ? 'To Date'
                    : 'To Date is before From Date'
                }
                renderInput={(params) => (
                  <TextField variant="standard" {...params} />
                )}
              />
            </LocalizationProvider>
            {loadingAwareButton}
          </form>
          {!this.hasData() ? (
            this.state.hasAttemptedFetch && (
              <DialogContentText
                aria-label="Error text"
                className={styles.errorMessage}
              >
                ERROR: Unable to fetch any data for site ID {this.props.id} for
                this time period. Please try again or pester Cassini ;)
              </DialogContentText>
            )
          ) : (
            <div aria-label="Sampling Graph">
              {/* There are no legends if there is not at least one count to graph. */}
              <SamplingGraph
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
                recValueData={this.state.recValueData}
                recordingCountData={this.state.recordingCountData}
              />

              <strong>Stats</strong>
              <Table className={styles.table} aria-label="Data table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align="right">Num Data Points</TableCell>
                    <TableCell align="right">Min</TableCell>
                    <TableCell align="right">Max</TableCell>
                    <TableCell align="right">Average</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!this.state.recValueData &&
                    this.state.recValueData.changes.length > 0 && (
                      <TableRow key="recvalue">
                        <TableCell component="th" scope="row">
                          Rec Value
                        </TableCell>
                        <TableCell align="right">
                          {this.state.recValueData.changes.length}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.recValueData.min.toFixed(4)}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.recValueData.max.toFixed(4)}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.recValueData.avg.toFixed(4)}
                        </TableCell>
                        <TableCell align="right">n/a</TableCell>
                      </TableRow>
                    )}
                  {/* We don't care if the length of `recordingCountData.counts` is 0,
                                       as that can just mean no data.
                                    */}
                  {!!this.state.recordingCountData &&
                    !!this.state.recordingCountData.counts && (
                      <TableRow key="recording-counts">
                        <TableCell component="th" scope="row">
                          Recording Counts Per Hour
                        </TableCell>
                        <TableCell align="right">
                          {this.state.recordingCountData.counts.length}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.recordingCountData.min}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.recordingCountData.max}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.recordingCountData.avg.toFixed(0)}
                        </TableCell>
                        <TableCell align="right">
                          {this.state.recordingCountData.sum}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>

              {!!this.state.recordingCountData &&
                !!this.state.recordingCountData.countsPerDay && (
                  <div>
                    <strong>Recording Counts Per Day Breakdown</strong>
                    <Table
                      className={styles.table}
                      aria-label="Recording counts per day table"
                    >
                      <TableHead>
                        <TableRow key="countsPerDayHeader">
                          {recordingCountsPerDayHeader}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key="countsPerDay">
                          {recordingCountsPerDayContent}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

export default SamplingVisualizer;
