import { useContext } from 'react';
import { LookupContext } from '../contexts/LookupContext';

export const useFeatureFlag = (flagName: string): boolean | undefined => {
  const { account } = useContext(LookupContext) || {};

  if (account === undefined) return undefined;

  // Check local storage for flag value
  const flagValue = localStorage.getItem(flagName);

  // If the flag is not present in local storage, use the default value from featureFlags object
  if (flagValue === null) {
    return account.features.includes(flagName) === true;
  }

  // Otherwise, parse the flag value from local storage and return it
  return flagValue === 'true';
};
