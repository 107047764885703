import { useContext, useEffect, useState } from 'react';
import { LookupContext } from '../../../contexts/LookupContext';
import { doesCountryRequireState } from '../../../utils/countries';
import { LoadingDialog } from '../LoadingDialog/index';
import { useToasts } from 'react-toast-notifications';
import { updateCustomerDetails } from '../../../utils/api';
import { BillingAddress } from '../SubscriptionCreationModal/BillingAddress';
import { Notes } from '../SubscriptionCreationModal/Notes';
import {
  SubscriptionsCommonFields,
  PaymentTerms,
} from '../SubscriptionCreationModal/SubscriptionsCommonFields';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { camelKeys } from 'js-convert-case';

export const UpdateCustomerDetailsModal = ({
  props: modalProps,
  onCloseModal,
}) => {
  const { addToast } = useToasts();

  const context = useContext(LookupContext);

  const { id: accountId } = context?.account;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    addressLine1: '',
    addressLine2: '',
    country: '',
    city: '',
    state: '',
    county: '',
    zipCode: '',
    companyName: '',
    taxNumber: '',
    purchaseOrderNumber: '',
    invoiceNotes: '',
    additionalEmailAddresses: '',
    paymentTerm: PaymentTerms[0].value,
  });

  useEffect(() => {
    if (!context?.magpie) {
      return;
    }

    const {
      companyName,
      taxNumber,
      purchaseOrderNumber,
      invoiceNotes,
      paymentTerm,
    } = camelKeys(context.magpie);
    const {
      addressLine1,
      addressLine2,
      country,
      city,
      state,
      county,
      zipCode,
    } = camelKeys(context.magpie.billing_address);

    setFormData({
      addressLine1: addressLine1 ?? '',
      addressLine2: addressLine2 ?? '',
      country: country ?? '',
      city: city ?? '',
      state: state ?? '',
      county: county ?? '',
      zipCode: zipCode ?? '',
      companyName: companyName ?? '',
      taxNumber: taxNumber ?? '',
      purchaseOrderNumber: purchaseOrderNumber ?? '',
      invoiceNotes: invoiceNotes ?? '',
      additionalEmailAddresses: null,
      paymentTerm: paymentTerm ?? PaymentTerms[0].value,
    });
  }, [context.magpie]);

  const handleUpdateField = (event) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const { firstName, lastName, emailAddress } = camelKeys(
      context.magpie.billing_address
    );
    try {
      const response = await updateCustomerDetails(accountId, {
        ...formData,
        user: {
          firstName,
          lastName,
          emailAddress,
        },
      });
      if (!response.ok) {
        const parsedResponse = await response.json();
        throw new Error(parsedResponse?.message ?? response.status);
      }
      window.location.reload();
    } catch (error) {
      setIsSubmitting(false);
      addToast('Order error', { appearance: 'error', autoDismiss: true });
      console.error('Order', error);
    }
  };

  if (!modalProps || !context?.magpie) {
    return null;
  }

  if (isSubmitting) {
    return <LoadingDialog title="Updating Customer Details" />;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open
      onClose={onCloseModal}
      disableEnforceFocus
    >
      <DialogTitle>Update Customer Details for Account {accountId}</DialogTitle>
      <DialogContent>
        <BillingAddress
          formData={formData}
          handleUpdateField={handleUpdateField}
        />
        <SubscriptionsCommonFields
          formData={formData}
          handleUpdateField={handleUpdateField}
          isCreationMode={false}
        />
        <Grid container spacing={10}>
          <Grid item xs>
            <Notes
              invoiceNotes={formData.invoiceNotes}
              handleUpdateField={handleUpdateField}
              textFieldRows={3}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={
            !formData.companyName ||
            !formData.country ||
            (doesCountryRequireState(formData.country) && !formData.state)
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCustomerDetailsModal;
