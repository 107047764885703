import { apiGet, apiPost } from '../../../utils/api';
import { API_SEARCH_BY } from './constants';

export const getEmailResults = (searchTerm) =>
  apiPost('/users', 'search', {
    email: searchTerm,
  });

export const getEmailContainsResults = (searchTerm) =>
  apiPost('/search', undefined, {
    search_by: API_SEARCH_BY.user_accounts_by_email_contains,
    search_key: searchTerm,
  });

export const getURLContainsResults = (searchTerm) =>
  apiPost('/search', undefined, {
    search_by: API_SEARCH_BY.sites_by_url_contains,
    search_key: searchTerm,
  });

/**
 * Returns a collection of customers from magpie
 *
 * Used to:
 * - retrieve the subscription status of the customers
 * - retrieve the total spend (current_period_cost_sumary.amount_with_tax)
 *
 * @param {Array<Number>} accountIds Account id collection
 * @returns {Promise<Array<Object>>}} A promise which, when successfully resolves, returns a Customer collection
 */
export const getCustomers = async (accountIds) => {
  const validAccountIds = accountIds.filter((id) => id).join(',');

  if (!validAccountIds.length) {
    return [];
  }

  const searchParams = new URLSearchParams({
    hotjar_account_ids: validAccountIds,
  });

  return apiGet(`/magpie/customers?${searchParams}`).then((response) =>
    JSON.parse(response)
  );
};
