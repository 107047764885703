import { fetchEnterpriseOrgURL } from './urls';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { SetOrgEnterpriseIDPResponse } from '../models/enterpriseSSO.model';

export const getOrgEnterpriseIDP = async (orgId: number): Promise<string> => {
  const response = await fetch(fetchEnterpriseOrgURL(orgId), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return handleFetchApiResponse<SetOrgEnterpriseIDPResponse>(response).then(
    (response) => response.idp_id
  );
};
