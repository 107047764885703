import { snakeKeys } from 'js-convert-case';
import { differenceWith, isEqual } from 'lodash';
import { getAdditionalEmailAddresses, magpiePostRequest } from './api';
import { getFreePlans } from './getFreePlans';

const getCustomerPayloadMigrate = (
  hotjarAccountId,
  {
    companyName,
    taxNumber,
    paymentTerm,
    invoiceNotes,
    purchaseOrderNumber,
    additionalEmailAddresses,
    firstName,
    lastName,
    emailAddress,
    ...billingAddress
  }
) => ({
  customer: {
    hotjarAccountId,
    companyName,
    taxNumber,
    paymentTerm,
    invoiceNotes,
    purchaseOrderNumber,
    additionalEmailAddresses: getAdditionalEmailAddresses(
      additionalEmailAddresses
    ),
    hotjar_braintree_payment_method_parameters: null,
    billingAddress: {
      ...billingAddress,
      firstName,
      lastName,
      emailAddress,
    },
  },
});

function getProducts(sitesToMigrate, skuType) {
  return sitesToMigrate.flatMap((site) => {
    return site.products.map((product) => {
      const entity = {
        entityIdentifier: site.id,
        entityLabel: site.url !== '' ? site.url : site.name,
        entityType: 'site',
      };

      return {
        sku: product[skuType],
        entity,
      };
    });
  });
}

const getSubscriptionPayload = (accountId, data, productsCatalog) => {
  const {
    discountPercentage,
    billingPeriod,
    sitesToMigrate,
    startDate,
    skipTaxation,
    ...customer
  } = data;

  const currentProducts = getProducts(sitesToMigrate, 'effectiveSku');

  // we're only sending paid plans as `products_to_renew_with`,
  //  non existent item means product will be downgraded to basic in the next billing cycle
  const futureProducts = getProducts(sitesToMigrate, 'sku').filter(
    (product) => !getFreePlans(productsCatalog).includes(product.sku)
  );

  const diffBetweenCurrentAndFuture = differenceWith(
    currentProducts,
    futureProducts,
    isEqual
  );

  // if there no changes between current and future products, then we need to send null as productsToRenewWith
  const productsToRenewWith =
    diffBetweenCurrentAndFuture.length === 0 ? null : futureProducts;
  const payload = {
    ...getCustomerPayloadMigrate(accountId, customer),
    subscription: {
      billingPeriod,
      startDate,
      products: currentProducts,
      productsToRenewWith,
      salesDeductionPercentage: Number(discountPercentage),
      grant_active_discounts: true,
    },
    skipTaxation,
  };

  return {
    customer_and_subscription_migration_order: snakeKeys(payload, {
      recursive: true,
      recursiveInArray: true,
    }),
  };
};

export const previewPriceForMigrate = (
  accountId,
  data,
  productsCatalog,
  abortSignal
) => {
  return magpiePostRequest(
    'orders/preview/v1',
    getSubscriptionPayload(accountId, data, productsCatalog),
    abortSignal
  );
};

export const migrateManualCustomer = (accountId, data, productsCatalog) => {
  return magpiePostRequest(
    'orders',
    getSubscriptionPayload(accountId, data, productsCatalog)
  );
};
