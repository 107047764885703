import moment from 'moment';

import { NO_DATA_PLACEHOLDER } from './constants';

export const localizeDates = (obj, fields) => {
  let clone = { ...obj };
  for (let field of fields) {
    if (Number.isInteger(clone[field])) {
      let dated = new Date(0);
      dated.setUTCSeconds(obj[field]);
      clone[field] = dated;
    }
  }
  return clone;
};

export const pad = (num) => {
  if (num < 10) {
    return '0' + num;
  }
  return num;
};

export const toDateString = (date) => {
  if (date === undefined) return '';
  return moment(date).format('YYYY-MM-DD');
};

// moment.utc(date) when passed a Date it interprets as in UTC timezone
//  disregarding the local timezone
export const toUTCDateString = (date) => {
  if (!date) {
    return '';
  }
  return moment.utc(date).format('YYYY-MM-DD');
};

export const toUTCDateTimeString = (date) => {
  if (!date) {
    return '';
  }
  return pad(toUTCDateString(date)) + ' ' + moment.utc(date).format('HH:mm');
};

export const toUTCTimeString = (date) => {
  if (!date) {
    return '';
  }
  return moment.utc(date).format('HH:mm:ss');
};

export const epochToDateString = (epochDate) => {
  var date = new Date(0);
  date.setUTCSeconds(epochDate);
  date = toUTCDateString(date);
  return date;
};

export const tzAdjustUTCToLocal = (date) => {
  if (!date) return date;
  return moment(date).local().toDate();
};

export const tzAdjustLocalToUTC = (date) => {
  if (!date) return date;
  let d = new Date(date);
  d.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return d;
};

/**
 * Add a number of days to an existing Date object.
 *
 * Use a negative `numDays` to subtract days.
 */
export const addDays = (date, numDays) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
};

/**
 * Formats a timestamp in seconds to `<time> ago` format.
 * With no timestamp, or an invalid one, it will return `-`.
 *
 * @param {Number} timestamp In seconds
 * @returns {String} Formated date as relative time from the present. '-' with no timestamp or an invalid one.
 */
export const timestampToTimeAgo = (timestamp) => {
  const timestampInMiliseconds = timestamp * 1000;
  if (!timestamp || !moment(timestampInMiliseconds).isValid()) {
    return NO_DATA_PLACEHOLDER;
  }

  return moment(timestampInMiliseconds).fromNow();
};
