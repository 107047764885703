import mixpanel from 'mixpanel-browser';
mixpanel.init('b00bc1d50ed5897eb8ebcbcc96df2b0f');

let env_check = process.env.NODE_ENV === 'production';

let Mixpanel = {
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
};

export default Mixpanel;
