import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HelpCenterResources from './HelpCenterResources';
import LearningResources from './LearningResources';
import ShowSpinner from '../ShowSpinner';
import ResourceListItem from './ResourceListItem';
import {
  getZdData,
  getLearningData,
  getTemplates,
  submitLearnLink,
} from './api';
import { trackSegmentEvent } from '../../utils/segment';
import './LearnLinker.css';
import { useUserContext } from '../../contexts/UserContext';

export function LearnLinker() {
  const [selectedResources, setSelectedResources] = useState([]);
  const [message, setMessage] = useState('');
  const [resourceCount, setResourceCount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [learnLink, setLearnLink] = useState(null);
  const [learnLinkLoading, setLearnLinkLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [footerOpen, setFooterOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const user = useUserContext();

  useEffect(() => {
    setLearnLinkLoading(false);
    setResourceCount(selectedResources.length);
  }, [selectedResources]);

  useEffect(() => {
    document.body.classList.add('learn-linker');
    const init = async () => {
      const zdData = await getZdData();
      setCategories(zdData);

      const learningData = await getLearningData();
      setCourses(learningData);

      const templateData = await getTemplates();
      setTemplates(templateData);
    };

    init();
  }, []);

  const updateResourceList = (resources, remove = false) => {
    let newResources = selectedResources;

    resources.forEach((resource) => {
      newResources = remove
        ? newResources.filter((oldResource) => oldResource.url !== resource.url)
        : [...newResources, resource];
    });
    setSelectedResources(newResources);
  };

  const selectTemplate = (template) => {
    let resources = [];
    template.resources.forEach((url) => {
      let resource;
      if (
        selectedResources.filter((resource) => resource.url === url).length ===
        0
      ) {
        Object.keys(courses).forEach((key) => {
          courses[key].forEach((course) => {
            if (course.url === url) resource = course;
          });
        });

        if (!resource) {
          categories.forEach((category) => {
            category.sections.forEach((section) => {
              section.articles.forEach((article) => {
                if (article.url === url) resource = article;
              });
            });
          });
        }
      }

      if (resource) resources.push(resource);
    });

    updateResourceList(resources);
  };

  const submitResources = async () => {
    setLearnLinkLoading(true);
    const entryObj = await submitLearnLink(selectedResources, message);
    setLearnLink(entryObj.entryId);
    setLearnLinkLoading(false);
    trackSegmentEvent('LearnLink Created', {
      LearnLinkId: entryObj['entry_id'],
      userEmail: user.email,
    });
  };

  return (
    <div className={`learnLinkWrapper${!!searchTerm ? ' searching' : ''}`}>
      <Link to="/" className="backToAdm">
        &lt; Back to ADM
      </Link>
      <h1>Welcome to the LearnLinker</h1>
      <p>
        Add any amount of Articles from the Help Center and Courses from Hotjar
        Learning, then hit the button to generate a LearnLink. Send the
        LearnLink to your customer and Hey Presto! They've got a collection of
        resources to start their Hotjar journey.
      </p>
      <div className="message">
        <h2>Message</h2>
        <p>
          Enter a message to be the first thing the customer sees at the top of
          their LearnLink. This is a good place to collect a breakdown of their
          goals.
        </p>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="DO NOT ADD PII HERE."
        />
      </div>

      {templates.length ? (
        <div className="templates">
          <h2>Templates</h2>
          <p>
            Select a template to pre-select some resources. You can still add
            and remove resources as usual. Each LearnLink is still unique, so
            tracking remains separate. If you would like a template creating,
            reach out to Customer Education.
          </p>

          <div className="templateWrapper">
            {templates.map((template) => (
              <div
                className="template"
                onClick={() => {
                  selectTemplate(template);
                }}
                key={template.name}
              >
                <h3>{template.name}</h3>
                <p>{template.resources.length} resources</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ''
      )}

      <div>
        <h2>Search</h2>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className={!!searchTerm.length ? 'searching' : ''}>
        {!categories.length || !Object.keys(courses).length ? (
          <ShowSpinner />
        ) : (
          <>
            <div>
              <h2>Help Center</h2>
              <HelpCenterResources
                categories={categories}
                updateResourceList={updateResourceList}
                searchTerm={searchTerm}
                selectedResources={selectedResources}
              />
            </div>
            <div>
              <h2>Hotjar Learning</h2>
              <LearningResources
                courses={courses}
                updateResourceList={updateResourceList}
                searchTerm={searchTerm}
                selectedResources={selectedResources}
              />
            </div>
          </>
        )}
      </div>
      <div className={`footer ${footerOpen ? 'open' : 'closed'}`}>
        <div className="buttonWrap">
          <p onClick={() => setFooterOpen(!footerOpen)}>
            {footerOpen ? 'Hide' : 'See'} Selected ({resourceCount})
          </p>
          <button
            onClick={submitResources}
            disabled={resourceCount === 0 || learnLinkLoading}
          >
            {learnLinkLoading ? <ShowSpinner /> : <>Generate URL</>}
          </button>
        </div>
        <div className="selectedList">
          <div className="listHead bordered">
            <h4>Selected Resources (click to remove)</h4>
          </div>
          {selectedResources.map((resource) => (
            <ResourceListItem
              resource={resource}
              updateResourceList={updateResourceList}
              checkbox={false}
            />
          ))}
        </div>
      </div>
      {!!learnLink ? (
        <>
          <div className="successBg"></div>
          <div className="linkSuccessBox">
            <h2>LearnLink created Successfully!</h2>
            <p>
              Your LearnLink can be found at
              <br />
              <a
                href={`https://learning.hotjar.com/page/learn?learnLink=${learnLink}`}
              >
                https://learning.hotjar.com/page/learn?learnLink={learnLink}
              </a>
              .<br />
              This shareable link can be copied and shared with the customer.
            </p>
            <p>
              The LearnLinker was built by Customer Education, and we'd love
              your feedback. Use the widget in the bottom-right corner, or find
              us on Slack!
            </p>

            <button
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://learning.hotjar.com/page/learn?learnLink=${learnLink}`
                );
                setIsCopied(true);
              }}
            >
              {!!isCopied ? 'Copied! Copy again?' : 'Copy to Clipboard'}
            </button>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
