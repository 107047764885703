import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  InputLabel,
  FormControl,
  FormControlLabel,
  Select,
  TextField,
} from '@mui/material';
import styles from './CreateForm.module.css';
import { getPlansAndPricingTables } from '../../utils/plans';

const ACTIVE_PRICING_TABLE_ID = '0';
const ACTIVE_DUAL_SKU_PRICING_TABLE_ID = '8';
const ACTIVE_UBP_PRICING_TABLE_ID = '16';

const filterPlans = (plans, pricingTables, ID) => {
  return plans.filter((plan) =>
    Object.keys(pricingTables[ID]).includes(plan.identifier)
  );
};

const separateSKUPlans = (plans) => {
  let finalPlans = {
    analytics: [],
    feedback: [],
  };
  plans.forEach((plan) => {
    let planParts = plan.identifier.split(';');
    if (!finalPlans.analytics.includes(planParts[0]))
      finalPlans.analytics.push(planParts[0]);
    if (!finalPlans.feedback.includes(planParts[1]))
      finalPlans.feedback.push(planParts[1]);
  });
  return finalPlans;
};

const CreateForm = ({ error_message, onFormSubmit }) => {
  const [createData, setCreateData] = useState({
    name: '',
    plan_id: 'plus_10000',
    plan_id_analytics: 'analytics_basic_2000',
    plan_id_feedback: 'feedback_basic_20',
    num_days: '',
    url: '',
    type: 'registration',
    dualSKU: false,
    usageBasedPricing: false,
  });
  const [plans, setPlans] = useState({
    singleSKU: [],
    usageBasedPricing: [],
    dualSKU: {
      analytics: [],
      feedback: [],
    },
  });

  useEffect(() => {
    getPlansAndPricingTables().then(({ plans, pricingTables }) => {
      let singleSKUPlans = filterPlans(
        plans,
        pricingTables,
        ACTIVE_PRICING_TABLE_ID
      );
      let dualSKUPlans = separateSKUPlans(
        filterPlans(plans, pricingTables, ACTIVE_DUAL_SKU_PRICING_TABLE_ID)
      );
      let usageBasedPricingPlans = separateSKUPlans(
        filterPlans(plans, pricingTables, ACTIVE_UBP_PRICING_TABLE_ID)
      );
      setPlans({
        singleSKU: singleSKUPlans,
        dualSKU: dualSKUPlans,
        usageBasedPricing: usageBasedPricingPlans,
      });
    });
  }, []);

  const handleFieldChange = (event, key = null) => {
    const { id, value } = event.target;
    setCreateData({
      ...createData,
      [key || id]: value,
    });
  };

  const handleSKUCheckChange = (event) => {
    setCreateData({
      ...createData,
      usageBasedPricing: false,
      dualSKU: event.target.checked,
    });
  };

  const handleUBPCheckChange = (event) => {
    setCreateData({
      ...createData,
      dualSKU: false,
      usageBasedPricing: event.target.checked,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onFormSubmit(createData);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <TextField
        label={error_message || 'Campaign Name'}
        id="name"
        name="name"
        value={createData.name}
        onChange={handleFieldChange}
        error={error_message != null}
        className={styles.inputField}
        required
        data-hj-suppress
        variant="standard"
      />
      <TextField
        label="Campaign URL"
        id="url"
        name="url"
        value={createData.url}
        onChange={handleFieldChange}
        className={styles.inputField}
        required
        data-hj-suppress
        variant="standard"
      />
      <span className={styles.checkboxes}>
        <FormControl className={styles.inputField}>
          <FormControlLabel
            control={<Checkbox />}
            checked={createData.dualSKU}
            label="Create campaign for Dual SKUs"
            onChange={handleSKUCheckChange}
          />
        </FormControl>
        <FormControl className={styles.inputField}>
          <FormControlLabel
            control={<Checkbox />}
            checked={createData.usageBasedPricing}
            label="Create campaign for UBP"
            onChange={handleUBPCheckChange}
          />
        </FormControl>
      </span>
      {createData.dualSKU || createData.usageBasedPricing ? (
        <>
          <FormControl required className={styles.inputField}>
            <InputLabel htmlFor="plan_id_analytics">Observe Plan</InputLabel>
            <Select
              native
              name="plan_id_analytics"
              id="plan_id_analytics"
              value={createData.plan_id_analytics}
              onChange={(e) => handleFieldChange(e, 'plan_id_analytics')}
              variant="standard"
            >
              {createData.dualSKU && plans?.dualSKU?.analytics
                ? plans.dualSKU.analytics.map((plan) => (
                    <option value={plan} key={plan}>
                      {plan.split('_').join(' ')}
                    </option>
                  ))
                : null}
              {createData.usageBasedPricing &&
              plans?.usageBasedPricing?.analytics
                ? plans.usageBasedPricing.analytics.map((plan) => (
                    <option value={plan} key={plan}>
                      {plan.split('_').join(' ')}
                    </option>
                  ))
                : null}
            </Select>
          </FormControl>
          <FormControl required className={styles.inputField}>
            <InputLabel htmlFor="plan_id_feedback">Ask Plan</InputLabel>
            <Select
              native
              name="plan_id_feedback"
              id="plan_id_feedback"
              value={createData.plan_id_feedback}
              onChange={(e) => handleFieldChange(e, 'plan_id_feedback')}
              variant="standard"
            >
              {createData.dualSKU && plans?.dualSKU?.analytics
                ? plans.dualSKU.feedback.map((plan) => (
                    <option value={plan} key={plan}>
                      {plan.split('_').join(' ')}
                    </option>
                  ))
                : null}
              {createData.usageBasedPricing &&
              plans?.usageBasedPricing?.analytics
                ? plans.usageBasedPricing.feedback.map((plan) => (
                    <option value={plan} key={plan}>
                      {plan.split('_').join(' ')}
                    </option>
                  ))
                : null}
            </Select>
          </FormControl>
        </>
      ) : (
        <FormControl required className={styles.inputField}>
          <InputLabel id="plan_label" htmlFor="plan_id">
            Plan
          </InputLabel>
          <Select
            native
            labelId="plan_label"
            name="plan_id"
            id="plan_id"
            value={createData.plan_id}
            onChange={(e) => handleFieldChange(e, 'plan_id')}
            variant="standard"
          >
            {plans && plans.singleSKU
              ? plans.singleSKU.map((plan) => (
                  <option value={plan.identifier} key={plan.identifier}>
                    {plan.label}
                  </option>
                ))
              : null}
          </Select>
        </FormControl>
      )}
      <TextField
        label="Number of Free Days"
        id="num_days"
        name="num_days"
        value={createData.num_days}
        onChange={handleFieldChange}
        className={styles.inputField}
        data-hj-suppress
        required
        type="number"
        variant="standard"
      />
      <FormControl required className={styles.inputField}>
        <InputLabel htmlFor="type">Type</InputLabel>
        <Select
          name="type"
          id="type"
          value={createData.type}
          onChange={(e) => handleFieldChange(e, 'type')}
          variant="standard"
          native
        >
          <option value="registration">Registration</option>
          <option value="extension">Trial Extension</option>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        id="search"
        className={styles.createButton}
        type="submit"
      >
        Create
      </Button>
    </form>
  );
};

export default CreateForm;
