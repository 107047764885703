import React, { useContext } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { MODAL_ADD_INTERNAL_USER } from '../../components/modals/constants';
import { ModalContext } from '../../contexts/ModalContext';

const UserRoleTableToolbar = () => {
  const context = useContext(ModalContext);

  const openModal = () => {
    context.openModal(MODAL_ADD_INTERNAL_USER);
  };

  return (
    <Tooltip title={'Add Internal User'}>
      <IconButton onClick={openModal}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
};

export default UserRoleTableToolbar;
