import moment from 'moment';
import t from 'tcomb-validation';

// eslint-disable-next-line
export const emailRe = new RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

export const Email = t.refinement(t.String, function (s) {
  return emailRe.test(s);
});
Email.getValidationErrorMessage = () => {
  return 'Valid email required';
};

export const Currency = t.refinement(t.String, function (s) {
  return ['eur', 'gbp', 'usd'].indexOf(s) !== -1;
});
Currency.getValidationErrorMessage = () => {
  return 'Currency must be EUR, USD or GBP';
};

export const PositiveInteger = t.refinement(t.Integer, function (s) {
  return s > 0;
});
PositiveInteger.getValidationErrorMessage = () => {
  return 'Positive Integer required';
};

export const PositiveNumber = t.refinement(t.Number, function (s) {
  return s > 0;
});
PositiveNumber.getValidationErrorMessage = () => {
  return 'Positive Number required';
};

export const RequiredString = t.refinement(t.String, function (s) {
  return !!s;
});
RequiredString.getValidationErrorMessage = () => {
  return 'This field is required';
};

export const DateBetweenNowAnd = (maxDate) => {
  const refinement = t.refinement(t.String, (val) => {
    const date = moment(val);
    const today = moment();
    return (
      date.isSameOrBefore(maxDate, 'day') && date.isSameOrAfter(today, 'day')
    );
  });

  refinement.getValidationErrorMessage = () => {
    return `Please select a date before the next billing date (${maxDate.format(
      'YYYY-MM-DD'
    )}) and after today (${moment().format('YYYY-MM-DD')})`;
  };

  return refinement;
};
