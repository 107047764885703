import React, { Component } from 'react';
import Select from '@mui/material/Select';
import { countryChoices } from '../../../utils/countries';
import styles from './UpgradeUser.module.css';

export default class Form extends Component {
  handleCountryChange = (event) => {
    this.props.onFormUpdate({
      country: event.target.value,
    });
  };

  handleAccountTypeChange = (event) => {
    this.props.onFormUpdate({
      account_type: event.target.value,
    });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <Select
          native
          label="Country"
          className={styles.select}
          value={this.props.country}
          onChange={this.handleCountryChange}
          data-testid="country_select"
          variant="standard"
        >
          {countryChoices.map(function (country) {
            return (
              <option value={country.value} key={country.value}>
                {country.label}
              </option>
            );
          })}
        </Select>
        <Select
          native
          label="Account Type"
          className={styles.select}
          value={this.props.account_type}
          onChange={this.handleAccountTypeChange}
          data-testid="account_type_select"
          variant="standard"
        >
          <option value="individual">Individual</option>
          <option value="agency">Agency</option>
        </Select>
      </form>
    );
  }
}
