import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const sources = new Map([
  ['add_hoc_granted_by_support', 'Ad hoc service granted by support'],
  [
    'provision_sales_plans',
    'Provision sales assisted service (VoC Pro, DXA Growth > 200K)',
  ],
]);

export function SourceSelect() {
  const {
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();
  return (
    <FormControl fullWidth error={!!errors.source}>
      <InputLabel id="source-select-label">Source</InputLabel>
      <Select
        labelId="source-select-label"
        id="source"
        label="Source"
        required
        value={watch('source')}
        error={!!errors.source}
        onChange={(event) => setValue('source', event.target.value)}
      >
        {Array.from(sources.entries()).map(([key, label]) => (
          <MenuItem key={key} value={key}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
