import { fetchEnterpriseOrgURL } from './urls';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { SetOrgEnterpriseIDPResponse } from '../models/enterpriseSSO.model';

export const setOrgEnterpriseIDP = async (
  orgId: number,
  idpId: string | null
): Promise<SetOrgEnterpriseIDPResponse> => {
  const response = await fetch(fetchEnterpriseOrgURL(orgId), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      idp_id: idpId,
    }),
  });

  return handleFetchApiResponse<SetOrgEnterpriseIDPResponse>(response);
};
