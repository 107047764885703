import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import Logout from './Logout';
import ClickToCopy from './ClickToCopy';
import Logo from '../images/logo.svg';
import styles from './MainAppBar.module.css';

class MainAppBar extends React.Component {
  render() {
    let isTopWindow = window.self === window.top; // Checks to see if being rendered NOT in an iframe
    return (
      <div className={styles.header}>
        <Link to="/">
          <img src={Logo} alt="Hotjar Admin" className={styles.logo} />
        </Link>
        {process.env.REACT_APP_ENVIRONMENT ? (
          <p className={styles.environment}>
            {process.env.REACT_APP_ENVIRONMENT} environment
          </p>
        ) : null}
        {!isTopWindow && (
          <ClickToCopy overwriteText={window.self.location.href}>
            <LinkIcon className={styles.link} />
          </ClickToCopy>
        )}
        <Logout />
      </div>
    );
  }
}
export default withRouter(MainAppBar);
