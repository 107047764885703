import React from 'react';
import { Link } from 'react-router-dom';
import { TextField, Button, Paper } from '@mui/material';
import styles from './ResetADMPassword.module.css';
import { postResetADMPassword } from './api';

export default class ResetADMPassword extends React.Component {
  state = {
    token: null,
    hasToken: false,
    passwordError: false,
    errorMessage: null,
    success: false,
    form: {
      password: '',
      password_confirm: '',
    },
  };

  PASSWORD_INVALID_MESSAGE = () => (
    <span className={styles['password-error']}>
      Passwords must include:
      <br />
      One uppercase letter
      <br />
      One lowercase letter
      <br />
      One number
      <br />
      One symbol
      <br />A minimum of 22 characters
    </span>
  );

  componentDidMount() {
    if (this.props.location.search.includes('token')) {
      // Get token from URL
      const token = this.props.location.search.split('=')[1];

      this.setState({
        ...this.state,
        token,
        hasToken: true,
      });
    } else {
      this.sendError("Oops! Seems like you don't have a token");
    }
  }

  sendError(errorMessage) {
    this.setState({
      ...this.state,
      passwordError: true,
      errorMessage,
    });
  }

  handleChange(e) {
    // Set form as state
    const name = e.target.name;
    const value = e.target.value;
    let form = {
      ...this.state.form,
    };
    form[name] = value;
    this.setState({
      ...this.state,
      form,
    });
  }

  handleSubmit() {
    // Validate and send
    if (this.validatePassword()) {
      this.sendPassword();
    }
  }

  validatePassword() {
    // Check the passwords are the same
    const { password, password_confirm } = this.state.form;
    if (password !== password_confirm) {
      this.sendError('Passwords must match');
      return false;
    }
    // 22 characters, 1 digit, 1 upper, 1 lower, 1 symbol
    const regex =
      /(?=.{22,})(?=.*\d)((?=.*[a-z]))((?=.*[A-Z]))((?=.*[!@#$£%^&*()\-_=+}{:;?/.,<>`[\]\\|~])).*/g;
    if (!password.match(regex)) {
      this.sendError(this.PASSWORD_INVALID_MESSAGE());
      return false;
    }
    return true;
  }

  async sendPassword() {
    const {
      token,
      form: { password, password_confirm },
    } = this.state;
    try {
      await postResetADMPassword(token, password, password_confirm);
      // redirect to login
      this.setState({
        success: true,
      });
    } catch (error) {
      this.setState({
        ...this.state,
        hasToken: error.success,
        errorMessage: error.error,
      });
    }
  }

  render() {
    if (this.state.success) {
      return (
        <Paper className={styles.form}>
          <p>Your password has been reset!</p>
          <p>
            Please <Link to="/login">Login</Link>
          </p>
        </Paper>
      );
    } else if (!this.state.hasToken) {
      return (
        <Paper className={styles.form}>
          <p>{this.state.errorMessage}</p>
        </Paper>
      );
    } else {
      return (
        <Paper className={styles.form}>
          <form>
            <h3>Enter a new password</h3>
            <TextField
              autoFocus
              label="New Password"
              value={this.state.form.password}
              name="password"
              id="reset_password_password"
              className={styles.input}
              onChange={(e) => this.handleChange(e)}
              type="password"
              error={this.state.passwordError}
              variant="standard"
            />
            <TextField
              label="Confirm Password"
              value={this.state.form['password_confirm']}
              name="password_confirm"
              id="reset_password_password_confirm"
              className={styles.input}
              onChange={(e) => this.handleChange(e)}
              type="password"
              error={this.state.passwordError}
              helperText={this.state.errorMessage}
              variant="standard"
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={() => this.handleSubmit()}
              className={styles.button}
            >
              Reset Password
            </Button>
          </form>
        </Paper>
      );
    }
  }
}
