import { useEffect } from 'react';
import type {
  DefaultValues,
  FieldValues,
  Resolver,
  UseFormReturn,
} from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { entities } from './formUtils';

export type FeatureAction<TFields extends FieldValues> = (
  data: { feature: string } & TFields
) => Promise<void>;

export type FeatureActionFormProps<TFields extends FieldValues> = {
  feature: string;
  action: FeatureAction<TFields>;
  close: () => void;
  defaultValues?: DefaultValues<TFields>;
  resolver?: Resolver<TFields>;
};

export type FeatureActionFormReturn<TFields extends FieldValues> = [
  UseFormReturn<TFields>,
  ReturnType<UseFormReturn<TFields>['handleSubmit']>
];

export const useFeatureActionForm = <TFields extends FieldValues>({
  feature,
  action,
  close,
  defaultValues,
  resolver,
}: FeatureActionFormProps<TFields>): FeatureActionFormReturn<TFields> => {
  const form = useForm<TFields>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues,
    resolver,
  });

  useEffect(() => {
    if (form.formState.isSubmitSuccessful) {
      close();
    }
  }, [form.formState.isSubmitSuccessful, close]);

  const handleSubmit = form.handleSubmit((fields) =>
    action({
      feature,
      ...fields,
    })
  );

  return [form, handleSubmit];
};

export const entityErrorMessage = `Entity must be one of: ${Object.values(
  entities
).join(', ')}`;
