type OrderStatus = 'SUCCESSFUL' | 'PROCESSING';

interface PollOrderStatusResponse {
  order_identifier: string;
  last_updated: string;
  status: OrderStatus;
  error: string | null;
}

export async function getOrderStatus(identifier: string) {
  const rawResponse = await fetch(`/api/v2/adm/magpie/orders/${identifier}`);
  const response: PollOrderStatusResponse = await rawResponse.json();
  return response;
}
