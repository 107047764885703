import React from 'react';

import Drawer from '@mui/material/Drawer';
import { NavLink } from 'react-router-dom';

import HasPermission from './HasPermission';
import styles from './SideMenu.module.css';
import { UsernameFooter } from './UsernameFooter';
import { trackSegmentEvent } from '../utils/segment';
import { getCookie } from '../utils/cookies';

const TrackedNavLink = ({ children, to, ...props }) => {
  const userIdFromCookie = getCookie('ajs_user_id');
  const handleEventTracking = () => {
    trackSegmentEvent('ADM Navigation Item Clicked', {
      navItemName: to,
      admUserId: userIdFromCookie,
    });
  };

  return (
    <NavLink to={to} {...props} onClick={handleEventTracking}>
      {children}
    </NavLink>
  );
};

export default function SideMenu({ isTopWindow }) {
  if (isTopWindow) {
    return (
      <Drawer open={true} variant="permanent" className={styles.sideMenu}>
        <TrackedNavLink to="/lookup">Lookup</TrackedNavLink>
        <TrackedNavLink to="/reports">Reports</TrackedNavLink>
        <TrackedNavLink to="/feature-manager">Feature Manager</TrackedNavLink>
        <TrackedNavLink to="/invoice-reports">Invoice Reports</TrackedNavLink>
        <TrackedNavLink to="/user-notifications">
          User Notifications
        </TrackedNavLink>
        <TrackedNavLink to="/campaigns">Campaigns</TrackedNavLink>
        <TrackedNavLink to="/referrals">Referrals</TrackedNavLink>
        <TrackedNavLink to="/learn-linker">LearnLinker</TrackedNavLink>
        <HasPermission rolesAccepted={['admin', 'adm_user_manager']}>
          <TrackedNavLink to="/internal-user-management">
            Internal Users
          </TrackedNavLink>
        </HasPermission>
        <HasPermission rolesAccepted={['admin', 'dxa_voc_manual_provisioning']}>
          <TrackedNavLink to="/provisioning-voc">
            Provision VoC insights
          </TrackedNavLink>
        </HasPermission>
        <UsernameFooter />
      </Drawer>
    );
  } else {
    return null;
  }
}
