import React, { Component } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styles from './SearchForm.module.css';

class SearchForm extends Component {
  handleTextFieldChange = (event) => {
    this.props.onFormUpdate({
      [event.target.name]: event.target.value.trim(),
    });
  };

  handleSelectChange = (event) => {
    this.props.onFormUpdate({
      search_by: event.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onFormSubmit();
  };

  getSearchTerm = () => {
    let self = this;
    let terms = self.props.search_fields.filter(function (obj) {
      return obj.value === self.props.search_by;
    });
    if (terms.length > 0) {
      return terms[0].text;
    }
  };

  render() {
    return (
      <form
        className={styles.form}
        onSubmit={this.handleSubmit}
        data-testid="searchform"
      >
        <SearchIcon className={styles.searchIcon} />
        <FormControl variant="standard">
          <InputLabel id="search-form-label" htmlFor="lookup.search_by">
            Search by
          </InputLabel>
          <Select
            labelId="search-form-label"
            name="search_by"
            value={this.props.search_by}
            className={styles.select}
            onChange={this.handleSelectChange}
            id="lookup.search_by"
          >
            {this.props.search_fields
              ? this.props.search_fields.map((field, i) => {
                  return (
                    <MenuItem
                      id={`menuItem_${field.value}`}
                      value={field.value}
                      key={i}
                    >
                      {field.text}
                    </MenuItem>
                  );
                })
              : ''}
          </Select>
        </FormControl>
        <TextField
          label={
            this.props.error_message === null
              ? this.getSearchTerm()
              : this.props.error_message
          }
          id="lookup.search_term"
          name="search_term"
          value={this.props.search_term}
          className={styles.textField}
          onChange={this.handleTextFieldChange}
          error={this.props.error_message !== null}
          data-hj-suppress
          variant="standard"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleSubmit}
          disabled={this.props.search_term === ''}
          className={styles.searchButton}
          type="submit"
        >
          lookup
        </Button>
      </form>
    );
  }
}

export default SearchForm;
