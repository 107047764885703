import { Link } from 'react-router-dom';
import clsx from 'clsx';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import {
  DetailItem,
  EntityDetail,
  EntityDetailsColumnsConfig,
  EntityDetailWithLink,
  EntityDetailWithLinkButton,
} from './interfaces';
import ButtonLink from '../ButtonLink';
import ExternalLink from '../ExternalLink';
import { NO_DATA_PLACEHOLDER } from '../../utils/constants';

import styles from './EntityDetails.module.css';

const outerLink = (item: EntityDetailWithLink) => (
  <ExternalLink url={item.url} text={item.value as string} />
);

const admLink = (item: EntityDetailWithLink) => (
  <Link to={item.url} className={styles.link}>
    {item.value}
  </Link>
);

const isAbsoluteUrl = (url: string) => url.includes('https://' || 'http://');
const getLinkComponent = (item: EntityDetailWithLink) =>
  isAbsoluteUrl(item.url) ? outerLink(item) : admLink(item);
const getButtonLinkComponent = (item: EntityDetailWithLinkButton) => (
  <ButtonLink className={styles.link} onClick={item.onClick}>
    {item.value}
  </ButtonLink>
);

const getRenderedValue = (
  item: EntityDetail | EntityDetailWithLink | EntityDetailWithLinkButton
) => {
  if ('url' in item) {
    return getLinkComponent(item);
  }

  if ('onClick' in item && item.onClick !== undefined) {
    return getButtonLinkComponent(item);
  }

  return item.value || NO_DATA_PLACEHOLDER;
};

const renderTooltip = (tooltipText?: string) => {
  if (!tooltipText) {
    return null;
  }

  return (
    <Tooltip title={tooltipText}>
      <InfoIcon fontSize="small" className={styles.costInfoIcon} />
    </Tooltip>
  );
};

export const buildItem = (index: number, item?: DetailItem) => {
  if (!item) {
    // Needed non-null element to:
    // * keep a slot empty
    // * prevent the next item from using that empty slot
    return <div key={index} />;
  }

  const values = Array.isArray(item.value)
    ? item.value
    : [getRenderedValue(item)];

  return (
    <div key={index} className={styles.detailsItem}>
      <dt
        className={clsx(styles.label, {
          [styles.labelWithTooltip]: item.tooltipText,
        })}
      >
        {item.name}
        {renderTooltip(item.tooltipText)}
      </dt>
      {values.map((value, index) => (
        <dd key={index} className={styles.value}>
          {value}
        </dd>
      ))}
    </div>
  );
};

export const getGridClass = (columnCount: 1 | 2 | 3 | 4): string => {
  const gridClass = {
    1: styles.columns1,
    2: styles.columns2,
    3: styles.columns3,
    4: styles.columns4,
  };

  return gridClass[columnCount];
};

export const getGridData = (data: EntityDetailsColumnsConfig): DetailItem[] => {
  // Check column count
  // to be used to set styling of the grid
  const columnCount = data.length;
  // build the rows
  const columnAccessKeys = Array.from(Array(columnCount).keys());
  // Check the column with highest number of items
  const columnLengths = data.map((column) => column!.length);
  // Needed to fill in eventual empty spaces in columns
  // for that we need the largest column
  const rowCount = Math.max(...columnLengths);
  const rowKeys = Array.from(Array(rowCount).keys());

  const gridData = rowKeys.reduce((allData, rowIndex) => {
    const rowData = columnAccessKeys.map(
      (columnKey) => data[columnKey]![rowIndex]
    );
    return [...allData, ...rowData];
  }, [] as DetailItem[]);

  return gridData;
};
