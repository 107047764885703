import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import styles from './Reports.module.css';

const QueryForm = ({ fields, persistedFields, submitForm }) => {
  const [formFields, setFormFields] = useState({});

  useEffect(() => {
    // Adding this to state here means we don't have to check the current state when we update the form.
    // It uses the operator[0] as default (same as the select), but if operators isn't an array it will fail.
    let initialFieldSetup = { ...persistedFields };
    fields.forEach((field) => {
      if (!initialFieldSetup[`${field.id}_operator`]) {
        initialFieldSetup[`${field.id}_operator`] = field.operators[0];
      }
      if (!initialFieldSetup[`${field.id}_data`]) {
        initialFieldSetup[`${field.id}_data`] = field.default_value || '';
      }
    });
    setFormFields(initialFieldSetup);
  }, [fields, persistedFields]);

  const handleSubmit = (e) => {
    e.preventDefault();
    submitForm(formFields);
  };

  const handleFormUpdate = (event, fieldId, property) => {
    const newState = { ...formFields };
    if (event.target.type === 'checkbox') {
      newState[`${fieldId}_${property}`] = event.target.checked;
    } else {
      newState[`${fieldId}_${property}`] = event.target.value;
    }
    setFormFields(newState);
  };

  const strToBool = (inputData) => {
    if (typeof inputData === 'string') {
      return inputData === 'true';
    } else {
      return inputData;
    }
  };

  const setupDataField = (field) => {
    const fieldMap = {
      text_area: TextField,
      text: TextField,
      select: Select,
      checkbox: FormControlLabel,
    };
    let ComponentToRender = fieldMap[field.type];
    let componentProps = {
      id: `${field.id}_data`,
      label: field.label,
      name: `${field.id}_data`,
      value: formFields[`${field.id}_data`] || '',
      onChange: (e) => handleFormUpdate(e, field.id, 'data'),
      required: field.required,
      variant: 'standard',
    };

    if (field.type === 'checkbox') {
      componentProps.control = (
        <Checkbox
          checked={strToBool(formFields[`${field.id}_data`]) || false}
          onChange={(e) => handleFormUpdate(e, field.id, 'data')}
          name={`${field.id}_data`}
        />
      );
    } else {
      componentProps.inputProps = {
        pattern: field.pattern,
        title: field.title,
      };
    }

    if (field.type === 'text_area') {
      componentProps.multiline = true;
    }

    return (
      <ComponentToRender {...componentProps} data-testid={field.id}>
        {field.type === 'select' &&
          field.options &&
          field.options.map((option, i) => (
            <MenuItem value={option.value} key={field.id + '-' + i}>
              {option.label}
            </MenuItem>
          ))}
      </ComponentToRender>
    );
  };

  const setupOperatorField = (field) => {
    if (field.type === 'checkbox') return null;
    if (field.operators.length === 1) return field.operators[0];
    else {
      return (
        <Select
          native
          name={`${field.id}_operator`}
          value={formFields[`${field.id}_operator`] || field.operators[0]}
          id={`${field.id}_operator`}
          onChange={(e) => handleFormUpdate(e, field.id, 'operator')}
        >
          {field.operators.map((operator) => (
            <option key={`${field.id}_operator_${operator}`} value={operator}>
              {operator}
            </option>
          ))}
        </Select>
      );
    }
  };

  return (
    <Paper className={styles.formWrapper}>
      <form data-testid="form" className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formFields}>
          {fields &&
            fields.map((field) => {
              return (
                <React.Fragment key={field.id}>
                  <div className={styles.fieldName}>
                    {field.type !== 'checkbox' && field.name}
                  </div>
                  <div className={styles.fieldOperator}>
                    {setupOperatorField(field)}
                  </div>
                  {setupDataField(field)}
                </React.Fragment>
              );
            })}
        </div>
        <div className={styles.buttonRow}>
          <Button
            disabled={Object.keys(formFields).length === 0}
            variant="contained"
            color="primary"
            type="submit"
            className={styles.submit}
          >
            Get Report
          </Button>
        </div>
      </form>
    </Paper>
  );
};

export default QueryForm;
