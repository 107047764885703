import React, { ReactNode, ForwardRefExoticComponent } from 'react';
import styles from './BadgeWrapper.module.css';

interface BadgeWrapperProps {
  children: ReactNode;
}

const BadgeWrapper: ForwardRefExoticComponent<BadgeWrapperProps> =
  React.forwardRef<HTMLDivElement, BadgeWrapperProps>(
    ({ children, ...props }, ref) => (
      <div {...props} ref={ref} className={styles.badgeWrapper}>
        {children}
      </div>
    )
  );

export default BadgeWrapper;
