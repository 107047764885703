import { useEffect } from 'react';
import { useModalContext } from '../../../../contexts/ModalContext';
import SamplingVisualizer from '../../../../components/modals/SamplingVisualizer';
import AdmActionsModal from '../../../../components/modals/AdmActions';
import { ModifyPlanSettingsModalMagpie } from '../../../../components/modals/ModifyPlanSettingsMagpie';
import ConfirmationDialog from '../../../../components/modals/ConfirmationDialog';
import {
  MODAL_SAMPLING_VISUALIZER,
  MODAL_ADM_ACTIONS,
  MODAL_MODIFY_PLAN_MAGPIE,
  MODAL_CONFIRMATION_DIALOG,
} from '../../../../components/modals/constants';
import { AdmModalRoot } from '../../../../components/modals/AdmModalRoot';

const MODAL_MAP = {
  [MODAL_SAMPLING_VISUALIZER]: SamplingVisualizer,
  [MODAL_ADM_ACTIONS]: AdmActionsModal,
  [MODAL_MODIFY_PLAN_MAGPIE]: ModifyPlanSettingsModalMagpie,
  [MODAL_CONFIRMATION_DIALOG]: ConfirmationDialog,
} as const;

const SiteModals = () => {
  const { closeAllModals } = useModalContext();

  useEffect(() => {
    let openModal = null;
    if ('URLSearchParams' in window) {
      // Handle automatic modal opening.
      const searchParams = new URLSearchParams(window.location.search);
      // We must have a target site_id or we can't open anything.
      openModal = searchParams.get('open');
    }

    if (!openModal) {
      closeAllModals();
    }
  }, [closeAllModals]);

  return <AdmModalRoot modalMap={MODAL_MAP} />;
};

export default SiteModals;
