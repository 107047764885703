import { Dialog, DialogContent, Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useUserAuditLogs } from '../../../../domains/users/api/useUserAuditLogs';
import moment from 'moment';
import { getLocationDisplay } from './getLocationDisplay';
import MUIDataTable from 'mui-datatables';
import { useModalContext } from '../../../../contexts/ModalContext';
import { MODAL_VIEW_USER_AUDIT_LOG } from '../../../../components/modals/constants';
import { DialogTitleWithClose } from '../../../../components/DialogTitleWithClose';

export const UserAuditLogModal = () => {
  const { isModalOpen, openModalProps, closeModal } = useModalContext();
  const modalProps = openModalProps[MODAL_VIEW_USER_AUDIT_LOG] as
    | {
        name: string;
        userId: number;
      }
    | undefined;
  const isOpen = isModalOpen(MODAL_VIEW_USER_AUDIT_LOG);

  const { auditLogs = [], isAuditLogLoading } = useUserAuditLogs(
    modalProps?.userId
  );

  const mappedData = useMemo(() => {
    return auditLogs.map((item) => ({
      sessionType: item.message,
      location: getLocationDisplay(
        item.client?.geo?.country_name,
        item.client?.geo?.city_name
      ),
      date: item['@timestamp'],
    }));
  }, [auditLogs]);

  const close = () => {
    closeModal(MODAL_VIEW_USER_AUDIT_LOG);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="md" open onClose={close}>
      <DialogTitleWithClose onClose={close}>
        {modalProps?.name}
      </DialogTitleWithClose>
      <DialogContent>
        {isAuditLogLoading ? (
          <Typography>Loading...</Typography>
        ) : (
          <MUIDataTable
            options={{ selectableRows: 'none' }}
            columns={[
              { name: 'sessionType', label: 'Session Type' },
              { name: 'location', label: 'Location' },
              {
                name: 'date',
                label: 'Date',
                options: {
                  customBodyRender: (value) => {
                    return (
                      <Tooltip title={value}>
                        <div>{moment(value).fromNow()}</div>
                      </Tooltip>
                    );
                  },
                },
              },
            ]}
            data={mappedData}
            title={`Sign In Activity`}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
