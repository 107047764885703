export const selects = {
  payment_method_type: [
    {
      value: 'bank_transfer',
      text: 'Bank Transfer',
    },
    {
      value: 'credit_card',
      text: 'Credit Card',
    },
    {
      value: 'paypal',
      text: 'Paypal',
    },
  ],
  status: [
    {
      value: 'paid',
      text: 'Paid',
    },
    {
      value: 'unpaid',
      text: 'Unpaid',
    },
    {
      value: 'processing',
      text: 'Processing',
    },
    {
      value: 'cancelled',
      text: 'Cancelled',
    },
  ],
  location: [
    {
      value: 'MT',
      text: 'Malta',
    },
    {
      value: 'SG',
      text: 'Singapore',
    },
    {
      value: 'EU',
      text: 'EU',
    },
    {
      value: 'OEU',
      text: 'OEU',
    },
  ],
  has_vat_number: [
    {
      value: 'true',
      text: 'Yes',
    },
    {
      value: 'false',
      text: 'No',
    },
  ],
};
