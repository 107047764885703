import { buildUrl } from '../../../utils/api';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { TrialEntityType } from '../models/TrialEntityType';
import { TrialEligibility } from '../models/TrialEligibility';

export const fetchTrialEligibility = async (
  accountId: string | number | undefined,
  entityType: TrialEntityType,
  entityId: string | undefined
): Promise<TrialEligibility> => {
  const response = await fetch(
    buildUrl(
      `/api/v2/adm/trials/trials/bundle/eligibility?entity_type=${entityType}&entity_id=${entityId}&hotjar_account_id=${accountId}`
    )
  );

  return handleFetchApiResponse<TrialEligibility>(response);
};
