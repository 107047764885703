import { isNil, isObject } from 'lodash';

export const _getByKey = (key, payload, defaultObj) => {
  return Object.keys(payload).indexOf(key) > -1
    ? payload[key]
    : defaultObj[key];
};

/**
 * This function flattens all data into one single array also processing the child elements.
 * @param object: an array, json object or a primitive data type
 * @return a single array with all values which are not null or undefined or an empty array
 */
export const flattenRecursively = (object) => {
  return !isNil(object) && Array.isArray(object)
    ? object
        .filter((value) => !isNil(value))
        .reduce((acc, val) => acc.concat(flattenRecursively(val)), [])
    : isObject(object)
    ? flattenRecursively(Object.values(object))
    : object;
};

/**
 * Check if the search term exists in the searchable text.
 * @param searchable: a string to search within
 * @param searchTerm: the search term to find int he searchable string
 * @return {boolean}: true if the searchTerm is found in the searchable string,
 *                      false if any of the strings is null or undefined or if
 *                      the searchTerm is not found in the searchable string.
 */
export const includesIgnoreCase = (searchable, searchTerm) => {
  return (
    !isNil(searchable) &&
    !isNil(searchTerm) &&
    searchable.toLowerCase().includes(searchTerm.toLowerCase())
  );
};
