import { Typography } from '@mui/material';
import { FC } from 'react';
import ClickToCopy from '../ClickToCopy';

import styles from './ViewHeader.module.css';
import ColoredLabel from '../ColoredLabel';

export enum ViewEntity {
  Site = 'Site',
  Account = 'Account',
  Organization = 'Organization',
}

interface ViewHeaderProps {
  entityId: string;
  entityName: ViewEntity;
  isEntityDeleted?: boolean;
  badgesRenderer: () => JSX.Element;
  actionsRenderer: () => JSX.Element;
}

const ViewHeader: FC<ViewHeaderProps> = ({
  entityId,
  entityName,
  isEntityDeleted,
  badgesRenderer,
  actionsRenderer,
}) => {
  return (
    <header className={styles.container}>
      <Typography variant="h1" className={styles.entityId}>
        {ViewEntity[entityName]} ID:{' '}
        <ClickToCopy copyIcon={true}>{entityId}</ClickToCopy>
      </Typography>

      <div className={styles.badgesAndActions}>
        {!isEntityDeleted && badgesRenderer()}
        {isEntityDeleted && (
          <ColoredLabel color="var(--red)">DELETED</ColoredLabel>
        )}
        {actionsRenderer()}
      </div>
    </header>
  );
};

export default ViewHeader;
