import { TrialState } from '../models/TrialState';

export const getLatestTrialBundle = (
  trialStates: Array<TrialState> | undefined
) => {
  if (trialStates === null || trialStates === undefined) {
    return null;
  }

  const latestEndDate = trialStates.reduce((latest, trialState) => {
    // check if endDate is after latest
    if (new Date(trialState.end_date) > latest) {
      return new Date(trialState.end_date);
    }
    return latest;
  }, new Date(2000, 1, 1));

  // Filter all trial states to include only entries with the latest endDate
  const filteredStates = trialStates.filter(
    (trialState) =>
      new Date(trialState.end_date).getTime() === latestEndDate.getTime()
  );

  return filteredStates;
};
