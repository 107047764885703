import { Currency } from '../../../../../../types/types';
import { formatMoney } from '../../../../../../utils/costs';
import { MagpieBillingPeriod } from '../../../../../magpie/models/magpie.model';

const billingPeriodMap: Record<MagpieBillingPeriod, string> = {
  MONTHLY: 'month',
  ANNUALLY: 'year',
  THREE_MONTHS: '3 months',
  SIX_MONTHS: '6 months',
  TWO_YEARS: '2 years',
  THREE_YEARS: '3 years',
};

export const billingPeriodCostFormatter = (
  currency: Currency,
  cost: number,
  billingPeriod: MagpieBillingPeriod
) => {
  return `${formatMoney(currency, cost)}/${billingPeriodMap[billingPeriod]}`;
};

export const capitalizeFirstLetter = (text: string) => {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
};
