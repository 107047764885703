import { apiGet, apiPost } from '../../../utils/api';

export const getInvoices = (account_id) => {
  return apiGet(
    '/accounts/' + account_id + '/invoices',
    {},
    2,
    {},
    true,
    'view invoices'
  );
};

export const viewInvoice = (invoice_id) => {
  return apiGet('/invoices/' + invoice_id, {});
};

export const regenerateInvoice = (invoice_id) => {
  return apiPost('/invoices/' + invoice_id, 'regenerate_invoice', {});
};
