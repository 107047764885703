import React, { useEffect } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import ShowSpinner from '../../components/ShowSpinner';
import { EntitySelect } from './EntitySelect';
import { getEntityName } from './formUtils';
import { JiraStoryField } from './JiraStoryField';
import styles from './ManageFeatures.module.css';
import { PercentageSlider } from './PercentageSlider';
import type { StartUpdateFeatureFields } from './useStartUpdateFeatureForm';

type StartUpdateFeatureFormProps = {
  mode: 'add' | 'remove';
  feature: string;
  form: UseFormReturn<StartUpdateFeatureFields>;
};

export const StartUpdateFeatureForm = ({
  mode,
  feature,
  form: {
    control,
    formState: { isSubmitting, isSubmitSuccessful, isValid },
    watch,
    reset,
  },
}: StartUpdateFeatureFormProps): JSX.Element => {
  const entity = watch('entity');
  const percentage = watch('percentage');

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }

    return reset();
  }, [isSubmitSuccessful, reset]);

  return (
    <form className={styles.form}>
      <Controller
        name="entity"
        control={control}
        render={({ field: { value, onChange, onBlur } }) => (
          <EntitySelect
            value={value}
            onValueChange={onChange}
            onBlur={onBlur}
          />
        )}
      />

      <Controller
        name="percentage"
        control={control}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error: { message: errorMessage } = {} },
        }) => {
          return (
            <PercentageSlider
              value={value}
              onValueChange={onChange}
              onBlur={onBlur}
              error={errorMessage}
            />
          );
        }}
      />

      <Controller
        name="jiraId"
        control={control}
        render={({
          field: { name, value, onChange, onBlur },
          fieldState: { error: { message: errorMessage } = {} },
        }) => (
          <JiraStoryField
            name={name}
            value={value}
            onValueChange={onChange}
            onBlur={onBlur}
            error={errorMessage}
          />
        )}
      />

      {isValid ? (
        <p>
          Feature <span className={styles.featureName}>{feature}</span> will be{' '}
          {mode === 'add' ? 'added to' : 'removed from'} {percentage}% of{' '}
          {getEntityName(entity)}s.
        </p>
      ) : (
        <p>&nbsp;</p>
      )}

      {isSubmitting && (
        <div className={styles.overlay}>
          <ShowSpinner />
        </div>
      )}
    </form>
  );
};
