import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { getReport, postQuery } from './api';
import QueryForm from './QueryForm';
import styles from './Reports.module.css';
import QueryResults from './QueryResults';
import { useUserContext } from '../../contexts/UserContext';
import HasPermission from '../../components/HasPermission';
import ShowSpinner from '../../components/ShowSpinner';
import AccessError from './AccessError';

const ReportQuery = (props) => {
  const [report, setReport] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [results, setResults] = useState([]);
  const [persistedFields, setPersistedFields] = useState({});
  const userContext = useUserContext();

  const sendPostQuery = (key, formFields) => {
    setLoading(true);
    setError('');
    postQuery(key, formFields)
      .then((data) => {
        setResults(data);
        setLoading(false);
      })
      .catch((e) => {
        setError(e.error_message || e.message || e.error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getReport(props.match.params['report_key'])
      .then((data) => {
        setReport(data.report);
        setLoading(false);
        if (
          props.location.search.length > 0 &&
          userContext.roleNames.indexOf('report_access') > -1
        ) {
          const formFields = queryString.parse(props.location.search.slice(1));
          sendPostQuery(data.report.report_key, formFields);
          setPersistedFields(formFields);
        }
      })
      .catch((e) => {
        setError(e.error_message || e.message || e.error);
        setLoading(false);
      });
  }, [props.match.params, props.location.search, userContext.roleNames]);

  const submitForm = (formFields) => {
    props.history.push(
      `/reports/${report.report_key}?${queryString.stringify(formFields)}`
    );
  };

  const renderForm = () => {
    if (Object.keys(report).length > 0)
      return (
        <HasPermission
          rolesAccepted={report.roles_accepted}
          message="Sorry, you do not have access to this report."
        >
          <React.Fragment>
            <QueryForm
              fields={report['query_fields']}
              persistedFields={persistedFields || {}}
              submitForm={submitForm}
            />
          </React.Fragment>
        </HasPermission>
      );
  };

  const renderResults = () => {
    if (error && !loading) return <div className={styles.error}>{error}</div>;
    if (loading && !error)
      return (
        <div data-testid="loadingSpinner">
          <ShowSpinner />
        </div>
      );
    if (!loading && !error && results.length > 0) {
      return (
        <HasPermission rolesAccepted={report.roles_accepted}>
          <QueryResults report={report} results={results} />
        </HasPermission>
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      <h2>{report.report_name}</h2>
      <p className={styles.reportDescription}>{report.report_description}</p>
      {userContext.roleNames.indexOf('report_access') > -1 ? (
        <React.Fragment>
          {renderForm()}
          {renderResults()}
        </React.Fragment>
      ) : (
        <AccessError />
      )}
    </div>
  );
};

export default ReportQuery;
