import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { CustomerCountryForm } from './CustomerCountryForm';
import { updateCustomerCountry } from '../../../utils/api';
import { LoadingDialog } from '../LoadingDialog';
import { useToasts } from 'react-toast-notifications';
import { LookupContext } from '../../../contexts/LookupContext';
import { countryChoices } from '../../../utils/countries';
import { AdmModalProps } from '../AdmModalRoot';

type Props = {
  accountId: number;
};

export const UpdateCustomerCountryModal = ({
  props: { accountId },
  onCloseModal,
}: AdmModalProps<Props>) => {
  const { addToast } = useToasts();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    country: '',
    annotation: '',
  });
  const {
    account: { country },
    lookupRequest,
  } = useContext(LookupContext) || {};

  useEffect(
    () => setForm((currentForm) => ({ ...currentForm, country: country })),
    [country]
  );

  if (!accountId) {
    return null;
  }

  const handleUpdateField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.id]: event.target.value });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await updateCustomerCountry({
        accountId,
        ...form,
      });
      onCloseModal();
      addToast(
        `Country has been set to ${
          countryChoices.find((countryChoice) => {
            return countryChoice.value === form.country;
          })?.label
        } successfully`,
        {
          appearance: 'success',
          autoDismiss: true,
        }
      );
    } catch (error) {
      addToast('An error occurred while submitting the country', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    lookupRequest();
    setIsSubmitting(false);
  };

  if (!accountId || !country) {
    return null;
  }

  if (isSubmitting) {
    return <LoadingDialog title="Updating country" />;
  }

  return (
    <Dialog fullWidth maxWidth="lg" open onClose={onCloseModal}>
      <DialogTitle>Update country for Account {accountId}</DialogTitle>
      <DialogContent>
        <CustomerCountryForm
          form={form}
          handleUpdateField={handleUpdateField}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!form.annotation}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCustomerCountryModal;
