import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import styles from '../../../../components/SearchForm.module.css';
import { useEnterpriseIDP } from '../../../../domains/enterpriseSSO/api/useEnterpriseIDP';
import { createEnterpriseIDP } from '../../../../domains/enterpriseSSO/api/createEnterpriseIDP';
import { useToasts } from 'react-toast-notifications';
import { AdmModalProps } from '../../../../components/modals/AdmModalRoot';

interface Props {
  accountId: number;
}

type AddIDPModalProps = AdmModalProps<Props>;

const StyledForm = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const AddIDPModal = ({
  onCloseModal,
  props: { accountId },
}: AddIDPModalProps) => {
  const { data = [], refetch } = useEnterpriseIDP(accountId);

  const { addToast } = useToasts();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [loginId, setLoginId] = useState<string>('');

  const onCreate = () => {
    if (!new RegExp(/^[a-z0-9\-]{3,63}$/).test(loginId)) {
      addToast(
        'Login can contain lowercase letters, numbers or dash (-). It can contain min 3 and max 63 characters.',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );
      return;
    }
    setLoading(true);
    createEnterpriseIDP(accountId, loginId)
      .then(() => {
        refetch();
        setLoginId('');
      })
      .catch(() => {
        addToast('Something went wrong.', {
          appearance: 'error',
          autoDismiss: true,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog fullWidth maxWidth="md" open onClose={onCloseModal}>
      <DialogTitle>Add Identity Provider</DialogTitle>
      <DialogContent>
        <Typography>
          Account ID: <span data-hj-suppress>{accountId}</span>
        </Typography>

        <StyledForm>
          <TextField
            autoFocus
            required
            fullWidth
            type="text"
            id="loginId"
            label="Login ID"
            disabled={isLoading}
            value={loginId}
            onChange={(e) => setLoginId(e.target.value)}
            variant="standard"
          />

          <Button
            variant="contained"
            color="primary"
            onClick={onCreate}
            disabled={isLoading}
            className={styles.searchButton}
            type="submit"
          >
            Create
          </Button>
        </StyledForm>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="account_id">Account ID</TableCell>
              <TableCell key="login_id">Login</TableCell>
              <TableCell key="idp_id">Identity Provider</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow key={`idp_table_row_${item.login_id}`}>
                <TableCell>{accountId}</TableCell>
                <TableCell>{item.login_id}</TableCell>
                <TableCell>{item.idp_id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
