import { Tooltip } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';
import { isNil } from 'lodash';

interface Props {
  magpieDate: string | undefined;
  insightsDate: string | undefined;
}

export function NextBillingDateMismatch({ magpieDate, insightsDate }: Props) {
  const warning = `Mismatch between Magpie and Insights dates: 
  ${magpieDate ?? 'undefined'} vs ${insightsDate ?? 'undefined'}`;

  // In case magpieCurrentPeriodEndDate is undefined, we don't show a warning since
  //  Insights always has a next billing date for churned customers.
  const isMismatch = magpieDate !== insightsDate && !isNil(magpieDate);

  if (!isMismatch) {
    return null;
  }

  return (
    <Tooltip title={warning}>
      <WarningIcon color="warning" />
    </Tooltip>
  );
}
