import { useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';

import { deleteOrganization } from './api';
import { MODAL_CONFIRMATION_DIALOG } from '../../../../components/modals/constants';
import { LookupContext } from '../../../../contexts/LookupContext';
import { useToasts } from 'react-toast-notifications';

export type DeleteOrgProps = {
  organizationId: number;
  handleContextModalOpen: (modal: string, payload: unknown) => void;
};

export const DeleteOrg = ({
  organizationId,
  handleContextModalOpen,
}: DeleteOrgProps) => {
  const { addToast } = useToasts();
  const { lookupRequest } = useContext(LookupContext);

  const onSubmit = () => {
    return deleteOrganization(organizationId);
  };

  return (
    <MenuItem
      onClick={() =>
        handleContextModalOpen(MODAL_CONFIRMATION_DIALOG, {
          noAnnotationRequired: true,
          title: 'Delete organization',
          onSubmit,
          modalWarning: 'Are you sure you want to delete this organization?',
          onSuccess: lookupRequest,
          onError: (error: any) => {
            addToast(error.error_message || error.message || error.error, {
              appearance: 'error',
              autoDismiss: true,
            });
          },
        })
      }
    >
      Delete Organization
    </MenuItem>
  );
};
