import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import styles from './ShowSpinner.module.css';

const ShowSpinner = () => (
  <div className={styles.ShowSpinner}>
    <CircularProgress />
  </div>
);

export default ShowSpinner;
