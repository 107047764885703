import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { TrimmedText } from './TrimmedText';

export function HashmapRenderer(value: { [key: string]: number | string }) {
  return (
    <Table size="small">
      <TableBody>
        {Object.entries(value).map(([allowance, value]) => {
          return (
            <TableRow key={allowance}>
              <TableCell>
                <TrimmedText text={allowance} maxLength={20} />
              </TableCell>
              <TableCell>{value}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
