import React, { Fragment } from 'react';

import styles from './UserManagement.module.css';
import UserRoleTable from './UserRoleTable';
import ConfirmationDialog from '../../components/modals/ConfirmationDialog';
import AdmActionsModal from '../../components/modals/AdmActions';

const InternalUserManagement = () => {
  return (
    <Fragment>
      <div className={styles.wrapper}>
        <UserRoleTable />
      </div>
      <AdmActionsModal />
      <ConfirmationDialog />
    </Fragment>
  );
};

export default InternalUserManagement;
