import { FC, PropsWithChildren } from 'react';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import styles from './DialogTitleWithClose.module.css';

interface DialogTitleWithCloseProps extends PropsWithChildren {
  onClose: () => void;
  dataId?: string;
  id?: string;
}

export const DialogTitleWithClose: FC<DialogTitleWithCloseProps> = ({
  children,
  onClose,
  dataId,
  id,
}) => {
  return (
    <header className={styles.dialogTitle} data-id={dataId} id={id}>
      <Typography
        variant="h2"
        // styles from MUI DialogTitle h2
        sx={{
          fontSize: '1.25rem',
          lineHeight: '1.6',
          fontWeight: '500',
          letterSpacing: '0.0075em',
        }}
      >
        {children}
      </Typography>
      {onClose && (
        <IconButton
          className={styles.icon}
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </header>
  );
};
