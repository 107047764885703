import { apiPatch } from '../../../utils/api';
import { toDateString } from '../../../utils/dates';

interface Args {
  adm_action_annotation: 'string';
  next_billing_date: string;
  billing_cycle: 'monthly' | 'yearly_recurring';
}

export const modifySubscription = (accountID: string, args: Args) => {
  let data = Object.assign({}, args);
  if (data['next_billing_date']) {
    data['next_billing_date'] = toDateString(data['next_billing_date']);
  }
  return apiPatch(
    '/account/' + accountID,
    data,
    2,
    {},
    true,
    'modify subscription'
  );
};
