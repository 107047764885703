import { apiGet, apiPost, apiPatch, apiDelete } from '../../utils/api';

export function getCampaigns(args) {
  return apiGet('/campaigns', args);
}

export function toggleEnabled(id, enabled) {
  return apiPatch('/campaigns/' + id, { enabled: enabled });
}

export function deleteCampaign(id) {
  return apiDelete('/campaigns/' + id, null, {});
}

export function createCampaign(data) {
  const {
    dualSKU,
    usageBasedPricing,
    num_days,
    plan_id_analytics,
    plan_id_feedback,
    plan_id,
    name,
    type,
    url,
  } = data;

  return apiPost('/campaigns', null, {
    plan_id:
      dualSKU || usageBasedPricing
        ? `${plan_id_analytics};${plan_id_feedback}`
        : plan_id,
    name,
    num_days,
    url,
    type,
  });
}
