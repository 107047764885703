import React, { Component } from 'react';
import { Button, Dialog } from '@mui/material';
import { MODAL_UPGRADE_USER } from '../constants';
import Form from './Form';
import { upgradeUser } from './api';
import { withLoadingDialog } from '../../withLoading';
import { withModalContext } from '../../../contexts/ModalContext';

const LoadingAwareDialog = withLoadingDialog(Dialog);

class UpgradeUserModal extends Component {
  state = {
    user_id: '',
    country: 'AF',
    account_type: 'individual',
    changed: false,
  };

  handleSubmit = () => {
    return upgradeUser(
      this.state.user_id,
      this.state.country,
      this.state.account_type
    )
      .then(() => {
        this.props.modalContext.closeAllModals();
        this.props.lookupRequest();
      })
      .catch((e) =>
        this.setState({ error_message: e.error_message || e.error })
      );
  };

  handleFormUpdate = (data) => {
    this.setState({ ...data, changed: true });
  };

  componentDidUpdate(prevProps) {
    const isModalOpen = this.props.modalContext.isModalOpen(MODAL_UPGRADE_USER);
    const modalProps =
      this.props.modalContext.openModalProps[MODAL_UPGRADE_USER];
    if (
      prevProps.modalContext.currentlyOpenModals.indexOf(MODAL_UPGRADE_USER) ===
        -1 &&
      isModalOpen
    ) {
      this.setState({
        user_id: modalProps.user_id,
      });
    }
  }

  render() {
    const isModalOpen = this.props.modalContext.isModalOpen(MODAL_UPGRADE_USER);
    const modalActions = [
      <Button
        color="primary"
        key="cancel"
        onClick={() => this.props.modalContext.closeModal(MODAL_UPGRADE_USER)}
      >
        Cancel
      </Button>,
      <Button
        color="primary"
        key="save"
        onClick={this.handleSubmit}
        disabled={!this.state.changed}
      >
        Save Changes
      </Button>,
    ];

    return (
      <LoadingAwareDialog
        open={isModalOpen}
        actions={modalActions}
        title="Upgrade User"
        isLoading={this.state.isLoading}
      >
        <div className="error">
          {this.state.error_message ? 'ERROR: ' + this.state.error_message : ''}
        </div>
        <br />
        User ID: {this.state.user_id}
        <Form
          country={this.state.country}
          account_type={this.state.account_type}
          onFormUpdate={this.handleFormUpdate}
        />
      </LoadingAwareDialog>
    );
  }
}

export default withModalContext(UpgradeUserModal);
