import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from '@mui/material';
import componentStyles from './DisplayAccount.module.css';
import { ExpandMore } from '@mui/icons-material';
import { getPrettyBillingV3Discounts } from './billingV3.functions';
import InfoIcon from '@mui/icons-material/InfoOutlined';

function TimedOffersTooltip({ startDate, endDate, amount, percentage }) {
  if (!amount && !percentage) {
    return null;
  }

  return (
    <>
      Offer active from {startDate} to {endDate}. For more information{' '}
      <a
        href="https://contentsquare.atlassian.net/wiki/x/o6lt2g"
        target="_blank"
        rel="noreferrer"
        className={componentStyles.discountsTooltipAnchor}
      >
        check this confluence page
      </a>
      .
    </>
  );
}

export default function AccountDiscountsAccordion({ customer }) {
  const [includeZeroDiscounts, setIncludeZeroDiscounts] = useState(false);
  const discounts = customer?.subscription?.products?.find(
    (product) => product.sku === 'compound_discount'
  );
  const currency = customer?.currency;

  if (!discounts || !currency) {
    return null;
  }

  const rows = [
    ...getPrettyBillingV3Discounts({
      deductions: discounts.components_monetary_deduction,
      currency,
      discountType: 'monetary',
      includeZeroDiscounts,
    }),
    ...getPrettyBillingV3Discounts({
      deductions: discounts.components_percentage_deduction,
      currency,
      discountType: 'percentage',
      includeZeroDiscounts,
    }),
    ...getPrettyBillingV3Discounts({
      deductions: discounts.components_timed_percentage_deduction,
      currency,
      discountType: 'percentage',
      includeZeroDiscounts,
      renderTooltip: TimedOffersTooltip,
    }),
    ...getPrettyBillingV3Discounts({
      deductions: discounts.components_timed_monetary_deduction,
      currency,
      discountType: 'monetary',
      includeZeroDiscounts,
      renderTooltip: TimedOffersTooltip,
    }),
  ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <h3>Discounts (Account-level)</h3>
      </AccordionSummary>
      <AccordionDetails className={componentStyles.accordion}>
        <FormGroup>
          <FormControlLabel
            control={<Switch />}
            label="Include zero discounts"
            onChange={(event) => setIncludeZeroDiscounts(event.target.checked)}
          />
        </FormGroup>
        {!rows.length ? (
          <Typography variant="h3" align="center">
            No discounts
          </Typography>
        ) : (
          <TableContainer>
            <Table size="small" aria-label="Account discounts table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(({ name, amount, tooltip }) => (
                  <TableRow
                    key={name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    data-testid="discounts-row"
                  >
                    <TableCell component="th" scope="row">
                      {tooltip != null ? (
                        <Tooltip title={tooltip}>
                          <span
                            className={componentStyles.cellContentWithTooltip}
                          >
                            {name}
                            <InfoIcon fontSize="small" />
                          </span>
                        </Tooltip>
                      ) : (
                        name
                      )}
                    </TableCell>
                    <TableCell>{amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
