import moment from 'moment';
import capitalize from 'lodash/capitalize';
import type { EntityDetailsColumnsConfig } from '../../../../components/EntityDetails/interfaces';
import { formatMoney } from '../../../../utils/costs';
import { toDateString } from '../../../../utils/dates';
import {
  COMPOUND_DISCOUNT,
  MagpieBillingPeriod,
  MagpieComponentsDeduction,
  MagpieCustomer,
  MagpiePaymentMethodType,
  MagpieSubscriptionProduct,
} from '../../models/magpie.model';
import { NO_DATA_PLACEHOLDER } from '../../../../utils/constants';
import { Currency } from '../../../../types/types';

const billingPeriodMap: Record<MagpieBillingPeriod, string> = {
  ANNUALLY: 'Annually',
  MONTHLY: 'Monthly',
  THREE_MONTHS: 'Three Months',
  SIX_MONTHS: 'Six Months',
  TWO_YEARS: 'Two years',
  THREE_YEARS: 'Three years',
};

const fieldNameToHumanReadable = (text: string): string =>
  capitalize(text).replace(/_/g, ' ');

export const formatPercentageDiscounts = (
  discounts: MagpieComponentsDeduction[]
): string[] | [] =>
  discounts
    .filter((discount) => discount.percentage)
    .map(
      (discount) =>
        `${fieldNameToHumanReadable(discount.identifier)} - ${
          discount.percentage
        }%`
    );

export const formatMonetaryDiscounts = (
  discounts: MagpieComponentsDeduction[],
  currency: Currency
): string[] | [] =>
  discounts
    .filter((discount) => discount.amount)
    .map(
      (discount) =>
        `${fieldNameToHumanReadable(discount.identifier)} - ${formatMoney(
          currency,
          discount.amount
        )}`
    );

export const getSubscriptionProductDiscounts = (
  subscriptionProduct: MagpieSubscriptionProduct,
  currency: Currency
): string[] | [] => {
  const {
    components_percentage_deduction: percentageDiscounts,
    components_monetary_deduction: monetaryDiscounts,
  } = subscriptionProduct;

  const formattedPercentageDiscounts =
    formatPercentageDiscounts(percentageDiscounts);

  const formattedMonetaryDiscounts = formatMonetaryDiscounts(
    monetaryDiscounts,
    currency
  );

  return [...formattedMonetaryDiscounts, ...formattedPercentageDiscounts];
};

export const displayAccountDiscounts = (
  products: MagpieSubscriptionProduct[],
  currency: Currency
) => {
  const accountDiscounts = products.filter(
    (product) => product.sku === COMPOUND_DISCOUNT
  )[0];

  const discounts = getSubscriptionProductDiscounts(accountDiscounts, currency);

  return discounts.length > 0 ? discounts : NO_DATA_PLACEHOLDER;
};

const paymentMethodMap: Record<MagpiePaymentMethodType, string> = {
  CREDIT_CARD: 'Credit card',
  PAYPAL: 'Paypal',
  HOTJAR_BRAINTREE_PAYMENT_METHOD: 'Hotjar Braintree payment method',
  EXTERNAL_PAYMENT_METHOD: 'External payment method',
  ELECTRONIC: 'Electronic',
  EXTERNAL: 'External',
};

export const getSubscriptionData = (
  magpieCustomerData: MagpieCustomer
): EntityDetailsColumnsConfig => {
  const {
    created_at,
    currency,
    subscription: { status, current_period_end_date, billing_period, products },
    payment_method,
  } = magpieCustomerData;
  const payment_method_type = payment_method?.payment_method_type;

  const billPeriodEnd = status === 'ACTIVE' ? current_period_end_date : null;

  return [
    [
      {
        name: 'Created',
        value: toDateString(created_at),
      },
      {
        name: 'Billing period',
        value: billingPeriodMap[billing_period],
      },

      {
        name: 'Account discounts',
        value: displayAccountDiscounts(products, currency),
      },
    ],
    [
      {
        name: 'Next bill',
        value: billPeriodEnd
          ? toDateString(moment(billPeriodEnd).add(1, 'days'))
          : NO_DATA_PLACEHOLDER,
      },
      {
        name: 'Payment method',
        value: payment_method_type
          ? paymentMethodMap[payment_method_type]
          : NO_DATA_PLACEHOLDER,
      },
      {
        name: 'Subscription status',
        value: capitalize(status),
      },
    ],
  ];
};

export const getCostsData = (
  magpieCustomerData: MagpieCustomer
): EntityDetailsColumnsConfig => {
  const {
    currency,
    current_period_cost_summary: { amount_with_tax, amount_without_tax },
  } = magpieCustomerData;

  return [
    [
      {
        name: 'Current period cost without tax',
        value: formatMoney(currency, amount_without_tax),
        tooltipText: 'Discounts are applied',
      },
    ],
    [
      {
        name: 'Current period cost with tax',
        value: formatMoney(currency, amount_with_tax),
        tooltipText: 'Discounts are applied',
      },
    ],
  ];
};
