import { localizeDates, tzAdjustUTCToLocal } from './dates';
import { totalCosts } from './costs';

export const normalizeOrganizationUsersResponse = (response) => {
  let normalizedResponse = {};
  response.forEach((ou) => {
    let id = ou.user.id + '-' + ou.organization.id;
    normalizedResponse[id] = {
      ...ou,
      user: { ...localizeDates(ou.user, ['created']) },
      organization: { ...ou.organization },
      id: id,
    };
  });
  return normalizedResponse;
};

export const normalizeAccountAPIResponse = (response) => {
  let normalizedResponse = {
    account: {
      ...localizeDates(response, ['deleted_datetime', 'next_billing_date']),
      organizations: [],
      sites: [],
      total_cost: totalCosts(response.costs),
      invoices: [],
      id: response.id,
    },
    organizations: {},
    organization_users: {},
    sites: {},
    costs: {},
    invoices: [],
  };

  if (response.costs) {
    response.costs.items.forEach((cost) => {
      normalizedResponse.costs[cost.id] = { ...cost };
    });
  }

  response.organizations.forEach(function (organization) {
    const isUsageBasedPricingAccount =
      normalizedResponse.account.features.includes(
        'pp_usage_based_pricing_phase_0_variant1'
      );
    normalizedResponse.organizations[organization.id] = {
      ...localizeDates(organization, ['deleted_datetime']),
      account_id: response.id,
      invitations: organization.invitations,
      sites: [],
      users: [],
    };

    normalizedResponse.account.organizations.push(organization.id);

    organization.sites.forEach(function (site) {
      normalizedResponse.sites[site.id] = {
        ...localizeDates(site, ['last_data']),
        organization_id: organization.id,
      };

      normalizedResponse.organizations[organization.id].sites.push(site.id);

      if (isUsageBasedPricingAccount) {
        normalizedResponse.sites[site.id].products[0] = {
          ...normalizedResponse.sites[site.id].products[0],
          cc_monthly_sessions:
            Math.round(
              (normalizedResponse.sites[site.id].products[0].cc_sessions * 30) /
                100
            ) * 100,
          effective_cc_monthly_sessions:
            Math.round(
              (normalizedResponse.sites[site.id].products[0]
                .effective_cc_sessions *
                30) /
                100
            ) * 100,
        };
      }
    });

    let orgUsers = normalizeOrganizationUsersResponse(organization.users);
    normalizedResponse.organizations[organization.id].users = [
      ...Object.keys(orgUsers),
    ];
    normalizedResponse.organization_users = {
      ...normalizedResponse.organization_users,
      ...orgUsers,
    };
  });

  return normalizedResponse;
};

export const normalizeInvoicesResponse = (response) => {
  let normalizedResponse = [];
  response.forEach((invoice) => {
    normalizedResponse.push({
      ...localizeDates(invoice, ['created']),
    });
  });
  return normalizedResponse;
};

export const normalizeSamplingChangesResponse = (changes, toDate) => {
  const normalizedResponse = changes.map((changeTuple) => {
    const [timestampSecs, value] = changeTuple;
    const newValue = value[1];
    return [tzAdjustUTCToLocal(new Date(timestampSecs * 1000)), newValue];
  });

  if (normalizedResponse.length) {
    // This ensures the line renders across the entire selected end range.
    normalizedResponse.push([
      tzAdjustUTCToLocal(toDate),
      normalizedResponse[normalizedResponse.length - 1][1],
    ]);
  }

  return normalizedResponse;
};

export const normalizeRecordingCountHourlyResponse = (counts) => {
  return counts.map((countTuple) => {
    const [timestampSecs, value] = countTuple;
    return [tzAdjustUTCToLocal(new Date(timestampSecs * 1000)), value];
  });
};
