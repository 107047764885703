import { FC } from 'react';

import styles from './Deductions.module.css';

export type Currency = '€' | '$';

interface DeductionType {
  id: string;
  name: string;
  application: string;
  percentage?: string;
}

export interface Deduction {
  amount: string;
  deduction: DeductionType;
}

interface DeductionsProps {
  currency: Currency;
  deductions?: Deduction[];
  hasSkuDiscount?: boolean;
}

const getKey = (deduction: DeductionType) =>
  `${deduction.id}-${deduction.name.replace(' ', '-')}`;

const Deductions: FC<DeductionsProps> = ({
  deductions,
  hasSkuDiscount,
  currency,
}) => {
  return (
    <dl>
      {deductions?.map(({ amount, deduction }) => (
        <div key={getKey(deduction)} className={styles.deductionItem}>
          <dt className={styles.discountName}>{deduction.name}</dt>
          <dd className={styles.discountValue}>
            {currency}
            {amount}
            {deduction.percentage && ` (${deduction.percentage}%)`}
          </dd>
        </div>
      ))}
      {/* Right now this is always 20% if you have paid plans on both SKUs.
      This isn't stored on the site object in the db like other discounts, so we need to do it manually.
      It could definitely change in future. If so, change it here,
      but ideally do something more robust so the info comes from the API. */}
      {hasSkuDiscount && (
        <div className={styles.deductionItem}>
          <dt className={styles.discountName}>Paid Dual-SKU Discount</dt>
          <dd className={styles.discountValue}>20%</dd>
        </div>
      )}
    </dl>
  );
};

export default Deductions;
