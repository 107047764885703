import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ToastProvider } from 'react-toast-notifications';

import QueryClientProvider from './query-client-provider';
import { ProtectedRoute } from './components/ProtectedRoute';
import Login from './containers/Login';
import ResetADMPassword from './containers/ResetADMPassword';
import RequestADMPasswordReset from './containers/RequestADMPasswordReset';
import AppContainer from './components/AppContainer';
import MainAppBar from './components/MainAppBar';
import styles from './App.module.css';

import fireTriggers from './utils/hotjarTriggers';

const App = () => {
  useEffect(() => {
    fireTriggers();
  }, []);

  const theme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            marginBottom: '12px',
            marginRight: '12px',
          },
        },
      },
    },
  });

  return (
    <Router>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ToastProvider placement={'bottom-right'}>
          <ThemeProvider theme={theme}>
            <QueryClientProvider>
              <MainAppBar />
              <div className={styles.container}>
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/reset_password" component={ResetADMPassword} />
                  <Route
                    path="/forgot_password"
                    component={RequestADMPasswordReset}
                  />
                  <ProtectedRoute component={AppContainer} />
                </Switch>
              </div>
            </QueryClientProvider>
          </ThemeProvider>
        </ToastProvider>
      </LocalizationProvider>
    </Router>
  );
};

export default App;
