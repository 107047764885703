import {
  Paper,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useIsAlp } from '../../../accounts/utils/useIsAlp';
import { useAccountServicePeriods } from '../../../services/api/use-account-service-periods';
import type { AccountServicePeriods } from '../../../services/models/service-alp.model';
import type { AccountServicePeriod } from '../../models/service-alp.model';
import styles from './account-allowances.module.css';
import {
  siteTableOptions,
  tableColumns,
} from './account-service-data-table-options';
import ShowSpinner from '../../../../components/ShowSpinner';

function getNonZeroAllowances(allowances: Record<string, number>) {
  const allowancesEntries = Object.entries(allowances).filter(
    ([_key, value]) => value !== 0 && value !== undefined
  );
  return Object.fromEntries(allowancesEntries);
}

function formatProductServiceData(servicePeriods: AccountServicePeriod[]) {
  return servicePeriods.map((servicePeriod) => {
    return [
      servicePeriod.product.name,
      servicePeriod.product.plan,
      getNonZeroAllowances(servicePeriod.product.allowances),
      servicePeriod.source[0],
      servicePeriod.start,
      servicePeriod.end,
      servicePeriod.created,
      servicePeriod.disabled ?? 'No',
    ];
  });
}

function formatServicesData(data: AccountServicePeriods) {
  return Object.values(data).flatMap((value) => {
    return formatProductServiceData(value);
  });
}

export function AccountServiceTable({ accountId }: { accountId: number }) {
  const isAlp = useIsAlp();
  const { accountServicePeriodsData, isLoading } = useAccountServicePeriods({
    enabled: isAlp ?? false,
    accountId,
  });

  if (!isAlp) {
    return null;
  }

  if (isLoading) {
    return <ShowSpinner />;
  }

  if (!accountServicePeriodsData) {
    return (
      <Stack padding={2}>
        <Typography variant="body2">
          There're no service periods for this account yet.
        </Typography>
      </Stack>
    );
  }

  const tableData = formatServicesData(accountServicePeriodsData);

  return (
    <Paper className={styles.container}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="service-periods-content"
          id="service-periods-header"
        >
          <Typography variant="h5">
            Account Service Periods for: {accountId}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MUIDataTable
            title=""
            data={tableData}
            columns={tableColumns}
            options={siteTableOptions}
          />
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}
