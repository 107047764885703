import React, { useMemo } from 'react';
import { useModalContext } from '../../contexts/ModalContext';

export type AdmModalProps<T> = { onCloseModal: () => void; props: T };
type ModalMap = Partial<Record<string, React.ElementType>>;

type ModalRootProps = {
  modalMap: ModalMap;
};

export const AdmModalRoot = ({ modalMap }: ModalRootProps) => {
  const modalContext = useModalContext();

  const modals = useMemo(() => {
    return modalContext.currentlyOpenModals.map((name) => {
      const Modal = modalMap[name];
      if (Modal === undefined) return null;
      const onCloseModal = () => modalContext.closeModal(name);
      return (
        <Modal
          key={name}
          props={modalContext.openModalProps[name]}
          onCloseModal={onCloseModal}
        />
      );
    });
  }, [modalContext, modalMap]);

  return <>{modals}</>;
};
