import { fetchEnterpriseIDPURL } from './urls';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { CreateEnterpriseIDPResponse } from '../models/enterpriseSSO.model';

export const createEnterpriseIDP = async (
  accountId: number,
  loginId: string
): Promise<CreateEnterpriseIDPResponse> => {
  const response = await fetch(fetchEnterpriseIDPURL(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      account_id: accountId,
      login_id: loginId,
    }),
  });

  return handleFetchApiResponse<CreateEnterpriseIDPResponse>(response);
};
