import { FC } from 'react';
import { Badge, IconButton, Tooltip } from '@mui/material';
import styles from './IconBadge.module.css';

export interface IconBadgeProps {
  icon: JSX.Element;
  tooltip: string;
  badgeText?: string;
  handleOnClick?: () => void;
}

const IconBadge: FC<IconBadgeProps> = ({
  icon,
  tooltip,
  badgeText,
  handleOnClick,
}) => {
  if (!badgeText) {
    return null;
  }

  return (
    <Tooltip title={tooltip}>
      <IconButton className={styles.iconBadge} onClick={handleOnClick}>
        <Badge badgeContent={badgeText} color="primary">
          {icon}
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default IconBadge;
