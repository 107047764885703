import React, { Component } from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import t from 'tcomb-validation';

import styles from './ModifySubscription.module.css';
import {
  PositiveInteger,
  Currency,
  PositiveNumber,
  DateBetweenNowAnd,
} from '../../../utils/validators';
import moment from 'moment';

export default class Form extends Component {
  chartmogul_field_validators = {
    invoice_external_id: { validator: t.String, cast: String },
    currency_paid: { validator: Currency, cast: String },
    monthly_amount: { validator: PositiveNumber, cast: Number },
    months_paid: { validator: PositiveInteger, cast: Number },
  };

  service_end_date_validators = {
    billing_date: {
      validator: DateBetweenNowAnd(moment(this.props.max_billing_date)),
      cast: String,
    },
  };

  originalBillingDate = this.props.billing_date;

  handleDatePickerChange = (date) => {
    this.props.onFormUpdate({
      billing_date: date.format(),
    });
  };

  handleBillingCycleChange = (event) => {
    let data = {
      billing_cycle: event.target.value,
      switch_to_yearly: false,
    };
    if (
      this.props.billing_cycle !== event.target.value &&
      event.target.value === 'yearly'
    ) {
      data['switch_to_yearly'] = true;
    }

    this.props.onFormUpdate(data);
  };

  handleCurrencyChange = (event) => {
    this.props.onFormUpdate({
      currency_paid: event.target.value,
    });
  };

  handleTextFieldChange = (event) => {
    this.props.onFormUpdate({
      [event.target.name]: event.target.value,
    });
  };

  validate = () => {
    let errors = '',
      valid = true,
      validators = [];

    if (this.props.switch_to_yearly) {
      validators = this.chartmogul_field_validators;
    }

    if (this.props.isBillingV3) {
      validators = this.service_end_date_validators;
    }

    for (let field in validators) {
      // Check for Falsey values
      if (!this.props[field]) {
        valid = false;
        errors = 'Please fill in ' + field;
        break;
      }

      // Check types
      let val = validators[field].cast(this.props[field]),
        status = t.validate(val, validators[field].validator);

      if (status.isValid()) {
        this.props.onFormUpdate({ [field]: val });
        errors = null;
      } else {
        valid = false;
        errors = status.firstError().message;
        break;
      }
    }

    this.props.setErrors(errors);
    return valid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.onFormSubmit();
      this.originalBillingDate = this.props.billing_date;
    }
  };

  render() {
    const isBillingV3 = this.props.isBillingV3 ?? false;
    const dateLabel = isBillingV3 ? 'Service End Date' : 'Billing Date';

    return (
      <form>
        <div className={styles.formColumn}>
          <h4>Subscription Details</h4>
          <DatePicker
            label={dateLabel}
            name="billing_date"
            id="billing_date"
            inputFormat="YYYY-MM-DD"
            variant="dialog"
            showTodayButton
            value={this.props.billing_date}
            onChange={this.handleDatePickerChange}
            minDate={
              this.originalBillingDate
                ? moment(this.originalBillingDate)
                : moment()
            }
            maxDate={
              this.props.max_billing_date
                ? moment(this.props.max_billing_date)
                : undefined
            }
            renderInput={(params) => (
              <TextField
                className={styles.datePicker}
                variant="standard"
                {...params}
              />
            )}
          />
          {!isBillingV3 && (
            <FormControl className={styles.billingCycle}>
              <InputLabel>Billing Cycle</InputLabel>
              <Select
                value={this.props.billing_cycle}
                onChange={this.handleBillingCycleChange}
                data-testid="billing_cycle_select"
                id="billing_cycle"
                variant="standard"
                native
              >
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
                <option value="lifetime">Lifetime</option>
              </Select>
            </FormControl>
          )}
          <TextField
            multiline
            required
            value={this.props.annotation}
            onChange={this.handleTextFieldChange}
            label="Annotation"
            name="annotation"
            id="annotation"
            helperText="Please provide reason for change and/or Zendesk ticket link"
            variant="standard"
          />
        </div>
        {this.props.switch_to_yearly && (
          <div className={styles.formColumn}>
            <h4>ChartMogul MRR </h4>

            <TextField
              name="invoice_external_id"
              value={this.props.invoice_external_id}
              id="externalInvoiceId"
              onChange={this.handleTextFieldChange}
              label="Xero Invoice ID*"
              variant="standard"
            />
            <FormControl className={styles.billingCycle}>
              <InputLabel>Currency Paid*</InputLabel>
              <Select
                name="currency_paid"
                value={this.props.currency_paid}
                onChange={this.handleCurrencyChange}
                data-testid="currency_select"
                variant="standard"
              >
                <MenuItem value="eur">EUR</MenuItem>
                <MenuItem value="usd">USD</MenuItem>
                <MenuItem value="gbp">GBP</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="monthly_amount"
              value={this.props.monthly_amount}
              id="monthlyAmount"
              onChange={this.handleTextFieldChange}
              label="Monthly Amount*"
              variant="standard"
            />
            <TextField
              name="months_paid"
              value={this.props.months_paid}
              id="numberOfMonths"
              onChange={this.handleTextFieldChange}
              label="Number of Months*"
              variant="standard"
            />
          </div>
        )}
      </form>
    );
  }
}
