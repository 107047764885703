import { fetchAuditLogURL } from './urls';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { AuditLogEvent } from '../models/audit.model';
import { useQuery } from '@tanstack/react-query';
import { useToasts } from 'react-toast-notifications';
import { useEffect } from 'react';

const getUserAuditLogs = async (id: number) => {
  const response = await fetch(fetchAuditLogURL(id));

  return handleFetchApiResponse<{ events: AuditLogEvent[] }>(response);
};

export const useUserAuditLogs = (id?: number) => {
  const { addToast } = useToasts();
  const { data, isInitialLoading, isError, error } = useQuery<AuditLogEvent[]>({
    queryKey: ['showUser', id],
    queryFn: () => getUserAuditLogs(id!).then((response) => response.events),
    enabled: !!id,
  });

  useEffect(() => {
    // Error toast not displayed on 404
    if (isError) {
      addToast(`There was an error fetching data for user with id:"${id}"`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [id, addToast, isError]);

  return {
    auditLogs: data,
    isAuditLogLoading: isInitialLoading,
    isAuditLogError: isError,
    auditLogError: error,
  };
};
