import type { AdmModalProps } from '../AdmModalRoot';
import { useIsCSLite } from '../../../domains/accounts/utils/useIsCSLite';
import PreviewCostsModalAlp from './PreviewCostModalAlp';
import PreviewCostsModalSlp from './PreviewCostModalSlp';
import { Dialog, DialogTitle } from '@mui/material';
import { useContext } from 'react';
import { LookupContext } from '../../../contexts/LookupContext';

export const PreviewCostsModal = (props: AdmModalProps<{}>) => {
  const context = useContext(LookupContext);
  const isCSLite = useIsCSLite();

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open
      onClose={props.onCloseModal}
      disableEnforceFocus
    >
      <DialogTitle>Preview Costs for Account {context?.account.id}</DialogTitle>
      {isCSLite ? (
        <PreviewCostsModalAlp {...props} />
      ) : (
        <PreviewCostsModalSlp {...props} />
      )}
    </Dialog>
  );
};

export default PreviewCostsModal;
