import { ACTIVE_SUBSCRIPTION_STATUS } from './constants';
import {
  getEmailContainsResults,
  getEmailResults,
  getURLContainsResults,
} from './api';

/**
 * Method that decorates entities data
 * with related customer data if exists.
 *
 * @param {Array<{ account_id: number }>} entities - A list of entities: user / site
 * @param {Array<Object>} customerData - A list of customers, if they exist, for the entities
 * @returns A list of decorated entities with some customer data fields.
 */
export const entitiesWithCustomerData = (entities = [], customerData) => {
  if (!customerData) {
    return entities;
  }

  return entities.map((entity) => {
    const entityAccountId = entity.account_id;

    const customerForAccountId = customerData.find(
      (customer) => customer.hotjar_account_id === entityAccountId
    );
    return {
      ...entity,
      hasActiveSubscription:
        customerForAccountId?.subscription.status ===
        ACTIVE_SUBSCRIPTION_STATUS,
      currency: customerForAccountId?.currency,
      totalSpend:
        customerForAccountId?.current_period_cost_summary?.amount_with_tax,
    };
  });
};

const fetchMethod = {
  email: getEmailResults,
  email_contains: getEmailContainsResults,
  url_contains: getURLContainsResults,
};

/**
 * Method used in the lookup feature
 * to fetch data from the API for the following:
 * - email lookup
 * - email contains lookup
 * - url contains lookup
 *
 * @param {('email'|'email_contains'|'url_contains')} searchBy One of the options
 * @param {String} searchTerm The term introduced in the UI search bar
 * @returns A Promise which resolves to a collection of users, users with account info or sites.
 */
export const fetchDomainData = async (searchBy, searchTerm) => {
  const apiResponse = await fetchMethod[searchBy](searchTerm);

  return searchBy === 'email' ? apiResponse : apiResponse.response;
};
