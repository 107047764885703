export const insightsMagpieBillingPeriodsMapping = {
  three_months: 'THREE_MONTHS',
  six_months: 'SIX_MONTHS',
  yearly: 'ANNUALLY',
  two_years: 'TWO_YEARS',
  three_years: 'THREE_YEARS',
};

export const defaultManualBillingPeriod =
  insightsMagpieBillingPeriodsMapping.yearly;
