import { FC } from 'react';

const AskIcon: FC = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.97971 3.75C6.33849 3.75 5.84314 4.18035 5.73342 4.69488C5.64703 5.09998 5.2486 5.35836 4.8435 5.27197C4.43839 5.18559 4.18002 4.78715 4.2664 4.38205C4.53104 3.14101 5.6662 2.25 6.97971 2.25C8.4744 2.25 9.74991 3.41238 9.74991 4.92308C9.74991 6.17264 8.87725 7.1839 7.72971 7.49617V7.5C7.72971 7.91421 7.39392 8.25 6.97971 8.25C6.56549 8.25 6.22971 7.91421 6.22971 7.5V6.84616C6.22971 6.43194 6.56549 6.09616 6.97971 6.09616C7.71647 6.09616 8.24991 5.53656 8.24991 4.92308C8.24991 4.3096 7.71647 3.75 6.97971 3.75Z"
      fill="#202641"
    />
    <path
      d="M7 11C7.55229 11 8 10.5523 8 10C8 9.44772 7.55229 9 7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11Z"
      fill="#202641"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.72222 8.02198e-07H11.2778C11.604 -1.38986e-05 11.8928 -2.69078e-05 12.1321 0.0195236C12.3869 0.0403433 12.6509 0.0870181 12.908 0.21799C13.2843 0.409736 13.5903 0.715697 13.782 1.09202C13.913 1.34907 13.9597 1.61311 13.9805 1.86793C14 2.10722 14 2.39596 14 2.72221V10.2778C14 10.604 14 10.8928 13.9805 11.1321C13.9597 11.3869 13.913 11.6509 13.782 11.908C13.5903 12.2843 13.2843 12.5903 12.908 12.782C12.6509 12.913 12.3869 12.9597 12.1321 12.9805C11.8928 13 11.604 13 11.2778 13H3.7604C3.44645 13 3.40197 13.0041 3.36712 13.0118C3.31974 13.0223 3.27414 13.0397 3.23175 13.0633C3.20057 13.0807 3.16459 13.1072 2.92995 13.3157L1.24828 14.8106C1.02753 15.0068 0.712185 15.0552 0.44276 14.9342C0.173336 14.8132 8.02198e-07 14.5453 8.02198e-07 14.25V2.72222C-1.38986e-05 2.39598 -2.69078e-05 2.10722 0.0195236 1.86793C0.0403433 1.61311 0.0870182 1.34907 0.21799 1.09202C0.409736 0.715697 0.715697 0.409736 1.09202 0.21799C1.34907 0.0870182 1.61311 0.0403433 1.86793 0.0195236C2.10722 -2.69059e-05 2.39598 -1.38986e-05 2.72222 8.02198e-07ZM1.99008 1.51454C1.82802 1.52778 1.78335 1.54923 1.77301 1.5545C1.67893 1.60244 1.60244 1.67893 1.5545 1.77301C1.54923 1.78335 1.52778 1.82802 1.51454 1.99008C1.50059 2.16089 1.5 2.38759 1.5 2.75V12.5799L1.9718 12.1604C2.14349 12.0075 2.30786 11.861 2.50126 11.7532C2.67079 11.6587 2.85319 11.5893 3.04271 11.5473C3.2589 11.4994 3.47903 11.4997 3.70898 11.5L11.25 11.5C11.6124 11.5 11.8391 11.4994 12.0099 11.4855C12.172 11.4722 12.2167 11.4508 12.227 11.4455C12.3211 11.3976 12.3976 11.3211 12.4455 11.227C12.4508 11.2167 12.4722 11.172 12.4855 11.0099C12.4994 10.8391 12.5 10.6124 12.5 10.25V2.75C12.5 2.38759 12.4994 2.16089 12.4855 1.99008C12.4722 1.82802 12.4508 1.78335 12.4455 1.77301C12.3976 1.67893 12.3211 1.60244 12.227 1.5545C12.2167 1.54923 12.172 1.52778 12.0099 1.51454C11.8391 1.50059 11.6124 1.5 11.25 1.5H2.75C2.38759 1.5 2.16089 1.50059 1.99008 1.51454Z"
      fill="#202641"
    />
  </svg>
);

export default AskIcon;
