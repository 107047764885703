import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import {
  ChangeEvent,
  KeyboardEvent,
  useContext,
  useMemo,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { useToasts } from 'react-toast-notifications';
import { addUser } from './api';
import type { AddUserFormData } from './api';
import { AdmModalProps } from '../../../../components/modals/AdmModalRoot';
import { LookupContext } from '../../../../contexts/LookupContext';

type AddUserModalProps = {
  organizationId: number;
};

type ProvisionError = {
  error?: string;
};

const StyledForm = styled.form`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const DEFAULT_DATA: AddUserFormData = {
  email: '',
  name: '',
};

export const AddUserModal = ({
  onCloseModal,
  props: { organizationId },
}: AdmModalProps<AddUserModalProps>) => {
  const { addToast } = useToasts();
  const { lookupRequest } = useContext(LookupContext) || {};

  const [formData, setFormData] = useState<AddUserFormData>(DEFAULT_DATA);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const stopPropagationForTab = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await addUser(organizationId, formData);

      if (response?.success === true) {
        setFormData(DEFAULT_DATA);
        addToast('Provisioning CS user done', {
          appearance: 'success',
        });

        if (lookupRequest !== undefined) {
          lookupRequest();
        }
      } else {
        addToast('There was an error while provisioning user', {
          appearance: 'error',
        });
      }
    } catch (error) {
      const errorText = (error as ProvisionError)?.error;

      addToast(
        errorText ||
          'There was an error while attempting the provisioning requests',
        { appearance: 'error' }
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateField = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target?.id]: event.target.value,
    });
  };

  const isDataReadyForSubmission = useMemo(
    () => Object.values(formData).every((e) => e.length > 0),
    [formData]
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open
      onClose={onCloseModal}
      onKeyDown={stopPropagationForTab}
    >
      <DialogTitle>Provision CS User</DialogTitle>
      <DialogContent>
        <Typography>
          Organization ID: <span data-hj-suppress>{organizationId}</span>
        </Typography>

        <StyledForm onSubmit={handleSubmit}>
          <TextField
            autoFocus
            required
            fullWidth
            type="text"
            id="name"
            label="Name"
            disabled={isSubmitting}
            value={formData.name}
            onChange={handleUpdateField}
            variant="standard"
          />
          <TextField
            required
            fullWidth
            type="text"
            id="email"
            label="Email"
            disabled={isSubmitting}
            value={formData.email}
            onChange={handleUpdateField}
            variant="standard"
          />

          <Button
            variant="contained"
            color="primary"
            disabled={!isDataReadyForSubmission || isSubmitting}
            type="submit"
          >
            Create
          </Button>
        </StyledForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
