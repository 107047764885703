import { Grid } from '@mui/material';
import ShowSpinner from '../../ShowSpinner';

interface Props {
  subTotal: number;
  taxPercentage: number;
  total: number;
  renewAmountWithTax?: number;
  renewAmountWithoutTax?: number;
  isPreviewLoading: boolean;
  currency: 'eur' | 'usd';
  textAlign?: 'right' | 'left' | 'center';
  title?: string;
}

export const Cost = ({
  subTotal,
  taxPercentage,
  total,
  renewAmountWithTax,
  renewAmountWithoutTax,
  isPreviewLoading,
  currency,
  textAlign = 'right',
  title = 'Cost',
}: Props) => (
  <div style={{ marginBottom: '50px' }}>
    <h2>{title}</h2>
    {!isPreviewLoading && (
      <Grid container spacing={2} sx={{ textAlign }}>
        <Grid item xs={6}>
          Subtotal
        </Grid>
        <Grid item xs={6}>
          {`${subTotal.toFixed(2)} ${currency?.toUpperCase()}`}
        </Grid>
        <Grid item xs={6}>
          Tax Percentage
        </Grid>
        <Grid item xs={6}>
          {`${(taxPercentage * 100).toFixed(2)}%`}
        </Grid>
        <Grid item xs={6}>
          <strong>Total</strong>
        </Grid>
        <Grid item xs={6}>
          {`${total.toFixed(2)} ${currency?.toUpperCase()}`}
        </Grid>
        {renewAmountWithoutTax !== undefined && (
          <>
            <Grid item xs={6}>
              Renew Subtotal
            </Grid>
            <Grid item xs={6}>
              {`${renewAmountWithoutTax.toFixed(2)} ${currency?.toUpperCase()}`}
            </Grid>
          </>
        )}
        {renewAmountWithTax !== undefined && (
          <>
            <Grid item xs={6}>
              <strong>Renew Total</strong>
            </Grid>
            <Grid item xs={6}>
              {`${renewAmountWithTax.toFixed(2)} ${currency?.toUpperCase()}`}
            </Grid>
          </>
        )}
      </Grid>
    )}
    {isPreviewLoading && <ShowSpinner />}
  </div>
);
