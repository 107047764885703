import React, { useState, useEffect } from 'react';
import CampaignTable from './CampaignTable';
import ShowSpinner from '../../components/ShowSpinner';
import { getCampaigns, createCampaign } from './api';
import { getPlans } from '../../utils/plans';
import CreateForm from './CreateForm';
import Paper from '@mui/material/Paper';
import styles from './Campaigns.module.css';

const transformData = (items, plans) =>
  items.map((item) => {
    const plan = plans.find((plan) => plan.identifier === item.plan_id);

    return [
      item.id,
      item.name,
      item.campaign_url,
      plan && plan.label,
      item.num_free_days,
      item.type,
      item.enabled,
    ];
  });

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = () => {
    setLoading(true);
    Promise.all([getCampaigns(), getPlans()])
      .then(([response, plans]) => {
        setCampaigns(transformData(response.campaigns, plans));
        setLoading(false);
      })
      .catch((e) => {
        setCampaigns([]);
        setErrorMessage(e.message);
        setLoading(false);
      });
  };

  useEffect(getData, []);

  const handleFormSubmit = (data) => {
    return createCampaign(data)
      .then(getData)
      .catch((e) => setErrorMessage(e.message));
  };

  return (
    <div className={styles.campaigns}>
      <h2>Campaigns</h2>
      <Paper className={styles.createForm} elevation={1}>
        <p>
          <strong>NOTE:</strong> Please use the name format outlined{' '}
          <a href="https://hotjar.atlassian.net/wiki/spaces/MAR/pages/33758967/Hotjar+Campaign+Links">
            on Confluence
          </a>
          .
        </p>
        <p>
          <i>
            $HotjarDepartment($Hotjarian) | $CustomerCompanyName |
            $StartDate(YYYYMM)-Permanent
          </i>
        </p>
        <p>EG: "Marketing (Sylvain) | Company1 | 202103-Permanent"</p>
        <p>
          <strong>
            Campaigns that don't follow these rules may be deleted.
          </strong>
        </p>
        <CreateForm
          onFormSubmit={handleFormSubmit}
          error_message={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      </Paper>
      <Paper className={styles.results} elevation={1}>
        {!loading ? (
          <CampaignTable campaigns={campaigns} getData={getData} />
        ) : (
          <ShowSpinner />
        )}
      </Paper>
    </div>
  );
};

export default Campaigns;
