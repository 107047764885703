import { EntityDetailsColumnsConfig } from '../../../../components/EntityDetails/interfaces';
import { NO_DATA_PLACEHOLDER } from '../../../../utils/constants';
import { formatMoney } from '../../../../utils/costs';
import { toUTCDateString } from '../../../../utils/dates';
import {
  EntityType,
  MagpieCustomer,
} from '../../../magpie/models/magpie.model';
import { Site } from '../../models/site.model';

interface EntityDetailsSiteArgs {
  isSiteDeleted: boolean;
  siteData: Site;
  magpieCustomerData?: MagpieCustomer;
  handleOnOpenVisualizerModalClick?: () => void;
}

export const getFullSiteCostWithoutTax = (
  siteId: string,
  customerData?: MagpieCustomer
) => {
  const siteCosts =
    customerData?.current_period_cost_summary.purchased_product_costs?.filter(
      (product) =>
        product.entity.entity_type === EntityType.Site &&
        product.entity.entity_identifier === siteId
    );

  const computedSiteCosts = siteCosts?.reduce(
    (acc, cost) => acc + cost.amount_without_tax,
    0
  );

  return formatMoney(customerData?.currency, computedSiteCosts);
};

export const entityDetailsSite = ({
  isSiteDeleted,
  siteData,
  magpieCustomerData,
  handleOnOpenVisualizerModalClick,
}: EntityDetailsSiteArgs): EntityDetailsColumnsConfig => [
  [
    { name: 'Name', value: siteData.site_name },
    {
      name: 'Full site cost',
      value: getFullSiteCostWithoutTax(
        siteData.site_id.toString(),
        !isSiteDeleted ? magpieCustomerData : undefined
      ),
    },
  ],
  [
    { name: 'URL', value: siteData.site_url, url: siteData.site_url },
    {
      name: 'Last data',
      value: toUTCDateString(siteData.last_data_time),
    },
  ],
  [
    { name: 'Created', value: toUTCDateString(siteData.created_time) },
    {
      name: 'Rec Value',
      value: !isSiteDeleted
        ? siteData.rec_value.toString()
        : NO_DATA_PLACEHOLDER,
      onClick: !isSiteDeleted ? handleOnOpenVisualizerModalClick : undefined,
    },
  ],
];
