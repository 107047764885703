export function mapEmailsFromResponse(emails) {
  return emails.join(', ');
}

export function mapEmailsToRequest(emails) {
  return emails
    .split(',')
    .map((email) => email.trim())
    .filter((email) => email !== '');
}
