import React, { useState } from 'react';
import styles from './FeatureManager.module.css';
import type { FormData } from './Form';
import { Form } from './Form';
import type { PostFeatureToggleProps } from './api';
import { postFeatureToggle } from './api';

type ToggleFeatureProps = {
  action: PostFeatureToggleProps['action'];
};

export const ToggleFeature = ({ action }: ToggleFeatureProps) => {
  const [error, setError] = useState<string | null>(null);
  const [response, setResponse] = useState<string | null>(null);

  const handleSubmit = (data: FormData) => {
    setError(null);
    setResponse(null);

    postFeatureToggle({
      ...data,
      action,
    }).then(
      (response) => {
        setResponse(response);
      },
      (error) => {
        setError(error.error_message || error.error);
      }
    );
  };

  if (error !== null) {
    return (
      <p className="error">
        Error {action === 'enable_feature' ? 'enabling' : 'disabling'} feature:
        &nbsp;{error}
      </p>
    );
  }

  return (
    <>
      <h3>{action === 'enable_feature' ? 'Enable' : 'Disable'} feature</h3>

      <Form onFormAction={handleSubmit} />

      {response && <div className={styles.response}>{response}</div>}
    </>
  );
};
