import React, { useContext, useState } from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, MenuItem } from '@mui/material';
import { useToasts } from 'react-toast-notifications';

import { ActionsMenu } from '../../components/ActionsMenu';
import {
  MODAL_ADM_ACTIONS,
  MODAL_CONFIRMATION_DIALOG,
} from '../../components/modals/constants';
import { useModalContext } from '../../contexts/ModalContext';
import { UserContext } from '../../contexts/UserContext';
import { deactivateUser, reactivateUser } from './api';
import { getAdmUserActions } from '../../components/AdmActionTable/api';

const InternalUserActionsMenu = ({ refreshTable, user }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const localModalContext = useModalContext();
  const userContext = useContext(UserContext);
  const { addToast } = useToasts();

  const userLocked =
    !user.active && user.insights_user && !!user.insights_user.locked_out;

  const openPopover = () => {
    setPopoverOpen(true);
  };

  const closePopover = () => {
    setPopoverOpen(false);
  };

  const handleUserAction = (deactivate = true) => {
    const action = deactivate ? 'Deactivate' : 'Reactivate';
    const apiFunc = deactivate ? deactivateUser : reactivateUser;
    const userIdentifier = deactivate ? user.id : user.email;
    closePopover();
    localModalContext.openModal(MODAL_CONFIRMATION_DIALOG, {
      title: action + ' User',
      onSubmit: (annotation) => {
        return apiFunc(userIdentifier, annotation).then(() => {
          const message = `Successfully ${action.toLowerCase()}d ${user.email}`;
          addToast(message, { appearance: 'success', autoDismiss: true });
        });
      },
      modalWarning: `Are you sure you want to ${action.toLowerCase()} ${
        user.name
      } on ADM and Insights?`,
      onSuccess: () => refreshTable(),
      onError: (error) => {
        addToast(error.error_message || error.message || error.error, {
          appearance: 'error',
          autoDismiss: true,
        });
      },
    });
  };

  const openActionsModal = () => {
    closePopover();

    // Get AdmActions for user.
    getAdmUserActions(user.id).then((admActions) => {
      localModalContext.openModal(MODAL_ADM_ACTIONS, {
        data: admActions,
        related_type: 'User',
        showAddButton: false,
      });
    });
  };

  return (
    <ActionsMenu
      button={
        <IconButton data-testid="menuButton">
          <MoreHorizIcon />
        </IconButton>
      }
      isOpen={popoverOpen}
      openPopover={openPopover}
      closePopover={closePopover}
    >
      <MenuItem
        onClick={handleUserAction}
        disabled={userContext.id === user.id || userLocked}
      >
        Deactivate User
      </MenuItem>
      <MenuItem
        onClick={() => handleUserAction(false)}
        disabled={userContext.id === user.id || !userLocked}
      >
        Reactivate User
      </MenuItem>
      <MenuItem onClick={openActionsModal}>Activity</MenuItem>
    </ActionsMenu>
  );
};

export default InternalUserActionsMenu;
