import MUIDataTable from 'mui-datatables';

export const SitesSelection = ({
  siteIds,
  sitesLookup,
  selectedSites,
  handleCheckboxChange,
}) => {
  const columns = [
    {
      name: 'ID',
      options: {
        sortOrder: { name: 'id', direction: 'asc' },
      },
    },
    {
      name: 'Name',
    },
    {
      name: 'Url',
    },
  ];

  const tableData = siteIds?.map((siteId) => {
    const { name, url } = Object.values(sitesLookup).find(
      ({ id }) => id === siteId
    );
    return [siteId, name, url];
  });

  return (
    <MUIDataTable
      title={'Sites'}
      data={tableData}
      columns={columns}
      options={{
        pagination: false,
        filter: false,
        print: false,
        download: false,
        search: true,
        responsive: 'vertical',
        viewColumns: false,
        elevation: 0,
        rowsSelected: selectedSites.map((selectedSite) =>
          siteIds.findIndex((site) => site === selectedSite)
        ),
        customToolbarSelect: () => {},
        onRowSelectionChange: (_currentRow, allRows) => {
          handleCheckboxChange(allRows.map((row) => siteIds[row.dataIndex]));
        },
      }}
    />
  );
};
