import React, { Component } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import containerStyles from './Lookup.module.css';
import componentStyles from './DisplayAccount.module.css';
import { getCurrencySymbol } from '../../utils/costs';
import { toUTCDateString } from '../../utils/dates';
import ColoredLabel from '../../components/ColoredLabel';
import HasPermission from '../../components/HasPermission';
import RemovePaymentDetailsModal from '../../components/modals/RemovePaymentDetails/index';
import DisplayTrialDetails from './DisplayTrialDetails';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import { getBillingV3Info } from './billingV3.functions';
import AccountDiscountsAccordion from './AccountDiscountsAccordion';
import { AccountNextBillingDate } from './display-account/next-billing-date';

class DisplayAccountDetails extends Component {
  braintreeUrl = 'https://www.braintreegateway.com/merchants/nskftsqqkcszbtny';

  state = {
    modalOpen: false,
    paymentDetailsRemoved: false,
  };

  openRemovePaymentDetailsModal = () => {
    this.setState({ modalOpen: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  onPaymentDetailsRemoved = () => {
    this.setState({ paymentDetailsRemoved: true });
  };

  renderPaymentLabel = () => {
    const billingV2PaymentLabel = this.props.payment.label;
    const magpiePaymentMethod =
      this.props.magpie?.payment_method?.payment_method_type;

    if (magpiePaymentMethod === 'CREDIT_CARD') {
      return `CC: ${this.props.magpie.payment_method.details.card_number}`;
    }

    if (magpiePaymentMethod === 'EXTERNAL_PAYMENT_METHOD') {
      return `External Payment method`;
    }

    if (magpiePaymentMethod === 'HOTJAR_BRAINTREE_PAYMENT_METHOD') {
      return (
        <>
          <span>Custom Payment Method &nbsp;</span>
          <Tooltip title="This is a Braintree proxy payment method for customers migrated from Billing V2 to V3. Once they update their payment details, it will show as regular CC">
            <HelpIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />
          </Tooltip>
        </>
      );
    }

    if (magpiePaymentMethod === 'PAYPAL') {
      return `PayPal: ${this.props.magpie.payment_method.details.email}`;
    }

    if (magpiePaymentMethod) {
      return magpiePaymentMethod;
    }

    if (!billingV2PaymentLabel || this.state.paymentDetailsRemoved) {
      return 'No payment method set';
    }

    if (billingV2PaymentLabel.length === 4) {
      return `CC: ****-****-****-${billingV2PaymentLabel}`;
    }

    return `Paypal: ${billingV2PaymentLabel}`;
  };

  renderAddress = () => {
    if (this.props.magpie) {
      let { address_line1, address_line2, city, country } =
        this.props.magpie.billing_address;
      let addressSting = '';
      if (address_line1) addressSting += address_line1 += ', ';
      if (address_line2) addressSting += address_line2 += ', ';
      if (city) addressSting += city += ', ';
      if (country) addressSting += country;
      return addressSting;
    } else return this.props.address || 'None';
  };

  render() {
    const recipients = this.props.magpie
      ? this.props.magpie.additional_email_addresses
      : this.props.email_recipients;

    const {
      account_costs: {
        monthly_subscription_cost_without_vat = 0,
        yearly_subscription_cost_without_vat = 0,
      } = {},
    } = this.props;

    const { billingV3Converted } = getBillingV3Info(
      this.props.magpie,
      this.props.features
    );

    const monthly_total_cost = monthly_subscription_cost_without_vat.toFixed(2);
    const yearly_total_cost = yearly_subscription_cost_without_vat.toFixed(2);

    return (
      <div className={componentStyles.accountDetails}>
        {this.props.deleted ? (
          <ColoredLabel color="#EA4031">DELETED</ColoredLabel>
        ) : (
          ''
        )}
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <h3>Subscription</h3>
          </AccordionSummary>
          <AccordionDetails className={componentStyles.accordion}>
            <dl>
              <div>
                <dt className={containerStyles.label}>Created:</dt>
                <dd className={containerStyles.value}>
                  {toUTCDateString(
                    new Date(
                      this.props.magpie?.created_at || this.props.created * 1000
                    )
                  )}
                </dd>
              </div>

              <AccountNextBillingDate
                magpieCurrentPeriodEndDate={
                  this.props.magpie?.subscription.current_period_end_date
                }
                insightsNextBillingDate={this.props.next_billing_date}
              />

              {this.props.magpie ? (
                <>
                  <div>
                    <dt className={containerStyles.label}>
                      Subscription Status:
                    </dt>
                    <dd className={containerStyles.value}>
                      {this.props.magpie.subscription.status}
                    </dd>
                  </div>
                  <div>
                    <dt className={containerStyles.label}>
                      Current period cost:
                    </dt>
                    <dd className={containerStyles.value}>
                      {getCurrencySymbol(this.props.magpie.currency)}
                      {
                        this.props.magpie.current_period_cost_summary
                          .amount_without_tax
                      }
                    </dd>
                  </div>
                  <div>
                    <dt className={containerStyles.label}>
                      Current period cost (after tax):
                    </dt>
                    <dd className={containerStyles.value}>
                      {getCurrencySymbol(this.props.magpie.currency)}
                      {
                        this.props.magpie.current_period_cost_summary
                          .amount_with_tax
                      }
                    </dd>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <dt className={containerStyles.label}>
                      Monthly total cost:
                    </dt>
                    <dd className={containerStyles.value}>
                      {getCurrencySymbol(this.props.currency)}
                      {monthly_total_cost}
                    </dd>
                  </div>
                  <div>
                    <dt className={containerStyles.label}>
                      Yearly total cost:
                    </dt>
                    <dd className={containerStyles.value}>
                      {getCurrencySymbol(this.props.currency)}
                      {yearly_total_cost}
                    </dd>
                  </div>
                </>
              )}
              <div>
                <dt className={containerStyles.label}>Account Discount:</dt>
                <dd className={containerStyles.value}>
                  {this.props.percentage_discount}%
                </dd>
              </div>
              {this.props.customer_id && (
                <div>
                  <dt className={containerStyles.label}>Customer ID:</dt>
                  <dd className={containerStyles.value} data-hj-suppress>
                    <a
                      href={`${this.braintreeUrl}/customers/${this.props.customer_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.props.customer_id}
                      <OpenInNewIcon className="open-in-new" />
                    </a>
                  </dd>
                </div>
              )}
              {this.props.subscription_id && (
                <div>
                  <dt className={containerStyles.label}>Subscription ID:</dt>
                  <dd className={containerStyles.value} data-hj-suppress>
                    <a
                      href={`${this.braintreeUrl}/subscriptions/${this.props.subscription_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.props.subscription_id}
                      <OpenInNewIcon className="open-in-new" />
                    </a>
                  </dd>
                </div>
              )}
              <div>
                <dt className={containerStyles.label}>Billing Cycle:</dt>
                <dd className={containerStyles.value}>
                  {this.props.magpie?.subscription?.billing_period ||
                    this.props.billing_cycle}
                </dd>
              </div>
              {this.props.pricing_table && (
                <div>
                  <dt className={containerStyles.label}>Pricing table:</dt>
                  <dd className={containerStyles.value}>
                    {this.props.pricing_table.label}
                  </dd>
                </div>
              )}
              <div>
                <dt className={containerStyles.label}>Payment:</dt>
                <dd className={containerStyles.value}>
                  {this.renderPaymentLabel()}
                </dd>
              </div>
              {this.props.payment.label && !this.state.paymentDetailsRemoved && (
                <div>
                  {this.props.payment.token && (
                    <div data-hj-suppress>
                      <dt className={containerStyles.label}>Token:</dt>
                      <dd className={containerStyles.value} data-hj-suppress>
                        {this.props.payment.token}
                      </dd>
                    </div>
                  )}
                  <HasPermission
                    rolesAccepted={['admin', 'billing_management_2']}
                  >
                    <button
                      className={componentStyles.removePaymentDetailsButton}
                      onClick={this.openRemovePaymentDetailsModal}
                    >
                      <DeleteForeverIcon />
                      Remove payment details
                    </button>
                    <RemovePaymentDetailsModal
                      open={this.state.modalOpen}
                      onClose={this.closeModal}
                      account={this.props}
                      onPaymentDetailsRemoved={this.onPaymentDetailsRemoved}
                    />
                  </HasPermission>
                </div>
              )}
            </dl>
          </AccordionDetails>
        </Accordion>

        {billingV3Converted && (
          <AccountDiscountsAccordion customer={this.props.magpie} />
        )}

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <h3>Company Details</h3>
          </AccordionSummary>
          <AccordionDetails>
            <dl>
              <div>
                <dt className={containerStyles.label}>Company Name:</dt>
                <dd className={containerStyles.value}>
                  {this.props.magpie?.company_name ||
                    this.props.company ||
                    'None'}
                </dd>
              </div>
              <div>
                <dt
                  className={
                    this.props.address
                      ? containerStyles.block_label
                      : containerStyles.label
                  }
                >
                  Address:
                </dt>
                <dd
                  className={
                    this.props.address
                      ? containerStyles.block_value
                      : containerStyles.value
                  }
                >
                  {this.renderAddress()}
                </dd>
              </div>
              <div>
                <dt className={containerStyles.label}>Tax Number:</dt>
                <dd className={containerStyles.value}>
                  {this.props.magpie?.tax_number ||
                    this.props.vat_number ||
                    'None'}
                </dd>
              </div>
              {recipients && recipients.length > 0 && (
                <div>
                  <dt className={containerStyles.block_label}>
                    Additional Invoice Recipients:
                  </dt>

                  <dd className={containerStyles.block_value}>
                    {recipients.join(', ')}
                  </dd>
                </div>
              )}
            </dl>
          </AccordionDetails>
        </Accordion>

        {this.props.trial.active && <DisplayTrialDetails {...this.props} />}

        {this.props.features.length > 0 && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h3>Enabled Features</h3>
            </AccordionSummary>
            <AccordionDetails>
              {this.props.features.map((featureName, i) => (
                <ColoredLabel key={i} color="var(--blue)">
                  {this.props.getFeatureName(featureName)}
                </ColoredLabel>
              ))}
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    );
  }
}

export default DisplayAccountDetails;
