import { getFreePlans } from '../../../utils/getFreePlans';

function getSkuByPlanCharacteristic(
  productName,
  featureGroup,
  allowance,
  productsCatalog
) {
  // Ask, Observe
  const allowanceType =
    productName === 'ask' ? 'monthly_responses' : 'daily_cc_sessions';

  const sku = productsCatalog?.find(
    (product) =>
      product.productName.toLowerCase() === productName &&
      product.planName.toLowerCase() === featureGroup &&
      product.allowance[allowanceType] === allowance
  )?.sku;

  return sku;
}

export function getSitesToMigrate(sites, productsCatalog) {
  if (!productsCatalog) {
    return [];
  }

  const sitesToMigrate = sites
    ? Object.values(sites).filter((site) => {
        return site.products.some(
          (product) => product.effective_feature_group !== 'BASIC'
        );
      })
    : [];

  const sitesWithSkus = sitesToMigrate.map((site) => {
    const products = site.products
      .map((product) => {
        const productName = product.name === 'ask' ? 'feedback' : 'analytics';
        const featureGroup = product.feature_group.toLowerCase();
        const effectiveFeatureGroup =
          product.effective_feature_group.toLowerCase();
        const allowance = product.sessions ?? product.monthly_responses;
        const actualAllowance =
          product.name === 'ask'
            ? product.monthly_responses
            : product.cc_sessions;
        const effectiveAllowance =
          product.name === 'ask'
            ? product.effective_monthly_responses
            : product.effective_cc_sessions;
        const planId = `${productName}_${featureGroup}_${allowance}`;

        const sku = getSkuByPlanCharacteristic(
          product.name,
          featureGroup.toLowerCase(),
          actualAllowance,
          productsCatalog
        );

        const effectiveSku = getSkuByPlanCharacteristic(
          product.name,
          effectiveFeatureGroup,
          effectiveAllowance,
          productsCatalog
        );

        return {
          ...product,
          planId,
          sku,
          effectiveSku,
        };
      })
      .filter(
        (product) =>
          !getFreePlans(productsCatalog).includes(product.effectiveSku) &&
          product.effectiveSku !== undefined
      );
    return { ...site, products };
  });

  return sitesWithSkus.filter((site) => site.products.length > 0);
}
