import type { ProductCatalogItem, ProductName } from '../typings';

export const getBasicPlan = (
  planType: ProductName,
  productsCatalog: ProductCatalogItem[]
) => {
  return productsCatalog.find(
    (plan) => plan.productName === planType && isFreePlan(plan)
  );
};

export const isFreePlan = (plan: ProductCatalogItem): boolean => {
  return !plan.allowance.chargeable;
};

export const getFreePlans = (productsCatalog: ProductCatalogItem[]) => {
  return productsCatalog.reduce((acc: ProductCatalogItem['sku'][], item) => {
    if (isFreePlan(item)) {
      return [...acc, item.sku];
    }

    return acc;
  }, []);
};
