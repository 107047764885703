import { TrialState } from '../models/TrialState';

const trialActiveStatuses = [
  'active',
  'initiated',
  'extension_pending',
  'extension_requested',
  'extended',
];

/**
 * Given an array of `TrialState` objects belonging to an entity, returns whether or not that
 * entity is undergoing an active trial.
 */
export const trialStatesIncludesActiveTrials = (
  trialStates: Array<TrialState> | undefined
): boolean => {
  return (
    trialStates?.some((trialState) =>
      trialState.status
        ? trialActiveStatuses.includes(trialState.status)
        : false
    ) ?? false
  );
};
