import type { FieldErrors, Resolver } from 'react-hook-form';
import type { Entity } from './api';
import { isEntity, isValidJiraId, isValidPercentage } from './formUtils';
import type {
  FeatureActionFormProps,
  FeatureActionFormReturn,
} from './useFeatureActionForm';
import {
  entityErrorMessage,
  useFeatureActionForm,
} from './useFeatureActionForm';

export type StartUpdateFeatureFields = {
  entity: Entity;
  percentage: number;
  jiraId: string;
};

const startUpdateFeatureResolver: Resolver<StartUpdateFeatureFields> = (
  values
) => {
  let hasError = false;
  const errors: FieldErrors<StartUpdateFeatureFields> = {};

  if (!isEntity(values.entity)) {
    hasError = true;
    errors['entity'] = {
      type: 'isEntity',
      message: entityErrorMessage,
    };
  }

  if (!isValidPercentage(values.percentage)) {
    hasError = true;
    errors['percentage'] = {
      type: 'isValidPercentage',
      message: 'Percentage must be between 0 and 100.',
    };
  }

  if (!isValidJiraId(values.jiraId)) {
    hasError = true;
    errors['jiraId'] = {
      type: 'isValidJiraId',
      message: 'Jira ID must be a valid story/task ID, e.g. ABC-123.',
    };
  }

  return {
    values: hasError ? {} : values,
    errors,
  };
};

type StartUpdateFeatureFormProps = Omit<
  FeatureActionFormProps<StartUpdateFeatureFields>,
  'defaultValues' | 'resolver'
>;

export const useStartUpdateFeatureForm = (
  props: StartUpdateFeatureFormProps
): FeatureActionFormReturn<StartUpdateFeatureFields> =>
  useFeatureActionForm<StartUpdateFeatureFields>({
    ...props,
    defaultValues: {
      entity: 'account',
      percentage: undefined,
      jiraId: '',
    },
    resolver: startUpdateFeatureResolver,
  });
