import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, TextField } from '@mui/material';
import { Email } from '../../utils/validators';
import t from 'tcomb-validation';
import styles from './Login.module.css';
import { postLogin } from './api';

const validators = {
  email: {
    validator: Email,
    cast: (s) => {
      return s.trim();
    },
  },
};

const Form = ({ location, history }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    error: null,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      return postLogin(formData.email, formData.password)
        .then(() => {
          const path = location.state ? location.state.from.pathname : '/';
          history.push(path);
        })
        .catch((error) => {
          setFormData({ ...formData, error: error.message });
        });
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const catchReturnThenSubmit = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const validate = () => {
    let status = t.validate(formData.email, validators.email.validator);
    if (status.isValid()) {
      setFormData({ ...formData, error: null });
      return true;
    } else {
      setFormData({ ...formData, error: status.firstError().message });
      return false;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        autoFocus
        label="Email"
        value={formData.email}
        className={styles.textField}
        name="email"
        id="login.email"
        onChange={handleChange}
        helperText={formData.error}
        error={formData.error !== null}
        onKeyPress={catchReturnThenSubmit}
        variant="standard"
      />
      <TextField
        label="Password"
        value={formData.password}
        className={styles.textField}
        name="password"
        id="login.password"
        type="password"
        onChange={handleChange}
        error={formData.error !== null}
        onKeyPress={catchReturnThenSubmit}
        variant="standard"
      />
      <Link
        className={styles.forgot}
        to={`/forgot_password?email=${formData.email}`}
      >
        Forgot your password?
      </Link>
      <Button
        variant="contained"
        color="primary"
        fullWidth={true}
        onClick={handleSubmit}
        className={styles.button}
      >
        Login
      </Button>
    </form>
  );
};

export default Form;
