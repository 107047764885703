import { CircularProgress } from '@mui/material';
import styles from './LoadingColumnHead.module.css';

const LoadingColumnHead = ({ columnName }) => (
  <div className={styles.columnHead}>
    {columnName}
    <div className={styles.loading}>
      Loading <CircularProgress variant="indeterminate" size={10} />
    </div>
  </div>
);

export default LoadingColumnHead;
