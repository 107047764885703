import type { Dispatch, SetStateAction } from 'react';
import React, { useState } from 'react';
import type { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import MUIDataTable from 'mui-datatables';
import { Button, ButtonGroup } from '@mui/material';
import ShowSpinner from '../../components/ShowSpinner';
import type { DefaultFeature } from './api';
import { useGetDefaultFeatures } from './api';
import styles from './ManageFeatures.module.css';
import { AddDefaultFeatureDialog } from './AddDefaultFeatureDialog';
import { useAddDefaultFeature } from './useAddDefaultFeature';
import { DeleteButton } from './DeleteDefaultFeature';

const createColumns = (
  entity: DefaultFeature['entity']
): MUIDataTableColumn[] => [
  { name: 'feature', label: 'Name' },
  {
    name: 'hardcoded',
    label: 'Hardcoded?',
    options: {
      searchable: false,
      filterOptions: { names: ['Yes', 'No'] },
      customBodyRender: (value: DefaultFeature['hardcoded']) =>
        value ? 'Yes' : 'No',
    },
  },
  { name: 'percentage', label: 'Percentage', options: { searchable: false } },
  {
    name: 'feature',
    label: ' ',
    options: {
      searchable: false,
      sort: false,
      customBodyRender: (value: DefaultFeature['feature']) => (
        <DeleteButton entity={entity} feature={value} />
      ),
    },
  },
];

const options: MUIDataTableOptions = {
  responsive: 'standard',
  selectableRows: 'none',
  print: false,
  download: false,
  viewColumns: false,
  elevation: 0,
  sortOrder: {
    name: 'feature',
    direction: 'asc',
  },
  filterType: 'dropdown',
};

type EntityButtonProps = {
  entity: DefaultFeature['entity'];
  currentEntity: DefaultFeature['entity'];
  setEntity: Dispatch<SetStateAction<DefaultFeature['entity']>>;
};

const EntityButton = ({
  entity,
  currentEntity,
  setEntity,
}: EntityButtonProps) => (
  <Button
    value={entity}
    tabIndex={-1}
    variant={currentEntity === entity ? 'contained' : undefined}
    onClick={() => {
      setEntity(entity);
    }}
  >
    {entity}
  </Button>
);

export const DefaultFeatures = (): JSX.Element => {
  const [entity, setEntity] = useState<DefaultFeature['entity']>('account');
  const { data: features = null, isError, error } = useGetDefaultFeatures();
  const [addFormData, addFormDispatch] = useAddDefaultFeature();

  if (isError) {
    return (
      <p className="error">{`Error fetching default features: ${error}`}</p>
    );
  }

  if (features === null) {
    return <ShowSpinner />;
  }

  const columns = createColumns(entity);

  const entityFeatures = features.filter(
    (feature) => feature.entity === entity
  );

  const handleAddFeature = () => {
    addFormDispatch({ type: 'show', payload: entity });
  };

  return (
    <>
      <div className={styles.actions}>
        <ButtonGroup
          tabIndex={-1}
          variant="outlined"
          color="primary"
          aria-label="Entity Filter"
        >
          <EntityButton
            entity="account"
            currentEntity={entity}
            setEntity={setEntity}
          />
          <EntityButton
            entity="organization"
            currentEntity={entity}
            setEntity={setEntity}
          />
          <EntityButton
            entity="site"
            currentEntity={entity}
            setEntity={setEntity}
          />
        </ButtonGroup>

        <Button variant="contained" onClick={handleAddFeature}>
          Add Default Feature
        </Button>
      </div>

      <MUIDataTable
        title="Default features"
        columns={columns}
        data={entityFeatures}
        options={options}
      />

      <AddDefaultFeatureDialog data={addFormData} dispatch={addFormDispatch} />
    </>
  );
};
