import { MenuItem } from '@mui/material';
import { useModalContext } from '../../../../contexts/ModalContext';
import { MODAL_ADD_USER } from '../../../../components/modals/constants';

export type AddUserProps = {
  organizationId: number;
};

export const AddUser = ({ organizationId }: AddUserProps) => {
  const modalContext = useModalContext();

  return (
    <>
      <MenuItem
        onClick={() =>
          modalContext.openModal(MODAL_ADD_USER, { organizationId })
        }
      >
        Add User
      </MenuItem>
    </>
  );
};
