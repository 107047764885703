import React, { Component } from 'react';
import moment from 'moment';
import {
  Button,
  InputLabel,
  FormControl,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { invoicesApi } from './api';
import { selects } from './constants';
import styles from './Invoices.module.css';
import { downloadInvoiceCsv } from '../../utils/CSVHelpers';
import { toDateString } from '../../utils/dates';

export class InvoiceReport extends Component {
  state = {
    from: moment().subtract(1, 'months'),
    to: moment(),
    error_message: null,
    payment_method_type: '',
    status: '',
    location: '',
    has_vat_number: '',
  };

  updateDateFrom = (date) => {
    this.setState({ from: date.utc() });
  };

  updateDateTo = (date) => {
    this.setState({ to: date.utc() });
  };

  handleSelectChange = (value, name) => {
    this.setState({ [name]: value });
  };

  getReport = () => {
    const from = toDateString(this.state.from);
    const to = toDateString(this.state.to);
    const { payment_method_type, status, location, has_vat_number } =
      this.state;
    const { content_type, report_type } = this.props.api_args;
    invoicesApi(
      from,
      to,
      content_type,
      report_type,
      payment_method_type,
      status,
      location,
      has_vat_number
    )
      .then((data) => {
        downloadInvoiceCsv(this.props.filename_prefix, data);
      })
      .catch((e) => {
        this.setState({ error_message: e });
      });
  };

  render() {
    return (
      <Paper className={styles.paper}>
        <h3>{this.props.report_title}</h3>
        <p>Extract a report between two dates</p>
        <div className={styles.fields}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id="from"
              name="from"
              inputFormat="YYYY-MM-DD"
              label="From"
              showTodayButton
              className={styles.date}
              error={!!this.state.error_message}
              helperText={this.state.error_message}
              onChange={this.updateDateFrom}
              value={this.state.from}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
            <DatePicker
              id="to"
              name="to"
              inputFormat="YYYY-MM-DD"
              variant="dialog"
              showTodayButton
              className={styles.date}
              error={!!this.state.error_message}
              helperText={this.state.error_message}
              label="To"
              onChange={this.updateDateTo}
              value={this.state.to}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </LocalizationProvider>
          {this.props.filters.includes('location') && (
            <FormControl className={styles.select}>
              <InputLabel htmlFor="location">Location</InputLabel>
              <Select
                id="location"
                name="location"
                value={this.state.location}
                onChange={(e) =>
                  this.handleSelectChange(e.target.value, 'location')
                }
                error={!!this.state.error_message}
                native
                variant="standard"
              >
                <option value={''} />
                {selects.location.map((field, i) => {
                  return (
                    <option value={field.value} key={i}>
                      {field.text}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {this.props.filters.includes('has_vat_number') && (
            <FormControl className={styles.select}>
              <InputLabel htmlFor="has_vat_number">Has Vat Number</InputLabel>
              <Select
                id="has_vat_number"
                name="has_vat_number"
                value={this.state.has_vat_number}
                onChange={(e) =>
                  this.handleSelectChange(e.target.value, 'has_vat_number')
                }
                error={!!this.state.error_message}
                variant="standard"
                native
              >
                <option value={''} />
                {selects.has_vat_number.map((field, i) => {
                  return (
                    <option value={field.value} key={i}>
                      {field.text}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {this.props.filters.includes('payment_method_type') && (
            <FormControl className={styles.select}>
              <InputLabel htmlFor="payment_method_type">
                Payment Method Type
              </InputLabel>
              <Select
                id="payment_method_type"
                name="payment_method_type"
                value={this.state.payment_method_type}
                onChange={(e) =>
                  this.handleSelectChange(e.target.value, 'payment_method_type')
                }
                error={!!this.state.error_message}
                variant="standard"
                native
              >
                <option value={''} />
                {selects.payment_method_type.map((field, i) => {
                  return (
                    <option value={field.value} key={i}>
                      {field.text}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {this.props.filters.includes('status') && (
            <FormControl className={styles.select}>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                id="status"
                name="status"
                value={this.state.status}
                className={styles.select}
                onChange={(e) =>
                  this.handleSelectChange(e.target.value, 'status')
                }
                error={!!this.state.error_message}
                variant="standard"
                native
              >
                <option value={''} />
                {selects.status.map((field, i) => {
                  return (
                    <option value={field.value} key={i}>
                      {field.text}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          )}
          <Button
            variant="contained"
            className={styles.button}
            color="primary"
            onClick={this.getReport}
          >
            Get Report
          </Button>
        </div>
      </Paper>
    );
  }
}

export default InvoiceReport;

InvoiceReport.defaultProps = {
  filters: [],
};
