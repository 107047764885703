import { SKU } from '../../../typings';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { TrialEntityType } from '../models/TrialEntityType';
import { TrialEligibility } from '../models/TrialEligibility';

interface ValidateTrialRequest {
  sku?: SKU;
  bundle_name?: `${SKU}_${SKU}`;
  entity: {
    type: TrialEntityType;
    id: string;
  };
  hotjar_account_id: string;
  source: string;
}

export const validateTrialEligibility = async (
  payload: ValidateTrialRequest,
  errorMessage: string
) => {
  const { entity, sku, hotjar_account_id, source } = payload;

  const response = await fetch(
    `/api/v2/adm/trials/trials/eligibility?entity_type=${entity.type}&entity_id=${entity.id}&sku=${sku}&hotjar_account_id=${hotjar_account_id}&source=${source}`
  );
  return handleFetchApiResponse<TrialEligibility>(response, errorMessage);
};
