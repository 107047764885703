import { Tooltip, Typography } from '@mui/material';

export function TrimmedText({
  text,
  maxLength,
}: {
  text: string;
  maxLength: number;
}) {
  const trimmedText =
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  return (
    <Tooltip title={text} arrow>
      <Typography noWrap fontSize={12}>
        {trimmedText}
      </Typography>
    </Tooltip>
  );
}
