import React from 'react';
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import {
  accountIdRenderer,
  lastDataRenderer,
  totalSpendErrorCustomHeadLabelRender,
  totalSpendLoadingCustomHeadLabelRender,
  totalSpendRenderer,
} from './renderers';
import { DOMAIN_SITE_SEARCH_LABEL } from './constants';
import styles from './DomainSearch.module.css';
import {
  isPayingCustomerCustomFilterListOptions,
  isPayingCustomerFilterOptions,
} from './filters';
import { entitiesWithCustomerData } from './utils';

const DomainSitesTable = ({
  sites,
  searchTerm,
  customerData,
  customerDataError,
  isLoadingCustomers,
}) => {
  const columnSettings = [
    {
      name: 'Site ID',
      options: {
        filter: false,
        customBodyRender: (id) => (
          <Link to={`/lookup/site_id/${id}`}>{id}</Link>
        ),
      },
    },
    {
      name: 'Account ID',
      options: {
        filter: false,
        customBodyRender: accountIdRenderer,
      },
    },
    {
      name: 'Site name',
      options: {
        filter: false,
      },
    },
    {
      name: 'URL',
      options: {
        filter: false,
      },
    },
    {
      name: 'Total spend',
      options: {
        filter: false,
        sort: !customerDataError,
        customBodyRender: totalSpendRenderer(isLoadingCustomers),
        ...(customerDataError && {
          customHeadLabelRender: totalSpendErrorCustomHeadLabelRender,
        }),
        ...(isLoadingCustomers && {
          customHeadLabelRender: totalSpendLoadingCustomHeadLabelRender,
        }),
      },
    },
    {
      name: 'lastData',
      label: 'Last data',
      options: {
        filter: false,
        customBodyRender: lastDataRenderer,
      },
    },
    {
      name: 'isPayingCustomer',
      options: {
        display: 'excluded',
        filterType: 'custom',
        filterOptions: isPayingCustomerFilterOptions(customerData),
        customFilterListOptions: isPayingCustomerCustomFilterListOptions,
      },
    },
    {
      name: 'Currency',
      options: {
        filter: false,
        // Used in the total spend to display the account currency
        display: 'excluded',
      },
    },
    {
      name: 'Company name',
      options: {
        filter: false,
        // Displayed in the account id tooltip
        display: 'excluded',
      },
    },
  ];

  const tableSettings = {
    pagination: false,
    print: false,
    download: false,
    filter: true,
    selectableRows: 'none',
    responsive: 'standard',
    searchAlwaysOpen: true,
    searchPlaceholder: DOMAIN_SITE_SEARCH_LABEL,
    searchProps: {
      'aria-label': DOMAIN_SITE_SEARCH_LABEL,
    },
    sortOrder: {
      name: 'lastData',
      direction: 'desc',
    },
  };

  const siteData = entitiesWithCustomerData(sites, customerData).map((site) => [
    site.id,
    site.account_id,
    site.name,
    site.url,
    site.totalSpend,
    site.last_data,
    site.hasActiveSubscription,
    // currency should be the before last item in the column array
    site.currency,
    // company name should be the last item in the column array
    site.company_name,
  ]);

  return (
    <div className={styles.container}>
      {sites.length <= 0 ? (
        `No sites found for "${searchTerm}"`
      ) : (
        <MUIDataTable
          columns={columnSettings}
          options={tableSettings}
          data={siteData}
        />
      )}
    </div>
  );
};

export default DomainSitesTable;
