import { apiPost } from '../../../../utils/api';

export type ProvisioningProps = {
  organization_name: string;
  site_name: string;
  site_url: string;
  limit: string;
  start_date: string | null;
  end_date: string | null;
};

export const setProvisionCsAccount = async (
  accountId: number,
  accountProps: ProvisioningProps
) => {
  return apiPost(
    `/account/${accountId}`,
    'provision_dxa_project',
    accountProps
  );
};
