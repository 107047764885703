import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';

/* @material-us/core/ExpansionPanel expands whenever it or any child is clicked.
This component controls the expansion state and connects it only to clicks on the icon. */
const ControlledExpansionPanel = (
  { children, className, defaultExpanded },
  ...otherProps
) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <Accordion
      expanded={expanded}
      className={className}
      onClick={(event) => {
        /* Allow only the <ExpandIcon> related to this Expansion panel to expand it. */
        if (
          event.target.parentElement &&
          event.target.parentElement.parentElement &&
          event.target.parentElement.parentElement.parentElement &&
          event.target.parentElement.parentElement.parentElement.parentElement
        ) {
          if (
            (event.target.parentElement.parentElement.parentElement ===
              event.currentTarget &&
              event.target.nodeName === 'svg') ||
            // eslint-disable-next-line max-len
            (event.target.parentElement.parentElement.parentElement
              .parentElement.parentElement === event.currentTarget &&
              event.target.nodeName === 'path')
          ) {
            setExpanded(!expanded);
          }
        }
      }}
    >
      {children}
    </Accordion>
  );
};

export default ControlledExpansionPanel;
