import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import type { DefaultFeature } from './api';
import { deleteDefaultFeature } from './api';

type ButtonIconProps = {
  deleting: boolean;
};

const ButtonIcon = ({ deleting }: ButtonIconProps): JSX.Element => {
  if (deleting) {
    return <CircularProgress title="Deleting default feature" size="1rem" />;
  }

  return <DeleteIcon />;
};

export const DeleteButton = ({
  entity,
  feature,
}: Pick<DefaultFeature, 'entity' | 'feature'>): JSX.Element => {
  const [deleting, setDeleting] = useState<boolean>(false);

  const handleClick = async () => {
    setDeleting(true);

    await deleteDefaultFeature({
      entity,
      feature,
    });

    setDeleting(false);
  };

  return (
    <Button
      variant="contained"
      aria-label="Delete"
      aria-busy={deleting}
      title="Delete"
      disabled={deleting}
      startIcon={<ButtonIcon deleting={deleting} />}
      onClick={handleClick}
    >
      Delete
    </Button>
  );
};
