import { getOrderStatus } from './get-order-status';

const POLL_INTERVAL = 1000;
const POLL_TIMEOUT = 60 * 1000;

export async function pollOrderStatus(identifier: string) {
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      const response = await getOrderStatus(identifier);
      if (response.status === 'SUCCESSFUL') {
        clearInterval(interval);
        resolve(response);
      }
    }, POLL_INTERVAL);
    setTimeout(() => {
      clearInterval(interval);
      reject(new Error(`Order status not updated in ${POLL_TIMEOUT} seconds`));
    }, POLL_TIMEOUT);
  });
}
