import { apiGet } from '../../../utils/api';
import {
  normalizeSamplingChangesResponse,
  normalizeRecordingCountHourlyResponse,
} from '../../../utils/normalizer';

const sanitizeTimestampForPython = (timestamp) => Math.floor(timestamp / 1000);

export function getRecValues(site_id, from, to) {
  return apiGet(
    `/sites/${site_id}/rec-values`,
    {
      from: sanitizeTimestampForPython(from),
      to: sanitizeTimestampForPython(to),
    },
    2,
    {},
    true,
    'get rec-values'
  ).then((res) => normalizeSamplingChangesResponse(res.changes, to));
}

export function getRecordingCountsHourly(site_id, from, to) {
  return apiGet(
    `/sites/${site_id}/recordings/count/hourly`,
    {
      from: sanitizeTimestampForPython(from),
      to: sanitizeTimestampForPython(to),
    },
    2,
    {},
    true,
    'get recording counts'
  ).then((res) => normalizeRecordingCountHourlyResponse(res.counts));
}
