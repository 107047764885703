import { TrialState } from '../models/TrialState';

export const trialStatesIncludesTrialExtensionInProgress = (
  trialStates: Array<TrialState> | undefined
): boolean => {
  return (
    trialStates?.some(
      (trialState) =>
        trialState.status === 'extension_requested' ||
        trialState.status === 'extension_pending'
    ) ?? false
  );
};
