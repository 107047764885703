import { useQuery } from '@tanstack/react-query';
import { fetchUserURL } from './urls';
import { User } from '../models/user.model';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';

const showUser = async (id: number): Promise<User> => {
  const response = await fetch(fetchUserURL(id));

  return handleFetchApiResponse<User>(response);
};

export const useShowsUser = (id?: number) => {
  const { addToast } = useToasts();
  const { data, isInitialLoading, isError, error } = useQuery<User>({
    queryKey: ['showUser', id],
    queryFn: () => showUser(id!),
    enabled: !!id,
  });

  useEffect(() => {
    // Error toast not displayed on 404
    if (isError) {
      addToast(`There was an error fetching data for user with id:"${id}"`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [id, addToast, isError]);

  return {
    userData: data,
    userError: error,
    isUserError: isError,
    isUserLoading: isInitialLoading,
  };
};
