import { useEffect, FC } from 'react';
import type { Modal } from '../../../../components/modals/constants';

interface SiteReopenModalsProps {
  siteId: string;
  modalNameToOpenMethod: Record<string, () => void>;
}

const SiteReopenModals: FC<SiteReopenModalsProps> = ({
  siteId,
  modalNameToOpenMethod,
}) => {
  const resetSearchParams = () => {
    const newRelativePathQuery = window.location.pathname;
    // eslint-disable-next-line no-restricted-globals
    history.replaceState(null, '', newRelativePathQuery);
  };

  const openModalBySearchParam = (openValue: Modal) => {
    if (openValue && openValue in modalNameToOpenMethod) {
      modalNameToOpenMethod[openValue]();
    } else {
      resetSearchParams();
    }
  };

  useEffect(() => {
    if ('URLSearchParams' in window) {
      // Handle automatic modal opening.
      const searchParams = new URLSearchParams(window.location.search);
      // We must have a target site_id or we can't open anything.
      const paramsSiteId = searchParams.get('site_id');
      const open = searchParams.get('open');

      if (
        paramsSiteId &&
        paramsSiteId === siteId &&
        open &&
        open in modalNameToOpenMethod
      ) {
        openModalBySearchParam(open as Modal);
      }
    }
  }, []);

  return null;
};

export default SiteReopenModals;
