import { Component } from 'react';
import { Dialog, Button } from '@mui/material';
import { withLoadingDialog } from '../../withLoading';
import Form from './Form';
import { inviteUser } from './api';

const LoadingAwareDialog = withLoadingDialog(Dialog);

class InviteUserModal extends Component {
  state = {
    error_message: null,
  };

  handleSubmit = (data) => {
    const { onCloseModal, props: modalProps } = this.props;

    return inviteUser(
      data.email,
      data.access,
      modalProps.org_id,
      data.adm_action_annotation
    )
      .then(() => {
        modalProps.onSuccess();
        onCloseModal();
      })
      .catch((e) => this.setErrors(e.error_message));
  };

  setErrors = (error) => {
    this.setState({ error_message: error });
  };

  triggerSubmit = () => {
    this.refs.form.handleSubmit(new Event('submit'));
  };

  render() {
    const { onCloseModal, props: modalProps } = this.props;

    const modalActions = [
      <Button color="primary" key="cancel" onClick={onCloseModal}>
        Cancel
      </Button>,
      <Button color="primary" key="save" onClick={this.triggerSubmit}>
        Invite
      </Button>,
    ];

    if (!modalProps) {
      return null;
    }

    return (
      <LoadingAwareDialog
        open
        actions={modalActions}
        title="Invite User to Organization"
      >
        <div className="error">
          {this.state.error_message && 'ERROR: ' + this.state.error_message}
        </div>
        <div>
          Organization ID: {modalProps.org_id} - "{modalProps.org_name}"
        </div>
        <Form ref="form" onFormSubmit={this.handleSubmit} />
      </LoadingAwareDialog>
    );
  }
}

export default InviteUserModal;
