import { ProductName } from '../../typings';

// modalContext modal constants
export const MODAL_ADM_ACTIONS = 'adm_actions';
export const MODAL_CONFIRMATION_DIALOG = 'confirmation_dialog';
export const MODAL_BASIC_ALERT_DIALOG = 'basic_alert_dialog';
export const MODAL_DOMAIN_SEARCH = 'domain_search';
export const MODAL_EMAIL_CHANGE = 'email_change';
export const MODAL_FEATURE_MANAGER = 'feature_manager_dialog';
export const MODAL_FORM_DIALOG = 'form_dialog';
export const MODAL_IGNORED_IPS = 'ignored_ips';
export const MODAL_INVITE_USER = 'invite_user';
export const MODAL_MODIFY_PLAN = 'modify_plan_and_rates';
export const MODAL_BULK_LOCK_PLAN = 'bulk_lock_plan';
export const MODAL_MODIFY_PLAN_MAGPIE = 'modify_plan_and_rates_magpie';
export const MODAL_MODIFY_SUBSCRIPTION = 'modify_subscription';
export const MODAL_UPDATE_CUSTOMER_DETAILS = 'update_customer_details';
export const MODAL_UPDATE_SALES_DISCOUNT = 'update_sales_discount';
export const MODAL_UPDATE_CUSTOMER_COUNTRY = 'update_customer_country';
export const MODAL_UPDATE_CUSTOMER_EMAIL_RECIPIENTS =
  'update_customer_email_recipients';
export const MODAL_ADD_INTERNAL_USER = 'add_internal_user';
export const MODAL_CREATE_SUBSCRIPTION = 'create_subscription_dialog';
export const MODAL_MIGRATE_MANUAL = 'migrate_manual';
export const MODAL_PREVIEW_COSTS = 'preview_costs';
export const MODAL_SAMPLING_VISUALIZER = 'sampling_visualizer';
export const MODAL_TRANSFER = 'transfer_modal';
export const MODAL_USER_DETAIL = 'user_detail';
export const MODAL_UPGRADE_USER = 'upgrade_user';
export const MODAL_VIEW_INVOICES = 'view_invoices';
export const MODAL_ACTIVATE_TRIAL = 'activate_trial';
export const MODAL_MANAGE_TRIAL = 'manage_trial';
export const MODAL_EXTEND_TRIAL = 'extend_trial';
export const MODAL_CANCEL_TRIAL = 'cancel_trial';
export const MODAL_MODIFY_ACCESS_LEVEL = 'modify_access_level';
export const MODAL_ADD_USER = 'add_user';
export const MODAL_VIEW_USER_AUDIT_LOG = 'user_audit_log';
export const MODAL_ADD_IDP = 'add_idp';
export const MODAL_ADD_ACTION = 'add_action';
export const MODAL_PROVISION_CS_ACCOUNT = 'provision_cs_account';
export const MODAL_ENABLE_SSO_FOR_ORG = 'enable_sso_for_org';
export const MODAL_ADD_SERVICE_PERIOD = 'service_period_creation';

export const ALL_MODALS = [
  MODAL_ADM_ACTIONS,
  MODAL_CONFIRMATION_DIALOG,
  MODAL_BASIC_ALERT_DIALOG,
  MODAL_DOMAIN_SEARCH,
  MODAL_EMAIL_CHANGE,
  MODAL_FEATURE_MANAGER,
  MODAL_FORM_DIALOG,
  MODAL_IGNORED_IPS,
  MODAL_INVITE_USER,
  MODAL_MODIFY_PLAN,
  MODAL_BULK_LOCK_PLAN,
  MODAL_MODIFY_PLAN_MAGPIE,
  MODAL_MODIFY_SUBSCRIPTION,
  MODAL_UPDATE_CUSTOMER_DETAILS,
  MODAL_UPDATE_SALES_DISCOUNT,

  MODAL_UPDATE_CUSTOMER_EMAIL_RECIPIENTS,
  MODAL_ADD_INTERNAL_USER,
  MODAL_CREATE_SUBSCRIPTION,
  MODAL_MIGRATE_MANUAL,
  MODAL_PREVIEW_COSTS,
  MODAL_SAMPLING_VISUALIZER,
  MODAL_TRANSFER,
  MODAL_USER_DETAIL,
  MODAL_UPGRADE_USER,
  MODAL_VIEW_INVOICES,
  MODAL_VIEW_USER_AUDIT_LOG,
  MODAL_MANAGE_TRIAL,
  MODAL_ACTIVATE_TRIAL,
  MODAL_EXTEND_TRIAL,
  MODAL_CANCEL_TRIAL,
  MODAL_MODIFY_ACCESS_LEVEL,
  MODAL_ADD_USER,
  MODAL_ADD_IDP,
  MODAL_ADD_ACTION,
  MODAL_PROVISION_CS_ACCOUNT,
  MODAL_ENABLE_SSO_FOR_ORG,
  MODAL_ADD_SERVICE_PERIOD,
] as const;

export type Modal = typeof ALL_MODALS[number];

// Billing v3 constants
export const ASK = 'Ask' as ProductName;
export const OBSERVE = 'Observe' as ProductName;
export const DXA = 'dxa' as ProductName;
export const VOC = 'voc' as ProductName;
