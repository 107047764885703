import { toDateString } from './dates';
import { downloadFile } from './index';

export function arrayOfObjectsToCSV(arr) {
  let objectToCSVRow = function (obj) {
    let row = [];
    for (let attr of Object.keys(obj)) {
      let result = obj[attr] ? obj[attr].toString() : '';
      result = result.replace(/"/g, '""');
      row.push(result);
    }
    return row.join(',') + '\n';
  };

  // Add Headers
  let csvContent = Object.keys(arr[0]).join(',') + '\n';

  arr.forEach(function (obj) {
    csvContent += objectToCSVRow(obj);
  });

  return csvContent;
}

export function downloadCSV(data, filename) {
  let encodedUri = encodeURI(data);

  let simulatedClick = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    view: window,
  });

  let a = document.createElement('a');
  a.style = 'display: none';
  a.href = encodedUri;
  a.download = filename;
  document.body.appendChild(a);

  a.dispatchEvent(simulatedClick);

  window.URL.revokeObjectURL(encodedUri);
}

export function downloadInvoiceCsv(filenamePrefix, content) {
  const currentDate = toDateString(new Date());
  const filename = `${filenamePrefix}${currentDate}.csv`;
  downloadFile(new Blob([content]), filename);
}

export function downloadReferralsCsv(filenamePrefix, content) {
  downloadInvoiceCsv(filenamePrefix, content);
}

export function downloadFileWithTimeStamp(filenamePrefix, content) {
  const currentDate = toDateString(new Date());
  const filename = `${filenamePrefix}${currentDate}.csv`;
  downloadFile(new Blob([content]), filename);
}
