import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { postLogout } from '../containers/Login/api';
import { isAuthenticated } from '../utils/auth';

const Logout = () => {
  const history = useHistory();
  const requestAndRedirect = () => {
    return postLogout()
      .then(() => {
        history.push('/login');
      })
      .catch((e) => console.error(e));
  };

  if (isAuthenticated()) {
    return <Button onClick={requestAndRedirect}>Logout</Button>;
  }
  return null;
};

export default Logout;
