const EMPTY_VALUE_PLACEHOLDER = '-';

export function getLocationDisplay(
  countryName: undefined | string,
  cityName: undefined | string
): string {
  if (countryName === '' || countryName === undefined) {
    return EMPTY_VALUE_PLACEHOLDER;
  }

  if (cityName === '' || cityName === undefined) {
    return countryName;
  }

  return `${countryName} (${cityName})`;
}
