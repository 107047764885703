import { Currency } from '../types/types';
import { NO_DATA_PLACEHOLDER } from './constants';

export const getCurrencySymbol = (currency: Currency) => {
  switch (currency.toLowerCase()) {
    case 'eur':
      return '€';
    default:
      return '$';
  }
};

export const totalCosts = (costs: { items: { total_price: number }[] }) => {
  if (costs) {
    return Number(
      costs.items.reduce((total, cost) => {
        return total + cost.total_price;
      }, 0)
    ).toFixed(2);
  }
};

export const formatMoney = (currency?: Currency, amount?: number) => {
  if (!amount || !currency) {
    return NO_DATA_PLACEHOLDER;
  }

  return `${getCurrencySymbol(currency)}${amount && amount.toFixed(2)}`;
};
