import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { AddDefaultFeatureForm } from './AddDefaultFeatureForm';
import type { WithAddDefaultFeatureProps } from './useAddDefaultFeature';

export const AddDefaultFeatureDialog = ({
  data,
  dispatch,
}: WithAddDefaultFeatureProps): JSX.Element => {
  const handleSubmit = () => {
    dispatch({ type: 'submit' });
  };

  const handleClose = () => {
    dispatch({ type: 'cancel' });
  };

  return (
    <Dialog fullWidth maxWidth="md" open={data.open} onClose={handleClose}>
      <DialogTitle>Add default feature</DialogTitle>
      <DialogContent>
        <AddDefaultFeatureForm data={data} dispatch={dispatch} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={data.submitting}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
