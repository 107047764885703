import { apiGet } from '../../utils/api';

/**
 *
 * @param {Date} dateFrom The start date for the query.
 * @param {Date} dateTo The end date for the query.
 * @param {String} contentType The accept type. Can be one of 'csv' or 'json'.
 * @param {String} [billingCycle='monthly'] The billing cycle to run for. Can be one of 'monthly', 'yearly',
 *                 'yearly_recurring'.
 * @param {Boolean} avoidedVat Filter for accounts that did not pay VAT, despite being within the EU, due to
 *                 giving us a VAT number
 * @param {String} format 'rdr' for Recapitulative Declaration report, else undefined
 * @returns {Promise<string>} The result of the API call in CSV format.
 */
export function invoicesApi(
  dateFrom,
  dateTo,
  contentType,
  report_type,
  payment_method_type,
  status,
  location,
  has_vat_number
) {
  const query = {
    from: dateFrom,
    to: dateTo,
    report_type: report_type,
    payment_method_type: payment_method_type,
    status: status,
    location: location,
    has_vat_number: has_vat_number,
  };

  return apiGet('/invoices/report', query, 2, { Accept: contentType })
    .then(handleApiErrors)
    .catch(function (error) {
      const message = error.error_message || error.message;
      throw message;
    });
}

/**
 * Handle any API errors and return the response body.
 * @param {string | Object} response The response body to parse out.
 * @param {string} [response.error_message] If present, this will be thrown.
 * @returns {string} The response CSV text.
 * @throws The raw error message.
 */
function handleApiErrors(response) {
  if (response.error_message) {
    throw response.error_message;
  }
  return response;
}
