import type { SelectProps } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import type { FocusEventHandler } from 'react';
import React from 'react';
import type { Entity } from './api';
import { isEntity } from './formUtils';
import styles from './ManageFeatures.module.css';

type SelectChangeEventHandler = SelectProps<Entity>['onChange'];

type EntitySelectProps = {
  value: Entity;
  onValueChange?: (value: Entity) => void;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
};

export const EntitySelect = ({
  value,
  onValueChange,
  onBlur,
}: EntitySelectProps): JSX.Element => {
  const handleChange: SelectChangeEventHandler = (event) => {
    if (!isEntity(event.target.value)) {
      return;
    }

    onValueChange?.(event.target.value);
  };

  return (
    <FormControl fullWidth className={styles.entitySelect}>
      <InputLabel id="label_entity-select" data-testid="entity-select-label">
        Entity
      </InputLabel>
      <Select
        labelId="label_entity-select"
        id="input_entity-select"
        label="Entity"
        name="entity"
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
      >
        <MenuItem value="account">Account</MenuItem>
        <MenuItem value="organization">Organization</MenuItem>
        <MenuItem value="site">Site</MenuItem>
      </Select>
    </FormControl>
  );
};
