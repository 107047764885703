import React from 'react';
import { toDateString } from '../../utils/dates';
import Button from '@mui/material/Button';
import ColoredLabel from '../../components/ColoredLabel';
import containerStyles from './Lookup.module.css';
import componentStyles from './DisplayUser.module.css';
import ClickToCopy from '../../components/ClickToCopy';
import { trackSegmentEvent } from '../../utils/segment';
import { useUserContext } from '../../contexts/UserContext';

const DisplayUser = (props) => {
  const userContext = useUserContext();

  const handleClick = () => {
    props.openModal();

    trackSegmentEvent('ADM Account User Details and Actions Clicked', {
      userId: props.id,
      accessLevel: userContext.roleNames.join(', '),
    });
  };

  return (
    <div className={componentStyles.userDetails}>
      <h2>
        User ID:{' '}
        <span data-hj-suppress>
          <ClickToCopy copyIcon={true}>{props.id}</ClickToCopy>
        </span>
      </h2>
      {props.email_blocklisted && (
        <ColoredLabel color="var(--red)">Email Blocklisted</ColoredLabel>
      )}
      {props.account_id && (
        <ColoredLabel color="var(--blue)">Account Owner</ColoredLabel>
      )}
      {props.is_sso && <ColoredLabel color="var(--green)">SSO</ColoredLabel>}
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        className={componentStyles.actionsButton}
      >
        User Details & Actions
      </Button>
      <dl>
        <dt className={containerStyles.label}>Email:</dt>
        <dd data-hj-suppress>{props.email}</dd> <br />
        <dt className={containerStyles.label}>Name:</dt>
        <dd data-hj-suppress>{props.name}</dd> <br />
        <dt className={containerStyles.label}>Last logged in:</dt>
        <dd>{toDateString(props.last_successful_login)}</dd>
        <br />
        {props.failed_login_attempts > 0 && (
          <div>
            <dt className={containerStyles.label}>Failed login attempts:</dt>
            <dd>{props.failed_login_attempts}</dd>
          </div>
        )}
        {props.login_blocked_until && (
          <div>
            <dt className={containerStyles.label}>Login blocked until:</dt>
            <dd>{toDateString(props.login_blocked_until)}</dd>
          </div>
        )}
      </dl>
    </div>
  );
};

export default DisplayUser;
