import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getOrgEnterpriseIDP } from './getOrgEnterpriseIDP';

export const useOrgEnterpriseIDP = (orgId: number) => {
  const { data, refetch } = useQuery<string | null, Error>({
    queryKey: ['orgEnterpriseIDP', orgId],
    queryFn: () => getOrgEnterpriseIDP(orgId).catch(() => null),
    retry: false,
  });

  const queryClient = useQueryClient();

  const invalidate = () =>
    queryClient.invalidateQueries(['orgEnterpriseIDP', orgId]);

  return {
    data,
    refetch,
    invalidate,
  };
};
