import { Grid } from '@mui/material';
import { Magpie } from '../../../typings';
import moment from 'moment';
import styled from '@emotion/styled';

interface Props {
  subscription: Magpie['subscription'];
}

const S = {
  BoldText: styled.span`
    font-weight: bold;
  `,
};

const getDaysDifferenceForBillingEndDate = (currentPeriodEndDate: Date) => {
  const daysDifference = moment(currentPeriodEndDate).diff(
    moment().format('YYYY-MM-DD'),
    'days'
  );
  const isExpiredSubscription = daysDifference < 0;
  return `${Math.abs(daysDifference)} ${
    isExpiredSubscription ? ' days ago' : ' days left'
  }`;
};

export function SubscriptionInfo({ subscription }: Props) {
  const { status, billingPeriod, currentPeriodEndDate } = subscription;
  return (
    <>
      <h2>Subscription Info</h2>
      <Grid container sx={{ marginBottom: '24px' }}>
        <Grid item xs={4}>
          <S.BoldText>Status:</S.BoldText> {status}
        </Grid>
        <Grid item xs={4}>
          <S.BoldText>Billing period:</S.BoldText> {billingPeriod}
        </Grid>
        <Grid item xs={4}>
          <S.BoldText>Next bill:</S.BoldText>
          {` ${currentPeriodEndDate.toString()} (${getDaysDifferenceForBillingEndDate(
            currentPeriodEndDate
          )})`}
        </Grid>
      </Grid>
    </>
  );
}
