import snakecaseKeys from 'snakecase-keys';

const isTestEnv =
  process.env.JEST_WORKER_ID !== undefined || process.env.NODE_ENV === 'test';

export const trackSegmentEvent = (eventName, data) => {
  if (
    isTestEnv ||
    !window.analytics ||
    !window.analytics.initialized ||
    !eventName ||
    !data
  ) {
    if (!isTestEnv) console.warn('Event ignored, required fields are missing');
    return;
  }

  window.analytics.track(eventName, {
    ...snakecaseKeys(data),
    time: Date.now(),
  });
};
