import { Box, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { toSentenceCase } from 'js-convert-case';
import { Moment } from 'moment';
import { useFormContext } from 'react-hook-form';

type DateSelectProps = {
  field: string;
  onChange: (date: Moment | null) => void;
};

export function ServiceDateSelect({ field, onChange }: DateSelectProps) {
  const { watch } = useFormContext();

  return (
    <Box sx={{ pb: 2 }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          inputFormat="YYYY-MM-DD"
          label={toSentenceCase(field)}
          value={watch(field)}
          renderInput={(params) => (
            <TextField variant="standard" {...params} id={field} />
          )}
          onChange={onChange}
          disablePast
        />
      </LocalizationProvider>
    </Box>
  );
}
