import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { bindDialog, usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { ActionButton } from './ActionButton';
import { postFeaturesAssignAdd } from './api';
import styles from './ManageFeatures.module.css';
import { ToggleFeatureForm } from './ToggleFeatureForm';
import { ManageFeatureDialog } from './ManageFeatureDialog';
import type { FeatureAction } from './useFeatureActionForm';
import type { ToggleFeatureFields } from './useToggleFeatureForm';
import { useToggleFeatureForm } from './useToggleFeatureForm';

const action: FeatureAction<ToggleFeatureFields> = ({
  feature,
  entity,
  identifiers,
}) =>
  postFeaturesAssignAdd({
    entity,
    entityIds: identifiers,
    feature,
  });

type EnableFeatureProps = {
  feature: string;
};

export const EnableFeature = ({ feature }: EnableFeatureProps): JSX.Element => {
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'enable-feature',
  });
  const [form, handleSubmit] = useToggleFeatureForm({
    action,
    feature,
    close: popupState.close,
  });

  return (
    <>
      <ActionButton
        action={popupState.open}
        Icon={CheckCircleOutlinedIcon}
        title="Enable (per entity)"
        busyMessage={`Enabling feature ${feature}`}
      />

      <ManageFeatureDialog
        title={
          <>
            Enable feature:{' '}
            <span className={styles.featureName}>{feature}</span>
          </>
        }
        onSubmit={handleSubmit}
        {...bindDialog(popupState)}
      >
        <ToggleFeatureForm mode="enable" feature={feature} form={form} />
      </ManageFeatureDialog>
    </>
  );
};
