import React, { Component } from 'react';
import { getAccountData, getUserData } from '../containers/Lookup/api';
import { getCustomerFromMagpie } from '../utils/api';
import { localizeDates } from '../utils/dates';
import { normalizeAccountAPIResponse } from '../utils/normalizer';

export const LookupContext = React.createContext();

export class LookupProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      user: null,
      account: null,
      costs: null,
      organizations: null,
      organization_users: null,
      sites: null,
      searchBox: {
        search_by: 'account_id',
        search_term: '',
      },
      error_message: null,
      lookupRequest: this.lookupRequest,
      setSearch: this.setSearch,
      setError: this.setError,
      updateUserEmail: this.updateUserEmail,
    };
  }

  setMagpieCustomer = async ({ id, features }) => {
    if (features.includes('billing_zuora') || features.includes('billing_v3')) {
      try {
        const magpieRes = await getCustomerFromMagpie(id);
        const magpieInfo = await magpieRes.json();
        if (magpieInfo.error || magpieInfo.error_message)
          throw new Error('Customer not found');
        this.setState({ magpie: magpieInfo });
      } catch (error) {
        // Don't set this error to state as we expect it from Billing V2 accounts.
        console.error(error);
      }
    }
  };

  lookupRequest = async (search_by, search_term, page) => {
    const initialState = {
      isLoading: false,
      user: null,
      error_message: null,
      account: null,
      costs: null,
      organizations: null,
      organization_users: null,
      sites: null,
      magpie: null,
      lookupRequest: this.lookupRequest,
    };

    this.setState({ ...initialState, isLoading: true });

    if (!search_by || !search_term) {
      ({ search_by, search_term } = this.state.searchBox);
    }
    if (search_by === 'user_id') {
      try {
        const userData = await getUserData('user_id', search_term);
        const user = localizeDates(userData, [
          'last_successful_login',
          'login_blocked_until',
        ]);
        this.setState({ user: user, error_message: null, isLoading: false });

        if (user.account_id) {
          const accountData = await getAccountData(
            'account_id',
            user.account_id,
            page
          );
          const normalizedAccountData =
            normalizeAccountAPIResponse(accountData);
          this.setState({ ...normalizedAccountData, error_message: null });

          await this.setMagpieCustomer(accountData);
          this.setState({ isLoading: false });
        }
      } catch (e) {
        this.setState({
          ...initialState,
          error_message: e.error_message || e.message || e.error,
        });
      }
    } else {
      try {
        const accountData = await getAccountData(search_by, search_term, page);
        const normalizedAccountData = normalizeAccountAPIResponse(accountData);
        this.setState({ ...normalizedAccountData, error_message: null });

        await this.setMagpieCustomer(accountData);
        this.setState({ isLoading: false });

        if (accountData.owner_id) {
          try {
            let user = await getUserData('user_id', accountData.owner_id);
            user = localizeDates(user, [
              'last_successful_login',
              'login_blocked_until',
            ]);
            this.setState({ user: user, isLoading: false });
          } catch (error) {
            console.error(error);
          }
        }
      } catch (e) {
        this.setState({
          ...initialState,
          error_message: e.error_message || e.message || e.error,
        });
      }
    }
  };

  setSearch = (data) => {
    this.setState({
      searchBox: {
        ...this.state.searchBox,
        ...data,
      },
    });
  };

  setError = (error) => {
    this.setState({ error_message: error });
  };

  updateUserEmail = (email) => {
    this.setState({ user: { ...this.state.user, email: email } });
  };

  render() {
    return (
      <LookupContext.Provider value={this.state}>
        {this.props.children}
      </LookupContext.Provider>
    );
  }
}

export const LookupConsumer = ({ children }) => {
  return (
    <LookupContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw Error('LookupConsumer must be used within a LookupProvider.');
        }
        return children(context);
      }}
    </LookupContext.Consumer>
  );
};

export const withLookupContext = (Component) => {
  return (props) => (
    <LookupContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw Error('LookupConsumer must be used within a LookupProvider.');
        }
        return <Component {...props} lookupContext={context} />;
      }}
    </LookupContext.Consumer>
  );
};
