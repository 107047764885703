import { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import DynamicForm from '../../DynamicForm';

const FormDialog = ({ onCloseModal, props: modalProps }) => {
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const modalActions = (
    <Button
      key="cancel"
      variant="contained"
      color="primary"
      onClick={onCloseModal}
    >
      Close
    </Button>
  );

  const onSubmit = (fields) => {
    setErrorMessage('');
    modalProps
      .onSubmit(fields)
      .then(() => {
        setSuccessMessage(modalProps.successMessage || 'Success!');
        if (modalProps.onSuccess) modalProps.onSuccess();
        onCloseModal();
      })
      .catch((e) => setErrorMessage(e.error_message || e.message || e.error));
  };

  if (!modalProps) {
    return null;
  }

  return (
    <Dialog
      open
      disableEnforceFocus={true}
      aria-labelledby="form-dialog-title"
      maxWidth={modalProps.width || 'sm'}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">{modalProps.title}</DialogTitle>
      <DialogContent>
        <DynamicForm
          fields={modalProps.fields}
          onSubmit={onSubmit}
          modalActions={modalActions}
          successMessage={successMessage}
          errorMessage={errorMessage}
        />
      </DialogContent>
    </Dialog>
  );
};

export default FormDialog;
