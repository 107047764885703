export const DOMAIN_USERS_SEARCH_LABEL = 'Filter results by name, user ID, …';
export const DOMAIN_SITE_SEARCH_LABEL =
  'Filter results by site name, site ID, …';

export const DOMAIN_USERS_PERMISSIONS_MAP = {
  write: 'Write',
  read: 'Read',
  admin: 'Admin',
  account_owner: 'Account owner',
};

export const API_SEARCH_BY = {
  user_accounts_by_email_contains: 'user_accounts_by_email_contains',
  sites_by_url_contains: 'sites_by_url_contains',
};

export const ACTIVE_SUBSCRIPTION_STATUS = 'ACTIVE';
