import React, { Component } from 'react';
import { Button, Dialog, DialogActions } from '@mui/material';
import { transferApiCall } from './api';
import Form from './form';
import styles from './Transfers.module.css';
import { withLoadingDialog } from '../../withLoading';
import { withModalContext } from '../../../contexts/ModalContext';
import { MODAL_TRANSFER } from '../constants';

const LoadingAwareDialog = withLoadingDialog(Dialog);

class TransfersModal extends Component {
  state = {
    changed: false,
    isLoading: false,
    errors: {
      id_to: null,
      server: null,
    },
    id_from: null,
    id_to: '',
    entityType: '',
    annotation: '',
  };

  render() {
    const context = this.props.modalContext;
    const modalProps = context.openModalProps[MODAL_TRANSFER];
    const isOpen = context.isModalOpen(MODAL_TRANSFER);
    if (isOpen && modalProps) {
      return (
        <LoadingAwareDialog
          open={isOpen}
          title={
            'Transfer ' +
            (modalProps.entityType === 'organization'
              ? 'Organization'
              : 'Account')
          }
          isLoading={this.state.isLoading}
        >
          <div className={styles.warning}>
            {modalProps.entityType === 'account'
              ? 'Ownership can only be given to users who already have access to at least 1 organization' +
                ' within the account. If the new owner is already the admin of another account, ' +
                'the accounts will be merged.'
              : 'Transfers can only be done for organizations on the Basic plan.'}
          </div>
          {this.state.errors.server && (
            <span className="error">{this.state.errors.server}</span>
          )}
          <Form
            ref="form"
            onFormSubmit={this.handleSubmit}
            onFormUpdate={this.handleFormUpdate}
            setErrors={this.setErrors}
            id_to={this.state.id_to}
            annotation={this.state.annotation}
            errors={this.state.errors}
            entityType={modalProps.entityType}
          />
          <DialogActions>
            <Button color="primary" key="cancel" onClick={this.closeModal}>
              Cancel
            </Button>
            <Button
              color="primary"
              key="save"
              onClick={this.triggerSubmit}
              disabled={!this.state.changed || this.state.annotation === ''}
            >
              Save Changes
            </Button>
          </DialogActions>
        </LoadingAwareDialog>
      );
    } else {
      return null;
    }
  }

  handleFormUpdate = (data) => {
    this.setState({ ...data, changed: true });
  };

  setErrors = (errors) => {
    this.setState({ errors: { ...errors }, isLoading: false });
  };

  closeModal = () => {
    this.setState({
      changed: false,
      isLoading: false,
      errors: {
        id_to: null,
        server: null,
      },
      id_from: null,
      id_to: '',
      entityType: '',
      annotation: '',
    });
    this.props.modalContext.closeModal(MODAL_TRANSFER);
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    transferApiCall(
      this.props.modalContext.openModalProps[MODAL_TRANSFER].entityType,
      this.props.modalContext.openModalProps[MODAL_TRANSFER].id_from,
      this.state.id_to,
      this.state.annotation
    )
      .then(() => {
        this.props.modalContext.openModalProps[MODAL_TRANSFER].onSuccess();
        this.props.modalContext.closeModal(MODAL_TRANSFER);
      })
      .catch((e) =>
        this.setErrors({ server: e.error_message || e.message || e.error })
      );
  };

  triggerSubmit = () => {
    this.refs.form.handleSubmit(new Event('submit'));
  };
}

export default withModalContext(TransfersModal);
