import React, { useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import styles from './AdmActionTable.module.css';

const AdmAddActionDialog = (modalProps) => {
  const { onCloseModal, props } = modalProps;
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const setErrorWithMessage = (response) => {
    let message = '';
    if (response.error_message) {
      message += response.error_message;
    } else if (response.message) {
      message += response.message;
    }
    setError(true);
    setErrorMessage(message);
  };

  const handleSave = () => {
    setIsLoading(true);
    return props
      .handleNewAction(description)
      .then(() => {
        setIsLoading(false);
        onCloseModal();
      })
      .catch((response) => {
        setErrorWithMessage(response);
        setIsLoading(false);
      });
  };

  const updateDescription = (event) => {
    if (error) {
      setError(false);
      setErrorMessage('');
    }
    setDescription(event.target.value);
  };

  return (
    <Dialog
      open
      disableRestoreFocus={true}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Add New Action</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Add a description for your action.
        </DialogContentText>
        <TextField
          autoFocus
          required
          multiline
          fullWidth
          type="text"
          id="description"
          label="Description"
          error={error}
          disabled={isLoading}
          onChange={updateDescription}
          variant="standard"
        />
        <div className={styles.error}>{errorMessage}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          disabled={isLoading || !description}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdmAddActionDialog;
