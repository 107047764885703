import React, { useEffect } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import ShowSpinner from '../../components/ShowSpinner';
import { EntitySelect } from './EntitySelect';
import { getEntityName } from './formUtils';
import { IdentifiersInput } from './IdentifiersInput';
import styles from './ManageFeatures.module.css';
import type { ToggleFeatureFields } from './useToggleFeatureForm';

type ToggleFeatureFormProps = {
  mode: 'enable' | 'disable';
  feature: string;
  form: UseFormReturn<ToggleFeatureFields>;
};

export const ToggleFeatureForm = ({
  mode,
  feature,
  form: {
    control,
    formState: { isSubmitting, isSubmitSuccessful },
    watch,
    reset,
  },
}: ToggleFeatureFormProps): JSX.Element => {
  const entity = watch('entity');

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }

    return reset();
  }, [isSubmitSuccessful, reset]);

  return (
    <form className={styles.form}>
      <Controller
        name="entity"
        control={control}
        render={({ field: { value, onChange } }) => (
          <EntitySelect value={value} onValueChange={onChange} />
        )}
      />

      <Controller
        name="identifiers"
        control={control}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { error: { message: errorMessage } = {} },
        }) => {
          const info =
            value.length > 0 ? (
              <>
                Feature <span className={styles.featureName}>{feature}</span>{' '}
                will be {mode}d for {getEntityName(entity)}
                {value.length > 1 && 's'} with ID{value.length > 1 && 's'}:{' '}
                {value.join(', ')}.
              </>
            ) : undefined;

          return (
            <IdentifiersInput
              entity={entity}
              value={value}
              onValueChange={onChange}
              onBlur={onBlur}
              error={errorMessage}
              info={info}
            />
          );
        }}
      />

      {isSubmitting && (
        <div className={styles.overlay}>
          <ShowSpinner />
        </div>
      )}
    </form>
  );
};
