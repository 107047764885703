import React, { useState } from 'react';
import type { SvgIconComponent } from '@mui/icons-material';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';

type ButtonIconProps = {
  busy: boolean;
  busyMessage: string;
  Icon: SvgIconComponent;
};

const ButtonIcon = ({
  busy,
  busyMessage,
  Icon,
}: ButtonIconProps): JSX.Element => {
  if (busy) {
    return <CircularProgress title={busyMessage} size="1rem" />;
  }

  return <Icon />;
};

type ActionButtonProps = {
  action: () => void | Promise<void>;
  Icon: SvgIconComponent;
  title: string;
  busyMessage: string;
};

export const ActionButton = ({
  action,
  Icon,
  title,
  busyMessage,
}: ActionButtonProps): JSX.Element => {
  const [busy, setBusy] = useState<boolean>(false);

  const handleClick = async () => {
    setBusy(true);

    await action();

    setBusy(false);
  };

  return (
    <Tooltip title={title}>
      <IconButton
        aria-label={title}
        aria-busy={busy}
        disabled={busy}
        onClick={handleClick}
      >
        <ButtonIcon busy={busy} busyMessage={busyMessage} Icon={Icon} />
      </IconButton>
    </Tooltip>
  );
};
