import { apiGet, apiPost } from '../../utils/api';

export const getAllUsers = () => apiGet('/adm_users');

export const getAllRoles = () => apiGet('/roles');

export const toggleRole = (userId, roleName, newState) => {
  const action = newState ? 'add_role' : 'remove_role';
  return apiPost('/adm_users/' + userId, action, { role_name: roleName }).then(
    () => newState
  );
};

export const deactivateUser = (userId, adm_action_annotation) => {
  return apiPost('/internal_users', 'deactivate_internal_user', {
    adm_user_id: userId,
    adm_action_annotation,
  });
};

export const reactivateUser = (user_email, adm_action_annotation) => {
  return apiPost('/internal_users', 'reactivate_internal_user', {
    user_email,
    adm_action_annotation,
  });
};
