import { FC, useEffect } from 'react';
import { Button } from '@mui/material';
import { useModalContext } from '../../../../contexts/ModalContext';
import { MODAL_CONFIRMATION_DIALOG } from '../../../../components/modals/constants';
import { useToasts } from 'react-toast-notifications';
import { buildUrl } from '../../../../utils/api';
import { useShowSite } from '../../api/useShowSite';

interface SiteUndeleteProps {
  siteId: string;
}

const SiteUndelete: FC<SiteUndeleteProps> = ({ siteId }) => {
  const { openModal } = useModalContext();
  const { addToast, removeAllToasts } = useToasts();
  const { refetchSite } = useShowSite(siteId);

  useEffect(() => {
    return () => {
      removeAllToasts();
    };
  }, []);

  const undeleteSite = async () => {
    try {
      await fetch(buildUrl(`/api/v2/adm/site/${siteId}`), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'undelete',
        }),
      });
      addToast('Site successfully undeleted', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch {
      throw new Error('Error undeleting site. Retry again later.');
    }
  };

  const modalWarning = `Are you sure you want to undelete the site with ID ${siteId}`;

  const handleOpenModal = () => {
    openModal(MODAL_CONFIRMATION_DIALOG, {
      title: 'Undelete site',
      noAnnotationRequired: true,
      modalWarning,
      onSubmit: undeleteSite,
      onSuccess: refetchSite,
    });
  };

  return (
    <Button variant="contained" color="primary" onClick={handleOpenModal}>
      Undelete
    </Button>
  );
};

export default SiteUndelete;
