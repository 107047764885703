import {
  countryChoices,
  doesCountryRequireState,
} from '../../../utils/countries';
import * as STATES from '../../../utils/states';
import { Grid, InputLabel, Select, TextField } from '@mui/material';

export const BillingAddress = ({
  formData: {
    addressLine1,
    addressLine2,
    country,
    city,
    zipCode,
    state,
    county,
    companyName,
    taxNumber,
  },
  handleUpdateField,
}) => (
  <>
    <h3>Billing address</h3>
    <TextField
      label="Address line 1"
      variant="standard"
      id="addressLine1"
      rows={4}
      value={addressLine1}
      onChange={handleUpdateField}
      fullWidth
    />
    <TextField
      label="Address line 2"
      variant="standard"
      id="addressLine2"
      rows={4}
      value={addressLine2}
      onChange={handleUpdateField}
      fullWidth
    />
    <Grid container spacing={10}>
      <Grid item xs={4}>
        <InputLabel variant="standard" htmlFor="country" shrink>
          Country *
        </InputLabel>
        <Select
          id="country"
          label="Country"
          inputProps={{ 'aria-label': 'Country' }}
          value={country}
          onChange={handleUpdateField}
          variant="standard"
          native
          fullWidth
        >
          <option value="">-</option>
          {countryChoices.map(({ label, value }) => {
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="City"
          variant="standard"
          id="city"
          value={city}
          onChange={handleUpdateField}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Postal Code/ZIP"
          variant="standard"
          id="zipCode"
          value={zipCode}
          onChange={handleUpdateField}
          fullWidth
        />
      </Grid>
    </Grid>
    {doesCountryRequireState(country) && (
      <Grid container spacing={10}>
        <Grid item xs={4}>
          <InputLabel variant="standard" htmlFor="state" shrink>
            State *
          </InputLabel>
          <Select
            id="state"
            label="State"
            value={state}
            inputProps={{ 'aria-label': 'State' }}
            onChange={handleUpdateField}
            variant="standard"
            native
            fullWidth
          >
            <option value="">-</option>
            {STATES[country].map((state) => (
              <option value={state.value} key={state.value}>
                {state.label}
              </option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="County"
            inputProps={{ 'aria-label': 'County' }}
            variant="standard"
            id="county"
            value={county}
            onChange={handleUpdateField}
            fullWidth
          />
        </Grid>
      </Grid>
    )}
    <h3>Organization information</h3>
    <TextField
      label="Organization name"
      variant="standard"
      id="companyName"
      value={companyName}
      onChange={handleUpdateField}
      fullWidth
      required
    />
    <TextField
      label="Tax number"
      variant="standard"
      id="taxNumber"
      value={taxNumber}
      onChange={handleUpdateField}
      fullWidth
    />
  </>
);
