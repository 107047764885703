import type { Modal } from '../../../../components/modals/constants';
import { useModalContext } from '../../../../contexts/ModalContext';

interface OpenSiteModalArgs {
  siteId: string;
  modal: Modal;
  payload: {};
}

export const useOpenSiteModal = () => {
  const { openModal } = useModalContext();

  const openSiteModal = ({ siteId, modal, payload }: OpenSiteModalArgs) => {
    openModal(modal, payload);

    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search);

      if (!searchParams.has('open') || searchParams.get('open') !== modal) {
        searchParams.set('open', modal);
        searchParams.set('site_id', siteId);
        const newRelativePathQuery = `${
          window.location.pathname
        }?${searchParams.toString()}`;
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, '', newRelativePathQuery);
      }
    }
  };

  return { openSiteModal };
};
