import type { ProductCatalogItem } from '../../../../typings';
import { convertReadableNumber } from '../../../../domains/products/utils/convert-to-readable-number';

const ALLOWANCE_KEYS = [
  'analyticsSessionQuota',
  'monthlyResponses',
  'yearlyResponses',
  'yearlyInterviewsAndTestsWithParticipantPool',
  'monthlyInterviewsAndTestsWithResearcherNetwork',
] as const;

const ALLOWANCE_LABELS = new Map([
  ['analyticsSessionQuota', 'sessions/m'],
  ['monthlyResponses', 'responses/m'],
  ['yearlyResponses', 'responses/y'],
  ['yearlyInterviewsAndTestsWithParticipantPool', 'interviews/y'],
  ['monthlyInterviewsAndTestsWithResearcherNetwork', 'interviews/m'],
]);

function getAllowance(option: ProductCatalogItem) {
  const { allowance } = option;
  const { quotas } = allowance;
  if (quotas === undefined) {
    return '';
  }

  const allowanceValues = ALLOWANCE_KEYS.map((key) => {
    if (key in quotas && quotas[key] !== 0) {
      const number = convertReadableNumber(quotas[key]);
      const label = ALLOWANCE_LABELS.get(key);
      return `${number} ${label}`;
    }

    return undefined;
  }).filter((value) => value !== undefined);

  return allowanceValues.join(', ');
}

export function getOptionLabel(option: ProductCatalogItem) {
  const allowance = getAllowance(option);
  return `${option.planName} ${option.sku} - ${allowance}`;
}
