import { Currency } from '../../../types/types';
import { buildUrl } from '../../../utils/api';
import { MagpieBillingPeriod } from '../models/magpie.model';

export const fetchMagpieCustomerURL = (accountId: number) =>
  buildUrl(`/api/v2/adm/magpie/customers/${accountId}`);

export const fetchMagpieProductCatalogURL = (
  billingPeriod: Lowercase<MagpieBillingPeriod>,
  currency: Lowercase<Currency>,
  productCatalogVersion: string | undefined
) =>
  buildUrl(
    `/api/v2/adm/magpie/${
      productCatalogVersion ?? 'v3'
    }/product_catalog/${billingPeriod}/${currency}`
  );

export const fetchMagpieAccountExecutedCommandsURL = (hotjarId: number) =>
  buildUrl(`/api/v2/adm/magpie/commands/${hotjarId}`);

export const fetchMagpieCustomerAccountBalanceURL = (hotjarId: number) =>
  buildUrl(`/api/v2/adm/magpie/customers/${hotjarId}/balance`);
