import React from 'react';
import styles from './UserNotifications.module.css';

const BounceRecipientsTable = ({ bounceRecipients }) => {
  return (
    <table className={styles.BounceRecipientsTable}>
      <thead>
        <tr>
          <th>Email</th>
          <th>Action</th>
          <th>Diagnostic Code</th>
        </tr>
      </thead>
      <tbody>
        {bounceRecipients.map((bounce, index) => {
          return (
            <tr key={'bounce_' + index}>
              <td>{bounce.emailAddress}</td>
              <td>{bounce.action}</td>
              <td>{bounce.diagnosticCode}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default BounceRecipientsTable;
