import { useContext, useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
  Stack,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ModalContext } from '../../../contexts/ModalContext';
import { MODAL_MODIFY_ACCESS_LEVEL } from '../constants';
import styles from './ModifyAccessLevel.module.css';
import { LookupContext } from '../../../contexts/LookupContext';
import { useAccessLevelMutation } from '../UserDetail/query';

type AccessLevel = 'write' | 'read' | 'admin';

export type ModifyAccessLevelModalProps = {
  organizationId: string;
  userId: string;
  accessLevel: AccessLevel;
};

export const ModifyAccessLevelModal = () => {
  const modalContext = useContext(ModalContext);
  const lookupContext = useContext(LookupContext);
  const [accessLevel, setAccessLevel] = useState<AccessLevel>('read');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { addToast } = useToasts();
  const { mutateAsync: modifyAccessLevel } = useAccessLevelMutation();

  const modalProps = modalContext?.openModalProps[MODAL_MODIFY_ACCESS_LEVEL] as
    | ModifyAccessLevelModalProps
    | undefined;

  useEffect(() => {
    setAccessLevel(modalProps?.accessLevel ?? 'read');
  }, [modalProps]);

  if (
    modalContext === null ||
    lookupContext === null ||
    modalProps === undefined
  )
    return null;

  const isModalOpen =
    modalContext.isModalOpen(MODAL_MODIFY_ACCESS_LEVEL) ?? false;

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);

      await modifyAccessLevel({
        organizationId: modalProps.organizationId,
        userId: modalProps.userId,
        accessLevel,
      });

      modalContext.closeModal(MODAL_MODIFY_ACCESS_LEVEL);
      addToast('Access level updated', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (e: any) {
      const errorMessage =
        e?.error_message ?? e?.message ?? e?.error ?? 'unknown error';
      addToast(`Error: ${errorMessage}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      open={isModalOpen}
      scroll="paper"
      onClose={() => modalContext.closeModal(MODAL_MODIFY_ACCESS_LEVEL)}
      maxWidth="md"
      disableEnforceFocus={true}
    >
      <DialogTitle>Modify Access Level</DialogTitle>
      <DialogContent>
        <form>
          <Stack>
            <Select
              native
              className={styles.select}
              value={accessLevel}
              onChange={(event) =>
                setAccessLevel(event.target.value as AccessLevel)
              }
              inputProps={{
                name: 'access-select',
                id: 'access-select',
              }}
              data-testid="access-select"
              variant="standard"
            >
              <option value="read">Read Only</option>
              <option value="write">Write</option>
              <option value="admin">Admin</option>
            </Select>
            <LoadingButton
              className={styles.confirmBtn}
              onClick={onSubmit}
              loading={isSubmitting}
            >
              Confirm
            </LoadingButton>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};
