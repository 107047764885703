export function downloadFile(blob, filename) {
  let simulatedClick = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
    url = URL.createObjectURL(blob);

  // Create hidden element and click it
  let a = document.createElement('a');
  a.style = 'display: none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.dispatchEvent(simulatedClick);

  // Revoke
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 100);
}

export function openFileInNewWindow(blob) {
  let simulatedClick = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
    url = URL.createObjectURL(blob);

  // Create hidden element and click it
  let a = document.createElement('a');
  a.style = 'display: none';
  a.href = url;
  a.target = '_blank';
  document.body.appendChild(a);
  a.dispatchEvent(simulatedClick);

  // Revoke
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 100);
}
