import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import t from 'tcomb-validation';
import { PositiveInteger } from '../../../utils/validators';
import styles from './Transfers.module.css';

class Form extends Component {
  validators = {
    id_to: { validator: PositiveInteger, cast: Number },
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.onFormSubmit();
    }
  };

  handleChange = (event) => {
    this.props.onFormUpdate({
      [event.target.name]: event.target.value,
    });
  };

  validate = () => {
    let errors = {},
      valid = true;

    for (let field in this.validators) {
      let val = this.validators[field].cast(this.props[field]),
        status = t.validate(val, this.validators[field].validator);
      if (status.isValid()) {
        this.props.onFormUpdate({ [field]: val });
        errors[field] = null;
      } else {
        valid = false;
        errors[field] = status.firstError().message;
      }
    }
    this.props.setErrors(errors);
    return valid;
  };

  render() {
    const labelText =
      (this.props.entityType === 'organization' ? 'Account' : 'User') +
      ' ID to Transfer To';
    // eslint-disable-next-line max-len
    return this.props.errors !== undefined ? ( // this.props somehow becomes undefined after 'cancel' button clicked on modal.
      <form onSubmit={this.handleSubmit}>
        <TextField
          label={labelText}
          id="modal_transfers_id"
          name="id_to"
          value={this.props.id_to}
          className={styles.textField}
          onChange={this.handleChange}
          error={this.props.errors.id_to ? true : false}
          helperText={this.props.errors.id_to}
          variant="standard"
        />
        <br />
        <TextField
          multiline
          required
          value={this.props.annotation}
          onChange={this.handleChange}
          label="Annotation"
          name="annotation"
          id="annotation"
          helperText="Please provide reason for change and/or Zendesk ticket link"
          variant="standard"
        />
      </form>
    ) : null;
  }
}

export default Form;
