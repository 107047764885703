import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const linkifyOpenInNew = (href, text, key) => (
  <a
    href={href}
    key={`linkify-${key}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {text} <OpenInNewIcon style={{ fontSize: '1em' }} />
  </a>
);
