import { useFeatureFlag } from '../../../utils/feature-flag';
import { CS_LITE } from './useIsCSLite';

export const VOC_PRO = 'voc.pro';

export function useIsAlp(): boolean | undefined {
  const isVocPro = useFeatureFlag(VOC_PRO);
  const isCSLite = useFeatureFlag(CS_LITE);
  return isVocPro || isCSLite;
}
