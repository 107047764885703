import type { ChangeEventHandler, FocusEventHandler, ReactNode } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import type { Entity } from './api';
import { getEntityName, sanitizeIdentifiers } from './formUtils';
import styles from './ManageFeatures.module.css';

// Define this here for consistency.
type IdentifiersElement = HTMLTextAreaElement;

type IdentifiersInputProps = {
  entity: Entity;
  value: number[];
  onValueChange?: (identifiers: number[]) => void;
  onBlur?: FocusEventHandler<IdentifiersElement>;
  error?: string;
  info?: ReactNode;
};

export const IdentifiersInput = ({
  entity,
  value,
  onValueChange,
  onBlur,
  error,
  info,
}: IdentifiersInputProps) => {
  const inputRef = useRef<IdentifiersElement>(null);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (inputRef.current != null && !isChanged) {
      inputRef.current.value = value.join(',');
    }
  }, [isChanged, value]);

  const handleChange: ChangeEventHandler<IdentifiersElement> = (event) => {
    setIsChanged(true);
    const identifiers = sanitizeIdentifiers(event.target.value);

    onValueChange?.(identifiers);
  };

  const entityName = getEntityName(entity);

  const helperText =
    error ??
    info ??
    `Enter a list of ${entityName} IDs, separated by newline or comma.`;

  return (
    <TextField
      inputRef={inputRef}
      id="input_identifiers"
      label={`${entityName} IDs`}
      name="identifiers"
      helperText={helperText}
      onChange={handleChange}
      onBlur={onBlur}
      multiline
      fullWidth={true}
      variant="standard"
      error={error != null}
      className={styles.field}
      autoComplete="off"
      inputProps={{
        'data-1p-ignore': true,
      }}
    />
  );
};
