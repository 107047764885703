import ButtonLink from '../../../../components/ButtonLink';
import { NO_DATA_PLACEHOLDER } from '../../../../utils/constants';
import Deductions, { Currency, Deduction } from './components/Deductions';

interface DiscountCustomRendererArgs {
  isBillingV3Converted: boolean;
  deductions?: Deduction[];
  hasSkuDiscount: boolean;
  currency: Currency;
}

export const discountsCustomRenderer =
  ({
    isBillingV3Converted,
    hasSkuDiscount,
    deductions,
    currency,
  }: DiscountCustomRendererArgs) =>
  () => {
    // No site discounts for Billing v3 yet
    return isBillingV3Converted ? (
      NO_DATA_PLACEHOLDER
    ) : (
      <Deductions
        currency={currency}
        deductions={deductions}
        hasSkuDiscount={hasSkuDiscount}
      />
    );
  };

export const recValueCustomRenderer =
  (onClikHandler: () => void) => (recValue: number) =>
    <ButtonLink onClick={onClikHandler}>{recValue}</ButtonLink>;
