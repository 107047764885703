import { Chip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/ErrorOutline';

import styles from './ErroredColumnHead.module.css';

const ErroredColumnHead = ({ columnName }) => (
  <div className={styles.error}>
    {columnName}
    <br />
    <Chip variant="error" label="Error fetching" avatar={<ErrorIcon />} />
  </div>
);

export default ErroredColumnHead;
