import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Organization } from '../models/organization.model';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { fetchOrganizationURL } from './urls';

const showOrganization = async (id: number): Promise<Organization> => {
  const response = await fetch(fetchOrganizationURL(id));

  return handleFetchApiResponse<Organization>(response);
};

export const useShowOrganization = (id?: number) => {
  const { addToast } = useToasts();
  const { data, isInitialLoading, isError, error } = useQuery<Organization>({
    queryKey: ['showOrganization', id],
    queryFn: () => showOrganization(id!),
    enabled: !!id,
  });

  useEffect(() => {
    if (isError) {
      addToast(`There was an error fetching data for organization id:"${id}"`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [id, addToast, isError]);

  return {
    organizationData: data,
    organizationError: error,
    isOrganizationError: isError,
    isOrganizationLoading: isInitialLoading,
  };
};
