import { Component } from 'react';
import {
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

export class IgnoredIPsModal extends Component {
  render() {
    const { props: modalProps, onCloseModal } = this.props;

    if (!modalProps) {
      return null;
    }
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open
        onClose={onCloseModal}
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ paddingBottom: '0px' }}>
          Ignored IP Addresses
        </DialogTitle>
        {modalProps.ignored_ips && modalProps.ignored_ips.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell key="name">Name</TableCell>
                <TableCell key="ip">IP or IP Regex</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modalProps.ignored_ips.map((entry, index) => (
                <TableRow key={index + 'row'}>
                  <TableCell key={index + 'name'}>{entry.name}</TableCell>
                  <TableCell key={index + 'regex'}>{entry.regex}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <p>No Ignored IPs.</p>
        )}
      </Dialog>
    );
  }
}

export default IgnoredIPsModal;
