import { Grid, TextField } from '@mui/material';

export function UserFields({ formData, handleUpdateField }) {
  return (
    <>
      <h2>User data</h2>
      <Grid container spacing={10}>
        <Grid item xs>
          <TextField
            label="First Name"
            id="firstName"
            variant="standard"
            value={formData.firstName}
            onChange={handleUpdateField}
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            label="Last Name"
            id="lastName"
            variant="standard"
            value={formData.lastName}
            onChange={handleUpdateField}
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <TextField
            label="Email"
            id="emailAddress"
            variant="standard"
            value={formData.emailAddress}
            onChange={handleUpdateField}
            type="email"
            fullWidth
            required
          />
        </Grid>
      </Grid>
    </>
  );
}
