/**
 * we are doing a 1-to-1 mapping with Zuora products to add additional information for translations purposes
 * and also to make it easy to find things by sku, plan, prod etc.
 * see [Billing 3.0 products](https://hotjar.atlassian.net/wiki/spaces/MP/pages/2974875706/Billing+3.0+Products)
 *
 * This is taken from Insights Webapp.
 * This can be removed after migrating all Billing v2 accounts to Billing v3 by fetching the product catalog, service management products and map them
 * The new mapping can be taken from Insights Webapp after it is implemented
 */

const productDictionary = {
  'PROD-1': 'ask',
  'PROD-2': 'observe',
};

const planDictionary = {
  'PLAN-1': 'basic',
  'PLAN-2': 'plus',
  'PLAN-3': 'business',
  'PLAN-4': 'scale',
  'PLAN-5': 'basic',
  'PLAN-6': 'plus',
  'PLAN-7': 'business',
  'PLAN-8': 'scale',
};

export const allowanceDictionary = {
  'SKU-22': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-1', name: planDictionary['PLAN-1'] },
  },
  'SKU-1': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-2', name: planDictionary['PLAN-2'] },
  },
  'SKU-2': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-3', name: planDictionary['PLAN-3'] },
  },
  'SKU-3': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-3', name: planDictionary['PLAN-3'] },
  },
  'SKU-4': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-3', name: planDictionary['PLAN-3'] },
  },
  'SKU-5': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-3', name: planDictionary['PLAN-3'] },
  },
  'SKU-6': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-3', name: planDictionary['PLAN-3'] },
  },
  'SKU-7': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-3', name: planDictionary['PLAN-3'] },
  },
  'SKU-8': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-3', name: planDictionary['PLAN-3'] },
  },
  'SKU-9': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-3', name: planDictionary['PLAN-3'] },
  },
  'SKU-10': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-3', name: planDictionary['PLAN-3'] },
  },
  'SKU-23': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-4', name: planDictionary['PLAN-4'] },
  },
  'SKU-43': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-4', name: planDictionary['PLAN-4'] },
  },
  'SKU-44': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-4', name: planDictionary['PLAN-4'] },
  },
  'SKU-45': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-4', name: planDictionary['PLAN-4'] },
  },
  'SKU-46': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-4', name: planDictionary['PLAN-4'] },
  },
  'SKU-47': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-4', name: planDictionary['PLAN-4'] },
  },
  'SKU-48': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-4', name: planDictionary['PLAN-4'] },
  },
  'SKU-49': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-4', name: planDictionary['PLAN-4'] },
  },
  'SKU-50': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-4', name: planDictionary['PLAN-4'] },
  },
  'SKU-51': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-4', name: planDictionary['PLAN-4'] },
  },
  'SKU-52': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-4', name: planDictionary['PLAN-4'] },
  },
  'SKU-53': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-4', name: planDictionary['PLAN-4'] },
  },
  'SKU-54': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-4', name: planDictionary['PLAN-4'] },
  },
  'SKU-55': {
    product: { sku: 'PROD-1', name: productDictionary['PROD-1'] },
    plan: { sku: 'PLAN-4', name: planDictionary['PLAN-4'] },
  },
  'SKU-24': {
    product: { sku: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { sku: 'PLAN-5', name: planDictionary['PLAN-5'] },
  },
  'SKU-11': {
    product: { sku: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { sku: 'PLAN-6', name: planDictionary['PLAN-6'] },
  },
  'SKU-12': {
    product: { sku: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { sku: 'PLAN-7', name: planDictionary['PLAN-7'] },
  },
  'SKU-13': {
    product: { sku: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { sku: 'PLAN-7', name: planDictionary['PLAN-7'] },
  },
  'SKU-14': {
    product: { sku: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { sku: 'PLAN-7', name: planDictionary['PLAN-7'] },
  },
  'SKU-15': {
    product: { sku: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { sku: 'PLAN-7', name: planDictionary['PLAN-7'] },
  },
  'SKU-16': {
    product: { sku: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { sku: 'PLAN-7', name: planDictionary['PLAN-7'] },
  },
  'SKU-17': {
    product: { sku: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { sku: 'PLAN-7', name: planDictionary['PLAN-7'] },
  },
  'SKU-18': {
    product: { sku: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { sku: 'PLAN-7', name: planDictionary['PLAN-7'] },
  },
  'SKU-19': {
    product: { sku: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { sku: 'PLAN-7', name: planDictionary['PLAN-7'] },
  },
  'SKU-20': {
    product: { sku: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { sku: 'PLAN-7', name: planDictionary['PLAN-7'] },
  },
  'SKU-21': {
    product: { sku: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { sku: 'PLAN-7', name: planDictionary['PLAN-7'] },
  },
  'SKU-25': {
    product: { sku: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { sku: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-26': {
    product: { sku: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { sku: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-27': {
    product: { id: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { id: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-28': {
    product: { id: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { id: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-29': {
    product: { id: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { id: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-30': {
    product: { id: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { id: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-31': {
    product: { id: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { id: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-32': {
    product: { id: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { id: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-33': {
    product: { id: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { id: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-34': {
    product: { id: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { id: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-35': {
    product: { id: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { id: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-36': {
    product: { id: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { id: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-37': {
    product: { id: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { id: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-38': {
    product: { id: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { id: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-39': {
    product: { id: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { id: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-40': {
    product: { id: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { id: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
  'SKU-41': {
    product: { id: 'PROD-2', name: productDictionary['PROD-2'] },
    plan: { id: 'PLAN-8', name: planDictionary['PLAN-8'] },
  },
};
