import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import ShowSpinner from '../../ShowSpinner';

interface Props {
  title: string;
}

export const LoadingDialog: FC<PropsWithChildren<Props>> = ({
  title,
  children,
}): JSX.Element => (
  <Dialog fullWidth maxWidth="sm" open disableEnforceFocus>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {children ? (
          children
        ) : (
          <div>
            It might take a while. The page will refresh automatically after the
            request is processed. If anything goes wrong, please leave us a
            message at{' '}
            <Link href="https://hotjar.slack.com/archives/C04TE3V9RAT">
              #helpdesk-monetization
            </Link>
            .
          </div>
        )}
      </DialogContentText>
      <ShowSpinner />
    </DialogContent>
  </Dialog>
);
