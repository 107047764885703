import { FC } from 'react';
import { clsx } from 'clsx';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import styles from './ExternalLink.module.css';

interface ExternalLinkProps {
  url: string;
  text: string;
  className?: string;
}

const ExternalLink: FC<ExternalLinkProps> = ({ url, text, className }) => (
  <a
    className={clsx(styles.link, className)}
    href={url}
    rel="nofollow noreferrer"
    target="_blank"
  >
    {text}

    <OpenInNewIcon aria-hidden />
  </a>
);

export default ExternalLink;
