import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import type { SelectChangeEvent } from '@mui/material';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import styles from './FeatureManager.module.css';
import { emailRe } from '../../utils/validators';
import type { PostFeatureToggleProps } from './api';
import { useGetFeaturesList } from './api';

export type FormData = Omit<PostFeatureToggleProps, 'action'>;

const sanitizeIdentifiers = (
  identifiers: FormData['identifiers']
): string[] => {
  return identifiers
    .split(/,|\n/)
    .map((identifier) => {
      return identifier.trim();
    })
    .filter((identifier) => {
      return !!identifier;
    });
};

type ValidationData = {
  isValid: boolean;
  errors: {
    emailInvalid: boolean;
    accountIDInvalid: boolean;
    identifiersMissing: boolean;
    featureMissing: boolean;
  };
  messages: {
    email: string;
    account_ids: string;
    feature: string;
  };
};

const validateIdentifiers = (
  sanitizedIdentifiers: string[],
  changeBy: FormData['changeBy'],
  feature: FormData['feature']
): ValidationData => {
  let isValid = true;
  const errors: ValidationData['errors'] = {
    emailInvalid: false,
    accountIDInvalid: false,
    identifiersMissing: false,
    featureMissing: false,
  };
  const messages: ValidationData['messages'] = {
    email: '',
    account_ids: '',
    feature: '',
  };

  if (feature === '') {
    errors.featureMissing = true;
    messages.feature = 'Please select a feature';
    isValid = false;
  }

  if (sanitizedIdentifiers.length < 1) {
    errors.identifiersMissing = true;
    if (changeBy === 'emails') {
      messages.email = 'Please enter a list of emails';
    } else if (changeBy === 'account_ids') {
      messages.account_ids = 'Please enter a list of Account IDs';
    }
    isValid = false;
  } else {
    if (changeBy === 'emails') {
      for (const email of sanitizedIdentifiers) {
        if (!emailRe.test(email)) {
          errors.emailInvalid = true;
          messages.email = email + ' is not a valid email';
          isValid = false;
          break;
        }
      }
    } else if (changeBy === 'account_ids') {
      for (const account_id of sanitizedIdentifiers) {
        if (Number.isNaN(parseInt(account_id, 10))) {
          errors.accountIDInvalid = true;
          messages.account_ids = account_id + ' is not a valid account ID.';
          isValid = false;
          break;
        }
      }
    }
  }

  return {
    isValid,
    errors,
    messages,
  };
};

type FormActionData = FormData & {
  validationErrors: ValidationData['errors'];
  validationMessages: ValidationData['messages'];
};

type FormProps = {
  onFormAction: (data: FormActionData) => void;
};

export const Form = ({ onFormAction }: FormProps): JSX.Element => {
  const [changeBy, setChangeBy] = useState<FormData['changeBy']>('emails');
  const [identifiers, setIdentifiers] = useState<FormData['identifiers']>('');
  const [feature, setFeature] = useState<FormData['feature']>('');

  const [validation, setValidation] = useState<Omit<ValidationData, 'isValid'>>(
    {
      errors: {
        emailInvalid: false,
        accountIDInvalid: false,
        identifiersMissing: false,
        featureMissing: false,
      },
      messages: {
        email: '',
        feature: '',
        account_ids: '',
      },
    }
  );

  const { data: allFeatures = null } = useGetFeaturesList();
  const featuresList =
    allFeatures === null
      ? []
      : allFeatures.map((f, i) => (
          <option value={f.name} key={`feature_${i}`}>
            {f.label}
          </option>
        ));

  const handleIdentifiersChange = (
    event: ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setIdentifiers(event.target.value);
    setValidation({
      ...validation,
      errors: {
        ...validation.errors,
        identifiersMissing: false,
      },
    });
  };

  const handleFeatureSelectChange = (event: SelectChangeEvent) => {
    setFeature(event.target.value);
  };

  const handleChangeBySelectChange = (
    event: SelectChangeEvent<FormData['changeBy']>
  ) => {
    setChangeBy(event.target.value as FormData['changeBy']);
    setIdentifiers('');
  };

  const handleFormSubmit = () => {
    const sanitizedIdentifiers = sanitizeIdentifiers(identifiers);
    const { isValid, errors, messages } = validateIdentifiers(
      sanitizedIdentifiers,
      changeBy,
      feature
    );

    setValidation({
      errors,
      messages,
    });

    if (isValid) {
      return onFormAction({
        changeBy,
        feature,
        identifiers: sanitizedIdentifiers.join(','),
        validationErrors: errors,
        validationMessages: messages,
      });
    }
  };

  return (
    <form className={styles.form}>
      <FormControl
        className={styles.input}
        error={validation.errors.featureMissing}
      >
        <InputLabel shrink={feature !== ''} htmlFor="feature_select">
          Select feature
        </InputLabel>
        <Select
          native
          value={feature}
          onChange={handleFeatureSelectChange}
          inputProps={{
            name: 'feature_select',
            id: 'feature_select',
          }}
          variant="standard"
        >
          <option value="" />
          {featuresList}
        </Select>
        <FormHelperText>{validation.messages.feature}</FormHelperText>
      </FormControl>
      <FormControl className={styles.input}>
        <InputLabel htmlFor="change_by">Change By</InputLabel>
        <Select
          native
          value={changeBy}
          onChange={handleChangeBySelectChange}
          inputProps={{
            name: 'change_by',
            id: 'change_by',
          }}
          variant="standard"
        >
          <option value="emails">Emails</option>
          <option value="account_ids">Account IDs</option>
        </Select>
      </FormControl>
      <TextField
        id="identifiers"
        label={changeBy === 'emails' ? 'Emails' : 'Account IDs'}
        name="identifiers"
        multiline
        value={identifiers}
        onChange={handleIdentifiersChange}
        error={
          validation.messages.email !== '' ||
          validation.messages.account_ids !== ''
        }
        helperText={
          validation.messages.email || validation.messages.account_ids
        }
        fullWidth={true}
        variant="standard"
        className={styles.input}
      />
      {feature && (
        <div>
          <p>Flag:</p>
          <p id="flag_selected" className={styles.flag}>
            {feature}
          </p>
        </div>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleFormSubmit}
        className={styles.submit}
        disabled={feature.length === 0 || identifiers.length === 0}
      >
        Submit
      </Button>
    </form>
  );
};
