import React from 'react';
import type {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from 'mui-datatables';
import MUIDataTable from 'mui-datatables';
import { useGetFeaturesList } from './api';
import ShowSpinner from '../../components/ShowSpinner';
import { DisableFeature } from './DisableFeature';
import { EnableFeature } from './EnableFeature';
import { StartUpdateRemoveFeature } from './StartUpdateRemoveFeature';
import { StartUpdateAddFeature } from './StartUpdateAddFeature';

const columns: Exclude<MUIDataTableColumnDef, string>[] = [
  { name: 'name', label: 'Name' },
  { name: 'label', label: 'Label', options: { searchable: false } },
  { name: 'description', label: 'Description', options: { searchable: false } },
  {
    name: 'name',
    label: 'Actions',
    options: {
      searchable: false,
      sort: false,
      customBodyRender: (value: string) => (
        <>
          <StartUpdateAddFeature feature={value} />
          <StartUpdateRemoveFeature feature={value} />
          <EnableFeature feature={value} />
          <DisableFeature feature={value} />
        </>
      ),
    },
  },
];

const options: MUIDataTableOptions = {
  responsive: 'standard',
  selectableRows: 'none',
  print: false,
  download: false,
  viewColumns: false,
  elevation: 0,
  sortOrder: {
    name: 'name',
    direction: 'asc',
  },
};

export const FeatureList = (): JSX.Element => {
  const { data: features = null, isError, error } = useGetFeaturesList();

  if (isError) {
    return <p className="error">{`Error fetching feature list: ${error}`}</p>;
  }

  if (features === null) {
    return <ShowSpinner />;
  }

  return (
    <MUIDataTable
      title="Feature list"
      columns={columns}
      data={features}
      options={options}
    />
  );
};
