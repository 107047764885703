import { Typography } from '@mui/material';
import { FC } from 'react';

import type { EntityDetailsColumnsConfig } from './interfaces';
import { buildItem, getGridClass, getGridData } from './utils';
import { clsx } from 'clsx';

import styles from './EntityDetails.module.css';

interface EntityDetailsProps {
  title?: string;
  wrapperClassName?: string;
  data: EntityDetailsColumnsConfig;
}

const EntityDetails: FC<EntityDetailsProps> = ({
  data,
  title,
  wrapperClassName,
}) => {
  const gridData = getGridData(data);

  const columnCount = data.length;
  const gridClass = getGridClass(columnCount);

  return (
    <div className={clsx(styles.wrapper, wrapperClassName)}>
      {title && (
        <Typography variant="h2" className={styles.title}>
          {title}
        </Typography>
      )}
      <dl className={`${styles.entityDetails} ${gridClass}`}>
        {gridData.map((item, index) => buildItem(index, item))}
      </dl>
    </div>
  );
};

export default EntityDetails;
