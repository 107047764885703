import { useContext, useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import {
  DialogContent,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Alert,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useToasts } from 'react-toast-notifications';
import { camelKeys } from 'js-convert-case';
import {
  previewPrice,
  sendAsyncOrderToMagpie,
  getAmendmentPayload,
  getReactivationPayload,
} from '../../../utils/api';
import { LookupContext } from '../../../contexts/LookupContext';
import { Magpie, ProductCatalogItem } from '../../../typings';
import { getSalesDeductionPercentage } from '../../../containers/Lookup/billingV3.functions';
import { SubscriptionInfo } from './SubscriptionInfo';
import { getProductCatalogVersion } from '../../../domains/accounts/utils/getProductCatalogVersion';
import moment, { Moment } from 'moment';
import type { AdmModalProps } from '../AdmModalRoot';
import { AlpSubscriptions } from './AlpSubscriptions';
import type { FormDataAlp } from './types';
import { CostPreview } from './components/CostPreview';
import { DateSelect } from './components/DateSelect';
import { Actions } from './components/Actions';
import { preparePayload } from './subscription-order-utils';
import { pollOrderStatus } from '../../../domains/magpie/api/poll-order-status';
import { useProducts } from '../../../domains/products/data-access/use-products';

const defaultFormData: FormDataAlp = {
  subscriptions: [],
  costs: {
    payNow: {
      subTotal: 0.0,
      taxPercentage: 0.0,
      total: 0.0,
    },
    renewWith: { subTotal: 0.0, taxPercentage: 0.0, total: 0.0 },
  },
  previewedChanges: {
    productsToAdd: [],
    productsToRemove: [],
    orderDate: null,
  },
};

export const PreviewCostsModalAlp = ({ onCloseModal }: AdmModalProps<{}>) => {
  const [mode, setMode] = useState<'preview' | 'modify'>('preview');
  const [hasChangedPlans, setHasChangedPlans] = useState(false);
  const [needsPreview, setNeedsPreview] = useState(false);
  const context = useContext(LookupContext);
  const magpie = context?.magpie
    ? (camelKeys(context.magpie, {
        recursive: true,
        recursiveInArray: true,
      }) as Magpie)
    : undefined;

  const { currency, id: accountId } = context?.account;
  const isCancelledSubscription = magpie?.subscription.status === 'CANCELLED';

  const { addToast } = useToasts();
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  const [selectedPreviewDate, setSelectedPreviewDate] = useState<Moment | null>(
    moment()
  );

  useEffect(() => {
    if (mode === 'modify') {
      // In case of modify, we always use today's date
      setSelectedPreviewDate(moment());
    }
  }, [mode]);

  const { plans, productCatalog } = useProducts({
    billingPeriod: magpie?.subscription.billingPeriod,
    currency,
    version: getProductCatalogVersion(context?.account),
  });

  const [formData, setFormData] = useState<FormDataAlp>(defaultFormData);

  const enrichedMagpieSubscriptions =
    magpie?.subscription.products
      .filter((subscription) => subscription.sku !== 'compound_discount')
      .map(({ sku }) => {
        return plans.find((plan) => plan.sku === sku) as ProductCatalogItem;
      }) ?? [];

  const getDefaultCosts = () => ({
    payNow: {
      subTotal: magpie?.currentPeriodCostSummary.amountWithoutTax ?? 0.0,
      taxPercentage: magpie?.currentPeriodCostSummary.taxRate ?? 0.0,
      total: magpie?.currentPeriodCostSummary.amountWithTax ?? 0.0,
    },
    renewWith: {
      subTotal: magpie?.currentPeriodCostSummary.amountWithoutTax ?? 0.0,
      taxPercentage: magpie?.currentPeriodCostSummary.taxRate ?? 0.0,
      total: magpie?.currentPeriodCostSummary.amountWithTax ?? 0.0,
    },
  });

  useEffect(() => {
    if (!magpie || !plans.length) {
      return;
    }

    const data = {
      ...formData,
      costs: getDefaultCosts(),
      subscriptions: enrichedMagpieSubscriptions,
    };
    setFormData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.magpie, plans]);

  useEffect(() => {
    const { productsToAdd, productsToRemove } = preparePayload(
      formData.subscriptions,
      enrichedMagpieSubscriptions,
      plans,
      accountId
    );
    setHasChangedPlans(productsToAdd.length > 0 || productsToRemove.length > 0);
    const needsPreview = !isEqual(formData.previewedChanges, {
      productsToAdd,
      productsToRemove,
      orderDate: selectedPreviewDate?.format('YYYY-MM-DD') ?? null,
    });
    setNeedsPreview(needsPreview);
  }, [
    formData,
    enrichedMagpieSubscriptions,
    plans,
    accountId,
    selectedPreviewDate,
  ]);

  const handleError = (error: Error) => {
    const errorMessage = error?.message ?? error?.name;
    addToast(errorMessage, {
      appearance: 'error',
      autoDismiss: true,
    });
    console.error('Order', JSON.stringify(error));
  };

  const previewCosts = async (action: 'preview' | 'modify' = 'preview') => {
    const { productsToAdd, productsToRemove } = preparePayload(
      formData.subscriptions,
      enrichedMagpieSubscriptions,
      plans,
      accountId
    );

    if (!productsToAdd.length && !productsToRemove.length) {
      setFormData({ ...formData, costs: getDefaultCosts() });
      return;
    }

    const salesDeductionPercentage =
      getSalesDeductionPercentage(magpie?.subscription) ?? 0;

    const amendmentData = {
      productsToAdd,
      productsToRemove,
      salesDeductionPercentage,
    };

    const reactivationData = {
      billingPeriod: magpie?.subscription.billingPeriod,
      products: productsToAdd,
      salesDeductionPercentage,
    };

    let payload = isCancelledSubscription
      ? getReactivationPayload(accountId, reactivationData)
      : getAmendmentPayload(accountId, amendmentData);

    setIsPreviewLoading(true);
    try {
      if (mode === 'preview') {
        payload = {
          ...payload,
          order_date: selectedPreviewDate?.format('YYYY-MM-DD'),
        };
      }
      const rawResponse =
        action === 'preview'
          ? await previewPrice(payload)
          : await sendAsyncOrderToMagpie(payload);
      const response = await rawResponse.json();
      if (!rawResponse.ok) {
        const errorMessage = response?.message ?? rawResponse.status;
        throw new Error(errorMessage);
      }
      const payNow = {
        subTotal: response.pay_now.amount_without_tax,
        taxPercentage: response.pay_now.tax_rate,
        total: response.pay_now.amount_with_tax,
      };

      const renewWith = {
        subTotal: response.renew_with.amount_without_tax,
        taxPercentage: response.renew_with.tax_rate,
        total: response.renew_with.amount_with_tax,
      };

      setFormData({
        ...formData,
        costs: {
          payNow,
          renewWith,
        },
        previewedChanges: {
          productsToAdd,
          productsToRemove,
          orderDate: selectedPreviewDate?.format('YYYY-MM-DD') ?? null,
        },
      });
      setIsPreviewLoading(false);
    } catch (error) {
      setIsPreviewLoading(false);
      handleError(error as Error);
    }
  };

  async function handleModifySubscription() {
    if (isCancelledSubscription) {
      return;
    }

    const amendmentData = preparePayload(
      formData.subscriptions,
      enrichedMagpieSubscriptions,
      plans,
      accountId
    );
    setIsPreviewLoading(true);
    try {
      const payload = getAmendmentPayload(accountId, {
        ...amendmentData,
        salesDeductionPercentage: 0,
      });
      const rawResponse = await sendAsyncOrderToMagpie(payload);
      const response = await rawResponse.json();
      const { identifier } = response;
      await pollOrderStatus(identifier);
      addToast('Order submitted successfully. Please reload the page.', {
        appearance: 'success',
        autoDismiss: false,
      });
      setIsPreviewLoading(false);
    } catch (error) {
      setIsPreviewLoading(false);
      handleError(error as Error);
    }
  }

  const handleSubscriptionChange = (sku: string) => {
    const newProductInfo = plans.find((plan) => plan.sku === sku);

    if (newProductInfo === undefined) {
      return;
    }

    const updatedSubscriptions = formData.subscriptions
      .filter(
        (subscription) =>
          subscription.productName !== newProductInfo?.productName
      )
      .concat(newProductInfo);

    setFormData({
      ...formData,
      subscriptions: updatedSubscriptions,
    });
  };

  const showPreviewButton = mode === 'preview' || needsPreview;
  const showModifyButton = mode === 'modify';
  const isModifyDisabled =
    isCancelledSubscription ||
    isPreviewLoading ||
    !hasChangedPlans ||
    needsPreview;

  return (
    <>
      <DialogContent>
        <ToggleButtonGroup
          value={mode}
          exclusive
          onChange={(_event, value) => setMode(value)}
        >
          <ToggleButton value="preview">Preview</ToggleButton>
          <ToggleButton value="modify">Modify</ToggleButton>
        </ToggleButtonGroup>
        <Grid container spacing={2}>
          <CostPreview
            costs={formData.costs}
            isPreviewLoading={isPreviewLoading}
            currency={currency}
          />
        </Grid>

        {magpie && <SubscriptionInfo subscription={magpie.subscription} />}

        {mode === 'preview' && (
          <DateSelect
            selectedPreviewDate={selectedPreviewDate}
            setSelectedPreviewDate={setSelectedPreviewDate}
          />
        )}

        {mode === 'modify' && (
          <Box sx={{ mt: 2, mb: 2 }}>
            <Alert severity="warning">
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                <li>This action will modify the subscription immediately</li>
                <li>
                  Modify subscription always submits the change with today's
                  date
                </li>
              </ul>
            </Alert>
          </Box>
        )}

        {mode === 'modify' && isCancelledSubscription && (
          <Box sx={{ mt: 2, mb: 2 }}>
            <Alert severity="warning">
              Reactivating a cancelled subscription is not supported right now
            </Alert>
          </Box>
        )}

        {!isEmpty(formData?.subscriptions) && (
          <AlpSubscriptions
            formData={formData}
            handleSubscriptionChange={handleSubscriptionChange}
            productCatalog={productCatalog}
          />
        )}
      </DialogContent>
      <Actions closeModal={onCloseModal}>
        {showPreviewButton && (
          <LoadingButton
            onClick={() => previewCosts('preview')}
            variant="contained"
            color="primary"
            disabled={!hasChangedPlans}
            loading={isPreviewLoading}
          >
            Preview costs
          </LoadingButton>
        )}
        {showModifyButton && (
          <LoadingButton
            onClick={() => handleModifySubscription()}
            variant="contained"
            color="primary"
            loading={isPreviewLoading}
            disabled={isModifyDisabled}
          >
            Modify subscription
          </LoadingButton>
        )}
      </Actions>
    </>
  );
};

export default PreviewCostsModalAlp;
