import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  useCallback,
} from 'react';
import type { Modal } from '../components/modals/constants';

type OpenModalProps = Record<string, {}>;

export interface ModalProviderContext {
  openModalProps: OpenModalProps;
  currentlyOpenModals: [] | string[];
  isModalOpen: (modalName: string) => boolean;
  openModal: (modal: Modal, payload?: {}) => void;
  closeAllModals: () => void;
  closeModal: (modal?: string) => void;
  onSuccess: () => void;
}

interface ModalProviderProps extends PropsWithChildren {
  onSuccess: () => void;
}

export const ModalContext = createContext<ModalProviderContext | null>(null);

export const ModalProvider: FC<ModalProviderProps> = ({
  onSuccess,
  children,
}) => {
  const [currentlyOpenModals, setCurrentlyOpenModals] = useState<string[]>([]);
  const [openModalProps, setOpenModalProps] = useState<OpenModalProps>({});

  const openModal = (modal: string, payload?: {}) => {
    setCurrentlyOpenModals((currentlyOpenModals) => [
      ...currentlyOpenModals,
      modal,
    ]);

    if (payload) {
      setOpenModalProps((openModalProps) => ({
        ...openModalProps,
        [modal]: payload,
      }));
    }
  };

  const resetSearchParams = useCallback(() => {
    const newRelativePathQuery = window.location.pathname;
    // eslint-disable-next-line no-restricted-globals
    history.replaceState(null, '', newRelativePathQuery);
  }, []);

  // is this right? can we call this method without a modal name
  const closeModal = useCallback(
    (modal?: string) => {
      const updatedModals = currentlyOpenModals.filter(
        (item) => item !== modal
      );
      if (updatedModals.length < 1) {
        resetSearchParams();
      }

      if (modal) {
        setCurrentlyOpenModals(updatedModals);
        setOpenModalProps((openModalProps) => {
          delete openModalProps[modal];
          return {
            ...openModalProps,
          };
        });
      }
    },
    [currentlyOpenModals, resetSearchParams]
  );

  const closeAllModals = useCallback(() => {
    resetSearchParams();
    setCurrentlyOpenModals([]);
    setOpenModalProps({});
  }, [resetSearchParams]);

  const isModalOpen = useCallback(
    (modal: string) => {
      return currentlyOpenModals.indexOf(modal) > -1;
    },
    [currentlyOpenModals]
  );

  const handleOnSuccess = useCallback(() => {
    closeModal();
    onSuccess();
  }, [closeModal, onSuccess]);

  const contextValue: ModalProviderContext = {
    currentlyOpenModals,
    openModalProps,
    closeAllModals,
    isModalOpen,
    closeModal,
    openModal,
    onSuccess: handleOnSuccess,
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

interface WithModalContextProps {
  modalContext: ModalProviderContext;
}

export const withModalContext = <T extends WithModalContextProps>(
  Component: React.ComponentType<T>
): FC<Omit<T, keyof WithModalContextProps>> => {
  return (props) => (
    <ModalContext.Consumer>
      {(context) => {
        if (context === null) {
          throw new Error('ModalConsumer must be used within a ModalProvider.');
        }

        return <Component {...(props as T)} modalContext={context} />;
      }}
    </ModalContext.Consumer>
  );
};

export const useModalContext = () =>
  useContext(ModalContext) as ModalProviderContext;
