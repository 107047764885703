import type { SliderProps } from '@mui/material';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Slider,
} from '@mui/material';
import type { ChangeEventHandler, FocusEventHandler } from 'react';
import React from 'react';
import styles from './ManageFeatures.module.css';

type PercentageSliderProps = {
  value: number;
  onValueChange?: (value: number) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  error?: string;
};

type SliderChangeEventHandler = SliderProps['onChange'];

export const PercentageSlider = ({
  value,
  onValueChange,
  onBlur,
  error,
}: PercentageSliderProps) => {
  const handleSliderChange: SliderChangeEventHandler = (_, value) => {
    if (Array.isArray(value)) {
      return;
    }

    onValueChange?.(value);
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = parseInt(event.target.value);
    if (isNaN(value)) {
      return;
    }

    onValueChange?.(value);
  };

  const hasError = error != null;

  return (
    <FormControl fullWidth className={styles.formControl}>
      <InputLabel id="label_percentage">Percentage</InputLabel>
      <div className={styles.percentage}>
        <Slider
          id="slider_percentage"
          aria-labelledby="label_percentage"
          value={value}
          onChange={handleSliderChange}
          onBlur={onBlur}
          // @ts-ignore - color accepts more values than the typings suggest
          color={hasError ? 'error' : 'primary'}
        />
        <Input
          id="input_percentage"
          name="percentage"
          value={value}
          size="small"
          className={styles.percentageInput}
          onChange={handleInputChange}
          onBlur={onBlur}
          error={hasError}
          inputProps={{
            step: 5,
            min: 0,
            max: 100,
            type: 'number',
            'aria-labelledby': 'label_percentage',
          }}
        />
      </div>
      <FormHelperText error={hasError}>{error || ' '}</FormHelperText>
    </FormControl>
  );
};
