import React, { Component } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import styles from './EmailChangeDialog.module.css';
import { updateUserEmail } from './api';
import { MODAL_EMAIL_CHANGE } from '../constants';
import { withModalContext } from '../../../contexts/ModalContext';
import { emailRe } from '../../../utils/validators';

class EmailChangeDialog extends Component {
  state = {
    changeEmailData: {
      oldEmail: '',
      newEmail: '',
      annotation: '',
      error: '',
    },
  };

  onFormUpdate = (e) => {
    const { name, value } = e.target;
    const formObj = { ...this.state.changeEmailData };
    formObj[name] = value;
    this.setState({
      ...this.state,
      changeEmailData: formObj,
    });
  };

  closeEmailModal = () => {
    this.setState({
      changeEmailData: {
        oldEmail: '',
        newEmail: '',
        annotation: '',
        error: '',
      },
    });
    this.props.modalContext.closeModal(MODAL_EMAIL_CHANGE);
  };

  setEmailError = (error) => {
    this.setState({
      ...this.state,
      changeEmailData: {
        ...this.state.changeEmailData,
        error,
      },
    });
  };

  validateEmails = (oldEmail, newEmail) => {
    if (
      oldEmail !==
      this.props.modalContext.openModalProps[MODAL_EMAIL_CHANGE].user_email
    ) {
      this.setEmailError("The old email does not match the user's email.");
      return false;
    }
    if (oldEmail === newEmail) {
      this.setEmailError('The new email cannot be the same as the old email.');
      return false;
    }
    if (!emailRe.test(newEmail)) {
      this.setEmailError(
        'The new email provided is not a valid email address.'
      );
      return false;
    }
    this.setEmailError('');
    return true;
  };

  onFormSubmit = (e) => {
    const modalProps =
      this.props.modalContext.openModalProps[MODAL_EMAIL_CHANGE];
    e.preventDefault();
    const { oldEmail, newEmail, annotation } = this.state.changeEmailData;
    if (this.validateEmails(oldEmail, newEmail)) {
      updateUserEmail(modalProps.user_id, oldEmail, newEmail, annotation)
        .then((data) => {
          modalProps.onSuccess(data.email);
          this.closeEmailModal();
        })
        .catch((e) => {
          this.setEmailError(e.error_message || e.message || e.error);
        });
    }
  };

  render() {
    const isModalOpen = this.props.modalContext.isModalOpen(MODAL_EMAIL_CHANGE);
    return (
      <div>
        <Dialog
          open={isModalOpen}
          aria-labelledby="form-dialog-title"
          disableEnforceFocus={true}
        >
          <DialogTitle id="form-dialog-title">Change Email Address</DialogTitle>
          <form onSubmit={this.onFormSubmit}>
            <DialogContent>
              <TextField
                className={styles.textField}
                required
                id="old_email"
                label="Old Email"
                name="oldEmail"
                value={this.state.oldEmail}
                onChange={this.onFormUpdate}
                variant="standard"
              />
              <TextField
                className={styles.textField}
                required
                id="new_email"
                label="New Email"
                name="newEmail"
                value={this.state.newEmail}
                onChange={this.onFormUpdate}
                variant="standard"
              />
              <TextField
                className={styles.annotation}
                fullWidth
                required
                margin="dense"
                id="annotation"
                label="Annotation"
                name="annotation"
                value={this.state.annotation}
                onChange={this.onFormUpdate}
                helperText="Please provide reason for change and/or Zendesk ticket link."
                variant="standard"
              />
              {this.state.changeEmailData.error && (
                <span className="error">
                  Error: {this.state.changeEmailData.error}
                </span>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.closeEmailModal}
                disabled={this.state.loading}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={this.onFormSubmit}
                type="submit"
                disabled={this.state.loading}
                color="primary"
              >
                Confirm
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default withModalContext(EmailChangeDialog);
