import { buildUrl } from '../../../utils/api';

export const fetchSiteServicePeriodsURL = (siteId: string) =>
  buildUrl(`/api/v2/adm/service-management/service-periods/site/${siteId}`);

export function fetchAccountServicePeriodsURL(accountId: number) {
  return `/api/v2/adm/service-management/service-periods/account/${accountId}`;
}

export function fetchSiteEntitlementsURL(accountId: number, siteId: number) {
  return `/api/v2/adm/service-management/entities/${accountId}/site/${siteId}/entitlements`;
}

export const queryKeys = {
  accountServicePeriods: (accountId: number | undefined) => [
    'accountServicePeriods',
    accountId,
  ],
  siteEntitlements: (
    accountId: number | undefined,
    siteId: number | undefined
  ) => ['siteEntitlements', accountId, siteId],
};
