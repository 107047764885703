import React, { useContext, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { useToasts } from 'react-toast-notifications';

import styles from './styles.module.css';
import { MODAL_ADD_INTERNAL_USER } from '../constants';
import { registerInternalUser } from './api';
import { snakeToTitle } from '../../../utils/strings';
import { ModalContext } from '../../../contexts/ModalContext';

// eslint-disable-next-line no-useless-escape
export const hotjarEmailPattern = '[a-z0-9._%+-]+@hotjar\\.com';

const AddInternalUser = ({ allRoles, updateUserData }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [rolesSelected, setRolesSelected] = useState([]);

  const context = useContext(ModalContext);
  const { addToast } = useToasts();

  const resetState = () => {
    setLoading(false);
    setEmail('');
    setIsSuperuser(false);
    setRolesSelected([]);
  };

  const isOpen = context.isModalOpen(MODAL_ADD_INTERNAL_USER);

  const closeModal = () => {
    context.closeModal(MODAL_ADD_INTERNAL_USER);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleIsSuperuserChange = (event) => {
    setIsSuperuser(event.target.checked);
  };

  const handleRolesChange = (event) => {
    setRolesSelected(event.target.value);
  };

  const showSuccessToast = () => {
    const message = 'Successfully created new user';
    addToast(message, { appearance: 'success', autoDismiss: true });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    registerInternalUser(email, rolesSelected, isSuperuser)
      .then((result) => {
        updateUserData();
        showSuccessToast();
        resetState();
        closeModal();
      })
      .catch((e) => {
        addToast(e.error_message || e.message || e.error, {
          appearance: 'error',
          autoDismiss: true,
        });
        setLoading(false);
      });
  };

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth={'sm'} onClose={closeModal}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add Internal User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will create an Insights user AND an Adm user.
            <br />
            If the Insights user already exists, it will be ignored.
          </DialogContentText>
          <FormGroup row>
            <FormControl className={styles.formControl} margin={'normal'}>
              <TextField
                id="email"
                autoFocus
                value={email}
                onChange={handleEmailChange}
                required
                label="Hotjar Email"
                inputProps={{
                  // eslint-disable-next-line no-useless-escape
                  pattern: hotjarEmailPattern,
                  title: 'name.surname@hotjar.com',
                }}
                variant="standard"
              />
            </FormControl>
          </FormGroup>
          <FormGroup row>
            <FormControl
              className={styles.formControl}
              margin={'normal'}
              data-testid="admRolesSelect"
            >
              <InputLabel id="adm-roles-label" htmlFor="adm-roles">
                Adm Roles
              </InputLabel>
              <Select
                labelid="adm-roles-label"
                id="adm-roles"
                multiple
                value={rolesSelected}
                onChange={handleRolesChange}
                input={<Input id="adm-roles-checkbox" />}
                renderValue={(selected) =>
                  selected.map((item) => snakeToTitle(item)).join(', ')
                }
              >
                {allRoles.map((role) => (
                  <MenuItem key={role.name} value={role.name}>
                    <Checkbox
                      checked={rolesSelected.indexOf(role.name) > -1}
                      color="primary"
                      data-testid={role.name}
                    />
                    <ListItemText primary={snakeToTitle(role.name)} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>This field is optional</FormHelperText>
            </FormControl>
          </FormGroup>
          <FormGroup row>
            <FormControl className={styles.formControl} margin={'normal'}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={isSuperuser}
                    onChange={handleIsSuperuserChange}
                    name="isSuperuser"
                  />
                }
                label="Insights Superuser"
              />
            </FormControl>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            color="primary"
            key="cancel"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            color="primary"
            key="submit"
            type={'submit'}
            data-testid="submitButton"
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddInternalUser;
