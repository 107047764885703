import type { FieldErrors, Resolver } from 'react-hook-form';
import type { Entity } from './api';
import { getEntityName, isEntity, isValidIdentifiers } from './formUtils';
import type {
  FeatureActionFormProps,
  FeatureActionFormReturn,
} from './useFeatureActionForm';
import {
  entityErrorMessage,
  useFeatureActionForm,
} from './useFeatureActionForm';

export type ToggleFeatureFields = {
  entity: Entity;
  identifiers: number[];
};

const toggleFeatureResolver: Resolver<ToggleFeatureFields> = (values) => {
  let hasError = false;
  const errors: FieldErrors<ToggleFeatureFields> = {};

  if (!isEntity(values.entity)) {
    hasError = true;
    errors['entity'] = {
      type: 'isEntity',
      message: entityErrorMessage,
    };
  }

  if (!isValidIdentifiers(values.identifiers)) {
    hasError = true;
    errors['identifiers'] = {
      type: 'isValidIdentifiers',
      message: `${getEntityName(values.entity)} IDs must be a list of numbers.`,
    };
  }

  return {
    values: hasError ? {} : values,
    errors,
  };
};

type ToggleFeatureFormProps = Omit<
  FeatureActionFormProps<ToggleFeatureFields>,
  'defaultValues' | 'resolver'
>;

export const useToggleFeatureForm = (
  props: ToggleFeatureFormProps
): FeatureActionFormReturn<ToggleFeatureFields> =>
  useFeatureActionForm<ToggleFeatureFields>({
    ...props,
    defaultValues: {
      entity: 'account',
      identifiers: [],
    },
    resolver: toggleFeatureResolver,
  });
