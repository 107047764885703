import Dialog from '@mui/material/Dialog';

import AdmActionTable from '../../AdmActionTable';

const AdmActionsModal = ({ props: modalProps, onCloseModal }) => {
  if (!modalProps) {
    return null;
  }
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xl'}
      open
      onClose={onCloseModal}
      disableEnforceFocus={true}
    >
      <AdmActionTable
        data={modalProps.data}
        title={`${modalProps.related_type} Activity`}
        relatedId={modalProps.related_id}
        relatedType={modalProps.related_type}
        newActionCallback={modalProps.newActionCallback}
        showAddButton={modalProps.showAddButton}
      />
    </Dialog>
  );
};

export default AdmActionsModal;
