import { HTTP_ERROR_STATUS, HTTP_ERROR_STATUS_STRING } from '../constants';

export const handleFetchApiResponse = async <T>(
  apiResponse: Response,
  errorMessage?: string
) => {
  if (apiResponse.status === HTTP_ERROR_STATUS) {
    throw new Error(HTTP_ERROR_STATUS_STRING);
  }

  if (!apiResponse.ok) {
    const errorResponse = await apiResponse.json();

    alert(errorMessage || 'Network response error');
    throw new Error(errorResponse?.error || 'Network response error');
  }

  return apiResponse.json() as Promise<T>;
};
