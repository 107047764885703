import { FC } from 'react';

import AccountWidget from '../../../accounts/components/AccountWidget';
import SubscriptionWidget from '../../../magpie/components/SubscriptionWidget';
import OrganizationWidget from '../../../organizations/components/OrganizationWidget';

import styles from './SiteSidebar.module.css';

interface SiteSidebarProps {
  accountId?: number;
  organizationId?: number;
  accountOwnerUserId?: number;
}

const SiteSidebar: FC<SiteSidebarProps> = ({
  accountId,
  organizationId,
  accountOwnerUserId,
}) => {
  return (
    <>
      <div className={styles.group}>
        <AccountWidget
          accountId={accountId}
          accountOwnerUserId={accountOwnerUserId}
        />
        <SubscriptionWidget accountId={accountId} />
      </div>
      <div className={styles.group}>
        <OrganizationWidget organizationId={organizationId} />
      </div>
    </>
  );
};

export default SiteSidebar;
