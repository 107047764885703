import { TextField } from '@mui/material';

export const Notes = ({ invoiceNotes, handleUpdateField, textFieldRows }) => (
  <>
    <TextField
      label="Custom invoice notes"
      inputProps={{ 'aria-label': 'Notes' }}
      variant="standard"
      id="invoiceNotes"
      rows={textFieldRows ?? 6}
      value={invoiceNotes}
      onChange={handleUpdateField}
      multiline
      fullWidth
    />
  </>
);
