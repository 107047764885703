import React from 'react';
import containerStyles from './Lookup.module.css';
import componentStyles from './DisplayAccount.module.css';
import { epochToDateString } from '../../utils/dates';

const DisplayTrialDetails = (props) => {
  return (
    <div className={componentStyles.accountDetails}>
      <h3>Trial</h3>
      <dl className={containerStyles.list}>
        <div>
          <dt className={containerStyles.label}>Days Remaining:</dt>
          <dd className={containerStyles.value}>
            {props.trial.days_remaining}
          </dd>
        </div>
        <div>
          <dt className={containerStyles.label}>Start Date:</dt>
          <dd className={containerStyles.value}>
            {epochToDateString(props.trial.start_date)}
          </dd>
        </div>
        <div>
          <dt className={containerStyles.label}>End Date:</dt>
          <dd className={containerStyles.value}>
            {epochToDateString(props.trial.end_date)}
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default DisplayTrialDetails;
