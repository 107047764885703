import { buildUrl } from '../../../utils/api';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { TrialEntityType } from '../models/TrialEntityType';
import { TrialState } from '../models/TrialState';

export const fetchTrialStates = async (
  entityType: TrialEntityType,
  entityId: string | undefined
): Promise<Array<TrialState>> => {
  if (!entityId) {
    return [];
  }

  const response = await fetch(
    buildUrl(
      `/api/v2/adm/trials/trials?entity_type=${entityType}&entity_id=${entityId}`
    )
  );

  return handleFetchApiResponse<Array<TrialState>>(response);
};

export const fetchAllSitesTrialStatesForAccount = async (
  entityId: string | undefined
): Promise<Array<TrialState>> => {
  if (!entityId) {
    return [];
  }

  const response = await fetch(
    buildUrl(`/api/v2/adm/trials/trials/account?hotjar_account_id=${entityId}`)
  );

  return handleFetchApiResponse<Array<TrialState>>(response);
};
