import { SKU } from '../../../typings';
import { handleFetchApiResponse } from '../../../utils/handleFetchApiResponse';
import { TrialEntityType } from '../models/TrialEntityType';
import { TrialsResponse } from '../models/TrialsResponse';

interface InitiateTrialRequest {
  sku?: SKU;
  bundle_name?: `${SKU}_${SKU}`;
  entity: {
    type: TrialEntityType;
    id: string;
  };
  duration: number;
  hotjar_account_id: string;
  source: string;
}

export const initiateSiteBundleTrial = async (
  payload: InitiateTrialRequest,
  errorMessage: string
) => {
  const response = await fetch('/api/v2/adm/trials/trials/bundle', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...payload,
    }),
  });
  return handleFetchApiResponse<TrialsResponse[]>(response, errorMessage);
};

export const initiateSiteSingleTrial = async (
  payload: InitiateTrialRequest,
  errorMessage: string
) => {
  const response = await fetch('/api/v2/adm/trials/trials', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...payload,
    }),
  });
  return handleFetchApiResponse<TrialsResponse[]>(response, errorMessage);
};
