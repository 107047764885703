export const SEARCH_FIELDS = [
  {
    value: 'account_id',
    text: 'Account ID',
  },
  {
    value: 'organization_id',
    text: 'Organization ID',
  },
  {
    value: 'site_id',
    text: 'Site ID',
  },
  {
    value: 'invoice_id',
    text: 'Invoice ID',
  },
  {
    value: 'email',
    text: 'Email',
  },
  {
    value: 'email_contains',
    text: 'Email Contains',
  },
  {
    value: 'url_contains',
    text: 'URL Contains',
  },
  {
    value: 'user_id',
    text: 'User ID',
  },
  {
    value: 'bt_customer_id',
    text: 'BT Customer ID',
  },
];
