import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { useUserContext } from '../../contexts/UserContext';
import HasPermission from '../../components/HasPermission';
import ShowSpinner from '../../components/ShowSpinner';
import { getAllReports } from './api';
import AccessError from './AccessError';
import styles from './Reports.module.css';

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const userContext = useUserContext();

  useEffect(() => {
    if (userContext.roleNames.indexOf('report_access') > -1) {
      getAllReports()
        .then((data) => {
          setReports(data.reports);
          setLoading(false);
        })
        .catch((e) => {
          setError(e.error_message || e.message || e.error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [userContext.roleNames]);

  const renderList = () => {
    if (error) return <div className={styles.error}>{error}</div>;
    if (loading)
      return (
        <div data-testid="loadingSpinner">
          <ShowSpinner />
        </div>
      );
    if (userContext.roleNames.indexOf('report_access') > -1) {
      return (
        <Paper>
          <dl className={styles.reportList}>
            {reports.map((report) => {
              return (
                <HasPermission
                  rolesAccepted={report.roles_accepted}
                  key={report.report_key}
                >
                  <div className={styles.dataSet}>
                    <dt>
                      <Link to={`/reports/${report.report_key}`}>
                        {report.report_name}
                      </Link>
                    </dt>
                    <dd>{report.report_description}</dd>
                  </div>
                </HasPermission>
              );
            })}
          </dl>
        </Paper>
      );
    } else {
      return <AccessError />;
    }
  };

  return (
    <div className={styles.wrapper}>
      <h2>Reports</h2>
      {renderList()}
    </div>
  );
};

export default Reports;
