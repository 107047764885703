import React, { Component } from 'react';

import { IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import RefreshIcon from '@mui/icons-material/Refresh';
import MUIDataTable from 'mui-datatables';

import BounceRecipientsTable from './BounceRecipientsTable';
import { toUTCDateTimeString } from '../../utils/dates';

export default class SNSMessageLogTable extends Component {
  getColumnSettings = () => [
    { name: 'ID', options: { filter: false } },
    {
      name: 'Created',
      options: {
        customBodyRender: (date) => toUTCDateTimeString(date),
        filter: false,
      },
    },
    { name: 'Bounce Type' },
    { name: 'Bounce Sub Type' },
    {
      name: 'Bounced Recipients',
      options: {
        filter: false,
        customBodyRender: (bounceRecipients) => (
          <BounceRecipientsTable
            bounceRecipients={bounceRecipients}
          ></BounceRecipientsTable>
        ),
      },
    },
  ];

  getTableSettings = () => ({
    responsive: 'standard',
    selectableRows: 'none',
    rowsPerPageOptions: [10, 25, 50, 100],
    pagination: true,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false,
    filter: false,
    search: false,
    sort: true,
    customToolbar: () => {
      return (
        <div>
          <Tooltip title={'Click for more information'}>
            <IconButton onClick={this.openExternalDocs}>
              <HelpIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Refresh'}>
            <IconButton onClick={this.props.onRefresh}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
  });

  openExternalDocs = () => {
    const url =
      'https://docs.aws.amazon.com/ses/latest/DeveloperGuide/notification-contents.html#bounce-types';
    window.open(url, '_blank');
  };

  render() {
    return (
      <MUIDataTable
        title={'Email Bounces (last 60 days)'}
        data={this.props.SNSMessageLogs}
        columns={this.getColumnSettings()}
        options={this.getTableSettings()}
      />
    );
  }
}
