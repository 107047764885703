import React from 'react';
import ResourceListHeader from './ResourceListHeader';
import ResourceListItem from './ResourceListItem';

function LearningResources({
  courses,
  searchTerm,
  updateResourceList,
  selectedResources,
}) {
  const courseTypeMap = {
    courses: 'Courses',
    videos: 'Videos',
    learningPaths: 'Learning Paths',
    webinars: 'Webinars',
    workbooks: 'Workbooks',
  };
  return (
    <ul>
      {Object.keys(courses).map((key) => (
        <li key={key}>
          {!searchTerm ? (
            <ResourceListHeader isBordered searchTerm={searchTerm}>
              <h3>{courseTypeMap[key]}</h3>
            </ResourceListHeader>
          ) : (
            ''
          )}
          <ul>
            {courses[key]
              .filter((resource) =>
                resource.title.toLowerCase().includes(searchTerm)
              )
              .map((course) => (
                <li key={course.title}>
                  <ResourceListItem
                    resource={course}
                    updateResourceList={updateResourceList}
                    selected={selectedResources.some(
                      (resource) => resource.url === course.url
                    )}
                  />
                </li>
              ))}
          </ul>
        </li>
      ))}
    </ul>
  );
}

export default LearningResources;
