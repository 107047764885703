import { FC, useCallback, useMemo } from 'react';
import { MagpieBillingPeriod } from '../../../magpie/models/magpie.model';
import { Currency } from '../../../../types/types';
import SiteProduct from './components/SiteProduct';
import { useShowSiteServicePeriods } from '../../../services/api/useShowSiteServicePeriods';
import { formatDefaultServices, formatServicesData } from './utils';
import { ServicePeriods } from '../../../services/models/service.model';
import ShowSpinner from '../../../../components/ShowSpinner';
import { useShowMagpieCustomer } from '../../../magpie/api/useShowMagpieCustomer';
import { useShowMagpieProductCatalog } from '../../../magpie/api/useShowMagpieProductCatalog';
import { decorateSubscriptionWithCatalog } from './components/SiteProduct/utils';
import { ProductName } from '../../../products/models/product.model';
import { useShowAccountPricingTable } from '../../../accounts/api/useShowAccountPricingTable';

interface SiteProductsProps {
  siteId: string | undefined;
  accountId: string;
}

const SiteProducts: FC<SiteProductsProps> = ({ siteId, accountId }) => {
  const { siteServicePeriodsData, isSiteServicePeriodsLoading } =
    useShowSiteServicePeriods(siteId);
  const { magpieCustomerData, isMagpieCustomerLoading } = useShowMagpieCustomer(
    parseInt(accountId, 10)
  );
  const { magpieProductCatalogData, isMagpieProductCatalogLoading } =
    useShowMagpieProductCatalog(
      accountId,
      magpieCustomerData?.subscription?.billing_period.toLowerCase() as Lowercase<MagpieBillingPeriod>,
      magpieCustomerData?.currency.toLocaleLowerCase() as Lowercase<Currency>
    );
  const { accountPricingTableData, isAccountPricingTableLoading } =
    useShowAccountPricingTable(accountId);

  const subscriptionData = useMemo(() => {
    if (!magpieProductCatalogData || !magpieCustomerData || !siteId) {
      return null;
    }
    return decorateSubscriptionWithCatalog(
      siteId,
      magpieCustomerData,
      magpieProductCatalogData
    );
  }, [siteId, magpieProductCatalogData, magpieCustomerData]);

  const servicesData = useMemo(() => {
    if (!siteServicePeriodsData) {
      return null;
    }

    return formatServicesData(siteServicePeriodsData as ServicePeriods);
  }, [siteServicePeriodsData]);

  const getServiceForProduct = useCallback(
    (product: ProductName) =>
      servicesData?.find(({ product: productName }) => product === productName),
    [servicesData]
  );

  const getSubscriptionForProduct = useCallback(
    (product: ProductName) => {
      return subscriptionData?.find(
        (subscription) =>
          subscription.product?.toLowerCase() === product.toLowerCase()
      );
    },
    [subscriptionData]
  );

  const defaultServicesData = useMemo(() => {
    if (!accountPricingTableData) {
      return null;
    }
    return formatDefaultServices(accountPricingTableData?.default_products);
  }, [accountPricingTableData]);

  const getDefaultServiceForProduct = useCallback(
    (product: ProductName) => {
      return defaultServicesData?.find(
        (defaultService) => defaultService.product === product
      );
    },
    [defaultServicesData]
  );

  const isSubscriptionLoading = useMemo(() => {
    return isMagpieCustomerLoading || isMagpieProductCatalogLoading;
  }, [isMagpieCustomerLoading, isMagpieProductCatalogLoading]);

  if (isSiteServicePeriodsLoading || isAccountPricingTableLoading) {
    return <ShowSpinner />;
  }

  if (!servicesData && !defaultServicesData) {
    return null;
  }

  return (
    <>
      {(Object.keys(ProductName) as Array<keyof typeof ProductName>).map(
        (productKey) => {
          const product = ProductName[productKey];
          const servicesForProduct = getServiceForProduct(product);
          const defaultForProduct = getDefaultServiceForProduct(product);
          const services =
            servicesForProduct?.services || defaultForProduct?.services;

          return (
            <SiteProduct
              key={product}
              product={product}
              services={services}
              isSubscriptionLoading={isSubscriptionLoading}
              subscriptionData={getSubscriptionForProduct(product)}
            />
          );
        }
      )}
    </>
  );
};

export default SiteProducts;
