import {
  InputLabel,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { countryChoices } from '../../../utils/countries';

interface Props {
  form: { country: string; annotation: string };
  handleUpdateField: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CustomerCountryForm = ({
  form: { country, annotation },
  handleUpdateField,
}: Props) => (
  <>
    <InputLabel variant="standard" htmlFor="country" shrink>
      Country *
    </InputLabel>
    <Select
      id="country"
      label="Country"
      inputProps={{ 'aria-label': 'Country' }}
      value={country}
      onChange={handleUpdateField as (event: SelectChangeEvent<string>) => void}
      variant="standard"
      native
      fullWidth
    >
      <option value="">-</option>
      {countryChoices.map(({ label, value }) => {
        return (
          <option key={value} value={value}>
            {label}
          </option>
        );
      })}
    </Select>

    <TextField
      multiline
      fullWidth
      required
      margin="dense"
      id="annotation"
      label="Annotation"
      name="annotation"
      value={annotation}
      onChange={handleUpdateField}
      helperText="Please provide reason for change and/or Zendesk ticket link."
      variant="standard"
    />
  </>
);
