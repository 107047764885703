import { ProductName } from '../../../../../products/models/product.model';
import {
  EntityType,
  MagpieProduct,
  MagpiePurchasedProductCost,
} from '../../../../../magpie/models/magpie.model';
import { SubscriptionData } from '../../components/SiteProductSubscription';
import { MagpieCustomer } from '../../../../../magpie/models/magpie.model';
import { getSubscriptionProductDiscounts } from '../../../../../magpie/components/SubscriptionWidget/utils';

// MagpieProduct is a complex object
// This method extracts and formats the products
// as plain objects with its allowance sku and allowance and plan properties
export const getProductsFromMagpieCatalog = (
  magpieProductCatalog: MagpieProduct[]
) => {
  return magpieProductCatalog.flatMap((product) => {
    const allowancesWithProductAndPlanList = product.plans.flatMap((plan) => {
      const productAndPlan = {
        product: product.product_name as ProductName,
        plan: plan.plan_name,
      };

      const allowancesWithProductAndPlan = plan.allowances.map((allowance) => {
        const { sku, price } = allowance;
        let computedAllowance;

        if ('monthly_responses' in allowance) {
          computedAllowance = allowance.monthly_responses;
        }

        if ('daily_cc_sessions' in allowance) {
          computedAllowance = allowance.daily_cc_sessions;
        }

        return {
          sku,
          ...productAndPlan,
          costBeforeDiscount: price,
          allowance: computedAllowance,
        };
      });

      return allowancesWithProductAndPlan;
    });

    return allowancesWithProductAndPlanList;
  });
};

const getFinalCostBySku = (
  siteId: string,
  sku: string,
  purchasedProductCosts?: MagpiePurchasedProductCost[]
) => {
  return purchasedProductCosts?.find(
    ({ sku: purchasedProductSku, entity }) =>
      purchasedProductSku === sku &&
      entity.entity_type === EntityType.Site &&
      entity.entity_identifier === siteId
  )?.amount_without_tax;
};

export const decorateSubscriptionWithCatalog = (
  siteId: string,
  customerData: MagpieCustomer,
  catalogData: MagpieProduct[]
) => {
  const {
    currency,
    subscription: { billing_period, products },
    current_period_cost_summary: { purchased_product_costs },
  } = customerData;

  const productsWithPlansBySKUs = getProductsFromMagpieCatalog(catalogData);

  const decoratedProducts = products
    .filter(
      ({ entity }) =>
        entity.entity_type === EntityType.Site &&
        entity.entity_identifier === siteId
    )
    .map((product) => {
      const catalogProduct = productsWithPlansBySKUs.find(
        ({ sku }) => sku === product.sku
      );

      const deductions = getSubscriptionProductDiscounts(product, currency);
      const finalCost = getFinalCostBySku(
        siteId,
        product.sku,
        purchased_product_costs
      );

      return {
        ...catalogProduct,
        currency,
        billingPeriod: billing_period,
        finalCost,
        deductions,
      };
    });

  return decoratedProducts as SubscriptionData[];
};
