import { FC } from 'react';
import { Badge, Tooltip } from '@mui/material';
import { CalendarMonth, Block } from '@mui/icons-material';
import observe from '../../../../../../images/observe.png';
import ask from '../../../../../../images/ask.png';
import BadgeWrapper from '../BadgeWrapper';
import { Products } from '../../../../../products/models/product.model';
import styles from './ProductBadges.module.css';

const formatNumber = (num: number) => {
  if (num > 999999) return `${Math.round(num / 100000) / 10}M`;
  if (num > 999) return `${Math.round(num / 100) / 10}K`;
  return num;
};

interface ProductBadgesProps {
  products: Products;
  ubpState?: {
    limit: number;
    usage: number;
    isBlocked: boolean;
    renewDate: string;
  };
}

const ProductBadges: FC<ProductBadgesProps> = ({ products, ubpState }) => {
  const {
    session_average_seven_day: sessionAverageSevenDay,
    effective_cc_sessions: sessions,
  } = products[0];
  const isOverObserveLimit = sessionAverageSevenDay >= sessions;
  const isOverHalfObserveLimit = sessionAverageSevenDay >= sessions / 2;
  const observeTooltip = ubpState
    ? `Sessions captured: ${ubpState.usage}/${ubpState.limit}`
    : `7-day session average: ${formatNumber(
        sessionAverageSevenDay
      )}/${formatNumber(sessions)}`;

  const {
    response_count: responseCount,
    effective_monthly_responses: monthlyResponses,
  } = products[1];
  const isOverAskLimit = responseCount >= monthlyResponses;
  const isOverHalfAskLimit = responseCount >= monthlyResponses / 2;
  const askTooltip = `Responses received: ${formatNumber(
    responseCount
  )}/${formatNumber(monthlyResponses)}`;

  return (
    <>
      {ubpState && ubpState.isBlocked && (
        <Tooltip title="UBP: Allowance reached">
          <BadgeWrapper>
            <Badge badgeContent="!" color="error">
              <Block />
            </Badge>
          </BadgeWrapper>
        </Tooltip>
      )}
      {ubpState && ubpState.renewDate && (
        <Tooltip title={`UBP cycle renew date: ${ubpState.renewDate}`}>
          <BadgeWrapper>
            <Badge
              badgeContent={ubpState.isBlocked ? '!' : 'i'}
              color={ubpState.isBlocked ? 'error' : 'primary'}
            >
              <CalendarMonth />
            </Badge>
          </BadgeWrapper>
        </Tooltip>
      )}
      <Tooltip title={observeTooltip}>
        <BadgeWrapper>
          <Badge
            badgeContent={isOverObserveLimit ? '!' : 'i'}
            color={isOverObserveLimit ? 'error' : 'primary'}
            className={isOverHalfObserveLimit ? styles.warning : ''}
          >
            <img src={observe} alt="" />
          </Badge>
        </BadgeWrapper>
      </Tooltip>
      <Tooltip title={askTooltip}>
        <BadgeWrapper>
          <Badge
            badgeContent={isOverAskLimit ? '!' : 'i'}
            color={isOverAskLimit ? 'error' : 'primary'}
            className={isOverHalfAskLimit ? styles.warning : ''}
          >
            <img src={ask} alt="" />
          </Badge>
        </BadgeWrapper>
      </Tooltip>
    </>
  );
};

export default ProductBadges;
