import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { KeyboardEvent, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useToasts } from 'react-toast-notifications';
import { AdmModalProps } from '../../../../components/modals/AdmModalRoot';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import type { Moment } from 'moment';
import styles from './AddServicePeriod.module.css';
import { AddServicePeriodProps, addServicePeriod } from './api';
import { invalidateServicePeriodsQuery } from '../../../../domains/services/api/useShowSiteServicePeriods';

type AddServicePeriodModalProps = {
  siteId: string;
  accountId: string;
};

type ProvisionError = {
  error?: string;
};

const StyledTypography = styled(Typography)`
  margin-bottom: 16px;
`;

const LIMIT_CHOICES = [
  '500',
  '1000',
  '2500',
  '5000',
  '7500',
  '10000',
  '25000',
  '50000',
  '100000',
  '250000',
  '500000',
  '750000',
  '1000000',
];

const DateField = ({
  value,
  label,
  error,
  onChange,
}: {
  value: string;
  label: string;
  error?: string;
  onChange: (value: Moment | null) => void;
}) => {
  const dateValue = useMemo(
    () => (value === null ? undefined : moment(value)),
    [value]
  );
  return (
    <DesktopDatePicker
      label={label}
      value={dateValue}
      onChange={onChange}
      inputFormat="YYYY-MM-DD"
      renderInput={(params) => (
        <TextField
          {...params}
          className={styles.inputField}
          data-hj-suppress
          required
          variant="standard"
          error={error !== undefined}
          helperText={error}
          inputProps={{ ...params.inputProps, 'aria-label': label }}
        />
      )}
    />
  );
};

const DEFAULT_DATA: AddServicePeriodProps = {
  limit: LIMIT_CHOICES[1],
  start_date: moment().format('YYYY-MM-DD'),
  end_date: moment().add(1, 'year').format('YYYY-MM-DD'),
};

export const AddServicePeriodModal = ({
  onCloseModal,
  props: { siteId, accountId },
}: AdmModalProps<AddServicePeriodModalProps>) => {
  const { addToast } = useToasts();

  const [formData, setFormData] = useState<AddServicePeriodProps>(DEFAULT_DATA);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const stopPropagationForTab = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await addServicePeriod(formData, siteId, accountId);

      if (response?.success === true) {
        addToast('Service period created', { appearance: 'success' });

        invalidateServicePeriodsQuery(siteId);
        onCloseModal();
      } else {
        addToast('There was an error while creating service period', {
          appearance: 'error',
        });
      }
    } catch (error) {
      const errorText = (error as ProvisionError)?.error;

      addToast(
        errorText ||
          'There was an error while attempting the service period creation requests',
        { appearance: 'error' }
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateDateField = (value: Moment | null, id: string) => {
    const formattedDate =
      value === null ? undefined : value.format('YYYY-MM-DD');
    setFormData({ ...formData, [id]: formattedDate });
  };

  const isEndDateAfterStartDate = useMemo(
    () => moment(formData.end_date).isAfter(moment(formData.start_date)),
    [formData.start_date, formData.end_date]
  );

  const isDataReadyForSubmission = useMemo(
    () =>
      Object.values(formData).every(
        (e) => typeof e !== 'string' || e.length > 0
      ) &&
      moment(formData.start_date).isValid() &&
      moment(formData.end_date).isValid() &&
      isEndDateAfterStartDate,
    [formData, isEndDateAfterStartDate]
  );

  const handleUpdateSelect = (event: SelectChangeEvent<string>, id: string) => {
    setFormData({ ...formData, [id]: event.target.value });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open
      onClose={onCloseModal}
      onKeyDown={stopPropagationForTab}
    >
      <DialogTitle>Add service period</DialogTitle>
      <DialogContent>
        <StyledTypography>
          Account ID: <span data-hj-suppress>{accountId}</span> - Site ID:{' '}
          <span data-hj-suppress>{siteId}</span>
        </StyledTypography>

        <form onSubmit={handleSubmit} className={styles.form}>
          <Stack direction="row" flexWrap="wrap" gap={1}>
            <FormControl required className={styles.inputField}>
              <InputLabel id="limit-label" htmlFor="limit">
                Monthly Limit
              </InputLabel>
              <Select
                inputProps={{ 'aria-labelledby': 'limit-label' }}
                labelId="limit-label"
                label="Monthly Limit"
                name="limit"
                id="limit"
                value={formData.limit}
                onChange={(e) => handleUpdateSelect(e, 'limit')}
                variant="standard"
                native
              >
                {LIMIT_CHOICES.map((value) => {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                })}
              </Select>
            </FormControl>

            <DateField
              value={formData.start_date}
              label="Start date"
              onChange={(value) => handleUpdateDateField(value, 'start_date')}
            />
            <DateField
              value={formData.end_date}
              label="End date"
              onChange={(value) => handleUpdateDateField(value, 'end_date')}
              error={
                isEndDateAfterStartDate ||
                formData.start_date === '' ||
                formData.end_date === ''
                  ? undefined
                  : 'End date must be after start date'
              }
            />
          </Stack>
          <Button
            variant="contained"
            color="primary"
            id="create"
            className={styles.createButton}
            type="submit"
            disabled={!isDataReadyForSubmission || isSubmitting}
          >
            Create
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
