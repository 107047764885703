import React, { Component } from 'react';
import { Dialog } from '@mui/material';

import DomainSitesTable from './DomainSitesTable';
import DomainUsersTable from './DomainUsersTable';

import { MODAL_DOMAIN_SEARCH } from '../constants';
import { withModalContext } from '../../../contexts/ModalContext';
import ShowSpinner from '../../ShowSpinner';
import { DialogTitleWithClose } from '../../DialogTitleWithClose';
import { getCustomers } from './api';
import { fetchDomainData } from './utils';

class DomainSearchModal extends Component {
  state = {
    loadCustomersError: undefined,
    loadEntitiesError: undefined,
    domainEntities: undefined,
    customers: undefined,
    loadingEntities: false,
    loadingCustomers: false,
  };

  setLoadingState = () => {
    this.setState({
      loadingEntities: true,
      loadingCustomers: false,
      loadCustomersError: undefined,
      loadEntitiesError: undefined,
      domainEntities: undefined,
      customers: undefined,
    });
  };

  async componentDidUpdate(prevProps) {
    const modalContext = this.props.modalContext;
    const modalProps = modalContext.openModalProps[MODAL_DOMAIN_SEARCH];
    const isOpen = modalContext.isModalOpen(MODAL_DOMAIN_SEARCH);
    const wasOpen =
      prevProps.modalContext.currentlyOpenModals.indexOf(MODAL_DOMAIN_SEARCH) >
      -1;
    if (!wasOpen && isOpen) {
      this.setLoadingState();

      let entitiesResponse = [];

      try {
        entitiesResponse = await fetchDomainData(
          modalProps.searchBy,
          modalProps.searchTerm
        );
      } catch (error) {
        this.setState({
          loadEntitiesError: error.error_message || error.error,
        });
      } finally {
        this.setState({
          domainEntities: entitiesResponse,
          loadingEntities: false,
        });
      }

      this.setState({ loadingCustomers: true });

      try {
        const customers = await getCustomers(
          entitiesResponse.map(({ account_id }) => account_id)
        );
        this.setState({ customers });
      } catch (error) {
        this.setState({
          loadCustomersError:
            error.error_message || error.error || error.message,
        });
      } finally {
        this.setState({ loadingCustomers: false });
      }
    }
  }

  closeModal = () => {
    const modalContext = this.props.modalContext;
    modalContext.closeModal(MODAL_DOMAIN_SEARCH);
  };

  renderTable = () => {
    const modalContext = this.props.modalContext;
    const modalProps = modalContext.openModalProps[MODAL_DOMAIN_SEARCH];
    if (modalProps.searchBy === 'url_contains') {
      return (
        <DomainSitesTable
          sites={this.state.domainEntities}
          searchTerm={modalProps.searchTerm}
          customerData={this.state.customers}
          customerDataError={this.state.loadCustomersError}
          isLoadingCustomers={this.state.loadingCustomers}
        />
      );
    } else {
      return (
        <DomainUsersTable
          users={this.state.domainEntities}
          searchTerm={modalProps.searchTerm}
          customerData={this.state.customers}
        />
      );
    }
  };

  render() {
    const modalContext = this.props.modalContext;
    const modalProps = modalContext.openModalProps[MODAL_DOMAIN_SEARCH];
    const isOpen = modalContext.isModalOpen(MODAL_DOMAIN_SEARCH);

    if (isOpen && modalProps) {
      return (
        <Dialog
          maxWidth="lg"
          scroll="paper"
          open={isOpen}
          fullWidth={true}
          onClose={this.closeModal}
          disableEnforceFocus={true}
        >
          <DialogTitleWithClose
            id="alert-dialog-title"
            onClose={this.closeModal}
            dataId={`domainSearchResults_${modalProps.searchBy}`}
          >
            {`Showing results for "${modalProps.searchTerm}"`}
          </DialogTitleWithClose>

          {this.state.loadingEntities && <ShowSpinner />}
          {!this.state.loadingEntities && this.state.loadEntitiesError && (
            <span className="error">{this.state.loadEntitiesError}</span>
          )}
          {!this.state.loadEntitiesError &&
            this.state.domainEntities &&
            this.renderTable()}
        </Dialog>
      );
    } else {
      return null;
    }
  }
}

export default withModalContext(DomainSearchModal);
