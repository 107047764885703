/**
 * Transforms a snake case string into a title case string
 *
 * @param s: the snake_case string
 * @returns human readable title case string
 */
export const snakeToTitleCase = (s: string) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase()
  );
