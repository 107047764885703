import { Grid } from '@mui/material';
import { Cost } from '../../SubscriptionCreationModal/Cost';
import { Costs } from '../types';

interface Props {
  costs: Costs;
  isPreviewLoading: boolean;
  currency: 'eur' | 'usd';
}
export const CostPreview = ({ costs, isPreviewLoading, currency }: Props) => {
  return (
    <>
      <Grid item xs={6}>
        <Cost
          subTotal={costs.payNow.subTotal}
          taxPercentage={costs.payNow.taxPercentage}
          total={costs.payNow.total}
          isPreviewLoading={isPreviewLoading}
          currency={currency}
          textAlign={'left'}
          title="Pay Now"
        />
      </Grid>
      <Grid item xs={6}>
        <Cost
          subTotal={costs.renewWith.subTotal}
          taxPercentage={costs.renewWith.taxPercentage}
          total={costs.renewWith.total}
          isPreviewLoading={isPreviewLoading}
          currency={currency}
          textAlign={'left'}
          title="Renew With"
        />
      </Grid>
    </>
  );
};
